import { useMediaQuery, Theme } from "@material-ui/core";
import { useMemo } from "react";

type headerString =
  | "inherit"
  | "button"
  | "overline"
  | "caption"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "srOnly"
  | undefined;

function getHeaderSizes(
  smDown: boolean,
  mdDown: boolean,
  xsDown: boolean
): [headerString, headerString] {
  if (xsDown) {
    return ["h5", "caption"];
  }

  if (smDown) {
    return ["h4", "body1"];
  }

  if (mdDown) {
    return ["h3", "h5"];
  }

  return ["h4", "h6"];
}

const useHeaderVariant = (): [headerString, headerString] => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const [header, subHeader] = useMemo(
    () => getHeaderSizes(smDown, mdDown, xsDown),
    [smDown, mdDown, xsDown]
  );

  return [header, subHeader];
};

export { useHeaderVariant };
export default useHeaderVariant;
