import {
  DesignProfileQuestion,
  DesignProfileQuestionResponse,
} from "../../../../api/apiTypes";
/**
 * Getting the questions and responses for the current page
 *
 * @param {DesignProfileQuestion[]} questions An array of design profile question objects
 * @param {string} path The path of the current page
 * @return {Pick<DesignProfileQuestion, "response">} A response value. Can be a primitive or object.
 */
export const getResponseForCurrentQuestion = (
  designProfileResponses: DesignProfileQuestion[],
  path: string
): DesignProfileQuestionResponse => {
  const defaultResponse = {};

  if (designProfileResponses.length) {
    return (
      designProfileResponses.find(questionGroup => questionGroup.path === path)
        ?.response ?? defaultResponse
    );
  }

  return defaultResponse;
};
