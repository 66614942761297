import React, { FC, ReactElement, useMemo, useState } from "react";
import {
  GenericLoadingSpinner,
  PaintColorSelectCard,
  YZTypography,
} from "@yardzen-inc/react-common";
import {
  Box,
  makeStyles,
  MenuItem,
  MenuItemProps,
  Theme,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { usePaintColors } from "./api/usePaintColors";
import { Color } from "@yardzen-inc/colors";
import PaintPickerSelect from "../helpers/PaintPickerSelect";
import GenericSnackBar from "../../utility/GenericSnackBar";

export interface ExteriorDesignPaintPickerProps {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  colorsContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    maxWidth: "1220px",
    paddingRight: ".5rem",
    paddingLeft: ".5rem",
    paddingTop: "1rem",
    paddingBottom: "2rem",
    position: "sticky",
    [theme.breakpoints.down("sm")]: {
      height: undefined,
    },
  },
  filterSelectsContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.background.default,
    alignSelf: "flex-start",
    position: "sticky",
    top: 0,
    width: "100%",
    maxWidth: "1220px",
    zIndex: 3,
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
    },
  },
  checkButtonContainer: {
    margin: 8,
    marginTop: 18,
  },
}));

type ColorCategory = "blacks" | "whites" | "greys" | "greens" | "";

const PLACEMENT_OPTIONS = [
  "Main Color",
  "Trim Color",
  "Door Color",
  "Not Sure",
];

let colorCategoryMemory: Set<string> = new Set();

const ExteriorDesignPaintPicker: FC<ExteriorDesignPaintPickerProps> = () => {
  const classes = useStyles();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const [categoryFilter, setCategoryFilter] = useState<ColorCategory>("");
  const [viewFilter, setViewFilter] = useState<boolean>(false);

  const {
    colors: paintColors,
    selectedColors,
    isReady,
    errorMessage,
    selectColor,
    removeColor,
    clearError,
  } = usePaintColors(categoryFilter || undefined);

  const uniqueColorCategories = useMemo(filterUniqueColorCategories, [
    paintColors,
  ]);

  if (!isReady) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <GenericLoadingSpinner />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      {/* Header */}
      <Box px={2}>
        <YZTypography
          variant={smDown ? "h5" : "h4"}
          type="serif"
          align="center"
        >
          Which paint colors would you like to use on your home?
        </YZTypography>
      </Box>

      {/* Filters */}
      <Box className={classes.filterSelectsContainer}>
        <PaintPickerSelect
          value={categoryFilter}
          onChange={e => setCategoryFilter(e.target.value as ColorCategory)}
          label="Filter"
          idPrefix="color-category-select"
        >
          {renderPaintCategoryMenuItems()}
        </PaintPickerSelect>
        <FormControlLabel
          className={classes.checkButtonContainer}
          control={
            <Checkbox
              checked={viewFilter}
              onChange={handleMyPicksCheckBoxSelect}
              name="viewFilter"
              color="primary"
            />
          }
          label="Show My Picks Only"
        />
      </Box>

      {/* Color Select Cards */}
      <Box className={classes.colorsContainer}>{renderPaintColorCards()}</Box>

      {/* Error Message Snackbar */}
      <GenericSnackBar
        in={!!errorMessage}
        message={errorMessage}
        variant="error"
        onClose={clearError}
        timeout={null}
      />
    </Box>
  );

  function handleMyPicksCheckBoxSelect() {
    setCategoryFilter("");
    setViewFilter(!viewFilter);
  }

  function renderPaintCategoryMenuItems(): ReactElement<MenuItemProps>[] {
    const items: ReactElement<MenuItemProps>[] = [
      <MenuItem value="" key="color-category-select-item-all">
        All Colors
      </MenuItem>,
    ];

    uniqueColorCategories.forEach(cat => {
      items.push(
        <MenuItem value={cat} key={`color-category-select-item-${cat}`}>
          {cat}
        </MenuItem>
      );
    });

    return items;
  }

  function renderPaintColorCards() {
    if (!paintColors.length) {
      return <GenericLoadingSpinner size={75} />;
    }

    return paintColors.map(renderColorSelectCard);
  }

  function renderColorSelectCard(color: Color) {
    if (
      viewFilter &&
      // TODO: pre sort color cards and ∩ selected color cards to different set to avoid O(n^2)
      !selectedColors.some(sel => sel.colorId === color.id)
    ) {
      return null;
    }

    const placement = getSelectedPlacement(color);
    return (
      <Box p={2} key={`${color.id}-key`}>
        <PaintColorSelectCard
          name={color.name as string}
          colorHex={color.hexCode as string}
          // TODO - dynamically set isDark
          isDark={false}
          selectedPlacement={placement}
          placementOptions={PLACEMENT_OPTIONS}
          selectColor={handleSelectColor}
          removeColor={handleRemoveColor}
        />
      </Box>
    );
  }

  function getSelectedPlacement(color: Color): string | null {
    const selectedPlacement = selectedColors.find(
      col => col.colorId === color.id
    )?.placement;

    return selectedPlacement ?? null;
  }

  async function handleSelectColor(hexCode: string, placement: string) {
    const color = paintColors.find(col => col.hexCode === hexCode);
    await selectColor(color?.id!, placement);
  }

  async function handleRemoveColor(hexCode: string) {
    const color = paintColors.find(col => col.hexCode === hexCode);
    await removeColor(color?.id!);
  }

  function filterUniqueColorCategories(): Set<string> {
    const newSet = new Set(colorCategoryMemory);

    if (!paintColors) {
      return newSet;
    }

    paintColors.forEach(color => {
      if (color.category) {
        newSet.add(color.category);
      }
    });

    colorCategoryMemory = newSet;

    return newSet;
  }
};

export { ExteriorDesignPaintPicker };
export default ExteriorDesignPaintPicker;
