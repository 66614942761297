import React, { FC } from "react";
import { Stepper, Step, StepLabel, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ProgressBarIcons } from "./ProgressBarIcons";
import classNames from "classnames";

export interface DesignProfileProgressBarProps {
  activeStep: number;
  disableOnMobile?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  stepper: {
    backgroundColor: "transparent",
    width: "460px",
  },
  disableOnMobile: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

const DesignProfileProgressBar: FC<DesignProfileProgressBarProps> = ({
  activeStep,
  disableOnMobile,
}) => {
  const classes = useStyles();
  const steps = [1, 2, 3, 4];

  return (
    <>
      <div
        className={classNames(
          classes.root,
          disableOnMobile ? classes.disableOnMobile : ""
        )}
      >
        <Stepper
          alternativeLabel
          activeStep={activeStep - 1}
          className={classes.stepper}
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel StepIconComponent={ProgressBarIcons} />
            </Step>
          ))}
        </Stepper>
      </div>
    </>
  );
};

export { DesignProfileProgressBar };
