import { useState, useEffect, Dispatch, SetStateAction } from "react";

interface StringKeyObj {
  [key: string]: string;
}

interface BudgetRedirectDictionaryInterface extends StringKeyObj {
  review: "wish list";
  complete: "navigation";
}

const BudgetRedirectDictionary: BudgetRedirectDictionaryInterface = {
  review: "wish list",
  complete: "navigation",
};

const useGetBudgetRedirectUrlAndBackText: (
  defaultPath: string,
  defaultBackText: string
) => [string, string] = (defaultPath, defaultBackText) => {
  const search = new URLSearchParams(window.location.search);
  const back = search.get("back");

  const [redirectUrl, setRedirectUrl] = useState<string>(defaultPath);
  const [backText, setBackText]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState<string>(defaultBackText);

  useEffect(() => {
    if (back && !!BudgetRedirectDictionary[back]) {
      setRedirectUrl(`/onboard/budget/${back}`);
      setBackText(BudgetRedirectDictionary[back]);
    }
  }, [back]);
  // hey Nick
  return [redirectUrl, backText];
};

export { useGetBudgetRedirectUrlAndBackText };
export default useGetBudgetRedirectUrlAndBackText;
