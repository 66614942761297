import React, { FC, ReactNode } from "react";
import {
  MultipleImageSelectItemContainer,
  StyleSelectItem,
} from "@yardzen-inc/react-common";
import { useBudgetChecklistStyleOptionsMinimalByPriceOptionIdQuery } from "@yardzen-inc/graphql";
import { useLogError } from "../../../util";
import { CircularProgress } from "@material-ui/core";

export interface StyleOptionSelectProps {
  onSelect: (id: string) => void;
  selectedItem: string;
  priceOptionId: string;
}

const StyleOptionSelect: FC<StyleOptionSelectProps> = ({
  priceOptionId,
  selectedItem,
  onSelect,
}) => {
  const {
    data: optionData,
    error: optionError,
    loading,
  } = useBudgetChecklistStyleOptionsMinimalByPriceOptionIdQuery({
    variables: {
      priceOptionId,
    },
    fetchPolicy: "cache-and-network",
  });

  useLogError(optionError as Error);

  if (loading) {
    return <CircularProgress></CircularProgress>;
  }

  return (
    <MultipleImageSelectItemContainer>
      {renderItems()}
    </MultipleImageSelectItemContainer>
  );

  function renderItems(): ReactNode {
    if (!optionData?.budget_checklist_style_option.length) return null;

    return optionData.budget_checklist_style_option.map(item => {
      let image: string = "";
      let thumbnail: string | undefined = void 0;

      if (item.image?.image_variants.length) {
        for (const variant of item.image.image_variants) {
          if (!!image && !!thumbnail) break;

          if (variant.type === "ORIGINAL") {
            image = variant.medium.public_uri ?? "";
          } else if (variant.type === "THUMBNAIL") {
            thumbnail = variant.medium.public_uri ?? void 0;
          }
        }
      }

      return (
        <StyleSelectItem
          name={""}
          key={item.id}
          imageUri={image}
          thumbnailUri={thumbnail}
          itemId={item.id}
          onSelect={() => onSelect(item.id)}
          selected={selectedItem === item.id}
        />
      );
    });
  }
};

export { StyleOptionSelect };
export default StyleOptionSelect;
