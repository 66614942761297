import { IAddressObject } from "../../../util/functions/parseGeocodeLocationToAddress";
import { ProfileProperties } from "@yardzen-inc/models";

export type confirmInfoModalGetInformationFromProfileProps = {
  profile: ProfileProperties;
  setFirstName: (value: React.SetStateAction<string>) => void;
  setLastName: (value: React.SetStateAction<string>) => void;
  setPhoneNumber: (value: React.SetStateAction<string>) => void;
  setInformation: (value: React.SetStateAction<IAddressObject>) => void;
};

export const confirmInfoModalGetInformationFromProfile = ({
  profile,
  setFirstName,
  setLastName,
  setPhoneNumber,
  setInformation,
}: confirmInfoModalGetInformationFromProfileProps): void => {
  const {
    firstName,
    lastName,
    street,
    state,
    city,
    zip,
    formattedAddress,
    phone,
  } = profile;

  setFirstName(firstName || "");
  setLastName(lastName || "");

  setPhoneNumber(phone || "");

  setInformation({
    street: street || "",
    state: state || "",
    city: city || "",
    zip: zip || "",
    formattedAddress: formattedAddress || "",
    country: "",
    county: "",
  });
};
