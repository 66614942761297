import React, { FC } from "react";
import {
  BoxProps,
  Box,
  CircularProgress,
  CircularProgressProps,
} from "@material-ui/core";

export interface BoxLoadingSpinnerProps {
  boxProps?: BoxProps;
  spinnerProps?: CircularProgressProps;
}

const BoxLoadingSpinner: FC<BoxLoadingSpinnerProps> = ({
  boxProps,
  spinnerProps,
}) => {
  return (
    <Box
      {...boxProps}
      display={boxProps?.display || "flex"}
      justifyContent={boxProps?.justifyContent || "center"}
      m={boxProps?.m || boxProps?.margin || "50px"}
    >
      <CircularProgress
        color={spinnerProps?.color || "primary"}
        size={spinnerProps?.size || 75}
        {...spinnerProps}
      />
    </Box>
  );
};

export { BoxLoadingSpinner };
export default BoxLoadingSpinner;
