import { useMediaQuery, Theme } from "@material-ui/core";

const useHeaderHeight = (): number => {
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));

  return xsDown ? 40 : 50;
};

export { useHeaderHeight };
export default useHeaderHeight;
