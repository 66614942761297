import React, {
  FC,
  useMemo,
  useState,
  useEffect,
  useRef,
  useContext,
  useLayoutEffect,
} from "react";
import { Box, Fade, makeStyles, Theme, useTheme } from "@material-ui/core";
import { ChecklistListTitle } from "./ChecklistListTitle";
import {
  useApolloClient,
  BUDGET_CHECKLIST_ITEMS_MINIMAL_PUBLISHED_BY_CHECKLIST_ID,
  useBudgetMetadataAndPhasingByProjectIdQuery,
  useUpdateBudgetMetadataMutation,
} from "@yardzen-inc/graphql";
import {
  BudgetCategory,
  BudgetItem,
  useTriggerTimeout,
  GenericLoadingSpinner,
  YZButton,
} from "@yardzen-inc/react-common";
import { useHistory } from "react-router";
import BudgetElementCategoryListWithResponseSubs from "./BudgetElementCategoryListWithResponseSubs";
import { ItemResponseCtx, ChecklistItem } from "./ItemResponseContext";
import useGetBudgetRedirectUrlAndBackText from "./useGetBudgetRedirectUrl";
import SaveBar from "../helpers/SaveBar";
import useAllowAccessToExteriorDesignStep from "../useAllowAccessToExteriorDesignStep";
import { BudgetStepCtx, ChecklistItemHistoryState } from ".";
import { BudgetIntent } from "../../../pages/onboard/budget/WishListPrioritizePageBudgetIntent";
import { Project } from "@yardzen-inc/models";
import useBudgetChecklistPriceEstimate from "./useBudgetChecklistPriceEstimate";
import { useFoundationalBudgetCostMultiplier } from "./useFoundationalBudgetCostMultiplier";
import { calculateBudgetRange } from "./calculateBudgetRange";
import { calculatePercentileOfRange } from "../../../util/funcitons/calculatePercentileOfRange/calculatePercentileOfRange";
import GenericSnackBar from "../../utility/GenericSnackBar";
import { getSortedWishlistCategories } from "../util/getSortedWishlistCategories";

export interface ChecklistListProps {}

const extDesignCategoryPattern = /exterior design/i;

const useStyles = makeStyles(({ breakpoints, palette, spacing }: Theme) => ({
  headerContainer: {
    backgroundColor: palette.background.default,
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    width: "100%",
    bgcolor: "inherit",
    zIndex: 999,
    position: "sticky",
    top: 0,
  },
  button: {
    background: "none",
    color: "#4C4C4C",
    "& span": {
      letterSpacing: "1px",
      fontSize: 12,
    },
  },
  saveBarContainer: {
    width: "calc(100% - 580px)",
    textAlign: "center",
    position: "fixed",
    bottom: "1.5rem",
    [breakpoints.down("md")]: {
      position: "relative",
      width: "100%",
      padding: "2rem",
    },
  },
}));

let cachedCategories: BudgetCategory[] | null = null;

const ChecklistList: FC<ChecklistListProps> = props => {
  const theme = useTheme();
  const history = useHistory();
  const client = useApolloClient();
  const containerRef = useRef<HTMLDivElement>(null);
  const showExtDesignItems = useAllowAccessToExteriorDesignStep();

  const classes = useStyles();

  const [redirectUrl, backText] = useGetBudgetRedirectUrlAndBackText("", "");
  const redirectToRef = useRef<string>("");
  const { triggerTimeout, triggered } = useTriggerTimeout(
    () => history.push(redirectToRef.current),
    100
  );

  const [checklistCategories, setChecklistCategories] = useState<
    null | BudgetCategory[]
  >(cachedCategories);

  const [checklistItemMap, { itemsLoading, responsesLoading }] = useContext(
    ItemResponseCtx
  );

  const {
    user: { id: userId },
    project: { id: projectId },
  } = useContext(BudgetStepCtx);

  // We use the default cache-first fetch policy because we're really only
  // interested in the budget_metadata's id, and we don't expect that to change
  const {
    data: budgetMetadataQueryResult,
  } = useBudgetMetadataAndPhasingByProjectIdQuery({ variables: { projectId } });

  const budgetMetadataId = useMemo(() => {
    if (budgetMetadataQueryResult?.budget_metadata?.[0]?.id) {
      return budgetMetadataQueryResult.budget_metadata[0].id;
    }

    return "";
  }, [budgetMetadataQueryResult]);

  // We need the client's budget intent to determine whether or not
  // a wishlist modification should retrigger a budget calculation.
  const [clientBudgetIntent, setClientBudgetIntent] = useState<BudgetIntent>(
    null
  );

  // We'll need these price estimates so that we can recalculate the budget
  // if the wishlist items were modified after previously submitting budget.
  const [wishlistPriceEstimates] = useBudgetChecklistPriceEstimate(projectId);
  const [
    lowEstimateMultiplier,
    highEstimateMultiplier,
  ] = useFoundationalBudgetCostMultiplier();

  const [lowAllInEstimate, highAllInEstimate] = useMemo(() => {
    const lowWishlistEstimate = wishlistPriceEstimates?.[0] ?? 0;
    const highWishlistEstimate = wishlistPriceEstimates?.[1] ?? 0;

    // We add one to the multipliers below becuase the all in estimates are
    // greater than the wishlist estimates.
    return calculateBudgetRange(
      [lowWishlistEstimate, highWishlistEstimate],
      1 + lowEstimateMultiplier,
      1 + highEstimateMultiplier
    );
  }, [wishlistPriceEstimates, lowEstimateMultiplier, highEstimateMultiplier]);

  const [
    updateBudgetMetadata,
    { error: updateBudgetMetadataError },
  ] = useUpdateBudgetMetadataMutation();

  // TODO: handle situation in which query comes back empty;
  // TODO: handle error accordingly

  useLayoutEffect(onRender, [containerRef]);
  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onChecklistItemDatChange, [checklistItemMap, showExtDesignItems]);

  useEffect(() => {
    // We need this avoid unmounted state updates that could lead to memory
    // leaks.
    let isMounted = true;

    (async () => {
      if (userId) {
        try {
          // We need to fetch with the profile id instead of the project
          // id because the project id fetch will not return the budget intent
          const project = await Project.fetchWithProfileId(userId);

          if (project.clientBudgetIntent && isMounted) {
            setClientBudgetIntent(
              project.clientBudgetIntent.toString() as BudgetIntent
            );
          }
        } catch (error) {
          window.newrelic.noticeError(error);
          console.error(error);
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [userId]);

  useEffect(() => {
    (async () => {
      const wishlistItemsModified = history.location.state
        ? (history.location.state as ChecklistItemHistoryState)
            .wishlistItemsModified
        : false;

      const isEstimateRangeValid = highAllInEstimate - lowAllInEstimate > 0;

      // We check for the client budget intent and budgetMetadata because if they
      // exist then we know the client has a previously set budget. We check
      // the variables themselves so we don't run into a TS error with the intent
      if (
        wishlistItemsModified &&
        clientBudgetIntent &&
        budgetMetadataId &&
        isEstimateRangeValid
      ) {
        const calculatedUpdatedTotalBudget = calculatePercentileOfRange(
          clientBudgetIntent,
          [lowAllInEstimate, highAllInEstimate]
        );

        try {
          await updateBudgetMetadata({
            variables: {
              design_project_for_single_phase: true,
              phasing: false,
              project_id: projectId,
              total_budget: calculatedUpdatedTotalBudget,
              id: budgetMetadataId,
            },
          });
        } catch (error) {
          window.newrelic.noticeError(error);
          // We only log the error here because the error object provided
          // by our graphgql function will signify if an error occurred.
          console.error(error);
        }
      }
    })();
  }, [
    history.location.state,
    clientBudgetIntent,
    budgetMetadataId,
    lowAllInEstimate,
    highAllInEstimate,
    projectId,
    updateBudgetMetadata,
  ]);

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderedCategories = useMemo(onChecklistCategoryChange, [
    checklistCategories,
  ]);

  const userHasSelectedItems: boolean = useMemo(() => {
    if (!checklistItemMap) return false;

    return Object.keys(checklistItemMap).some(
      id => !!checklistItemMap[id].response
    );
  }, [checklistItemMap]);

  return (
    <>
      <GenericSnackBar
        variant="error"
        message={
          "An error occurred when updating your budget from your" +
          "wishlist modifications. Please try again or contact support."
        }
        in={
          updateBudgetMetadataError !== null &&
          updateBudgetMetadataError !== undefined
        }
        // We don't maintain a seperate state variable for the open state, so
        // we don't need to provide an onClose function that actually does anything.
        onClose={() => 0}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: theme.spacing(2),
          alignItems: "center",
          maxWidth: "730px",
          margin: "0 auto 3rem",
        }}
      >
        {backText && (
          <Box className={classes.headerContainer}>
            <YZButton
              className={classes.button}
              onClick={handleBackClick}
              // @ts-ignore
              slim
              size="small"
            >
              <>{`← Back to ${backText}`}</>
            </YZButton>
          </Box>
        )}
        <Fade in={!triggered} timeout={{ exit: 100, enter: 100 }} mountOnEnter>
          <Box
            width="100%"
            maxWidth="1080px"
            display="flex"
            flexDirection="column"
            p={2}
            pt={0}
          >
            <ChecklistListTitle />
            {componentIsLoading() && <GenericLoadingSpinner size={75} />}
            <Box pt={2}>{renderedCategories}</Box>
          </Box>
        </Fade>
      </div>
      <SaveBar
        label={
          userHasSelectedItems
            ? "Submit and review budget →"
            : "Select some items to continue..."
        }
        disabled={!userHasSelectedItems}
        onClick={handleSubmit}
      />
    </>
  );

  function onRender(): void {
    try {
      window.scrollTo({ top: 0 });
    } catch {}
    try {
      document.documentElement.scrollTo({ top: 0 });
    } catch {}
    if (containerRef.current) {
      try {
        containerRef.current.scrollTo({ top: 0 });
      } catch {}
    }
  }

  function componentIsLoading(): boolean {
    return (
      itemsLoading && responsesLoading && !checklistItemMap && !cachedCategories
    );
  }

  function onChecklistCategoryChange() {
    return renderBudgetCategories(checklistCategories);
  }

  function onChecklistItemDatChange() {
    void (async function() {
      if (!checklistItemMap) return;
      const data = Object.values(checklistItemMap).map(val => val.item);

      client.writeQuery({
        query: BUDGET_CHECKLIST_ITEMS_MINIMAL_PUBLISHED_BY_CHECKLIST_ID,
        data,
      });

      const checklistCategories = associateChecklistItemsByCategory(data);
      const sortedCategories = getSortedWishlistCategories(checklistCategories);

      setChecklistCategories(sortedCategories);
    })();
  }

  function associateChecklistItemsByCategory(
    items: ChecklistItem[]
  ): BudgetCategory[] {
    if (!items) {
      return [];
    }

    const categories: {
      [name: string]: BudgetCategory & {
        description?: string;
      };
    } = {};

    for (let item of items) {
      if (
        !showExtDesignItems &&
        extDesignCategoryPattern.test(item.budget_checklist_category.name ?? "")
      ) {
        continue;
      }

      const budgetItem: Partial<BudgetItem & {
        description?: string;
      }> = {};

      const categoryName = item.budget_checklist_category.name ?? "Other";

      budgetItem.id = item.id;
      budgetItem.name = item.name;

      budgetItem.description = item.description ?? "";

      if (!categories[categoryName]) {
        categories[categoryName] = {
          name: categoryName,
          description: item.budget_checklist_category.description ?? void 0,
          items: [budgetItem as BudgetItem],
        };
      } else {
        categories[categoryName].items.push(budgetItem as BudgetItem);
      }
    }

    cachedCategories = Object.values(categories).sort((a, b) => {
      if (a.name === b.name) {
        return 0;
      }

      return a.name < b.name ? -1 : 1;
    });

    return cachedCategories;
  }

  function renderBudgetCategories(categories: BudgetCategory[] | null) {
    if (!categories) return null;

    return categories.map(category => (
      <BudgetElementCategoryListWithResponseSubs
        category={category}
        onItemClick={handleClick}
        key={`checklist-category-${category.name}`}
      />
    ));

    function handleClick(id: string): void {
      redirectToRef.current = `/onboard/budget/checklist/item/${id}`;
      triggerTimeout();
    }
  }

  function handleBackClick() {
    if (!redirectUrl) return;
    redirectToRef.current = redirectUrl;
    triggerTimeout();
  }

  function handleSubmit() {
    // TODO: error handling/prevent click until user has selected some items
    redirectToRef.current = "/onboard/budget/prioritize";
    triggerTimeout();
  }
};

export { ChecklistList };
export default ChecklistList;
