import React, {
  FC,
  CSSProperties,
  ReactNode,
  useState,
  useMemo,
  useContext,
  useEffect,
  useRef,
} from "react";
import { Box, CircularProgress } from "@material-ui/core";
import {
  FileUpload,
  LargeUploadBox,
  getDocRecordsWithDownloadURLAndID,
} from "../upload";
import { MediaProperties } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import useRatio from "../../util/hooks/useRatio";
import { UserCtx } from "../../util";
import { OnboardImageCard } from "./OnboardImageCard";
import deleteMediaObject from "../../util/firebase/deleteMediaObject";
import { FullScreenMedia } from "../mediaDisplay";
import { ConfirmDialog } from "../utility";
import BoxLoadingSpinner from "../loadingComponents/BoxLoadingSpinner";

export interface YardUploadGridProps {
  onUploadComplete?: (files: File[][]) => void;
  onDelete?: (media: MediaProperties) => void;
  style?: CSSProperties;
  projectId: string;
  fileTag: string;
  variant: string;
  setFileCount?: (n: number) => void;
  accept: string[];
  video?: boolean;
  id?: string;
  className?: string;
  uploadClassName?: string;
}

const YardUploadGrid: FC<YardUploadGridProps> = props => {
  const [media, setMedia] = useState<MediaProperties[] | null>(null);
  const [
    mediaToFullScreen,
    setMediaToFullScreen,
  ] = useState<MediaProperties | null>(null);

  const user = useContext(UserCtx);

  const fileCountRef = useRef<number | null>(null);
  const [mediaToDelete, setMediaToDelete] = useState<MediaProperties | null>(
    null
  );

  const width = useMemo(getWidth, []);
  const height = useRatio(16, 9, false, width);

  const [loading, setLoading] = useState(true);

  // TODO: remove disable comment and fix warning next time these hooks are updated
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(subscribeToMedia, [props.fileTag, props.variant, user && user.uid]);
  useEffect(handleMediaChange, [media]);
  /* eslint-enable react-hooks/exhaustive-deps */

  if (loading) {
    return <BoxLoadingSpinner boxProps={{ justifyContent: "flex-start" }} />;
  }

  return (
    <>
      <FullScreenMedia
        media={mediaToFullScreen ?? ""}
        open={!!mediaToFullScreen}
        onClose={() => setMediaToFullScreen(null)}
      />
      <ConfirmDialog
        open={!!mediaToDelete}
        onClose={() => setMediaToDelete(null)}
        title={`Are you sure you want to delete this ${
          props.video ? "video" : "image"
        }?`}
        buttonText="DELETE"
        onButtonClick={() => {
          if (!mediaToDelete) {
            return setMediaToDelete(null);
          }
          removeMedia(mediaToDelete);
          setMediaToDelete(null);
          if (props.onDelete) {
            props.onDelete(mediaToDelete);
          }
        }}
      />
      <Box
        width="100%"
        p={1}
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="center"
        flexWrap="wrap"
        style={props.style}
        id={props.id}
        className={props.className}
      >
        {media && (
          <FileUpload
            onUploadComplete={props.onUploadComplete}
            fileTag={props.fileTag}
            variant={props.variant}
            projectId={props.projectId}
            accept={props.accept}
          >
            <LargeUploadBox
              style={{ width, height }}
              className={props.uploadClassName}
              acceptedFileTypes={props.accept}
            />
          </FileUpload>
        )}
        {renderImages()}
      </Box>
    </>
  );

  // get appropriate width based on screen width media queries
  function getWidth(): number {
    return 300;
  }

  function renderImages(): ReactNode[] | ReactNode {
    if (!media) {
      return (
        <Box p={3}>
          <CircularProgress color="primary" size={75} />
        </Box>
      );
    }

    return media.map(renderImage);
  }

  function handleMediaChange(): void {
    if (props.setFileCount && media) {
      const count = media.length;

      if (fileCountRef.current !== count) {
        fileCountRef.current = count;
        props.setFileCount(count);
      }
    }
  }

  function renderImage(media: MediaProperties): ReactNode {
    return (
      <Box m={1} key={`${media.id}-${props.fileTag}-${props.variant}`}>
        <OnboardImageCard
          iconURL={
            props.video
              ? "https://yardzen-public-assets.storage.googleapis.com/video-icon_upload_success.png"
              : undefined
          }
          isConverting={props.video}
          height={height}
          width={width}
          backgroundSize={props.video ? "contain" : undefined}
          expand={() => setMediaToFullScreen(media)}
          delete={() => setMediaToDelete(media)}
          media={media}
        />
      </Box>
    );
  }

  async function removeMedia(mediaObject: MediaProperties): Promise<any> {
    if (!media) {
      throw new Error("tried to remove media from non existant array");
    }

    const result = deleteMediaObject(mediaObject);
    const mediaIndex = media.findIndex(m => m.id === mediaObject.id);

    if (mediaIndex < 0) {
      throw new Error("tried to remove media that doesn't exist");
    }

    media.splice(mediaIndex, 1);

    setMedia([...media]);
    return result;
  }

  function subscribeToMedia() {
    if (!user) {
      return;
    }

    const userId = user.uid;
    return firebase
      .firestore()
      .collection("media")
      .where("tag", "==", props.fileTag)
      .where("variant", "==", props.variant)
      .where("userId", "==", userId)
      .where("projectId", "==", props.projectId)
      .onSnapshot(async snap => {
        const media = await getDocRecordsWithDownloadURLAndID(snap);

        setMedia(media as MediaProperties[]);
        setLoading(false);
      });
  }
};

export { YardUploadGrid };
export default YardUploadGrid;
