import * as React from "react";
import {
  Modal,
  ModalProps,
  Box,
  BoxProps,
  useMediaQuery,
  Theme,
  makeStyles,
} from "@material-ui/core";
import { CenterFullScreen } from "../utility";
import { Close } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";

export interface SquareModalBaseProps extends ModalProps {
  closeable?: boolean;
  leftBoxChildren?: React.ReactNode;
  rightBoxChildren?: React.ReactNode;
  contentContainerProps?: BoxProps;
  leftBoxProps?: BoxProps;
  rightBoxProps?: BoxProps;
  maxWidth?: string;
  noPaddingOnMobile?: boolean;
  onboardSidebar?: boolean;
}

const useStyles = makeStyles(theme => ({
  invis: {
    transition: "opacity 2000ms",
    opacity: 0,
  },
  backdrop: {
    opacity: 0.5,
    background: `linear-gradient(to top left, ${theme.palette.primary.main +
      "CC"}, ${theme.palette.secondary.main + "CC"})`,
  },
}));

const SquareModalBase: React.FC<SquareModalBaseProps> = ({
  closeable,
  leftBoxChildren,
  rightBoxChildren,
  contentContainerProps,
  leftBoxProps,
  rightBoxProps,
  maxWidth,
  noPaddingOnMobile,
  onboardSidebar,
  ...props
}: SquareModalBaseProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [invis, setInvis] = React.useState<boolean>(!props.open);
  const theme = useTheme<any>();

  React.useEffect(onOpenClose, [props.open]);

  return (
    <Modal
      {...props}
      open={props.open}
      BackdropProps={{
        className: classes.backdrop,
      }}
    >
      <>
        <CenterFullScreen
          className={classes.invis}
          onContainerClick={
            !!closeable ? (props.onClose as () => void) : undefined
          }
          style={{ opacity: !invis ? 0 : 1 }}
        >
          <Box
            display="flex"
            flexDirection="column"
            width={smDown ? "100%" : "fit-content"}
            height="fit-content"
            maxHeight={"100%"}
            paddingLeft={onboardSidebar && !smDown ? getSidebarWidth() : "0px"}
          >
            <Box
              maxHeight="inherit"
              width="100%"
              display="flex"
              flexDirection="row-reverse"
            >
              {!!closeable && (
                <Box
                  style={{ pointerEvents: "all", cursor: "pointer" }}
                  onClick={props.onClose as () => void}
                  pr={rightBoxChildren ? 1 : undefined}
                >
                  <Close
                    style={{
                      color: "white",
                      width: "2.7rem",
                      height: "2.7rem",
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              maxHeight="inherit"
              flexDirection={smDown ? "column" : "row"}
              flexGrow={smDown ? 1 : undefined}
            >
              <Box
                p={smDown && noPaddingOnMobile ? 0 : 1}
                bgcolor="white"
                flexGrow={smDown ? 1 : undefined}
                maxWidth={maxWidth || "700px"}
                // minWidth="1500px"
                maxHeight={smDown ? "100%" : undefined}
                zIndex={1}
                position="relative"
                onClick={e => e.stopPropagation()}
                boxShadow={smDown ? 0 : 1}
                {...contentContainerProps}
              >
                <Box>
                  <Box
                    p={1}
                    mt={smDown ? "175px" : "237.5px"}
                    display="inline-flex"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    zIndex={5}
                    minWidth="2.7rem"
                    {...leftBoxProps}
                    onClick={
                      leftBoxChildren ? e => e.stopPropagation() : undefined
                    }
                  >
                    {leftBoxChildren ?? null}
                  </Box>
                  {props.children}
                  <Box
                    p={1}
                    mt={smDown ? "175px" : "237.5px"}
                    display="inline-flex"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    right={0}
                    top={0}
                    zIndex={5}
                    minWidth="2.7rem"
                    {...rightBoxProps}
                    onClick={
                      rightBoxChildren ? e => e.stopPropagation() : undefined
                    }
                  >
                    {rightBoxChildren ?? null}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </CenterFullScreen>
      </>
    </Modal>
  );

  function onOpenClose(): () => void {
    const timeout = setTimeout(() => {
      setInvis(props.open);
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }

  function getSidebarWidth(): string {
    if (theme.breakpoints.down("md")) return "280px";
    if (theme.breakpoints.down("lg")) return "310px";
    return "330px";
  }
};

export { SquareModalBase };
export default SquareModalBase;
