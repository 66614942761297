import React, { FC, useMemo, useContext } from "react";
import {
  BudgetCategoryChecklistContainerProps,
  BudgetCategoryChecklistContainer,
} from "@yardzen-inc/react-common";
import {
  BudgetChecklistCategoeryListItemWrapperProps,
  BudgetChecklistCategoeryListItemWrapper,
} from "./BudgetChecklistCategoryListItem";
import { ItemResponseCtx } from "./ItemResponseContext";
import { PLANTING_ELEMENTS_CATEGORY } from "../util/wishlistCategoryConstants";
import { getSortedPlantingElements } from "../util/getSortedPlantingElements";

export interface BudgetElementCategoryListWithResponseSubsProps
  extends Omit<BudgetCategoryChecklistContainerProps, "selected" | "children"> {
  onItemClick: (id: string) => any;
}

export interface BudgetCategoryChecklistItemPropsWithKey
  extends BudgetChecklistCategoeryListItemWrapperProps {
  key: string;
}

const BudgetElementCategoryListWithResponseSubs: FC<BudgetElementCategoryListWithResponseSubsProps> = ({
  onItemClick,
  ...props
}) => {
  const [checklistItemMap] = useContext(ItemResponseCtx);

  // TODO: remove disable comment and fix warning next time this hook is updated
  /* eslint-disable react-hooks/exhaustive-deps */
  const itemProps:
    | BudgetCategoryChecklistItemPropsWithKey[]
    | null = useMemo(filterChecklistItems, [
    checklistItemMap,
    props.category.name,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <BudgetCategoryChecklistContainer
      {...props}
      category={{
        name: props.category.description ?? props.category.name,
        items: [],
      }}
    >
      {renderItems()}
    </BudgetCategoryChecklistContainer>
  );

  function filterChecklistItems() {
    if (!checklistItemMap) {
      return null;
    }

    const items: BudgetCategoryChecklistItemPropsWithKey[] = [];

    for (let ir of Object.values(checklistItemMap)) {
      if (ir?.item.budget_checklist_category.name !== props.category.name) {
        continue;
      }

      let mod = 0;

      if (!ir.item.quantitative_item) {
        void [
          ir.response?.front_yard,
          ir.response?.back_yard,
          ir.response?.left_yard,
          ir.response?.right_yard,
        ].forEach(booleanValue => {
          if (booleanValue) {
            mod += 1;
          }
        });
      }

      items.push({
        checked: !!ir.response?.id,
        name: ir.item.name,
        onClick: onItemClick,
        value: ir.item.id,
        iconUri: "", //no longer using the icons on the main budget checklist view
        key: ir.response?.id ?? ir.item.id,
        priceOptionId: ir?.response?.budget_checklist_price_option_id,
        mod: mod || 1,
      });
    }

    if (props.category.name === PLANTING_ELEMENTS_CATEGORY) {
      return getSortedPlantingElements(items);
    }

    return items;
  }

  function renderItems() {
    if (!itemProps) {
      return [];
    }

    return itemProps.map(itemProps => (
      <BudgetChecklistCategoeryListItemWrapper
        {...itemProps}
        name={itemProps.name}
      />
    ));
  }
};

export { BudgetElementCategoryListWithResponseSubs };
export default BudgetElementCategoryListWithResponseSubs;
