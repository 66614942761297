import React, { FC, useContext } from "react";
import { ItemResponseCtx } from "./ItemResponseContext";
import { RouteComponentProps } from "react-router";
import ChecklistItemDetail from "./ChecklistItemDetail";

export interface ChecklistItemDetailWrapperProps extends RouteComponentProps {}

/*
  This component exists purely to make `ChecklistItemDetail` wait for
  the `ItemResponseProvider` to make it's initial fetch.
*/

const ChecklistItemDetailWrapper: FC<ChecklistItemDetailWrapperProps> = props => {
  const [itemResponses, { itemsLoading, responsesLoading }] = useContext(
    ItemResponseCtx
  );

  if ((!itemsLoading && !itemResponses) || (!itemResponses && responsesLoading))
    return null;

  return <ChecklistItemDetail {...props} />;
};

export { ChecklistItemDetailWrapper };
export default ChecklistItemDetailWrapper;
