import React, { FC, useState } from "react";
import { Box, Theme, useMediaQuery } from "@material-ui/core";
import { Page } from "../../types/DesignProfile";
import { useDesignProfileCtx } from "../../components/designProfile";
import { YZTypography } from "@yardzen-inc/react-common";
import { DesignProfileBlackButton } from "../../components/designProfile/DesignProfileBlackButton";
import { DesignProfileOutlineButton } from "../../components/designProfile/DesignProfileOutlineButton";
import { Map } from "../../components/map";
import MapController from "../../components/map/util/MapController";
import { handleConfirmAddress } from "../designProfile/util/handleConfirmAddress";
import { handleRejectAddress } from "../designProfile/util/handleRejectAddress";
import { DesignProfileRouteAccess } from "../../components/designProfile/DesignProfileRouteAccess";

type AddressConfirmationPageProps = {
  page: Page;
  onNext: () => void;
  onPrevious: () => void;
};

export const AddressConfirmationPage: FC<AddressConfirmationPageProps> = ({
  page,
  onNext,
  onPrevious,
}) => {
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const { designProfile } = useDesignProfileCtx();

  const city = designProfile?.contactInformation?.city;
  const coordinates = designProfile?.contactInformation?.latLng;

  const [mapController, setMapController] = useState<MapController | null>(
    null
  );

  // Update map center and marker location when mapController or coordinates change
  React.useEffect(() => {
    if (mapController && coordinates) {
      mapController.markers.forEach(marker =>
        mapController.removeMarker(marker)
      );

      mapController.map.setCenter(coordinates);
      mapController.addMarker(coordinates);
    }
  }, [mapController, coordinates]);

  return (
    <DesignProfileRouteAccess>
      <Box
        mt={mobile ? 12 : 6}
        mb={2}
        mx={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
      >
        <Box>
          <YZTypography type="serif" variant="h3">
            {page.title}
          </YZTypography>
        </Box>
        {city && (
          <Box mt={2}>
            <YZTypography type="serif" variant="h3">
              We love designing in {city}.
            </YZTypography>
          </Box>
        )}
        <Box mt={4}>
          <YZTypography>
            Our remote team of experts use data science to design for your
            unique property.
          </YZTypography>
        </Box>
        <Box mt={4}>
          <Box display="flex" flexDirection={mobile ? "column" : "row"}>
            <Box>
              <DesignProfileBlackButton
                text={page.button_text}
                onClick={() => handleConfirmAddress(onNext, page.button_text)}
                fullWidth={true}
              />
            </Box>
            <Box ml={mobile ? 0 : 4} mt={mobile ? 2 : 0}>
              <DesignProfileOutlineButton
                text={page.alt_button_text}
                onClick={() =>
                  handleRejectAddress(onPrevious, page.alt_button_text)
                }
              />
            </Box>
          </Box>
        </Box>
        <Box mt={8} height={mobile ? "400px" : "600px"} width="100%">
          <Map
            style={{ height: "100%", width: "100%" }}
            onMapInitialize={map => setMapController(new MapController(map))}
            mapOptions={{
              disableDoubleClickZoom: true,
              disableDefaultUI: true,
              zoom: 20,
              tilt: 0,
            }}
          />
        </Box>
      </Box>
    </DesignProfileRouteAccess>
  );
};
