import * as React from "react";
import { Box, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";
import { YZTypography } from "@yardzen-inc/react-common";

interface NewspaperQuotesProps {
  type: "NYTimes";
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#ECF0EC",
    paddingBottom: "8rem",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "4rem",
      paddingTop: 0,
    },
    paddingTop: theme.spacing(8),
    padding: theme.spacing(3),
    minHeight: "30vh",
  },
  quote: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "60%",
    },
  },
  logo: {
    marginTop: theme.spacing(3),
  },
  nyTimesLogo: {
    width: "320px",
    [theme.breakpoints.down("sm")]: {
      width: "240px",
    },
  },
}));

const NewspaperQuotes: React.FC<NewspaperQuotesProps> = ({ type }) => {
  const classes = useStyles();
  const NYTimes = (
    <>
      <YZTypography
        className={classes.quote}
        align="center"
        variant="h3"
        type="serif"
        paragraph
      >
        “The Yardzen process was fast and easy... and there was very little
        thinking required on my end. I was pleased.”
      </YZTypography>
      <img
        src="https://yardzen-public-assets.storage.googleapis.com/public/NYTimesLogo.png"
        alt="The New York Times logo"
        className={classnames(classes.nyTimesLogo, classes.logo)}
      />
    </>
  );

  return (
    <Box
      className={classes.root}
      textAlign="center"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {renderNewpaperQuote(type)}
    </Box>
  );

  function renderNewpaperQuote(type: NewspaperQuotesProps["type"]) {
    switch (type) {
      case "NYTimes":
        return NYTimes;
      default:
        return <h1>Problem</h1>;
    }
  }
};

export { NewspaperQuotes };
export default NewspaperQuotes;
