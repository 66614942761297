export default function sendTatariPurchaseEvent(
  purchaseObject: TatariPurchaseObject
) {
  if (
    !(process.env.NODE_ENV === "production") ||
    window.location.href.includes("dogfood")
  ) {
    return;
  }

  const tatari = window["tatari"];

  if (typeof tatari === "object" && typeof tatari["track"] === "function") {
    tatari.track("purchase", purchaseObject);
  }
}

export { sendTatariPurchaseEvent };
