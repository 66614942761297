import * as React from "react";
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom";
import { UserCtx } from "../../util";
import { mustAcceptNewTermsOfService } from "../../util/mustAcceptNewTermsOfService";
import { useState } from "react";
import { TermsOfService } from "../termsOfService/TermsOfService";

export interface ProtectedRouteProps extends RouteProps {
  unauthorized?:
    | string
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

function ProtectedRoute({
  component,
  unauthorized,
  ...rest
}: ProtectedRouteProps) {
  const user = React.useContext(UserCtx);
  const [showNewTermsOfService, setShowNewTermsOfService] = useState<boolean>(
    false
  );

  if (user === null) {
    return null;
  }

  if (user) {
    mustAcceptNewTermsOfService().then(result => {
      setShowNewTermsOfService(result);
    });
  }

  function queryStringParamsToPass(): string {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search.slice(1)).toString();
    return params;
  }

  return showNewTermsOfService ? (
    <TermsOfService userId={user.uid} />
  ) : (
    <Route
      {...rest}
      render={props => {
        const newProps = {
          ...props,
          user,
        };
        return !!user && (!user.isAnonymous || user.email) ? (
          React.createElement(component, newProps)
        ) : !unauthorized ? (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: rest.location?.pathname,
              },
              search: queryStringParamsToPass(),
            }}
          />
        ) : !!unauthorized && typeof unauthorized === "string" ? (
          <Redirect
            to={{
              pathname: unauthorized,
              state: {
                from: rest.location?.pathname,
              },
            }}
          />
        ) : (
          !React.createElement(unauthorized)
        );
      }}
    />
  );
}

export default ProtectedRoute;
