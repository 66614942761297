import {
  UserCredential,
  User,
  linkWithPopup,
  GoogleAuthProvider,
  EmailAuthProvider,
  linkWithCredential,
  signInWithPopup,
  getAuth,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { HRError } from "./HRError";

/**
 * Asynchronously links a Google account to a given user.
 *
 * This function uses Firebase's `linkWithPopup` method to link a Google account to the provided user.
 * If the linking process encounters an error, it formats the error into an `HRError` and throws it.
 *
 * @param {User} user - The user to which the Google account should be linked.
 * @returns {Promise<UserCredential | undefined>} - A promise that resolves to the user credential data resulting from the linking process, or undefined if the process fails.
 * @throws {HRError} - If an error occurs during the linking process, it is formatted into an `HRError` and thrown.
 */
export async function linkGoogleAccount(
  user: User
): Promise<UserCredential | undefined> {
  try {
    const provider = new GoogleAuthProvider();
    const result = await linkWithPopup(user, provider);

    return result;
  } catch (error) {
    throw formatLoginError(error);
  }
}

export function formatLoginError(error: unknown): HRError {
  let formattedError: HRError;

  if (isErrorWithCode(error)) {
    if (
      error.code === "auth/credential-already-in-use" ||
      error.code === "auth/email-already-in-use"
    ) {
      formattedError = HRError.fromError(
        error,
        "It looks like you already have a yardzen account, please try logging in."
      );
    } else if (error.code === "auth/account-exists-with-different-credential") {
      formattedError = HRError.fromError(
        error,
        "It looks like you have an account but are signing in with a different method than you used last time. Please try signing in with the same method you used previously."
      );
    } else if (error.code === "auth/popup-closed-by-user") {
      formattedError = HRError.fromError(
        error,
        "Google Authentication was cancelled. Please try again."
      );
    } else {
      formattedError = HRError.fromError(
        error,
        "An error occurred while trying to link your account, please try again."
      );
    }
  } else {
    formattedError = HRError.fromError(
      error,
      "An error occurred while trying to link your account, please try again."
    );
  }

  return formattedError;
}

function isErrorWithCode(error: unknown): error is Error & { code: string } {
  return error instanceof Error && "code" in error;
}

export async function linkAccountsWithEmail(
  user: User,
  email: string,
  password: string
) {
  const credential = EmailAuthProvider.credential(email, password);

  try {
    return await linkWithCredential(user, credential);
  } catch (error) {
    throw formatLoginError(error);
  }
}

export async function signInWithEmail(
  email: string,
  password: string
): Promise<UserCredential | undefined> {
  const auth = getAuth();
  try {
    return await signInWithEmailAndPassword(auth, email, password);
  } catch (error) {
    throw formatLoginError(error);
  }
}

export async function signInWithGoogle(): Promise<UserCredential | undefined> {
  const auth = getAuth();
  try {
    const provider = new GoogleAuthProvider();
    return await signInWithPopup(auth, provider);
  } catch (error) {
    throw formatLoginError(error);
  }
}

export async function checkLoginProviders(email: string) {
  const auth = getAuth();
  const methods = await fetchSignInMethodsForEmail(auth, email);

  return methods;
}
