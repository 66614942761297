import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { Media, MediaProperties } from "@yardzen-inc/models";

const deleteMediaObject = async (
  media: Media | MediaProperties
): Promise<any> => {
  return Promise.all([
    firebase
      .firestore()
      .collection("media")
      .doc(media.id)
      .delete(),
    firebase
      .storage()
      .ref(media.path)
      .delete(),
  ]);
};

export { deleteMediaObject };
export default deleteMediaObject;
