import { identifyLogRocketUser } from "../../../util/identifyLogRocketUser";
import { DesignProfile, UpdateDesignProfileDto } from "../../../api/apiTypes";

interface HandleEmailPhoneSubmitArgs {
  userId?: string;
  referralSource?: string;
  onNext: () => void | null;
  designProfile: DesignProfile | undefined;
  updateDesignProfile: (
    updateDesignProfileDto: UpdateDesignProfileDto
  ) => Promise<boolean | undefined>;
  email: string;
  phone: string;
}

export const handleEmailPhoneSubmit = async ({
  userId,
  onNext,
  referralSource,
  designProfile,
  updateDesignProfile,
  email,
  phone,
}: HandleEmailPhoneSubmitArgs) => {
  const cleanedPhone = (phone && phone.replace(/[^\d]/g, "")) || "";

  try {
    await updateDesignProfile({
      contactInformation: {
        ...designProfile?.contactInformation,
        email,
        phoneNumber: cleanedPhone,
      },
      referralSource: !!referralSource?.length ? referralSource : null,
    });

    identifyLogRocketUser({ userId, email });

    onNext();
  } catch (err) {
    window.newrelic.noticeError(err);
    console.error(err);
  }
};
