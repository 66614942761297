import * as React from "react";
import { Info as HelpIcon } from "@material-ui/icons";
import {
  Box,
  TypographyProps,
  BoxProps,
  Button,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";

export interface HelpIconWithTextProps extends BoxProps {
  typographyProps?: TypographyProps;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: 0,
    textTransform: "none",
    borderRadius: "4px",
    border: "1px solid transparent",
    "&:hover": {
      backgroundColor: "initial",
      transform: "none",
    },
    "&:focus-visible": {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
    },
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}));

const HelpIconWithText: React.FC<HelpIconWithTextProps> = ({
  typographyProps,
  children,
  ...props
}) => {
  const { button, icon } = useStyles();

  return (
    <Button
      {...props}
      style={{ ...props.style }}
      className={button}
      disableRipple
      tabIndex={0}
    >
      <HelpIcon className={icon} />
      <Box pl={1}>
        <YZTypography {...typographyProps}>{children}</YZTypography>
      </Box>
    </Button>
  );
};

export { HelpIconWithText };
export default HelpIconWithText;
