export interface CheckoutFAQ {
  title: string;
  description: string | string[];
  index: number;
}

const checkoutFAQs: CheckoutFAQ[] = [
  {
    index: 1,
    title: "Get access to the Yard Builder",
    description:
      "You’ll take a style quiz, share videos and photos of your yard, and tell us about your budget and priorities, our support team will help you in every step of the process.",
  },
  {
    index: 2,
    title: "Receive your designs and collaborate with your designer",
    description:
      "Your first draft designs are just the beginning. Share all of your feedback in our Feedback Studio and your designer will refine. ",
  },
  {
    index: 3,
    title: "Get revised renders and plans",
    description:
      "Once we get your feedback on the first design, your design team will work to make your design a reality. With your revisions comes a CAD plan for your contractor.",
  },
  {
    index: 4,
    title: "Get connected with a contractor",
    description:
      "We move from the design process into the build phase. Alongside a Yardzen account manager, you’ll get your project ready for install. We’ll connect you with a vetted contractor who can bring your design to life.",
  },
  {
    index: 5,
    title: "Enjoy your dream yard",
    description:
      "Your Yardzen designs are a stepping stone to the ultimate goal: a beautiful yard designed for your lifestyle.",
  },
];

export { checkoutFAQs };
