import React, { useState, useContext, useEffect, FC } from "react";
import { makeStyles, Theme, Box, LinearProgress } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import { FileUpload, LargeUploadBox } from "../../../upload";
import { Media } from "@yardzen-inc/models";
import { OnboardCtx, ProfileCtx, smartRedirect } from "../../../../util";
import firebase from "firebase/compat/app";
import MediaGrid from "./MediaGrid";
import SaveBar from "../../helpers/SaveBar";
import GoBackButton from "../../../checkout/GoBack";
import { useHistory } from "react-router";
import BottomCenterContentContainer from "../../../onboardLayout/BottomCenterContentContainer";

const MAX_FILE_COUNT = 5;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: 100,
    minWidth: "400px",
    maxWidth: "880px",
    margin: "auto",
    textAlign: "left",
    position: "relative",
    paddingBottom: "3rem",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      minWidth: "0",
      padding: "0 !important",
    },
  },
  icon: {
    width: "100px",
    height: "100px",
    marginRight: "1.5rem",
  },
  title: {
    letterSpacing: "0.2rem",
    flexGrow: 1,
  },
  inputProps: {
    color: "rgba(0, 0, 0, 0.45)",
  },
  stepText: {
    marginBottom: "1.5rem",
  },
  fileUploadBoxContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 30,
  },
  saveBarContainer: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    maxWidth: "100vw",
    justifyContent: "center",
  },
  navButtonsContainer: {
    height: "60px",
    width: "90%",
    maxWidth: "1080px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: "90px",
    },
  },
}));

const ExteriorDesignInspiration: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [media, setMedia] = useState<Media[]>([]);
  const [isLoadingMedia, setIsLoadingMedia] = useState<boolean>(true);
  const {
    state: { projectId },
    setExteriorDesignStepComplete,
    setCurrentStep,
  } = useContext(OnboardCtx);
  const profile = useContext(ProfileCtx);

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getUserMedia, []);

  return (
    <div className={classes.root}>
      <Box className={classes.navButtonsContainer}>
        <GoBackButton goBack={handleGoBack} label="back" />
      </Box>
      <Box textAlign="center" mb={5}>
        <YZTypography type="serif" variant="h4" paragraph>
          Show us up to {MAX_FILE_COUNT} exterior designs you love
        </YZTypography>
        <YZTypography paragraph>
          Upload {MAX_FILE_COUNT} inspiration photos. These can be photos you
          take of neighbors' homes or images of exterior designs you find
          online. Then use the text next to each photo to let us know what,
          specifically, you like about each.
        </YZTypography>
      </Box>
      <div className={classes.fileUploadBoxContainer}>
        <FileUpload
          fileTag={"exterior-design-inspiration"}
          variant={""}
          projectId={projectId as string}
          accept={[".jpg", ".jpeg", ".png", ".svg", ".heic"]}
          maxFileCount={MAX_FILE_COUNT}
          currentFileCount={media.length}
        >
          <LargeUploadBox
            style={{ width: 300 }}
            acceptedFileTypes={[".jpg", ".jpeg", ".png", ".svg", ".heic"]}
          />
        </FileUpload>
      </div>
      {renderCards()}
      <BottomCenterContentContainer>
        <SaveBar
          label="Save and Continue to My Goals →"
          onClick={handleNextClick}
        />
      </BottomCenterContentContainer>
    </div>
  );

  function renderCards() {
    if (isLoadingMedia) {
      return <LinearProgress variant="indeterminate" />;
    }
    return (
      <MediaGrid
        removeMediaItemFromParentArray={handleRemoveMediaItemFromParentArray}
        media={media}
      />
    );
  }

  function handleNextClick() {
    setExteriorDesignStepComplete(true);
    setCurrentStep("Goals");
    return smartRedirect("v1", "project/Goals");
  }

  function handleRemoveMediaItemFromParentArray(id: string) {
    setMedia(media.filter(mediaItem => mediaItem.id !== id));
  }

  function getUserMedia() {
    setIsLoadingMedia(true);
    if (!profile) return;

    firebase
      .firestore()
      .collection("media")
      .where("tag", "==", "exterior-design-inspiration")
      .where("userId", "==", profile.userId)
      .onSnapshot(async snap => {
        setMedia(
          await Promise.all(
            snap.docs.map(doc =>
              Media.createFromQuerySnapshot(
                (doc as any) as firebase.firestore.QueryDocumentSnapshot
              )
            )
          )
        );
        setIsLoadingMedia(false);
      });
  }

  function handleGoBack() {
    if (profile && profile.exteriorPaintDesired === true) {
      history.push("/onboard/exterior-design/paint/picker");
    } else {
      history.push("/onboard/exterior-design/paint");
    }
  }
};

export default ExteriorDesignInspiration;
export { ExteriorDesignInspiration };
