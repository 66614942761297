import { Color, ColorResponse } from "@yardzen-inc/colors";
import { useState, useEffect, useContext } from "react";
import { OnboardCtx } from "../../../../util";
import { ColorsContext } from "../../../../util/contexts/ColorsContext";

export interface IUsePaintColors {
  (category?: string): {
    colors: Color[];
    selectedColors: ColorResponse[];
    isReady: boolean;
    errorMessage: string;
    selectColor: (colorId: string, placement: string) => Promise<void>;
    removeColor: (colorId: string) => Promise<void>;
    clearError: () => void;
  };
}

/**
 * Hook used to interact with our colors API in the client onboard experience.
 *
 * `isReady` variable returns whether both the colors API client and the projectId
 * are loaded or not. Without either of them being loaded, nothing returned from this
 * hook is operational.
 *
 * This hook must be used within a `ColorsProvider` component.
 *
 * This hook must be used within an `OnboardProvider` component.
 * @param category string (optional). Color category to filter by. Pass undefined to get all colors.
 * This only affects the return value of `colors`. `selectedColors` is unaffected by category.
 */
const usePaintColors: IUsePaintColors = category => {
  const [colors, setColors] = useState<Color[]>([]);
  const [selectedColors, setSelectedColors] = useState<ColorResponse[]>([]);
  const [isReady, setIsReady] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const colorsClient = useContext(ColorsContext);
  const {
    state: { projectId },
  } = useContext(OnboardCtx);

  // isReady effect
  useEffect(() => {
    if (isReady) {
      return;
    }

    if (colorsClient && projectId) {
      setIsReady(true);
    }
  }, [colorsClient, projectId, isReady]);

  // Effect to fetch all color options optionally filtered by category
  useEffect(() => {
    if (!isReady) {
      return;
    }

    async function _fetchColors() {
      try {
        const res = await colorsClient.colors.getColors(
          undefined,
          "100",
          undefined,
          category ?? undefined
        );
        if (res.empty) {
          throw new Error("No colors returned from API!");
        }

        setColors(res.colors);
      } catch (err) {
        window.newrelic.noticeError(err);
        parseError(
          err,
          "We were unable to fetch the available color options. Please refresh the page and try again."
        );
      }
    }

    _fetchColors();
  }, [colorsClient, category, projectId, isReady]);

  // Effect to fetch all colors selected by the current user
  useEffect(() => {
    if (!isReady) {
      return;
    }

    async function _fetchSelectedColors() {
      if (!projectId) {
        return;
      }

      try {
        const res = await colorsClient.colorResponses.colorResponses(projectId);

        if (!res.empty) {
          setSelectedColors(res.responses);
        }
      } catch (err) {
        window.newrelic.noticeError(err);
        parseError(
          err,
          "We were unable to fetch your selected colors. Please refresh the page and try again."
        );
      }
    }

    _fetchSelectedColors();
  }, [colorsClient, projectId, isReady]);

  // Select Color function
  async function selectColor(colorId: string, placement: string) {
    if (!isReady) {
      return;
    }

    const existingColorSelection = selectedColors.find(
      col => col.colorId === colorId
    );

    try {
      if (existingColorSelection) {
        await colorsClient.colorResponse.deleteColorResponse(
          existingColorSelection.id
        );
      }

      await colorsClient.colorResponse.newColorResponse({
        colorId,
        placement,
        projectId: projectId as string,
      });
      await _refreshSelectedColors();
    } catch (err) {
      window.newrelic.noticeError(err);
      parseError(
        err,
        "We were unable to save your color selection. Please refresh the page and try again."
      );
    }
  }

  // Remove Color selection function
  async function removeColor(colorId: string) {
    if (!isReady) {
      return;
    }

    const responseId = selectedColors.find(col => col.colorId === colorId)?.id!;

    try {
      await colorsClient.colorResponse.deleteColorResponse(responseId);
      await _refreshSelectedColors();
    } catch (err) {
      window.newrelic.noticeError(err);
      parseError(
        err,
        "We were unable to remove your color selection. Please refresh the page and try again."
      );
    }
  }

  async function _refreshSelectedColors() {
    const res = await colorsClient.colorResponses.colorResponses(projectId!);

    setSelectedColors(res.responses);
  }

  function clearError() {
    setErrorMessage("");
  }

  function parseError(err: any, message: string) {
    if (err.code === 403) {
      setErrorMessage(
        "We are having trouble connecting to our servers. Please refresh the page and try again"
      );
    } else {
      setErrorMessage(message);
    }
  }

  return {
    colors,
    selectedColors,
    isReady,
    errorMessage,
    selectColor,
    removeColor,
    clearError,
  };
};

export { usePaintColors };
export default usePaintColors;
