import { PromotionalCodeStatus } from "../../../services/promotionalCodes";
import { PromotionalCode } from "../../../api/apiTypes";
import { Product } from "../../../types/Products";
import {
  discountIsEqualToExpeditedProductPrice,
  getExpeditedProduct,
} from "./promoCodeIncludesFreeExpedited";

/**
 * Returns true if the promotional code is only for Free Expedited Add On
 * If the promotional code is ONLY for free expedited, the primary discount should be set to 0 and the additional discount should be set to the price of the expedited add-on
 * @param promotionalCode - The promotional code to check
 * @param products - The products list from Stripe
 */
export const promoCodeIsOnlyFreeExpedited = (
  promotionalCode: PromotionalCode,
  products: Product[]
) => {
  const promoCodeIsActive =
    promotionalCode?.status === PromotionalCodeStatus.USABLE;
  if (!promoCodeIsActive) {
    return false;
  }
  const additionalDiscounts = promotionalCode.additionalDiscounts || [];
  if (additionalDiscounts.length !== 1) {
    return false;
  }
  if (promotionalCode.discount !== "0") {
    return false;
  }
  const expeditedAddOnProduct = getExpeditedProduct(products);
  if (!expeditedAddOnProduct) {
    return false;
  }
  return discountIsEqualToExpeditedProductPrice(
    promotionalCode,
    expeditedAddOnProduct
  );
};
