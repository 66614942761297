import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { FieldValue } from "firebase/firestore";

// we may want to expand this interface if we want to make other
// modifications to profile docs
export interface UpdateProfileObj {
  firstName?: string;
  lastName?: string;
  street?: string;
  state?: string;
  city?: string;
  zip?: string;
  phone?: string;
  consultCall?: string;
  exteriorPaintDesired?: boolean;
  dateAgreedToTOS?: FieldValue | null | Date;
  typeforms?: {
    [key: string]: boolean;
  };
}

// update profile doc by user id
//
// this function MUST be wrapped in a try/catch to handle possible error in the UI
async function updateProfileDoc(userId: string, data: UpdateProfileObj) {
  try {
    return firebase
      .firestore()
      .collection("profiles")
      .doc(userId)
      .update(data);
  } catch (err) {
    window.newrelic.noticeError(err);
    throw new Error(
      `Error updating profile document with id of ${userId} in address confirmation`
    );
  }
}

export { updateProfileDoc };
