import React, { createContext, FC, useState, useEffect, memo } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const FirebaseJWTContext = createContext<string | false | null>(null);

export interface FirebaseJwtProviderProps {
  noRenderUntilAuthResult?: boolean;
}

const FirebaseJwtProvider: FC<FirebaseJwtProviderProps> = memo(
  ({ children, noRenderUntilAuthResult }) => {
    const [token, setToken] = useState<string | false | null>(null);

    useEffect(handleAuthStateChange, []);

    return (
      <FirebaseJWTContext.Provider value={token || null}>
        {!!noRenderUntilAuthResult && token === null ? null : children}
      </FirebaseJWTContext.Provider>
    );

    function handleAuthStateChange(): () => void {
      return firebase.auth().onIdTokenChanged(async user => {
        if (user) {
          return setToken(await user.getIdToken());
        }

        setToken(null);
      });
    }
  }
);

export { FirebaseJwtProvider, FirebaseJWTContext };
export default FirebaseJwtProvider;
