import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import firebase from "firebase/compat/app";
import {
  DesignProfile,
  FindAllDesignProfilesQueryParams,
  CreateDesignProfileDto,
  UpdateDesignProfileDto,
  UpdateDesignProfilePathParams,
  UpdateProfileDto,
  UpdateProfilePathParams,
} from "./apiTypes";

export const PANGAEA_V1_BASE_URL =
  process.env["REACT_APP_PANGAEA_BASE_URL"] || "http://localhost:7777/v1/";

enum ApiTags {
  DESIGNPROFILES = "DESIGNPROFILES",
  PROFILES = "PROFILES",
}

export const pangaeaV1API = createApi({
  reducerPath: "pangaeaAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: PANGAEA_V1_BASE_URL,
    prepareHeaders: async headers => {
      const user = firebase.auth()?.currentUser;
      if (user) {
        headers.set("Authorization", `Bearer ${await user.getIdToken()}`);
      }

      return headers;
    },
  }),

  tagTypes: [ApiTags.DESIGNPROFILES, ApiTags.PROFILES],
  endpoints: builder => ({
    //DESIGNPROFILES
    getDesignProfiles: builder.query<
      DesignProfile[],
      FindAllDesignProfilesQueryParams
    >({
      query: params => {
        let url = "design-profiles";
        if (params) {
          const paramKeys = Object.keys(params);

          if (paramKeys.length) {
            const queryStringArray = paramKeys.map(
              key =>
                `${key}=${
                  params[key as keyof FindAllDesignProfilesQueryParams]
                }`
            );

            url = `${url}?${queryStringArray.join("&")}`;
          }
        }

        return {
          url,
          method: "GET",
        };
      },
      providesTags: [ApiTags.DESIGNPROFILES],
    }),
    createDesignProfile: builder.mutation<
      DesignProfile,
      CreateDesignProfileDto
    >({
      query: body => ({ url: "design-profiles", method: "POST", body }),
      invalidatesTags: [ApiTags.DESIGNPROFILES],
    }),
    updateDesignProfile: builder.mutation<
      // The design profile PATCH endpoint returns no data on success,
      // so we use unknown as it's type.
      unknown,
      UpdateDesignProfileDto & UpdateDesignProfilePathParams
    >({
      query: ({ id, ...updateDesignProfileDto }) => ({
        url: `design-profiles/${id}`,
        method: "PATCH",
        body: updateDesignProfileDto,
      }),
      invalidatesTags: [ApiTags.DESIGNPROFILES],
    }),
    //PROFILES
    updateProfile: builder.mutation<
      // The profile PATCH endpoint returns no data on success,
      // so we use unknown as it's type.
      unknown,
      UpdateProfileDto & UpdateProfilePathParams
    >({
      query: ({ id, ...updateProfileDto }) => ({
        url: `profiles/${id}`,
        method: "PATCH",
        body: updateProfileDto,
      }),
      invalidatesTags: [ApiTags.PROFILES],
    }),
  }),
});
