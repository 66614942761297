import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { CenterFullScreen } from "../../components/utility/CenterFullScreen";
import { GenericLoadingSpinner } from "@yardzen-inc/react-common";
import { SplitContext } from "@splitsoftware/splitio-react";
export interface WaitForSplitClientProps {
  children: ReactNode;
}

export const WaitForSplitClient: FC<WaitForSplitClientProps> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const { isReady } = useContext(SplitContext);

  // waiting for Split client to be ready
  useEffect(() => {
    if (isReady && loading) {
      setLoading(false);
    }
  }, [isReady, loading]);

  // this is a fallback to load the app in case the split client fails to load
  React.useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      if (loading) {
        setLoading(false);
      }
    }, 3000);
    return () => window.clearTimeout(timeoutID);
  }, [loading]);

  return loading ? (
    <CenterFullScreen>
      <GenericLoadingSpinner />
    </CenterFullScreen>
  ) : (
    <>{children}</>
  );
};
