import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

// fetch onboardState doc by userId
// this function MUST be wrapped in a try/catch when implemented

async function fetchOnboardStateDocByUserId(userId: string) {
  try {
    let online = false;
    const connectedRef = firebase.database().ref(".info/connected");

    const handler = (resolve: any, reject: any) => (
      snap: firebase.database.DataSnapshot
    ) => {
      const timeout = setTimeout(() => {
        reject(new Error("timed out"));
      }, 5000);

      if (snap.val() === true) {
        clearTimeout(timeout);
        online = true;
        resolve();
      }
    };

    try {
      await new Promise((resolve, reject) => {
        connectedRef.on("value", handler(resolve, reject));
      });
    } catch (error) {
      window.newrelic.noticeError(error);
      throw error;
    } finally {
      connectedRef.off("value", handler);
    }

    const onboardStateDocSnap = await firebase
      .firestore()
      .collection("onboardStates")
      .where("userId", "==", userId)
      .get();

    return online ? onboardStateDocSnap : false;
  } catch (err) {
    window.newrelic.noticeError(err);
    throw new Error(
      `Error fetching onboardState document for user with id of ${userId} in Onboard Context`
    );
  }
}

export { fetchOnboardStateDocByUserId };
export default fetchOnboardStateDocByUserId;
