import { PriceObject } from "../../types/Prices";
import { Product } from "../../types/Products";

/**
 * Parameters for the add to cart tracking event.
 */
interface TikTokAddToCartEventArgs {
  isPremium: boolean;
  skuPrice: number;
  premiumSku: Partial<PriceObject>;
  product: Partial<Product>;
}

/**
 * Possible parameters that can be passed into a TikTok pixel tracking
 * event.
 */
export interface TikTokPixelTrackingEventParams {
  content_type: "product" | "product_group";
  content_id?: string;
  contents?: { id: string; quantity: number }[];
  content_category?: string;
  content_name?: string;
  currency: "USD";
  value: number;
  price?: number;
  quantity: number;
  query?: string;
  description: string;
  status?: string;
}

/**
 * Function for tracking events with the TikTok pixel.
 * See https://ads.tiktok.com/marketing_api/docs?id=1701890973258754 for more info.
 */
type TikTokPixelTrackingFunction = (
  eventType: string,
  data?: TikTokPixelTrackingEventParams
) => void;

/**
 * Type that adds the TikTok pixel properties on the global window object.
 */
export type WindowWithTikTokPixel = Window &
  typeof globalThis & {
    // The ttq property can be undefined if we're missing the setup code from
    // the <head> tag of our page.
    ttq?: { track: TikTokPixelTrackingFunction };
  };

/**
 * An object with functions that will internally call the appropriate
 * TikTok tracking function with the expected arguments.
 */
interface UseTikTokPixelResult {
  trackAddToCartEvent: (data: TikTokAddToCartEventArgs) => void;
  trackPaymentCompleteEvent: (data: TikTokPixelTrackingEventParams) => void;
  trackRegistrationCompleteEvent: () => void;
}

/**
 * Hook that encapsulates the TikTok pixel tracking method and returns methods
 * for calling different tracking events.
 * @returns An object with methods for calling the TikTok pixel tracking events
 */
export const useTikTokPixel = (): UseTikTokPixelResult => {
  const tikTokTrackingFunction = (window as WindowWithTikTokPixel).ttq?.track;

  if (!tikTokTrackingFunction) {
    console.error(
      "The tiktok pixel tracking function could not be found. " +
        "Tracking will not fire."
    );
  }

  const trackEvent: TikTokPixelTrackingFunction = (
    eventType: string,
    data?: TikTokPixelTrackingEventParams
  ) => {
    // We allow this to run in test because in test we can mock the tiktok
    // requests. We prevent this from running in staging so that we don't
    // make unnecessary calls.
    const isValidEnvironment =
      process.env.NODE_ENV === "production" || process.env.NODE_ENV === "test";

    if (!tikTokTrackingFunction || !isValidEnvironment) {
      return;
    }

    if (data) {
      tikTokTrackingFunction(eventType, data);
      return;
    }

    tikTokTrackingFunction(eventType);
  };

  return {
    trackAddToCartEvent: ({
      isPremium,
      skuPrice,
      premiumSku,
      product,
    }: TikTokAddToCartEventArgs) => {
      const productData = isPremium
        ? {
            contents: [
              { id: product.id, quantity: 1 },
              { id: premiumSku.product, quantity: 1 },
            ],
            content_category: "Landscape design with premium upgrade",
            content_name: `Premium ${product.name}`,
            description: `${product.description} Premium`,
            price: skuPrice - (premiumSku.price ?? 0) / 100,
            quantity: 2,
          }
        : {
            content_id: product.id,
            content_category: "package",
            content_name: product.name,
            description: product.description,
            quantity: 1,
          };

      trackEvent("AddToCart", {
        ...productData,
        content_type: "product",
        currency: "USD",
        value: skuPrice,
      } as TikTokPixelTrackingEventParams);
    },
    trackPaymentCompleteEvent: (data: TikTokPixelTrackingEventParams) => {
      trackEvent("CompletePayment", data);
    },
    trackRegistrationCompleteEvent: () => {
      trackEvent("CompleteRegistration");
    },
  };
};
