import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// fetch profile doc by userId
//
// this function MUST be wrapped in a try/catch to handle possible error in the UI
async function fetchProfileDocByUserId(
  userId: string
): Promise<
  firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
> {
  try {
    return await firebase
      .firestore()
      .collection("profiles")
      .doc(userId)
      .get();
  } catch (err) {
    window.newrelic.noticeError(err);
    throw new Error(
      `Error fetching profile document for user with id of ${userId} in Onboard Context`
    );
  }
}

export { fetchProfileDocByUserId };
export default fetchProfileDocByUserId;
