import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Typography, TypographyProps, Theme } from "@material-ui/core";

export interface YZButtonProps extends ButtonProps {
  typographyProps?: TypographyProps;
  slim?: boolean;
}

interface styleProps {
  color: "primary" | "secondary";
  variant: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: styleProps) => ({
    ...(props.variant === "contained"
      ? {
          border: `solid 1px ${
            props.color === "primary"
              ? theme.palette.primary.dark
              : theme.palette.secondary.dark
          }`,
        }
      : {}),
    borderRadius: 0,
  }),
  contained: (props: styleProps) => ({
    [theme.breakpoints.up("md")]: {
      "&:hover": {
        backgroundColor: `${
          props.color === "primary"
            ? theme.palette.primary.light
            : theme.palette.secondary.light
        } !important`,
      },
    },
  }),
}));

const YZButton: React.FC<YZButtonProps> = React.forwardRef(
  ({ children, typographyProps, slim, ...props }, ref) => {
    const theme = useTheme<Theme>();
    const classes = useStyles({
      color: (props.color as "primary" | "secondary") ?? "primary",
      variant: props.variant || "",
    });

    return (
      <Button
        ref={ref}
        color="primary"
        variant="contained"
        disableElevation
        {...props}
        style={
          slim ? { padding: theme.spacing(0.5), ...props.style } : props.style
        }
        classes={{
          root: classes.root,
          contained: classes.contained,
        }}
      >
        {renderChildren(children, typographyProps, slim)}
      </Button>
    );
  }
);

function renderChildren(
  children: any,
  typographyProps?: TypographyProps,
  slim?: boolean
) {
  if (typeof children === "string") {
    return (
      <Typography
        {...typographyProps}
        style={{
          padding: "4px",
          fontSize: slim ? "12px" : undefined,
          ...typographyProps?.style,
        }}
      >
        {children}
      </Typography>
    );
  }

  return children || null;
}

export { YZButton };
export default YZButton;
