import { PromotionalCode } from "../../../api/apiTypes";
import { mustParseInt } from "../../../util/mustParseInt";
import { noticeError } from "../../../util/newrelic/newrelic";
import { DesignProfileCtxValue } from "../../designProfile";
import { Order } from "../CheckoutForm.a";
import {
  handleUpdatePromotionalCode,
  updatePromotionalCode,
} from "./handleUpdatePromotionalCode";

interface GetPaymentIntentArgs {
  order: Order;
  dsContext: DesignProfileCtxValue | null;
  setError: (error: string) => void;
  setClientSecret: (secret: string) => void;
  setPaymentIntentId: (paymentIntentId: string) => void;
  idToken: string;
  setPromotionalCode: (PromotionalCode: PromotionalCode | null) => void;
  setOrderPrice: (price: number | null) => void;
  setOriginalOrderPrice: (price: number | null) => void;
}
export const getPaymentIntent = async ({
  order,
  dsContext,
  setError,
  setClientSecret,
  setPaymentIntentId,
  idToken,
  setPromotionalCode,
  setOrderPrice,
  setOriginalOrderPrice,
}: GetPaymentIntentArgs) => {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${idToken}`);
  headers.append("Content-Type", "application/json");
  const pangeaBaseUrl = process.env.REACT_APP_PANGAEA_BASE_URL;
  const url = `${pangeaBaseUrl}rpc/shop/legacy-get-payment-code`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify({
        userId: dsContext?.user.uid,
        order,
      }),
    });
    const {
      clientSecret,
      paymentIntentId,
      message,
      discountCodesApplied,
      price,
      originalPrice,
    } = await response.json();

    if (response.status !== 201) {
      console.error(response);
      setError(message);
      noticeError(message);
      return;
    }

    setClientSecret(clientSecret);
    setPaymentIntentId(paymentIntentId);

    try {
      setOrderPrice(mustParseInt(price));
      setOriginalOrderPrice(mustParseInt(originalPrice));
    } catch (error) {
      console.error("failed to parse order prices", error);
      noticeError(error);
    }

    await handleUpdatePromotionalCode(discountCodesApplied, setPromotionalCode);
  } catch (error) {
    console.log("Error getting PaymentIntent: ", error);
    noticeError(error);
  }
};

export const getPaymentIntent2 = async ({
  order,
  userId,
  idToken,
  setOrderPrice,
  setOriginalOrderPrice,
}: {
  order: Order;
  userId: string | undefined;
  idToken: string;
  setOrderPrice: (price: number | null) => void;
  setOriginalOrderPrice: (price: number | null) => void;
}): Promise<
  | {
      error: string;
    }
  | {
      clientSecret: string;
      paymentIntentId: string;
      price: number | null;
      originalPrice: number | null;
      promoCode: PromotionalCode | null;
    }
> => {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${idToken}`);
  headers.append("Content-Type", "application/json");
  const pangeaBaseUrl = process.env.REACT_APP_PANGAEA_BASE_URL;
  const url = `${pangeaBaseUrl}rpc/shop/legacy-payment-code`;

  try {
    const response = await fetch(url, {
      method: "PUT",
      headers,
      body: JSON.stringify({
        userId,
        order,
      }),
    });
    const {
      clientSecret,
      paymentIntentId,
      message,
      discountCodesApplied,
      price,
      originalPrice,
    } = await response.json();

    const promoCode = await updatePromotionalCode(discountCodesApplied);

    if (response.status !== 200) {
      noticeError(message);
      return {
        error:
          message ||
          `An error occurred while getting the payment intent. ${response.status}`,
      };
    }

    try {
      setOrderPrice(mustParseInt(price));
      setOriginalOrderPrice(mustParseInt(originalPrice));
    } catch (error) {
      console.error("failed to parse order prices", error);
      noticeError(error);
    }

    return {
      clientSecret,
      paymentIntentId,
      price,
      originalPrice,
      promoCode,
    };
  } catch (error) {
    console.log("Error getting PaymentIntent: ", error);
    noticeError(error);
    return {
      error: "An error occurred while getting the payment intent.",
    };
  }
};
