import { LotSizeTypes } from "../../../util/constants/lotSizeTypes";
import {
  BACK_YARD_PACKAGE,
  FRONT_YARD_PACKAGE,
  FULL_YARD_PACKAGE,
  OUTDOOR_TRANSFORMATION_PACKAGE,
  UBER_PREMIUM_PACKAGE,
  PackageTypes,
} from "../../../util/constants/packageTypes";
import { YardSizeTypes } from "../../../util/constants/yardSizeTypes";
import { getYardSelectionResponse } from "./getYardSelectionResponse";
import { getWishListResponse } from "./getWishListResponse";
import { getYardSizeResponse } from "./getYardSizeResponse";
import {
  BACK_YARD,
  ENTIRE_PROPERTY,
  FRONT_YARD,
  YardSelectionTypes,
} from "../../../util/constants/yardSelectionTypes";
import {
  ExteriorTypes,
  LandscapingTypes,
} from "../../../util/constants/wishListTypes";
import { getBudgetResponse } from "./getBudgetResponse";
import { DesignProfileQuestion } from "../../../api/apiTypes";
import {
  BudgetOptions,
  uberPremiumBudgets,
} from "../../../util/constants/budgetOptions";
import { wishListIncludesExterior } from "./wishListIncludesExterior";
import { getLotSize } from "./getLotSize";

interface IdentifyRecommendedPackageArgs {
  responses: DesignProfileQuestion[];
}

export interface identifiedPackageInterface {
  pkg: PackageTypes;
  lotSize: LotSizeTypes;
}

export const identifyRecommendedPackage = ({
  responses,
}: IdentifyRecommendedPackageArgs): identifiedPackageInterface | void => {
  const wishList: (LandscapingTypes | ExteriorTypes)[] = getWishListResponse(
    responses
  );
  const yardSelection: YardSelectionTypes = getYardSelectionResponse(responses);
  const yardSize: YardSizeTypes = getYardSizeResponse(responses);
  const lotSize: LotSizeTypes = getLotSize(yardSize);
  const budgetResponse: BudgetOptions = getBudgetResponse(responses);

  if (uberPremiumBudgets.includes(budgetResponse)) {
    return { pkg: UBER_PREMIUM_PACKAGE, lotSize };
  }

  if (wishListIncludesExterior(wishList)) {
    return { pkg: OUTDOOR_TRANSFORMATION_PACKAGE, lotSize };
  }
  if (yardSelection === FRONT_YARD) {
    return { pkg: FRONT_YARD_PACKAGE, lotSize };
  }
  if (yardSelection === BACK_YARD) {
    return { pkg: BACK_YARD_PACKAGE, lotSize };
  }
  if (yardSelection === ENTIRE_PROPERTY) {
    return { pkg: FULL_YARD_PACKAGE, lotSize };
  }
};
