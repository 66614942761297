import { segment } from "./util/Segment";
import React, { useEffect } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { Providers } from "./util";
import { AppRouter } from "./components";
import FirebaseClientUtil from "@yardzen-inc/firebase-client-util";
import { forceLogoutWhenInconsistentUserDetected } from "./util/cookies/methods";
import { store } from "./store";
import { Provider } from "react-redux";
import { ErrorBoundary } from "./util/errors/ErrorBoundary";
import "./util/newrelic";
import { CrashPage } from "./pages/CrashPage";
import { persistStore } from "redux-persist";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getStripeKey } from "./util/functions/getStripeKey";
import { SplitAlertBannerByTrafficType } from "./components/SplitAlertBannerByTrafficType";
import { ANON_DISPLAY_BANNER } from "./util/Split/splitTreatments";
import { TrafficTypes } from "@yardzen-inc/react-split";
import { ScrollToTop } from "./components/ScrollToTop";
import { useLogRocket } from "./util/hooks/useLogRocket";
import { getAuth } from "firebase/auth";

const stripePromise = loadStripe(getStripeKey());

let persistor = persistStore(store);

forceLogoutWhenInconsistentUserDetected();
FirebaseClientUtil.setUserClaimListener();

const App: React.FC = () => {
  useEffect(() => {
    const auth = getAuth();
    return auth.onAuthStateChanged(user => {
      if (user) {
        segment.setUserId(user.uid);
        segment.identify({
          email: user?.email ?? undefined,
        });
      }
    });
  }, []);

  useLogRocket();
  return (
    <>
      <ErrorBoundary
        onError={err => {
          try {
            window?.newrelic?.noticeError(err, {
              "ErrorBoundary Exception": 1,
            });
          } catch (error) {
            console.error(error);
          }
        }}
        ErrorPageComponent={CrashPage}
      >
        <Providers>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Elements stripe={stripePromise}>
                <SplitAlertBannerByTrafficType
                  splitTreatment={ANON_DISPLAY_BANNER}
                  trafficType={TrafficTypes.ANONYMOUS}
                />
                <ScrollToTop />
                <AppRouter />
              </Elements>
            </PersistGate>
          </Provider>
        </Providers>
      </ErrorBoundary>
    </>
  );
};

export default App;
