import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks";
import { setBudgetIntent } from "../../../slices/onboardSlice";

export type BudgetIntent = "25" | "40" | "70" | null;

/**
 * Wish List Budget Intent component.
 * @returns The component.
 */
const WishListPrioritizePageBudgetIntent: FC = () => {
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { budgetIntent, budgetIntentErrorText } = useAppSelector(
    state => state.onboard
  );

  return (
    <Box>
      <FormControl error>
        <FormLabel id="budget-intent-label">
          <YZTypography
            type="serif"
            variant="h5"
            color={budgetIntentErrorText ? "error" : "textPrimary"}
          >
            To design with your preferences in mind, what budget should we
            target within your estimate range?
          </YZTypography>
        </FormLabel>
        <Box ml={mobile ? 3 : 5} mt={2} textAlign="left">
          <RadioGroup
            aria-labelledby="budget-intent-label"
            name="budget-intent"
            value={budgetIntent}
            onChange={e =>
              dispatch(
                setBudgetIntent({
                  budgetIntent: e.target.value as BudgetIntent,
                })
              )
            }
          >
            <FormControlLabel
              value="25"
              control={<Radio />}
              label="Prioritize budget - Show me a design at the lower end of the range."
            />
            <FormControlLabel
              value="40"
              control={<Radio />}
              label="Balance budget and possibility - Show me a design in the middle of the range."
            />
            <FormControlLabel
              value="70"
              control={<Radio />}
              label="Prioritize vision - Show me a design at the higher end of the range."
            />
          </RadioGroup>
          {budgetIntentErrorText && (
            <FormHelperText>{budgetIntentErrorText}</FormHelperText>
          )}
        </Box>
      </FormControl>
    </Box>
  );
};

export { WishListPrioritizePageBudgetIntent };
