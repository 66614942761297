export type LotSizeTypes =
  | "Small Lot: Less than 5000 sq feet"
  | "Standard lot: Up to 1/2 acre (B)"
  | "Standard lot: Up to 1/2 acre"
  | "Large lot: Up to 1 acre"
  | "Large lot: Up to 1 acre (B)"
  | "Acreage: More than 1 acre"
  | "Standard lot: Up to 1/2 acre (Lifestyle)"
  | "Large lot: Up to 1 acre (Lifestyle)"
  | "Standard lot: Up to 1/2 acre - Lowes";

export const ACREAGE_LOT: LotSizeTypes = "Acreage: More than 1 acre";
export const LARGE_LOT: LotSizeTypes = "Large lot: Up to 1 acre";
export const LARGE_LOT_B: LotSizeTypes = "Large lot: Up to 1 acre (B)";
export const SMALL_LOT: LotSizeTypes = "Small Lot: Less than 5000 sq feet";
export const STANDARD_LOT: LotSizeTypes = "Standard lot: Up to 1/2 acre";
export const STANDARD_LOT_B: LotSizeTypes = "Standard lot: Up to 1/2 acre (B)";
export const LOWES_LOT: LotSizeTypes = "Standard lot: Up to 1/2 acre - Lowes";

// Lifestyle packages
export const STANDARD_LIFESTYLE: LotSizeTypes =
  "Standard lot: Up to 1/2 acre (Lifestyle)";
export const LARGE_LIFESTYLE: LotSizeTypes =
  "Large lot: Up to 1 acre (Lifestyle)";

export const ACREAGE_LOT_WORDPRESS_NAME = "acreage";
export const LARGE_LOT_WORDPRESS_NAME = "large";
export const STANDARD_LOT_WORDPRESS_NAME = "standard";
export const LOWES_LOT_WORDPRESS_NAME = "mylowes-rewards-design-package";

// Display names
export const STANDARD_LOT_DISPLAY_NAME = "Standard Lot";
export const LARGE_LOT_DISPLAY_NAME = "Large Lot (over 1/2 acre)";

// RE: GREEN-153
// The lot names seem to correspond to data on actual stripe products
// It seems like a safer option to modify the strings displayed in the ui
// rather than the strings used to identify the products
export const lotSizeDisplayNames = Object.freeze({
  [ACREAGE_LOT]: ACREAGE_LOT,
  [LARGE_LOT]: LARGE_LOT_DISPLAY_NAME,
  [LARGE_LOT_B]: LARGE_LOT_DISPLAY_NAME,
  [SMALL_LOT]: SMALL_LOT,
  [STANDARD_LOT]: STANDARD_LOT_DISPLAY_NAME,
  [STANDARD_LOT_B]: STANDARD_LOT_DISPLAY_NAME,
  [STANDARD_LIFESTYLE]: STANDARD_LOT_DISPLAY_NAME,
  [LARGE_LIFESTYLE]: LARGE_LOT_DISPLAY_NAME,
});
