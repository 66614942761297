import { useEffect } from "react";

/** Prevents navigation with an alert using `onbeforeunload`
 *
 * Text in alert is not changeable on modern browsers
 *
 * @param prevent `boolean`
 */
function usePreventNavigation(prevent: boolean) {
  useEffect(() => {
    if (!prevent) {
      window.onbeforeunload = null;
    } else {
      window.onbeforeunload = () => {
        return "Your account may not be set up if you leave";
      };
    }
  }, [prevent]);
}

export { usePreventNavigation };
