import { MenuItem, TextField } from "@material-ui/core";
import * as React from "react";
import availableStatesConstant from "../../util/constants/availableStatesConstant";
import { SelectOption } from "../onboard";

interface StateSelectProps {
  value: string;
  required?: boolean;
  error: boolean;
  onBlur?: (...args: any) => any;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const StateSelect: React.FunctionComponent<StateSelectProps> = ({
  value,
  error,
  onChange,
  onBlur,
  required,
}) => {
  return (
    <TextField
      select
      error={error}
      label="State"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      fullWidth
      variant="outlined"
      size="small"
      required={required}
    >
      {availableStatesConstant.map((availableState: SelectOption) => (
        <MenuItem key={availableState.mask} value={availableState.value}>
          {availableState.value}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default StateSelect;
