import * as React from "react";
import UploadInstructionSequence from "./UploadInstructionSequence";
import { Typography, makeStyles, Theme } from "@material-ui/core";
import NavButtons from "./NavButtons";
import { useHistory } from "react-router";
import useHeaderVariant from "../../util/hooks/useHeaderVariant";

export interface InstructionContainerProps {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: "auto",
    width: "960px",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentRoot: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  button: {
    alignSelf: "flex-end",
    width: "min-content",
    margin: theme.spacing(2),
  },
  gridRoot: {
    display: "grid",
    marginTop: theme.spacing(4),
    margin: "auto",
    width: "100%",
    justifyItems: "center",
    gridTemplateColumns: "50% 50%",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "100%",
    },
  },
  gridItem: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
    pointerEvents: "all",
  },
  gridItemTitle: {
    minHeight: "48px",
  },
  gridItemImg: {
    maxWidth: "340px",
    minHeight: "152px",
  },
}));

export interface InstructionStep {
  title: string;
  subtitle: string;
  altTitle: string;
  imgUrl: string;
  pictureTag: (className: string) => JSX.Element;
}

export const instructionSteps: InstructionStep[] = [
  {
    title: "Let’s take some wide angle photos!",
    subtitle:
      "Make sure to have your phone horizontal (in landscape mode). Take at least one photo per angle.",
    altTitle: "1. Wide photos of the entire space",
    imgUrl:
      "https://yardzen-public-assets.storage.googleapis.com/Modal-01-WideAngle.jpg",
    pictureTag: (className: string) => (
      <picture>
        <img
          src="https://yardzen-public-assets.storage.googleapis.com/Modal-01-WideAngle.jpg"
          className={className}
          alt="[ALT HERE]"
        />
      </picture>
    ),
  },
  {
    title: "Next, you’ll take a slow pan video",
    subtitle:
      "We recommend slowly panning around each yard. Make sure to capture everything (including your home!).",
    altTitle: "2. A panning video to capture everything",
    imgUrl:
      "https://yardzen-public-assets.storage.googleapis.com/Modal-02-SlowPanVid.png",
    pictureTag: (className: string) => (
      <picture>
        <source
          type="image/webp"
          srcSet="https://yardzen-public-assets.storage.googleapis.com/Modal-02-SlowPanVid.webp"
        />
        <img
          src="https://yardzen-public-assets.storage.googleapis.com/Modal-02-SlowPanVid.png"
          className={className}
          alt="[ALT HERE]"
        />
      </picture>
    ),
  },
  {
    title: "Talk through a video *optional*",
    subtitle:
      "If there are areas where you need to highlight special circumstances, talk to us in another 2-3 min video. This is optional!",
    altTitle: "3. An audible video",
    imgUrl:
      "https://yardzen-public-assets.storage.googleapis.com/Modal-03-AudibleVid.png",
    pictureTag: (className: string) => (
      <picture>
        <source
          type="image/webp"
          srcSet="https://yardzen-public-assets.storage.googleapis.com/Modal-03-AudibleVid.webp"
        />
        <img
          src="https://yardzen-public-assets.storage.googleapis.com/Modal-03-AudibleVid.png"
          className={className}
          alt="[ALT HERE]"
        />
      </picture>
    ),
  },
  {
    title: "Pics of what we should keep and remove *optional*",
    subtitle:
      "After you upload your images, you’ll have a chance to describe what you want to keep or remove.",
    altTitle: "4. Photos of elements to keep and remove",
    imgUrl:
      "https://yardzen-public-assets.storage.googleapis.com/Modal-04-Keep_Remove.png",
    pictureTag: (className: string) => (
      <picture>
        <source
          type="image/webp"
          srcSet="https://yardzen-public-assets.storage.googleapis.com/Modal-04-Keep_Remove.webp"
        />
        <img
          src="https://yardzen-public-assets.storage.googleapis.com/Modal-04-Keep_Remove.png"
          className={className}
          alt="[ALT HERE]"
        />
      </picture>
    ),
  },
];

const InstructionContainer: React.FunctionComponent<InstructionContainerProps> = props => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(true);
  const history = useHistory();
  const [header, subHeader] = useHeaderVariant();
  const classes = useStyles();
  const [specificModalIndex, setSpecificModalIndex] = React.useState<number>();

  return (
    <div className={classes.root}>
      <UploadInstructionSequence
        instructionSteps={instructionSteps}
        open={modalOpen}
        onClose={handleClose}
        specificIndex={specificModalIndex}
      />
      <div className={classes.contentRoot}>
        <Typography className={classes.title} variant={header}>
          Time to get outside! ~ 15 mins
        </Typography>
        <Typography className={classes.subtitle} variant={subHeader}>
          It’s time to get your phone and take some photos and videos of your
          yard. For <b>each yard</b>, here’s what we’re going to need.
        </Typography>
        <div className={classes.gridRoot}>
          {instructionSteps.map((step, index) => {
            return (
              <div
                key={`${step.title}-${index}`}
                className={classes.gridItem}
                onClick={() => {
                  setSpecificModalIndex(index);
                  setModalOpen(true);
                }}
              >
                <Typography className={classes.gridItemTitle}>
                  {step.altTitle}
                </Typography>
                {step.pictureTag(classes.gridItemImg)}
              </div>
            );
          })}
        </div>
      </div>
      <NavButtons
        previous={() => history.push("/onboard/my-yard/choose-yards")}
        next={() => history.push("/onboard/my-yard/select-yard")}
      />
    </div>
  );

  function handleClose() {
    setSpecificModalIndex(undefined);
    setModalOpen(false);
  }
};

export default InstructionContainer;
export { InstructionContainer };
