import React, {
  createContext,
  FC,
  memo,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useCreateUserProjectInPostgres } from "./useCreateUserProjectInPostgres";
import ItemResponseProvider from "./ItemResponseContext";
import { GenericLoadingSpinner } from "@yardzen-inc/react-common";
import { Box } from "@material-ui/core";
import { OnboardCtx } from "../../../util";

export interface BudgetStepCtxValue {
  user: { id: string };
  project: { id: string; user_id: string; budget_checklist_id: string };
}

export interface BudgetStepProviderProps {}

const BudgetStepCtx = createContext<BudgetStepCtxValue>(null as any);

const BudgetStepProvider: FC<BudgetStepProviderProps> = memo(({ children }) => {
  const res = useCreateUserProjectInPostgres();
  const { setOnboardContextError } = useContext(OnboardCtx);
  const timeoutRef = useRef<any>(null);

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(triggerErrorTimeout, [res]);
  useEffect(clearTimeoutOnUmount, []);

  if (!res)
    return (
      <>
        <Box width="100%" pt={10}>
          <GenericLoadingSpinner />
        </Box>
      </>
    );

  const [user, project] = res;

  return (
    <BudgetStepCtx.Provider
      value={{
        user,
        project: project as NonNullable<typeof project> & {
          budget_checklist_id: string;
        },
      }}
    >
      <ItemResponseProvider
        projectId={project.id}
        checklistId={project.budget_checklist_id}
      >
        {children}
      </ItemResponseProvider>
    </BudgetStepCtx.Provider>
  );

  function clearTimeoutOnUmount() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  function triggerErrorTimeout() {
    if (!res) {
      timeoutRef.current = setTimeout(() => {
        setOnboardContextError(
          "hook useCreateUserProjectInPostgres timed out after 10 seconds"
        );
      }, 10000);
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  }
});

export { BudgetStepProvider, BudgetStepCtx };
export default BudgetStepProvider;
