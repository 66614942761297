import { useMemo } from "react";

export interface UseRatio {
  (
    numerator: number,
    denominator: number,
    yValue: boolean,
    value: number
  ): number;
}

/*
  solves for the corresponding x or y value of a pair based
  on a given ratio and known x or y value.
*/
const useRatio: UseRatio = (numerator, denominator, valueIsY, value) => {
  return useMemo(solveFor, [numerator, denominator, valueIsY, value]);

  /*
    rx = numerator
    ry = denominator

    solve for y:
    rx/ry = value/y
    value = y(rx/ry)
    y = value(ry/rx)

    solve for x:
    rx/ry = x/value
    x = value(rx/ry)
  */
  function solveFor(): number {
    // solve for x
    if (valueIsY) {
      return value * (numerator / denominator);
    }

    // solve for y
    return value * (denominator / numerator);
  }
};

export { useRatio };
export default useRatio;
