import React from "react";
import { Box, Grow, makeStyles, Theme } from "@material-ui/core";
import { HeaderBase, DefaultHeader, CenterFullScreen } from "../../components";
import { PaymentConfirmed } from "../../components/checkout";
import YZFooter from "../../components/footer/YZFooter";
import { useAppSelector } from "../../hooks";
import { getEmail } from "../../components/checkout/util/getEmail";
import { UserCtx } from "../../util";
import {
  AnonymousUserControlWrapper,
  useDesignProfileCtx,
} from "../../components/designProfile";
import {
  GenericLoadingSpinner,
  YZThemeProvider,
} from "@yardzen-inc/react-common";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
}));

export const PackageFlowPaymentConfirmedPage = () => {
  const { root } = useStyles();
  const history = useHistory();
  const { products } = useAppSelector(state => state.products);
  const userContext = React.useContext(UserCtx);
  const { designProfile } = useDesignProfileCtx();
  const { selectedSku, orderId, modifiedPrice } = useAppSelector(
    state => state.products
  );
  const { firstName, lastName, address } = useAppSelector(
    state => state.checkout
  );
  const email = getEmail({ userContext, designProfile });

  if (!selectedSku) {
    history.push("/packages");
    return null;
  }

  if (!products || !products.length) {
    return (
      <CenterFullScreen>
        <GenericLoadingSpinner />
      </CenterFullScreen>
    );
  }

  return (
    <AnonymousUserControlWrapper>
      <YZThemeProvider>
        <HeaderBase>
          <DefaultHeader />
        </HeaderBase>
        <Box mt={3} className={root}>
          <Grow in={true}>
            <Box>
              <PaymentConfirmed
                orderId={orderId}
                email={email}
                phone={designProfile?.contactInformation?.phoneNumber as string}
                firstName={firstName}
                lastName={lastName}
                address={address}
                skuObject={selectedSku}
                modifiedPrice={modifiedPrice}
              />
              <YZFooter />
            </Box>
          </Grow>
        </Box>
      </YZThemeProvider>
    </AnonymousUserControlWrapper>
  );
};
