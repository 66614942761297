export type YardSizeTypes =
  | "LESS THAN 5000 SQUARE FEET"
  // We'll leave this type in so we don't have to add it back later when
  // setting up the small lot A/B test.
  | "LESS THAN 1/2 ACRE"
  | "5000 SQ FT TO 1/2 ACRE"
  | "1/2 ACRE TO 1 ACRE"
  | "MORE THAN 1/2 ACRE"
  | "MORE THAN 1 ACRE"
  | "I DON'T KNOW";

export const SMALL_YARD: YardSizeTypes = "LESS THAN 5000 SQUARE FEET";

export const STANDARD_YARD: YardSizeTypes = "LESS THAN 1/2 ACRE";

export const LARGE_YARD: YardSizeTypes = "MORE THAN 1/2 ACRE";

export const ACREAGE_YARD: YardSizeTypes = "MORE THAN 1 ACRE";
