import {
  CREATE_ACCOUNT_PATH,
  FIND_YOUR_PACKAGE_PATH,
  PAYMENT_CONFIRMED_PATH,
  PURCHASE_PATH,
  RECOMMENDED_PACKAGE_PATH,
  TYPEFORM_PATH,
} from "../constants/paths";

export const isQuizPage = (path: string) => {
  switch (path) {
    case FIND_YOUR_PACKAGE_PATH:
    case RECOMMENDED_PACKAGE_PATH:
    case CREATE_ACCOUNT_PATH:
    case PURCHASE_PATH:
    case PAYMENT_CONFIRMED_PATH:
    case TYPEFORM_PATH:
      return false;
    default:
      return true;
  }
};
