export interface IAddressObject {
  placeId?: string;
  formattedAddress?: string;
  street: string;
  city: string;
  zip: string;
  country?: string;
  county?: string;
  state: string;
  latLng?: google.maps.LatLngLiteral | google.maps.LatLng;
  aptNumber?: string;
}

function parseGeocodeLocationToAddress(
  location: google.maps.GeocoderResult
): IAddressObject {
  if (!location?.address_components) {
    throw new Error("tried to store location data with no location chosen");
  }

  let placeId: string = location.place_id;
  let streetNumber: string = "";
  let route: string = "";
  let city: string = "";
  let state: string = "";
  let zip: string = "";
  let country: string = "";
  let county: string = "";

  for (const addressComponent of location.address_components) {
    if (addressComponent.types.includes("street_number")) {
      streetNumber = addressComponent.short_name;
    } else if (addressComponent.types.includes("route")) {
      route = addressComponent.short_name;
    } else if (addressComponent.types.includes("locality")) {
      city = addressComponent.long_name;
    } else if (addressComponent.types.includes("administrative_area_level_1")) {
      state = addressComponent.short_name;
    } else if (addressComponent.types.includes("administrative_area_level_2")) {
      county = addressComponent.long_name;
    } else if (addressComponent.types.includes("postal_code")) {
      zip = addressComponent.long_name;
    } else if (addressComponent.types.includes("country")) {
      country = addressComponent.short_name;
    }
  }

  if (!city) {
    for (const addressComponent of location.address_components) {
      if (
        addressComponent.types.some(type =>
          ["neighborhood", "administrative_area_level_3"].includes(type)
        )
      ) {
        city = addressComponent.long_name;
      }
    }
  }

  return {
    placeId,
    zip,
    street: `${streetNumber} ${route}`,
    city,
    country,
    county,
    state,
    latLng: location?.geometry.location.toJSON
      ? location?.geometry.location.toJSON()
      : location?.geometry.location,
    formattedAddress: location.formatted_address,
  };
}

export { parseGeocodeLocationToAddress };
