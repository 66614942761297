import { useEffect } from "react";

const useLogError = (
  error: Error | null,
  throwsError: boolean = false,
  devOnly: boolean = false
) => {
  useEffect(logError);

  function logError(): void {
    if (error) {
      if (devOnly && !(process.env.NODE_ENV === "development")) {
        return;
      }

      if (throwsError) {
        throw error;
      }

      console.error(error);
    }
  }
};

export { useLogError };
export default useLogError;
