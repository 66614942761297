/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grow } from "@material-ui/core";
import { StripeSKU } from "@yardzen-inc/models";
import Cookies from "js-cookie";
import * as React from "react";
import { injectStripe } from "react-stripe-elements";
import { PriceObject } from "../../types/Prices";
import { Product } from "../../types/Products";
import { useGoogleAnalyticsPageview, UserCtx } from "../../util";
import { IAddressObject } from "../../util/functions/parseGeocodeLocationToAddress";
import { SegmentFlows, useSegment } from "../../util/Segment";
import { useDesignProfileCtx } from "../designProfile";
import { CheckoutForm } from "./CheckoutForm";
import { getGAPageName } from "./util/getGAPageName";

export interface PurchaseProps {
  match?: {
    path: string;
    url: string;
    params: {
      productSku: string;
    };
  };
  location?: {
    state?: {
      skuObject: StripeSKU;
    };
  };
  nextStep: () => void;
  requireUser?: boolean;
  stripe?: any;
  setCheckoutFirstName?: React.Dispatch<React.SetStateAction<string | null>>;
  setCheckoutEmail?: React.Dispatch<React.SetStateAction<string | null>>;
  selectedProduct?: Product;
  selectedSku?: PriceObject;
}

const Purchase: React.FC<PurchaseProps> = props => {
  const segment = useSegment();
  const { designProfile } = useDesignProfileCtx();
  const userContext = React.useContext(UserCtx);
  const [propertyAddress] = React.useState<IAddressObject>({
    street: designProfile?.contactInformation?.street || "",
    city: designProfile?.contactInformation?.city || "",
    zip: designProfile?.contactInformation?.zip || "",
    state: designProfile?.contactInformation?.state || "",
    latLng: designProfile?.contactInformation?.latLng || undefined,
    formattedAddress: "",
    country: "US",
  });

  const pageName = window.location.href.includes("packages")
    ? getGAPageName({ userContext, propertyAddressComplete: false })
    : "";

  useGoogleAnalyticsPageview(pageName);

  React.useEffect(onMount, []);

  React.useEffect(() => {
    if (pageName) {
      segment.page(window.location.pathname + "/" + pageName, {
        fbp: Cookies.get("_fbp") || null,
        fbc: Cookies.get("_fbc") || null,
      });
    }
  }, [pageName]);

  React.useEffect(() => {
    // Sets checkout email
    if (!props.setCheckoutEmail) return;

    if (!!userContext && !userContext.isAnonymous && !!userContext.email) {
      props.setCheckoutEmail(userContext.email);
      return;
    }

    if (designProfile?.contactInformation?.email) {
      props.setCheckoutEmail(designProfile.contactInformation.email);
    }
  }, [designProfile, userContext]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={3}
      mb={3}
    >
      <Grow in={true}>
        <>
          <CheckoutForm
            nextStep={props.nextStep}
            address={propertyAddress}
            noBackButton={props.requireUser}
            propertyAddress={propertyAddress}
            showSidebar
          />
        </>
      </Grow>
    </Box>
  );

  function onMount() {
    segment.trackFlowStarted({
      flow_name: SegmentFlows.CHECKOUT,
    });
  }
};

export default injectStripe(Purchase);
