import * as React from "react";
import { Box } from "@material-ui/core";
import { usePageDimensions } from "../../util";

export interface CenterFullScreenProps {
  children: any;
  headerHeight?: string;
  onContainerClick?: () => void;
  resizeDeps?: any[];
  className?: string;
  style?: React.CSSProperties;
  keepFullScreenHeight?: boolean;
}

const CenterFullScreen: React.FC<CenterFullScreenProps> = React.memo(
  (props: CenterFullScreenProps) => {
    const dims = usePageDimensions(
      undefined,
      props.resizeDeps,
      props.keepFullScreenHeight
    );

    return (
      <Box
        className={props.className}
        style={{ transition: "height 200ms", ...props.style }}
        display="flex"
        flexDirection="column"
        width={dims[0]}
        maxWidth={"100vw"}
        marginLeft="auto"
        marginRight="auto"
        justifyContent="center"
        alignItems="center"
        height={
          props.headerHeight
            ? `calc(${dims[1].toString()}px - ${props.headerHeight})`
            : dims[1]
        }
        maxHeight={dims[1]}
        onClick={props.onContainerClick}
      >
        {props.children}
      </Box>
    );
  }
);

export { CenterFullScreen };
