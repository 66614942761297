import {
  BACK_YARD,
  ENTIRE_PROPERTY,
  FRONT_YARD,
} from "../../../../util/constants/yardSelectionTypes";
import {
  ACREAGE_YARD,
  LARGE_YARD,
  STANDARD_YARD,
} from "../../../../util/constants/yardSizeTypes";

export const helpNeededOptions = [
  "DESIGNING MY YARD",
  "MAINTAINING MY YARD",
  "INSTALLING MY DESIGN",
  "STYLING MY YARD",
  "I'M NOT SURE",
];

export const yardSelectionOptions = [FRONT_YARD, BACK_YARD, ENTIRE_PROPERTY];

export const yardSizeOptions = [
  STANDARD_YARD,
  LARGE_YARD,
  ACREAGE_YARD,
  "I DON'T KNOW",
];

export const landscapingOptions = [
  "PLANTS",
  "HARDSCAPING",
  "POOL OR HOT TUB",
  "PLAY AREA",
  "OUTDOOR KITCHEN",
  "FURNITURE & DECOR",
  "DECK OR PATIO",
  "LIGHTING",
  "FENCE",
];

export const exteriorOptions = [
  "EXTERIOR PAINT OR SIDING",
  "DOORS & WINDOWS",
  "ROOF",
];

export const uniqueYardOptions = [
  "SLOPED",
  "POOR DRAINAGE",
  "LACK OF PRIVACY",
  "FULL SHADE",
  "EMPTY LOT",
  "DIFFICULT TERRAIN",
  "DISJOINTED STYLE",
  "OVERGROWN",
  "UNHAPPY PLANTS",
];

export const timeFrameOptions = [
  "RIGHT AWAY",
  "1 - 3 MONTHS",
  "4 - 6 MONTHS",
  "7 - 12 MONTHS",
  "12+ MONTHS",
  "I DON'T KNOW",
];

export const budgetOptions = [
  "LESS THAN $10,000",
  "$10,000 - $50,000",
  "$50,000 - $100,000",
  "$100,000+",
  "I'M NOT SURE",
];
