import * as React from "react";
import NavButtons from "./NavButtons";
import GenericSnackBar from "../utility/GenericSnackBar";
import { Box, BoxProps, Theme } from "@material-ui/core";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  saveBar: {
    width: "calc(100% - 280px)",
    textAlign: "center",
    position: "fixed",
    bottom: "1.5rem",
    [breakpoints.down("sm")]: {
      width: "70vw",
    },
  },
}));

interface StepWrapperProps extends BoxProps {
  next?: () => void;
  noScrollOnNext?: boolean;
  previous?: () => void;
  noScrollOnPrevious?: boolean;
  errorMessage?: string | false;
  onErrorClose?: () => void;
  noScrollToTop?: boolean;
  nextButtonText?: string;
  saveBar?: JSX.Element;
  setMaxWidth?: string;
}

const StepWrapper: React.FunctionComponent<StepWrapperProps> = ({
  next,
  previous,
  errorMessage,
  onErrorClose,
  noScrollOnNext,
  noScrollOnPrevious,
  children,
  nextButtonText,
  noScrollToTop,
  saveBar,
  setMaxWidth,
  ...boxProps
}) => {
  const classes = useStyles();
  const history = useHistory();

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _scrollToTop = React.useCallback(scrollToTop, [
    noScrollToTop,
    noScrollOnNext,
  ]);

  React.useEffect(() => {
    if (!noScrollToTop) {
      _scrollToTop();
    }
  }, [noScrollToTop, noScrollOnNext, _scrollToTop]);

  return (
    <Box>
      <NavButtons
        previous={
          !!previous
            ? () => {
                if (!!errorMessage) {
                  scrollToTop();
                }
                if (!!previous) {
                  previous();
                }
              }
            : defaultPreviousFunction()
        }
        nextButtonText={nextButtonText}
      />
      <Box
        maxHeight="100%"
        maxWidth="100%"
        width={setMaxWidth || "960px"}
        p={2}
        pb={8}
        margin="auto"
        {...boxProps}
      >
        <div>{children}</div>

        {!!onErrorClose && (
          <GenericSnackBar
            variant="error"
            message={!!errorMessage ? errorMessage : undefined}
            in={!!errorMessage}
            onClose={onErrorClose}
          />
        )}
      </Box>
      {saveBar && <Box className={classes.saveBar}>{saveBar}</Box>}
    </Box>
  );

  function defaultPreviousFunction() {
    if (history.location.pathname.split("/").pop() !== "my-yard") {
      return () => history.push("/onboard/my-yard");
    }
    return void 0;
  }

  function scrollToTop() {
    if (noScrollOnNext) {
      return;
    }

    window.scrollTo({ top: 0 });
    const myDiv = document.getElementById("onboardContent-root");
    if (!myDiv) return;
    myDiv.scrollTop = 0;
  }
};

export default StepWrapper;
