import * as React from "react";
import {
  Typography,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Theme,
  FormControl,
  FormHelperText,
  FormLabel,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import { OnboardCtx } from "../../util";
import { useHistory } from "react-router";
import useHeaderVariant from "../../util/hooks/useHeaderVariant";
import StepWrapper from "./StepWrapper";
import useSetTitle from "../../util/hooks/useSetTitle";

interface ChooseYardProps {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "960px",
    maxWidth: "100%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  inputs: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  checkBoxRow: {
    display: "flex",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  checkBox: {},
}));

const ChooseYard: React.FunctionComponent<ChooseYardProps> = props => {
  useSetTitle("Yardzen - Choose Yard", true, []);

  const classes = useStyles();
  const history = useHistory();
  const { setYardsStatusObj } = React.useContext(OnboardCtx);

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));

  const [primaryYard, setPrimaryYard] = React.useState<"front" | "back">();
  const [secondaryYards, setSecondaryYards] = React.useState<
    ("left" | "right")[]
  >([]);
  const [error, setError] = React.useState<string>();

  const [headerVariant, subHeaderVariant] = useHeaderVariant();

  return (
    <StepWrapper
      errorMessage={error}
      next={handleSubmit}
      onErrorClose={() => setError(undefined)}
      className={classes.root}
      px={xsDown ? 2 : 5}
    >
      <Typography variant={headerVariant as any}>
        We can’t wait to get started!
      </Typography>
      <Typography variant={subHeaderVariant as any}>
        But first, which yards are we designing?
      </Typography>
      <div className={classes.inputs}>
        <FormControl error={!!error}>
          <FormLabel color="primary" component="legend">
            Please choose one:
          </FormLabel>
          <Box pl={smDown ? 2 : 4} className={classes.checkBoxRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={primaryYard === "front"}
                  onChange={handlePrimaryChange}
                  name="frontYard"
                  color="primary"
                  value="frontYard"
                />
              }
              label="Front yard"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={primaryYard === "back"}
                  onChange={handlePrimaryChange}
                  name="backYard"
                  color="primary"
                  value="backYard"
                />
              }
              label="Backyard"
            />
          </Box>
          <Box pb={2} display="flex" justifyContent="center">
            <FormHelperText>{error}</FormHelperText>
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel>Please select all that apply:</FormLabel>
          <Box pl={smDown ? 2 : 4} className={classes.checkBoxRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={secondaryYards.includes("left")}
                  onChange={handleSecondaryChange}
                  name="leftYard"
                  color="primary"
                  value="leftYard"
                />
              }
              label="Left side yard"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={secondaryYards.includes("right")}
                  onChange={handleSecondaryChange}
                  name="rightYard"
                  color="primary"
                  value="rightYard"
                />
              }
              label="Right side yard"
            />
          </Box>
        </FormControl>
      </div>
    </StepWrapper>
  );

  function handleSecondaryChange(event: React.ChangeEvent<HTMLInputElement>) {
    const yardIdx = secondaryYards.indexOf(
      event.target.value as "left" | "right"
    );
    if (yardIdx >= 0) {
      setSecondaryYards([
        ...secondaryYards.slice(0, yardIdx),
        ...secondaryYards.slice(yardIdx + 1),
      ]);
    } else {
      setSecondaryYards([
        ...secondaryYards,
        event.target.value as "left" | "right",
      ]);
    }
  }

  function handlePrimaryChange(evt: React.ChangeEvent<HTMLInputElement>) {
    if (primaryYard === evt.target.value) {
      setPrimaryYard(undefined);
    } else {
      setPrimaryYard(evt.target.value as "front" | "back");
    }
  }

  function handleSubmit(): void {
    if (!primaryYard) {
      return setError("Please select a primary yard for your design");
    }
    setYardsStatusObj(primaryYard, secondaryYards, false);
    history.push("/onboard/my-yard#instructions");
  }
};

export default ChooseYard;
