import { Box } from "@material-ui/core";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import { useDesignProfileCtx } from "./DesignProfileCtx";

interface DesignProfileContextErrorProps {
  children: any;
}

export const DesignProfileContextError = ({
  children,
}: DesignProfileContextErrorProps) => {
  const { error } = useDesignProfileCtx();

  if (error) {
    console.error(
      `An error occurred within the design profile. Error message : ${error.message}`
    );

    return (
      <Box my={8} mx={2} textAlign="center">
        <Box>
          <YZTypography variant="h4">
            Oops! Something went wrong while finding your design profile.
          </YZTypography>
        </Box>
        <Box mt={2}>
          <YZTypography>
            Please use the support chat on the bottom of your screen, or email{" "}
            <a href="mailto:support@yardzen.com">support@yardzen.com</a> for
            assistance.
          </YZTypography>
        </Box>
        <Box mt={4}>
          <YZButton color="primary" href="/packages">
            View all available packages
          </YZButton>
        </Box>
      </Box>
    );
  }

  return children;
};
