import { ExperienceVersionType } from "@yardzen-inc/react-common";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// we may want to expand this interface if we want to make other
// modifications to project docs
export interface UpdateProjectDocObj {
  experienceVersion: ExperienceVersionType;
  tags: string[] | firebase.firestore.FieldValue;
  referralSource?: string;
}

// update project doc by project id
// this function MUST be wrapped in a try/catch to handle possible error in the UI
async function updateProjectDoc(
  projectId: string,
  data: Partial<UpdateProjectDocObj>
) {
  try {
    return firebase
      .firestore()
      .collection("projects")
      .doc(projectId)
      .update(data);
  } catch (err) {
    window.newrelic.noticeError(err);
    throw new Error(`Error updating project document with id of ${projectId}`);
  }
}

export { updateProjectDoc };
export default updateProjectDoc;
