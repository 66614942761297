/**
 * Encrypts a string using SHA-1, and returns the hashes' hex representation.
 * @param stringToHash The string to encrypt.
 * @returns The hexadecimal representation of the SHA-1 hash.
 */
export const getSHA1HashOfString = async (
  stringToHash: string
): Promise<string> => {
  const buffer = new TextEncoder().encode(stringToHash);

  const hashedBuffer = await crypto.subtle.digest("SHA-1", buffer);

  const hashArray = Array.from(new Uint8Array(hashedBuffer));

  return hashArray
    .map(bufferValue => bufferValue.toString(16).padStart(2, "0"))
    .join("");
};
