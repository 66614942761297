import * as React from "react";
import { Box, Typography, makeStyles, BoxProps } from "@material-ui/core";
import classnames from "classnames";

export interface ToggleSelectButtonProps {
  value: string;
  toggleState: boolean;
  containerProps?: BoxProps;
  color?: "primary" | "secondary";
  children: string;
  onChange: (value: string) => void;
}

const useStyles = makeStyles(theme => ({
  typo: {
    fontSize: "1.5rem",
    textAlign: "center",
  },
  hover: (props: { color: "primary" | "secondary" }) => ({
    borderRadius: "5px",
    backgroundColor: "#ededed",
    transition: "background-color 150ms",
    pointerEvents: "all",
    "&:hover": {
      cursor: "pointer",
      backgroundColor:
        props.color === "primary"
          ? theme.palette.primary.light
          : theme.palette.secondary.light,
    },
    padding: "15px 20px 15px 20px",
    textTransform: "uppercase",
  }),
  toggled: (props: { color: "primary" | "secondary" }) => ({
    cursor: "pointer",
    backgroundColor: `${
      props.color === "primary"
        ? theme.palette.primary.main
        : theme.palette.secondary.main
    } !important`,
  }),
}));

const ToggleSelectButton: React.FC<ToggleSelectButtonProps> = (
  props: ToggleSelectButtonProps
) => {
  const classes = useStyles({ color: props.color ?? "primary" });

  return (
    <Box
      p={1}
      px={2}
      {...props.containerProps}
      onClick={() => props.onChange(props.value)}
      className={classnames(
        classes.hover,
        props.toggleState ? classes.toggled : undefined,
        props.containerProps?.className
      )}
      width="200px"
    >
      <Typography component="p" align="center" noWrap>
        {props.children}
      </Typography>
    </Box>
  );
};

export { ToggleSelectButton };
export default ToggleSelectButton;
