import {
  setAddress,
  setAddressAddedInDesignProfile,
} from "../../../slices/checkoutSlice";
import { AppDispatch } from "../../../store";
import { IAddressObject } from "../../../util/functions/parseGeocodeLocationToAddress";

interface HandleSetAddressArgs {
  dispatch: AppDispatch;
  address: IAddressObject;
}

export const handleSetAddress = ({
  dispatch,
  address,
}: HandleSetAddressArgs) => {
  dispatch(setAddress({ address }));
  dispatch(
    setAddressAddedInDesignProfile({ addressAddedInDesignProfile: true })
  );
};
