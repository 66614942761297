import React, { FC /*, useContext , useEffect */ } from "react";
import {
  Switch,
  useRouteMatch,
  Route /* Redirect */,
  Redirect,
} from "react-router-dom";
import HandleIncorrectRoute from "../../routing/HandleIncorrectRoute";
import useAllowAccessToExteriorDesignStep from "../useAllowAccessToExteriorDesignStep";
// import { OnboardCtx } from "../../../util";
import { FullScreenLoadingPage } from "../../loadingComponents/FullScreenLoadingPage";
import { ExteriorDesignIntro } from "./ExteriorDesignIntro";
import { ExteriorDesignPaint } from "./ExteriorDesignPaint";
import { ExteriorDesignInspiration } from "./inspiration/ExteriorDesignInspiration";
import { ColorsProvider } from "../../../util/contexts/ColorsContext";
import useAuthToken from "../../../util/hooks/useAuthToken";
import {
  SegmentFlows,
  SegmentSubFlows,
  useSegment,
} from "../../../util/Segment";
import { useEffect } from "react";

export interface ExteriorDesignRouterProps {}

const ExteriorDesignRouter: FC<ExteriorDesignRouterProps> = props => {
  const segment = useSegment();
  const match = useRouteMatch();
  const hasAccessToExteriorDesign = useAllowAccessToExteriorDesignStep();
  const token = useAuthToken();

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onMount, []);

  // Checks to see if user has permission to access Exterior Design
  // Null means that Split Feature Flag is not yet ready
  // False means that user should not have access

  if (hasAccessToExteriorDesign === null) {
    return <FullScreenLoadingPage in={true} />;
  } else if (hasAccessToExteriorDesign === false) {
    return <HandleIncorrectRoute />;
  }

  return (
    <ColorsProvider getBearerToken={() => token}>
      <Switch>
        <Route path={`${match.url}/`} exact component={ExteriorDesignIntro} />

        <Route path={`${match.url}/paint`} component={ExteriorDesignPaint} />

        <Route
          path={`${match.url}/inspiration`}
          component={ExteriorDesignInspiration}
        />
        <Route render={() => <Redirect to={`${match.url}/`} />} />
      </Switch>
    </ColorsProvider>
  );

  function onMount() {
    segment.trackSubFlowStarted({
      flow_name: SegmentFlows.ONBOARD,
      sub_flow_name: SegmentSubFlows.MY_YARD,
    });

    return () => {
      segment.trackSubFlowCompleted({
        flow_name: SegmentFlows.ONBOARD,
        sub_flow_name: SegmentSubFlows.MY_YARD,
      });
    };
  }
};

export { ExteriorDesignRouter };
export default ExteriorDesignRouter;
