import { PromotionalCode } from "../../../api/apiTypes";
import { getPromotionalCode } from "../../../services/promotionalCodes";
import { noticeError } from "../../../util/newrelic/newrelic";

export async function handleUpdatePromotionalCode(
  discountCodesApplied: any,
  setPromotionalCode: (PromotionalCode: PromotionalCode | null) => void
) {
  // it is important that the discount code and totals shown in checkout reflect that of
  // what the server decides is the source of truth. Currently there is no actual coupling
  // between what the server reports as the purchase price and what stripe will actually process,
  // I hope to solve some of that here.

  if (Array.isArray(discountCodesApplied) && discountCodesApplied.length) {
    try {
      const promoCode = await getPromotionalCode(discountCodesApplied[0]);

      if (!promoCode) {
        throw new Error(
          `Promo code ${discountCodesApplied[0]} should exist but is not found`
        );
      }
      setPromotionalCode(promoCode);
    } catch (err) {
      console.error(
        `failed to lookup promo code ${discountCodesApplied[0]}`,
        err
      );
      noticeError(err);
      setPromotionalCode(null);
    }
  } else {
    setPromotionalCode(null);
  }
}

/**
 * promisified alias for handleUpdatePromotionalCode
 * @param discountCodesApplied
 * @returns Promise<PromotionalCode | null>
 */
export async function updatePromotionalCode(
  discountCodesApplied: any
): Promise<PromotionalCode | null> {
  return new Promise(resolve => {
    handleUpdatePromotionalCode(discountCodesApplied, promoCode => {
      resolve(promoCode);
    });
  });
}
