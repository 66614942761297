// TODO: refactor all of this logic to use rtk query, use generate ts types
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { FetchError } from "../util/errors/FetchError";

export enum PromotionalCodeStatus {
  DEACTIVATED = "DEACTIVATED",
  MAX_REDEMPTIONS_EXCEEDED = "MAX_REDEMPTIONS_EXCEEDED",
  NOT_YET_AVAILABLE = "START_TIME_NOT_REACHED",
  EXPIRED = "EXPIRED",
  USABLE = "USABLE",
}

export enum DiscountType {
  PERCENTAGE = "PERCENTAGE",
  FIXED = "FIXED",
}

const promotionalCodeBaseUrl = (function() {
  const url = new URL(
    process.env["REACT_APP_PANGAEA_BASE_URL"] ?? "http://localhost:7777/v1/"
  );
  url.pathname = "/v1/promotional-codes";
  return url.toString();
})();

export async function getPromotionalCode(id: string) {
  const response = await fetch(`${promotionalCodeBaseUrl}/${id}`, {
    method: "GET",
    headers: await getAuthenticatedHeaders(),
  });

  if (response.status === 404) {
    return null;
  } else if (!response.ok) {
    throw new FetchError(response);
  }

  return await response.json();
}

async function getAuthenticatedHeaders() {
  const headers = new Headers();
  headers.set(
    "Authorization",
    `Bearer ${await firebase.auth()?.currentUser?.getIdToken()}`
  );

  return headers;
}
