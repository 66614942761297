import { Page, Selections } from "../../../../types/DesignProfile";
import { WISH_LIST_PATH } from "../../constants/paths";

interface GetNewResponseArgs {
  page: Pick<Page, "path" | "multiple_selections" | "quiz_step_number">;
  selectionValue: string;
  selectionKey: keyof Selections;
}

/**
 * Gets a new response to add to the design profile questions array.
 * @param {Pick<Page, "path" | "multiple_selections" | "completion_number">} page The page we're adding a response for.
 * @param {string} selectionValue The value we're adding to the response..
 * @param {keyof Selections} selectionKey The key of the type of selection.
 * @returns A design profile response object.
 */
export const getNewResponse = ({
  page,
  selectionValue,
  selectionKey,
}: GetNewResponseArgs) => {
  if (page.path === WISH_LIST_PATH) {
    return {
      path: page.path,
      response: {
        [selectionKey]: [selectionValue],
      },
      sequence: page.quiz_step_number ?? -1,
    };
  }

  return {
    path: page.path,
    response: page.multiple_selections ? [selectionValue] : selectionValue,
    sequence: page.quiz_step_number ?? -1,
  };
};
