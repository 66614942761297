import * as React from "react";
import { useUser } from "../hooks";
import { setUserIdInGoogleAnalytics } from "../functions";
import { setUserIdInTatari } from "../functions";
import { useSegment } from "../Segment";
import usePrevious from "../hooks/usePrevious";
import firebase from "firebase/compat/app";
import { identifyLogRocketUser } from "../identifyLogRocketUser";

interface UserProviderProps {
  children: React.ReactNode;
}

const UserCtx = React.createContext<firebase.User | null>(null);
const UserConsumer = UserCtx.Consumer;

const UserProvider: React.FC<UserProviderProps> = (
  props: UserProviderProps
) => {
  const segment = useSegment();
  const [error, user] = useUser();
  const previousValue = usePrevious(user, true);
  const Component = UserCtx.Provider;
  const uidDepCheck = user && user?.uid;

  React.useEffect(segmentIdentify, [previousValue, segment, user, uidDepCheck]);
  React.useEffect(() => {
    if (user) {
      identifyLogRocketUser({ userId: user?.uid || null });
    }
  }, [user, uidDepCheck]);
  React.useEffect(() => setUserIdInGoogleAnalytics(user), [user]);
  React.useEffect(() => setUserIdInTatari(user), [user]);
  // React.useEffect(() => setUserIdInCookies(user), [user]);
  React.useEffect(handleError, [error]);

  return <Component value={user || null}>{props.children}</Component>;

  function segmentIdentify() {
    if (user) {
      if (user.isAnonymous) {
        segment.identify({ yardzen_anonymous_id: user.uid });
      } else {
        segment.identify(user.uid, {
          email: user.email ?? "",
        });
      }
    } else if (previousValue && !user) {
      segment.reset();
    }
  }

  function handleError(): void {
    if (error) {
      console.error(error);
    }
  }
};

export { UserCtx, UserProvider, UserConsumer };
