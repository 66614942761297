import React, { FC } from "react";

export interface ErrorBoundaryProps {
  onError: (error: unknown) => void;
  ErrorPageComponent: FC<{ error: unknown }>;
}

export interface ErrorBoundaryState {
  error: unknown;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = {
    error: null,
  };

  componentDidCatch(error: unknown) {
    this.props.onError(error);
  }

  static getDerivedStateFromError(error: unknown) {
    return { error };
  }

  render() {
    return this.state.error ? (
      <this.props.ErrorPageComponent
        error={this.state.error}
      ></this.props.ErrorPageComponent>
    ) : (
      this.props.children
    );
  }
}
