/**
 * calculateBudgetRange is a helper for the budget checklist price displays that will increase
 * (or decrease if multipliers < 1) the estimate range and coerce into an integer.
 * @param estimate A tuple of *integers* that represents a budget estimate range, [low, high]
 * @param lowMultiplier A number to multiply the low estimate range by
 * @param highMultiplier A number to multiply the high estimate range by
 * @returns A tuple of integers that represents the modified price range [low, high]
 */
function calculateBudgetRange(
  estimate: [number, number] = [0, 0],
  lowMultiplier = 1,
  highMultiplier = 1
): [number, number] {
  return [
    Math.floor((estimate?.[0] ?? 0) * lowMultiplier),
    Math.floor((estimate?.[1] ?? 0) * highMultiplier),
  ];
}

export { calculateBudgetRange };
