import React, { FC } from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { YZTypography } from "@yardzen-inc/react-common";

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    left: "20px",
  },
}));

type DesignProfileBackButtonProps = {
  onPrevious: () => void;
};

/**
 * Design Profile Back Button component.
 * @param props The DesignProfileBackButtonProps to pass to the component
 * @returns The button component.
 */
const DesignProfileBackButton: FC<DesignProfileBackButtonProps> = (
  props: DesignProfileBackButtonProps
) => {
  const { onPrevious } = props;
  const { root } = useStyles();

  return (
    <Button onClick={onPrevious} variant="text" className={root}>
      <Box display="flex" alignItems="center" fontWeight={600}>
        <Box display="flex" alignItems="center">
          <ArrowBack fontSize="small" />
        </Box>{" "}
        <Box ml={0.5}>
          <YZTypography>Back</YZTypography>
        </Box>
      </Box>
    </Button>
  );
};

export { DesignProfileBackButton };
