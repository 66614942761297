import { getPromotionalCode } from "../../../services/promotionalCodes";
import { noticeError } from "../../../util/newrelic/newrelic";
import { withTimeout } from "../../../util/withTimeout";

export async function lookupPromoCode(code: string) {
  try {
    return await withTimeout(
      getPromotionalCode(code.trim().toUpperCase()),
      10000
    );
  } catch (err) {
    // for now, this error should not effect progress through checkout
    // for a v2 we should probably add feedback to referral/promo code validation
    console.error("Failed to look up promo code:", code);
    console.error(err);
    noticeError(err);
  }

  return null;
}
