import * as React from "react";
import { Card, CardActionArea, Typography, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CheckCircle } from "../iconComponents";
import useHeaderVariant from "../../util/hooks/useHeaderVariant";

interface YardSelectBoxProps {
  onClick: () => void;
  title: string;
  complete?: boolean;
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "140px",
    width: "210px",
    pointerEvents: "all",
    margin: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "120px",
    },
  },
  cardActionArea: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  cardDivider: {
    marginTop: 15,
    marginBottom: 15,
  },
  overlay: {
    float: "right",
    top: 0,
    position: "absolute",
    width: "100%",
    height: 55,
    color: "#80a1c1",
    opacity: 0.8,
    paddingRight: 30,
  },
}));

const YardSelectBox: React.FunctionComponent<YardSelectBoxProps> = ({
  title,
  complete,
  onClick,
  style,
}) => {
  const classes = useStyles();
  const subHeader = useHeaderVariant()[1];

  return (
    <Card style={style} className={classes.root}>
      <CardActionArea className={classes.cardActionArea} onClick={onClick}>
        <Typography variant={subHeader} align="center">
          {title}
        </Typography>
        <CheckCircle complete={complete} />
      </CardActionArea>
    </Card>
  );
};

export { YardSelectBox };
export default YardSelectBox;
