import { useHistory } from "react-router";
import { getFirstPageOfFlow } from "../../pages/designProfile/util/getFirstPageOfFlow";
import { DesignProfileVersionId } from "../../types/DesignProfile";
import { useDesignProfileCtx } from "./DesignProfileCtx";

interface DesignProfileWithEmailProps {
  children?: any;
}

export const DesignProfileWithEmail = ({
  children,
}: DesignProfileWithEmailProps) => {
  const history = useHistory();
  const {
    designProfile,
    isLoading,
    isFetching,
    designProfileVersionId,
  } = useDesignProfileCtx();

  if (
    designProfile &&
    !designProfile.contactInformation?.email &&
    !isLoading &&
    !isFetching
  ) {
    history.push(
      getFirstPageOfFlow(
        (designProfileVersionId ?? "") as DesignProfileVersionId
      )
    );
  }

  return children;
};
