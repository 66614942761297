import React, { FC, useContext, useEffect, useMemo } from "react";
import { ItemResponseCtx } from "../../../components/onboard/budget/ItemResponseContext";
import { WishListPrioritizePage } from "./WishListPrioritizePage";
import { calculateBudgetRange } from "../../../components/onboard/budget/calculateBudgetRange";
import { useFoundationalBudgetCostMultiplier } from "../../../components/onboard/budget/useFoundationalBudgetCostMultiplier";
import useGetBudgetComparisonData from "../../../components/onboard/budget/useGetBudgetComparisonData";
import { useBudgetMetadataAndPhasingByProjectIdQuery } from "@yardzen-inc/graphql";
import { BudgetStepCtx } from "../../../components/onboard/budget";
import { Box, LinearProgress } from "@material-ui/core";
import { useLogError } from "../../../util";

/**
 * Wish List Prioritize page container component.
 * @returns The page container component.
 */
const WishListPrioritizePageContainer: FC = props => {
  const [itemResponseData] = useContext(ItemResponseCtx);
  const {
    project: { id },
  } = useContext(BudgetStepCtx);
  const {
    data: budgetMetadata,
    loading,
    error,
    fetchMore,
  } = useBudgetMetadataAndPhasingByProjectIdQuery({
    variables: {
      projectId: id,
    },
    fetchPolicy: "network-only",
  });
  const { lowEstimate, highEstimate } = useGetBudgetComparisonData();

  const budgetCostMultiplier = useFoundationalBudgetCostMultiplier();

  const [
    lowAllInEstimate,
    highAllInEstimate,
    lowBaseCost,
    highBaseCost,
  ] = useMemo(
    () => [
      ...calculateBudgetRange(
        [lowEstimate, highEstimate],
        ...budgetCostMultiplier.map(cost => 1 + cost)
      ),
      ...calculateBudgetRange(
        [lowEstimate, highEstimate],
        ...budgetCostMultiplier
      ),
    ],
    [lowEstimate, highEstimate, budgetCostMultiplier]
  );

  useLogError(error as Error);

  useEffect(handleRetry, [error]);

  if (loading) {
    return (
      <Box width="100%">
        <LinearProgress variant="indeterminate" />
      </Box>
    );
  }

  return (
    itemResponseData && (
      <WishListPrioritizePage
        budgetMetadataQuery={budgetMetadata}
        reloadMetadataQuery={async () =>
          void (await fetchMore({ variables: { projectId: id } }))
        }
        itemResponseData={itemResponseData}
        lowEstimate={lowEstimate}
        highEstimate={highEstimate}
        lowAllInEstimate={lowAllInEstimate}
        highAllInEstimate={highAllInEstimate}
        lowBaseCost={lowBaseCost}
        highBaseCost={highBaseCost}
      ></WishListPrioritizePage>
    )
  );

  function handleRetry(): void | (() => void) {
    if (error) {
      if (error.networkError) {
        console.error(error.networkError);
      }
    }
  }
};

export { WishListPrioritizePageContainer };
