import * as React from "react";
import { Page } from "../types/DesignProfile";
import { DesignProfileQuizPage } from "../pages/designProfile/DesignProfileQuizPage";
import { MapLoadingPage } from "../pages/designProfile/MapLoadingPage";
import { CreateAccountPage } from "../pages/designProfile/CreateAccountPage";
import { DesignProfileLandingPage } from "../pages/designProfile/DesignProfileLandingPage";
import { RecommendedPackagePage } from "../pages/designProfile/RecommendedPackagePage";
import { DesignProfileEmailPhoneInputPage } from "../pages/designProfile/DesignProfileEmailPhoneInputPage";
import { AddressInputPage } from "../pages/designProfile/AddressInputPage";
import { AddressConfirmationPage } from "../pages/designProfile/AddressConfirmationPage";
import { PurchasePage } from "../pages/designProfile/PurchasePage";
import { PaymentConfirmedPage } from "../pages/designProfile/PaymentConfirmedPage";
import { DesignProfileTypeform } from "../pages/designProfile/DesignProfileTypeform";

export const designProfileComponents: Record<
  string,
  React.FC<{ page: Page; onNext: any; onPrevious: any }>
> = {};

designProfileComponents["DesignProfileLandingPage"] = DesignProfileLandingPage;
designProfileComponents["MapLoadingPage"] = MapLoadingPage;
designProfileComponents[
  "DesignProfileEmailPhoneInputPage"
] = DesignProfileEmailPhoneInputPage;
designProfileComponents["AddressInputPage"] = AddressInputPage;
designProfileComponents["AddressConfirmationPage"] = AddressConfirmationPage;
designProfileComponents["DesignProfileQuizPage"] = DesignProfileQuizPage;
designProfileComponents["RecommendedPackagePage"] = RecommendedPackagePage;
designProfileComponents["CreateAccountPage"] = CreateAccountPage;
designProfileComponents["PurchasePage"] = PurchasePage;
designProfileComponents["PaymentConfirmedPage"] = PaymentConfirmedPage;
designProfileComponents["DesignProfileTypeform"] = DesignProfileTypeform;
