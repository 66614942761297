import React, { FC, useState } from "react";
import { Box, makeStyles, useMediaQuery, Theme } from "@material-ui/core";
import { Close, Fullscreen } from "@material-ui/icons";
import { MediaProperties } from "@yardzen-inc/models";
import HEICPlaceHolder from "../mediaDisplay/HEICPlaceHolder";

export interface OnboardImageCardProps {
  width: number;
  height: number;
  expand?: () => void;
  delete?: () => void;
  backgroundSize?: "cover" | "contain" | string;
  iconURL?: string; // provided when preferencing an icon over a preview
  isConverting?: boolean;
  media: MediaProperties;
}

const useStyles = makeStyles(theme => ({
  overlay: {
    display: "flex",
    flexFlow: "row-reverse",
    [theme.breakpoints.up("md")]: {
      transition: "all 250ms",
      transitionProperty: "box-shadow, background-color, blur, filter",
    },
  },
  icon: {
    color: "#FFFFFFFF",
    pointerEvents: "all",
    zIndex: 2,
    [theme.breakpoints.up("md")]: {
      cursor: "pointer",
      transition: "opacity 250ms",
    },
  },
}));

const OnboardImageCard: FC<OnboardImageCardProps> = props => {
  const [hovered, setHovered] = useState<boolean>(false);
  const classes = useStyles();
  const [imageURL, setImageURL] = useState<string | undefined>("");

  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  React.useEffect(() => {
    const { thumbnailURL, downloadURL } = props.media;
    setImageURL(!!thumbnailURL ? thumbnailURL : downloadURL);
  }, [props.media]);

  return (
    <Box
      height={`${props.height}px`}
      width={`${props.width}px`}
      display="inline-block"
      boxShadow={2}
      style={{
        backgroundImage: `url(${!!props.iconURL ? props.iconURL : imageURL})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: !!props.backgroundSize ? props.backgroundSize : "cover",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box
        style={
          hovered
            ? {
                backgroundColor: "#00000099",
              }
            : undefined
        }
        width="inherit"
        height="inherit"
        className={classes.overlay}
        display="inline-flex"
      >
        {props.delete && (
          <Box p={1}>
            <Close
              onClick={props.delete}
              className={classes.icon}
              style={
                mdUp
                  ? {
                      opacity: hovered ? 1 : 0,
                    }
                  : undefined
              }
            ></Close>
          </Box>
        )}
        {props.expand && (
          <Box p={1}>
            <Fullscreen
              className={classes.icon}
              onClick={props.expand}
              style={
                mdUp
                  ? {
                      opacity: hovered ? 1 : 0,
                    }
                  : undefined
              }
            ></Fullscreen>
          </Box>
        )}
        <div style={{ left: "15%", position: "relative" }}>
          {props.media.fileType && props.media.fileType.includes("hei") && (
            <HEICPlaceHolder />
          )}
        </div>
      </Box>
    </Box>
  );
};

export { OnboardImageCard };
export default OnboardImageCard;
