import * as React from "react";
import { Box } from "@material-ui/core";
import Map from "./Map";
import { usePageDimensions, useLogError } from "../../util";
import MapController from "./util/MapController";
import GeocodeBar from "./GeocodeBar";

export interface MapDemoPageProps {}

export const MapDemoPage: React.FC<MapDemoPageProps> = (
  props: MapDemoPageProps
) => {
  const [mpController, setMpController] = React.useState<MapController | null>(
    null
  );
  const [mpError, setMpError] = React.useState<Error | null>(null);
  const dims = usePageDimensions();

  const [width, height] = dims;

  useLogError(mpError);

  React.useEffect(handleMpChange, [mpController]);

  return (
    <Box width={width} height={height}>
      <GeocodeBar mpController={mpController} />
      <Map
        onMapInitialize={g => setMpController(new MapController(g))}
        onError={err => setMpError(err)}
      />
    </Box>
  );

  function handleMpChange(): (() => void) | void {
    if (mpController) {
      return onMapInitialize(mpController);
    }
  }
};

function onMapInitialize(mp: MapController): () => void {
  return () => mp.destroy;
}

export default MapDemoPage;
