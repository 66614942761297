import React, { FC, useRef } from "react";
import { Box, Fade, makeStyles } from "@material-ui/core";
import {
  useTriggerTimeout,
  YZButton,
  YZTypography,
} from "@yardzen-inc/react-common";
import { useHistory } from "react-router";
import Typeform from "../TypeformQuiz";
import { UserCtx, ProfileCtx, updateProfileDoc } from "../../../util";
import LogRocket from "logrocket";
import { FullScreenLoadingPage } from "../../loadingComponents";
import { ASSETS_BASE_URL } from "../../../util/constants/assetsBaseUrl";

const useStyles = makeStyles(({ breakpoints }) => ({
  successBG: {
    background: `url('${ASSETS_BASE_URL}/public/budget-bg.jpg')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    minHeight: "100%",
    padding: "3rem 2rem 4rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [breakpoints.down("sm")]: {
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "initial",
      minHeight: "calc(100vh - 40px)",
    },
  },
  successCard: {
    background: "#fff",
    borderRadius: 8,
    boxShadow: "0 10px 30px rgba(0,0,0,0.30)",
    maxWidth: 700,
    padding: "2.5rem",
    [breakpoints.down("sm")]: {
      padding: "1.5rem 1rem",
    },
  },
  typeform: {
    position: "fixed",
    top: 74,
    left: 280,
    right: 0,
    bottom: 0,
    [breakpoints.down("sm")]: {
      top: 40,
      left: 0,
    },
  },
}));

export interface ExteriorDesignIntroProps {}

const ExteriorDesignIntro: FC<ExteriorDesignIntroProps> = props => {
  const classes = useStyles();
  const [typeformOpen, setTypeformOpen] = React.useState(false);
  const [isQuizAlreadyCompleted, setIsQuizAlreadyCompleted] = React.useState(
    false
  );

  // This is the Id of the quiz being served - found in Typeform
  const typeformFormId = "x82ERVFi";
  const profile = React.useContext(ProfileCtx);
  const user = React.useContext(UserCtx);
  const userId = !!user ? user.uid : undefined;

  const history = useHistory();
  const redirectToRef = useRef<string>("");
  const { triggerTimeout, triggered } = useTriggerTimeout(
    () => history.push(redirectToRef.current),
    200
  );

  React.useEffect(() => {
    if (
      profile &&
      profile.typeforms &&
      profile.typeforms[typeformFormId] === true
    ) {
      setIsQuizAlreadyCompleted(true);
    }
  }, [profile, isQuizAlreadyCompleted]);

  if (userId === undefined || profile === null) {
    return <FullScreenLoadingPage in={true} />;
  }

  return (
    <>
      <Fade mountOnEnter in={!triggered} timeout={{ enter: 200, exit: 200 }}>
        <Box className={classes.successBG}>
          <Box className={classes.successCard}>
            <YZTypography paragraph variant="h4" type="serif" align="center">
              Let's discover your exterior design goals
            </YZTypography>
            <YZTypography align="center">
              Please spend 5-10 mins completing this quiz to give your designers
              a broader perspective of what you like and need for your exterior
              design.
            </YZTypography>
            <Box textAlign="center" mt={3}>
              <YZButton color="primary" onClick={handleContinue}>
                {isQuizAlreadyCompleted
                  ? "Quiz completed - continue to next step →"
                  : "Start style and goals quiz →"}
              </YZButton>
            </Box>
          </Box>
        </Box>
      </Fade>
      <Typeform
        formId={typeformFormId}
        variant="widget"
        className={classes.typeform}
        open={typeformOpen}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </>
  );

  function onClose() {
    setTypeformOpen(false);
  }

  function onSubmit() {
    if (profile && userId) {
      profile.typeforms = profile.typeforms ?? {};
      profile.typeforms[typeformFormId] = true;
      const newTypeforms = {
        ...profile.typeforms,
        [typeformFormId]: true,
      };
      try {
        updateProfileDoc(userId, {
          typeforms: newTypeforms,
        });
      } catch (err) {
        window.newrelic.noticeError(err);
        console.error(err);
        LogRocket.captureMessage(err.message);
      }
    }
    setTypeformOpen(false);

    // redirect to next step
    goToNextStep();
  }

  function handleContinue() {
    if (isQuizAlreadyCompleted) {
      goToNextStep();
    } else {
      setTypeformOpen(true);
    }
  }

  function goToNextStep() {
    redirectToRef.current = "/onboard/exterior-design/paint";
    triggerTimeout();
  }
};

export { ExteriorDesignIntro };
export default ExteriorDesignIntro;
