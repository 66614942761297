import firebase from "firebase/compat/app";

export default function setUserIdInTatari(user: firebase.User | false | null) {
  if (!user || user.isAnonymous) {
    return;
  }

  if (
    !(process.env.NODE_ENV === "production") ||
    window.location.href.includes("dogfood")
  ) {
    return;
  }

  const tatari = window["tatari"];

  if (typeof tatari === "object" && typeof tatari["identify"] === "function") {
    tatari.identify(user.uid);
  }
}

export { setUserIdInTatari };
