import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Divider,
  Fade,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import {
  ChecklistItem,
  ChecklistItemResponse,
  ItemResponseCtx,
} from "../../../components/onboard/budget/ItemResponseContext";
import BudgetPrioritiesDndList from "../../../components/onboard/budget/BudgetPrioritiesDndList";
import {
  BudgetMetadataAndPhasingByProjectIdQuery,
  DocumentNode,
  gql,
  InsertBudgetMetadataMutationVariables,
  InsertBudgetPhaseMetadataMutationVariables,
  UpdateBudgetMetadataMutationVariables,
  UpdateBudgetPhaseMetadataMutationVariables,
  useApolloClient,
  useInsertBudgetMetadataMutation,
  useMutation,
  useUpdateBudgetMetadataMutation,
} from "@yardzen-inc/graphql";
import {
  INSERT_BUDGET_PHASE_METADATA,
  UPDATE_BUDGET_PHASE_METADATA,
} from "./budgetMutations";
import { WishListPrioritizePageEstimate } from "./WishListPrioritizePageEstimate";
import { WishListPrioritizePageEstimateMobile } from "./WishListPrioritizePageEstimateMobile";
import {
  WishListPrioritizePageBudgetIntent,
  BudgetIntent,
} from "./WishListPrioritizePageBudgetIntent";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useLogError, UserCtx } from "../../../util";
import { Project } from "@yardzen-inc/models";
import { calculatePercentileOfRange } from "../../../util/funcitons/calculatePercentileOfRange/calculatePercentileOfRange";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks";
import {
  setBudgetIntent,
  setBudgetIntentErrorText,
  setSubmittedInstallationPlans,
} from "../../../slices/onboardSlice";

export interface WishListPrioritizePageProps {
  itemResponseData: {
    [key: string]: { item: ChecklistItem; response?: ChecklistItemResponse };
  };
  lowEstimate: number;
  highEstimate: number;
  lowAllInEstimate: number;
  highAllInEstimate: number;
  lowBaseCost: number;
  highBaseCost: number;
  budgetMetadataQuery?: BudgetMetadataAndPhasingByProjectIdQuery;
  reloadMetadataQuery: () => Promise<void>;
}

const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    color: "white",
    backgroundColor: "black",
    border: "2px solid transparent",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      border: "2px solid lightgrey",
    },
  },
  divider: {
    backgroundColor: "black",
  },
}));

/**
 * Wish List Prioritize page component.
 * @param props The WishListPrioritizeProps to pass to the component
 * @returns The page component.
 */
const WishListPrioritizePage: FC<WishListPrioritizePageProps> = props => {
  const classes = useStyles();
  const history = useHistory();
  const user = React.useContext(UserCtx);
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const { refetchResponseData } = useContext(ItemResponseCtx)[1];
  const client = useApolloClient();
  const [
    insertBudgetMetadata,
    { loading: insertingBudgetMetadata, error: insertBudgetMetadataError },
  ] = useInsertBudgetMetadataMutation();
  const [
    updateBudgetMetadata,
    { loading: updatingBudgetMetadata, error: updateBudgetMetadataError },
  ] = useUpdateBudgetMetadataMutation();
  const [
    insertBudgetPhaseMetadata,
    {
      loading: insertingBudgetPhaseMetadata,
      error: insertBudgetPhaseMetadataError,
    },
  ] = useMutation(INSERT_BUDGET_PHASE_METADATA);
  const [
    updateBudgetPhaseMetadata,
    {
      loading: updatingBudgetPhaseMetadata,
      error: updateBudgetPhaseMetadataError,
    },
  ] = useMutation(UPDATE_BUDGET_PHASE_METADATA);

  const {
    itemResponseData,
    lowEstimate,
    highEstimate,
    lowAllInEstimate,
    highAllInEstimate,
    lowBaseCost,
    highBaseCost,
    budgetMetadataQuery,
    reloadMetadataQuery,
  } = props;

  const budgetMetadata = useMemo(
    () => budgetMetadataQuery?.budget_metadata?.[0],
    [budgetMetadataQuery]
  );

  const [project, setProject] = useState<Project | null>(null);
  const [items, setItems] = useState<
    { item: ChecklistItem; response: ChecklistItemResponse }[]
  >(
    () =>
      Object.values(itemResponseData)
        .filter(ir => !!ir.response)
        .sort(
          (a, b) => (a?.response?.order ?? 0) - (b?.response?.order ?? 0)
        ) as {
        item: ChecklistItem;
        response: ChecklistItemResponse;
      }[]
  );
  const { budgetIntent } = useAppSelector(state => state.onboard);

  function onOrderChange(): void {
    updateResponseOrder();
  }

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onOrderChange, [items]);

  // Fetch project based on user context
  useEffect(() => {
    (async () => {
      if (user) {
        try {
          const p = await Project.fetchWithProfileId(user.uid);

          setProject(p);

          if (p.clientBudgetIntent) {
            dispatch(
              setBudgetIntent({
                budgetIntent: String(p.clientBudgetIntent) as BudgetIntent,
              })
            );
            dispatch(
              setSubmittedInstallationPlans({
                submittedInstallationPlans: true,
              })
            );
          }
        } catch (error) {
          window.newrelic.noticeError(error);
          console.error(error);
        }
      }
    })();
  }, [user, dispatch]);

  useLogError(updateBudgetMetadataError as Error);
  useLogError(insertBudgetMetadataError as Error);
  useLogError(updateBudgetPhaseMetadataError as Error);
  useLogError(insertBudgetPhaseMetadataError as Error);

  return (
    <>
      {mobile && (
        <WishListPrioritizePageEstimateMobile
          lowEstimate={lowEstimate}
          highEstimate={highEstimate}
          lowAllInEstimate={lowAllInEstimate}
          highAllInEstimate={highAllInEstimate}
          lowBaseCost={lowBaseCost}
          highBaseCost={highBaseCost}
        />
      )}
      <Box mt={mobile ? 12 : 4}>
        <Fade mountOnEnter in timeout={{ enter: 200, exit: 200 }}>
          <Box m={3}>
            <Box display="flex" width="100%">
              <Box mr={mobile ? 0 : 3} textAlign="center" flexBasis="100%">
                <Box>
                  <YZTypography type="serif" variant="h4">
                    Let's Prioritize!
                  </YZTypography>
                </Box>
                <Box mt={2}>
                  <YZTypography>
                    We know that every yard and every client is unique. Please
                    help us understand how best to provide you with a design
                    tailored to your priorities and your budget.
                  </YZTypography>
                </Box>
              </Box>
              {!mobile && (
                <Box flexBasis="100%">
                  <WishListPrioritizePageEstimate
                    lowEstimate={lowEstimate}
                    highEstimate={highEstimate}
                    lowAllInEstimate={lowAllInEstimate}
                    highAllInEstimate={highAllInEstimate}
                    lowBaseCost={lowBaseCost}
                    highBaseCost={highBaseCost}
                  />
                </Box>
              )}
            </Box>
            <Box mt={4}>
              <Divider variant="middle" className={classes.divider} />
            </Box>
            <Box mt={3} textAlign="center">
              <WishListPrioritizePageBudgetIntent />
            </Box>
            <Box mt={5}>
              <Box textAlign="center">
                <YZTypography type="serif" variant="h5">
                  Drag your Wish List items from most to least important
                </YZTypography>
              </Box>
              <Box mt={2} textAlign="center">
                <YZTypography>
                  This will inform our design decision making around costs and
                  value for you.
                </YZTypography>
              </Box>
              <Box mt={2} mx="auto" width={mobile ? "100%" : "60%"}>
                <BudgetPrioritiesDndList
                  itemResponses={items}
                  setItemResponses={setItems}
                />
              </Box>
              <Box mt={3} textAlign="center">
                <Box>
                  <YZButton
                    className={classes.submitButton}
                    onClick={() => handleSubmit(budgetIntent)}
                    disabled={!budgetIntent || isLoading()}
                  >
                    Submit Installation Plans
                  </YZButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Box>
    </>
  );

  /**
   * Handle Submit
   * @description Update clientBudgetIntent on the Project document and push to the next page
   * @returns Promise<void>
   */
  async function handleSubmit(value: BudgetIntent): Promise<void> {
    if (project && value) {
      const calculatedBudget = calculatePercentileOfRange(value, [
        lowAllInEstimate,
        highAllInEstimate,
      ]);
      let possiblyNewBudgetMetadataId: string;

      try {
        await firebase
          .firestore()
          .collection("projects")
          .doc(project.id)
          .update({
            clientBudgetIntent: Number(value),
          });

        dispatch(
          setSubmittedInstallationPlans({ submittedInstallationPlans: true })
        );

        if (budgetMetadata) {
          const result = await updateBudgetMetadata({
            variables: {
              ...transformInputIntoBudgetMetadataProperties(project),
              total_budget: calculatedBudget,
              id: budgetMetadata.id,
            },
          });

          possiblyNewBudgetMetadataId =
            result?.data?.update_budget_metadata?.returning[0].id;
        } else {
          const result = await insertBudgetMetadata({
            variables: {
              ...transformInputIntoBudgetMetadataProperties(project),
              total_budget: calculatedBudget,
            },
          });
          possiblyNewBudgetMetadataId =
            result?.data?.insert_budget_metadata?.returning?.[0].id;
        }

        const phasingMetadata = budgetMetadata?.budget_phase_metadata?.[0];
        if (phasingMetadata) {
          await updateBudgetPhaseMetadata({
            variables: {
              ...transformInputIntoBudgetPhasingMetadataProperties(
                possiblyNewBudgetMetadataId
              ),
              id: phasingMetadata.id,
              budget: calculatedBudget,
            },
          });
        } else {
          await insertBudgetPhaseMetadata({
            variables: {
              ...transformInputIntoBudgetPhasingMetadataProperties(
                possiblyNewBudgetMetadataId
              ),
              budget: calculatedBudget,
            },
          });
        }

        dispatch(setBudgetIntentErrorText({ budgetIntentErrorText: "" }));

        await reloadMetadataQuery();
        history.push("/onboard/budget/complete");
      } catch (error) {
        window.newrelic.noticeError(error);
        console.error(error);
        dispatch(
          setBudgetIntentErrorText({
            budgetIntentErrorText:
              "There was an issue submitting your budget intent, please refresh the page and try again.",
          })
        );
        document?.getElementById("budget-intent-label")?.scrollIntoView();
      }
    }
  }

  function transformInputIntoBudgetMetadataProperties(
    project: Project
  ): InsertBudgetMetadataMutationVariables &
    UpdateBudgetMetadataMutationVariables {
    return {
      design_project_for_single_phase: true,
      phasing: false,
      project_id: project.id,
    };
  }

  function transformInputIntoBudgetPhasingMetadataProperties(
    budgetMetadataId: string
  ): InsertBudgetPhaseMetadataMutationVariables &
    UpdateBudgetPhaseMetadataMutationVariables {
    return {
      budget_metadata_id: budgetMetadataId,
    };
  }

  function isLoading(): boolean {
    return !!(
      insertingBudgetMetadata ||
      insertingBudgetPhaseMetadata ||
      updatingBudgetMetadata ||
      updatingBudgetPhaseMetadata
    );
  }

  /**
   * Update item response order
   * @description Generate a mutation to update all changed responses and send
   * @returns void
   */
  async function updateResponseOrder(): Promise<void> {
    try {
      if (itemResponseData) {
        const mutation = generateBulkUpdateMutation();

        const { errors } = (await client.mutate({ mutation })) as {
          errors: any;
        };

        if (errors?.length) {
          console.error(errors);
        }

        await refetchResponseData();
      }
    } catch (error) {
      window.newrelic.noticeError(error);
      console.error(error);
    }
  }

  /**
   * Generate bulk update mutation
   * @description Dynamically generate an 'update many' mutation below based on responses with altered order property
   * @returns DocumentNode
   */
  function generateBulkUpdateMutation(): DocumentNode {
    const mutationBody = Object.entries(items)
      .map(([key, value], i) => {
        if (itemResponseData?.[key]?.response?.order !== i) {
          return generateSingleUpdateFragment(value.response.id, i);
        }

        return null;
      })
      .filter(frag => !!frag)
      .join("");

    return gql`
      mutation UpdateManyBudgetChecklistResponseOrder {
        ${mutationBody}
      }
    `;
  }

  /**
   * Generate single update fragment
   * @description Generate an operation to update a singe response with an alias based off of the new order value
   * @returns string
   */
  function generateSingleUpdateFragment(id: string, order: number): string {
    return `
        r_${order}: update_budget_checklist_response(where: {id: { _eq: "${id}" } }, _set: { order: ${order.toString()} }) {
          returning {
            id
          }
        }
     `;
  }
};

export { WishListPrioritizePage };
