import * as React from "react";
import {
  useParams,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from "react-router";
import MyYardUpload from "./MyYardUpload";
import MyYardComments from "./MyYardComments";
import Priorities from "./Priorities";
import { YardName } from "./stepData";

interface IStepRouterProps {}

const StepRouter: React.FunctionComponent<IStepRouterProps> = props => {
  const { yard } = useParams<{ yard: YardName }>();
  let { url } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${url}/upload`}>
          <MyYardUpload yard={yard} />
        </Route>
        <Route
          path={`${url}/keep`}
          component={() => <MyYardComments yard={yard as YardName} />}
        />
        <Route
          path={`${url}/priorities`}
          component={() => <Priorities yard={yard as YardName} />}
        />
        <Route>
          <Redirect to="/onboard/my-yard" />
        </Route>
      </Switch>
    </>
  );
};

export { StepRouter };
export default StepRouter;
