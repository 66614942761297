import { Box, Theme, useMediaQuery } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import React, { FC, useState } from "react";
import { useDesignProfileCtx } from "../../components/designProfile";
import { Page } from "../../types/DesignProfile";
import { useTimeout } from "../../util/hooks/useTimeout";
import PlantsIcon from "../../images/design-profile-plants.png";
import PropertyIcon from "../../images/design-profile-property.png";
import { DesignProfileRouteAccess } from "../../components/designProfile/DesignProfileRouteAccess";

interface MapLoadingPageProps {
  page: Page;
  onNext: () => void;
  onPrevious: () => void;
}

type LoadingStage = {
  text: string;
  icon: string;
};

const loadingStages: LoadingStage[] = [
  { text: "Learning your native plants...", icon: PlantsIcon },
  { text: "Learning your property characteristics...", icon: PropertyIcon },
];

export const MapLoadingPage: FC<MapLoadingPageProps> = ({ page, onNext }) => {
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const { designProfile } = useDesignProfileCtx();
  const city = designProfile?.contactInformation?.city;

  const [stage, setStage] = useState<number>(0);

  // Move to next page after 3 seconds
  useTimeout(() => onNext(), 3000);

  // Increment stage after 1.5 seconds
  useTimeout(() => setStage(stage + 1), 1500);

  return (
    <DesignProfileRouteAccess>
      <Box
        mt={mobile ? 12 : 6}
        mb={32}
        mx={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
      >
        {city && (
          <YZTypography type="serif" variant="h3">
            {page.title} {city}
          </YZTypography>
        )}
        <Box mt={4}>
          <YZTypography>{loadingStages[stage].text}</YZTypography>
        </Box>
        <Box mt={4} width="60px">
          <img src={loadingStages[stage].icon} alt="" width="100%" />
        </Box>
      </Box>
    </DesignProfileRouteAccess>
  );
};
