import {
  setAddressAddedInDesignProfile,
  setZip,
} from "../../../slices/checkoutSlice";
import { AppDispatch } from "../../../store";

interface HandleSetAddressArgs {
  dispatch: AppDispatch;
  zip: string;
}

export const handleSetZip = ({ dispatch, zip }: HandleSetAddressArgs) => {
  dispatch(setZip({ zip }));
  dispatch(
    setAddressAddedInDesignProfile({ addressAddedInDesignProfile: true })
  );
};
