import {
  SegmentSubFlows,
  SegmentFlows,
  SegmentClient,
} from "../../util/Segment";

/**
 * segmentTrackUploadHandler abstracts the code needed to track upload events from the
 * file matrix given by the `onUploadComplete` event handler on the component `YardUploadGrid`.
 * @param segment Segment Client
 * @param opts Files matrix and segment properties that can not be inferred from files
 */
function segmentTrackUploadHandler(
  segment: SegmentClient,
  opts: {
    form_name: string;
    upload_name: string;
    files: File[][];
  }
) {
  for (const fileArray of opts.files) {
    for (const file of fileArray) {
      segment.trackUploaded({
        upload_name: opts.upload_name,
        sub_flow_name: SegmentSubFlows.MY_YARD,
        flow_name: SegmentFlows.ONBOARD,
        file_name: file.name,
        file_size: file.size,
        file_type: file.type,
        form_name: opts.form_name,
        comment_required: false,
      });
    }
  }
}

export { segmentTrackUploadHandler };
