import { BudgetCategoryChecklistItemPropsWithKey } from "../budget/BudgetElementCategoryListWithResponseSubs";
import { GENERAL_PLANTING_ELEMENT } from "./wishlistElementConstants";

/**
 * Retrieves a sorted list of planting elements for the wishlist. Current sort
 * order is to place the 'General Planting' element before all others, and
 * then sort alphabetically.
 * @param plantingElements The planting elements to sort.
 * @returns A sorted array of planting elements.
 */
export const getSortedPlantingElements = (
  plantingElements: BudgetCategoryChecklistItemPropsWithKey[]
): BudgetCategoryChecklistItemPropsWithKey[] => {
  // We use includes instead of === in our searches here, because the planting
  // element name also has (*Selection Required) as part of its text, and that
  // could change in the future. It's unlikely that the "General Planting" part
  // of the name will change in the future.
  const generalPlantingItem = plantingElements.find(plantingElement =>
    plantingElement.name.includes(GENERAL_PLANTING_ELEMENT)
  );

  const otherItems = plantingElements
    .filter(
      plantingElements =>
        !plantingElements.name.includes(GENERAL_PLANTING_ELEMENT)
    )
    .sort((element1, element2) => element1.name.localeCompare(element2.name));

  if (!generalPlantingItem) {
    return otherItems;
  }

  return [generalPlantingItem, ...otherItems];
};
