import { passesExperienceVersionCheck } from "@yardzen-inc/react-common";
import { ProfileProperties } from "@yardzen-inc/models";
import { useContext, useMemo } from "react";
import {
  OnboardCtx,
  useGetProjectExperienceVersion,
  useProfile,
  useUser,
} from "../../util";

export interface UseAllowAccessToMyYardStep {
  (): boolean | null;
}

const STORAGE_KEY = "ALLOW_ACCESS_MY_YARD_STEP";

const useAllowAccessToMyYardStep: UseAllowAccessToMyYardStep = () => {
  const profile = useProfile(useUser()[1])[1];
  const experienceVersion = useGetProjectExperienceVersion(
    profile as ProfileProperties
  );
  const {
    state: { contextError },
    setOnboardContextError,
  } = useContext(OnboardCtx);

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allowedAccess = useMemo(allowAccess, [profile, experienceVersion]);
  return allowedAccess;

  function allowAccess(): boolean | null {
    if (!profile && profile !== false) {
      return sessionStorage.getItem(STORAGE_KEY) === "true";
    }

    try {
      if (experienceVersion === null) {
        return null;
      }
      if (experienceVersion === false) {
        throw new Error("Experience verison not found");
      }
      const allowed = passesExperienceVersionCheck(experienceVersion, "2.1");

      const allowedString = allowed.toString();

      if (sessionStorage.getItem(STORAGE_KEY) !== allowedString) {
        sessionStorage.setItem(STORAGE_KEY, allowedString);
      }

      if (typeof allowed !== "boolean") {
        return null;
      }

      return allowed;
    } catch (err) {
      window.newrelic.noticeError(err);
      console.error(err);
      if (!contextError) {
        setOnboardContextError("missingRecords");
      }
      return null;
    }
  }
};

export { useAllowAccessToMyYardStep };
export default useAllowAccessToMyYardStep;
