import {
  DiscountType,
  PromotionalCodeStatus,
} from "../../../services/promotionalCodes";
import { PromotionalCode } from "../../../api/apiTypes";
import { Product } from "../../../types/Products";

/**
 * Returns true if the promotional code is active and includes a free expedited addtional discount
 * Promo code must be set up with an additional discount for the expedited add-on product that is equal to the price of the expedited add-on
 * If the promotional code is ONLY for free expedited, the primary discount should be set to 0 and the additional discount should be set to the price of the expedited add-on
 * @param promotionalCode - The promotional code to check
 * @param products - The products list from Stripe
 */
export const promoCodeIncludesFreeExpedited = (
  promotionalCode: PromotionalCode,
  products: Product[]
) => {
  const promoCodeIsActive =
    promotionalCode?.status === PromotionalCodeStatus.USABLE;
  if (!promoCodeIsActive) {
    return false;
  }
  const additionalDiscounts = promotionalCode.additionalDiscounts || [];
  if (additionalDiscounts.length === 0) {
    return false;
  }
  const expeditedAddOnProduct = getExpeditedProduct(products);

  if (!expeditedAddOnProduct) {
    return false;
  }
  return discountIsEqualToExpeditedProductPrice(
    promotionalCode,
    expeditedAddOnProduct
  );
};

export const discountIsEqualToExpeditedProductPrice = (
  promoCode: PromotionalCode,
  expeditedProduct: Product
) => {
  const additionalDiscounts = promoCode.additionalDiscounts || [];
  const expeditedAddOnProductPrice = expeditedProduct.skus.data[0].price;
  return additionalDiscounts.some(
    discount =>
      discount.forProductId === expeditedProduct.id &&
      discount.discountType === DiscountType.FIXED &&
      discount.discount === expeditedAddOnProductPrice?.toString()
  );
};

export const getExpeditedProduct = (products: Product[]) => {
  return products.find(
    product =>
      product.active &&
      product.metadata?.type === "addOn" &&
      product.name.toLowerCase().includes("expedited")
  );
};
