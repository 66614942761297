import * as React from "react";
import { Grid, TextField, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { YZButton } from "../buttons";
import StateSelect from "./StateSelect";
import {
  FormFieldFilledProps,
  SegmentClickTypes,
  SegmentFlows,
  SegmentForms,
  SegmentInputTypes,
  useSegment,
} from "../../util/Segment";

export interface Address {
  street: string;
  city: string;
  zip: string;
  state: string;
  aptNumber?: string;
}

export interface AddressFormProps {
  address: Address;
  hasTriedSubmitting: boolean;
  setAddress: (addressObject: Address) => void;
  handleSubmitPropertyAddress?: () => void;
  submitButtonText?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
  },
}));

const AddressForm: React.FunctionComponent<AddressFormProps> = ({
  hasTriedSubmitting,
  setAddress,
  address,
  handleSubmitPropertyAddress,
  submitButtonText,
}) => {
  const segment = useSegment();
  const classes = useStyles();

  const { city, state, street, zip, aptNumber } = address;

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <Grid container spacing={2} style={{ marginBottom: "0.5rem" }}>
        <Grid item xs>
          <TextField
            error={hasTriedSubmitting && !street.trim().length}
            label="Street"
            value={street}
            onChange={e => handleSetAddress("street", e.target.value)}
            fullWidth
            onBlur={getSegmentBlurHandler(
              {
                form_name: SegmentForms.CHECKOUT_ADDRESS,
                field_name: "Street",
                flow_name: SegmentFlows.CHECKOUT,
                input_type: SegmentInputTypes.TEXT,
              },
              street
            )}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs>
          <TextField
            label="apt #"
            value={aptNumber}
            onChange={e => handleSetAddress("aptNumber", e.target.value)}
            onBlur={getSegmentBlurHandler(
              {
                form_name: SegmentForms.CHECKOUT_ADDRESS,
                field_name: "apt #",
                flow_name: SegmentFlows.CHECKOUT,
                input_type: SegmentInputTypes.TEXT,
              },
              aptNumber
            )}
            fullWidth
            variant="outlined"
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: "0.5rem" }}>
        <Grid item xs>
          <TextField
            onBlur={getSegmentBlurHandler(
              {
                form_name: SegmentForms.CHECKOUT_ADDRESS,
                field_name: "City",
                flow_name: SegmentFlows.CHECKOUT,
                input_type: SegmentInputTypes.TEXT,
              },
              city
            )}
            error={hasTriedSubmitting && !city.trim().length}
            label="City"
            value={city}
            onChange={e => handleSetAddress("city", e.target.value)}
            fullWidth
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs>
          <StateSelect
            onChange={e => handleSetAddress("state", e.target.value as string)}
            value={state}
            onBlur={getSegmentBlurHandler(
              {
                form_name: SegmentForms.CHECKOUT_ADDRESS,
                field_name: "State",
                flow_name: SegmentFlows.CHECKOUT,
                input_type: SegmentInputTypes.TEXT,
              },
              state
            )}
            error={hasTriedSubmitting && !state.length}
          />
        </Grid>
      </Grid>
      <TextField
        error={hasTriedSubmitting && !zip.trim().length}
        inputProps={{ maxLength: "5" }}
        onBlur={getSegmentBlurHandler(
          {
            form_name: SegmentForms.CHECKOUT_ADDRESS,
            field_name: "Zip Code",
            flow_name: SegmentFlows.CHECKOUT,
            input_type: SegmentInputTypes.TEXT,
          },
          zip
        )}
        label="Zip Code"
        value={zip}
        style={{ marginBottom: "1rem" }}
        onChange={e =>
          !!Number(e.target.value) || e.target.value === ""
            ? handleSetAddress("zip", e.target.value)
            : null
        }
        fullWidth
        variant="outlined"
        size="small"
      />
      {handleSubmitPropertyAddress && (
        <YZButton
          type="submit"
          onClick={() => {
            segment.trackClicked({
              button_name: SegmentForms + " Submit",
              click_type: SegmentClickTypes.BUTTON,
              flow_name: SegmentFlows.CHECKOUT,
              button_content: "Submit",
              city: address.city,
              state: address.state,
              zip: address.zip,
            });
          }}
        >
          {submitButtonText ? submitButtonText : "Submit"}
        </YZButton>
      )}
    </form>
  );

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (handleSubmitPropertyAddress) {
      handleSubmitPropertyAddress();
    }

    segment.identify({
      street_address: address.street,
      state: address.state,
      city: address.city,
      zip_code: address.zip,
    });

    segment.trackFormSubmitted({
      form_name: SegmentForms.CHECKOUT_ADDRESS,
      flow_name: SegmentFlows.CHECKOUT,
    });
  }

  function handleSetAddress(key: string, value: string) {
    return setAddress({
      ...address,
      [key]: value,
    });
  }

  function getSegmentBlurHandler(
    props: FormFieldFilledProps,
    value: any
  ): (() => any) | undefined {
    if (value) {
      return () => {
        segment.trackFormFieldFilled(props);
      };
    }

    return undefined;
  }
};

export { AddressForm };
export default AddressForm;
