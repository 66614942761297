import firebase from "firebase/compat";

interface GetGAPageNameArgs {
  userContext: firebase.User | null;
  propertyAddressComplete: boolean;
}

export const getGAPageName = ({
  userContext,
  propertyAddressComplete,
}: GetGAPageNameArgs) => {
  let step: string;

  if (!userContext || !userContext.email) {
    step = "create-account-step";
  } else if (!!userContext && !!userContext.email && !propertyAddressComplete) {
    step = "property-address-step";
  } else if (!!userContext && !!userContext.email && propertyAddressComplete) {
    step = "billing-details-step";
  } else {
    return "";
  }

  return `packages-${step}`;
};
