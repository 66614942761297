import { gql } from "@apollo/client";

export const INSERT_BUDGET_PHASE_METADATA = gql`
  mutation InsertBudgetPhaseMetadata(
    $budget: bigint = ""
    $budget_metadata_id: uuid = ""
  ) {
    insert_budget_phase_metadata(
      objects: { budget: $budget, budget_metadata_id: $budget_metadata_id }
    ) {
      returning {
        budget
        budget_metadata_id
        created_at
        id
        yards
        updated_at
      }
    }
  }
`;

export const UPDATE_BUDGET_PHASE_METADATA = gql`
  mutation UpdateBudgetPhaseMetadata(
    $id: uuid = ""
    $budget: bigint = ""
    $budget_metadata_id: uuid = ""
  ) {
    update_budget_phase_metadata(
      where: { id: { _eq: $id } }
      _set: { budget: $budget, budget_metadata_id: $budget_metadata_id }
    ) {
      returning {
        budget
        budget_metadata_id
        created_at
        id
        yards
        updated_at
      }
    }
  }
`;
