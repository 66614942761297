import { SegmentClient } from "./SegmentClient";
import { segment } from "./init";

export interface UseSegment {
  (): SegmentClient;
}

const useSegment: UseSegment = () => {
  return segment;
};

export { useSegment };
export default UseSegment;
