import { getLoginPageUrl } from "./getLoginPageUrl";
import { getAuth } from "firebase/auth";

const AUTH_URL = process.env["REACT_APP_AUTH_API_URL"]!;

export async function prepareRedirect(
  url: URL,
  userId: string,
  idToken: string
) {
  const params: Record<string, unknown> = {};
  url.searchParams?.forEach?.((value, key) => {
    params[key] = value;
  });

  const payload = {
    host: url.host,
    path: url.pathname,
    parameters: params,
    userId,
  };
  const body = JSON.stringify(payload);
  const headers = new Headers();
  headers.set("Content-Type", "application/json");
  headers.set("Authorization", `Bearer ${idToken}`);

  const res = await fetch(AUTH_URL + "/prepare", {
    method: "POST",
    body,
    credentials: "include",
    headers,
  });

  if (!res.ok) {
    throw new Error(
      `Failed to prepare redirect: ${res.status} ${res.statusText}`
    );
  }
}

export type RedirectBehaior = "redirect" | "replace" | "open";
export type FallbackBehavior = "login-page" | "no-auth" | "error";

export interface RedirectWithAuthOpts {
  destination: URL;
  redirectBehavior?: RedirectBehaior;
  fallbackBehavior?: FallbackBehavior;
}

export async function redirectWithAuth(opts: RedirectWithAuthOpts) {
  const _opts = mergeDefaultOpts(opts);
  const userId = getAuth().currentUser?.uid ?? "";
  const idToken = (await getAuth().currentUser?.getIdToken()) ?? "";

  try {
    const url = AUTH_URL + "/redirect";
    await prepareRedirect(_opts.destination, userId, idToken);
    return redirect(url, _opts.redirectBehavior);
  } catch (error) {
    console.error(error);
    return doFallbackRedirect(_opts);
  }
}

function doFallbackRedirect(opts: Required<RedirectWithAuthOpts>) {
  switch (opts.fallbackBehavior) {
    case "error":
      throw new Error(
        "Failed to prepare redirect to " + opts.destination.toString()
      );
    case "login-page":
      const loginUrl = getLoginPageUrl();
      loginUrl.searchParams.set("callbackUrl", opts.destination.toString());
      loginUrl.searchParams.set("skip_logged_in", "true");
      redirect(loginUrl.toString(), opts.redirectBehavior);
      return;
    case "no-auth":
      redirect(opts.destination.href, opts.redirectBehavior);
      return;
    default:
      throw new Error(`Invalid fallback behavior: ${opts.fallbackBehavior}`);
  }
}

function mergeDefaultOpts(opts: RedirectWithAuthOpts) {
  return {
    ...opts,
    redirectBehavior: opts.redirectBehavior ?? "redirect",
    fallbackBehavior: opts.fallbackBehavior ?? "login-page",
  };
}

function redirect(url: string, behavior: RedirectBehaior) {
  switch (behavior) {
    case "redirect":
      window.location.href = url;
      return;
    case "replace":
      window.location.replace(url);
      return;
    case "open":
      window.open(url);
      return;
    default:
      throw new Error(`Invalid redirect behavior: ${behavior}`);
  }
}
