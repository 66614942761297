const nextDoorPixelScript = `
   (function(win, doc, sdk_url){
   if(win.ndp) return;
   var tr=win.ndp=function(){
   tr.handleRequest? tr.handleRequest.apply(tr, arguments):tr.queue.push(arguments);
  };
   tr.queue = [];
   var s='script';
   var new_script_section=doc.createElement(s);
   new_script_section.async=!0;
   new_script_section.src=sdk_url;
   var insert_pos=doc.getElementsByTagName(s)[0];
   insert_pos.parentNode.insertBefore(new_script_section, insert_pos);
  })(window, document, 'https://ads.nextdoor.com/public/pixel/ndp.js');
   ndp('init','dc775aa2-0ca7-420a-8f1f-90d65a6d1105', {})
   ndp('track','PAGE_VIEW');
`;

let activated = false;

function activateNextdoorPixel() {
  if (activated) {
    return;
  }
  try {
    // eslint-disable-next-line no-eval
    eval(nextDoorPixelScript);
    activated = true;
  } catch (error) {
    window.newrelic.noticeError(error);
    console.error(error);
  }
}

export { activateNextdoorPixel };
