import React, { FC, useMemo } from "react";
import { useMediaQuery, Theme, Box } from "@material-ui/core";
import { SquareModalBase } from "../modals";
import { OnboardCtx } from "../../util";
import { useTheme } from "@material-ui/styles";
import { ContextErrorValue } from "./stepData";
import { YZTypography, YZButton } from "@yardzen-inc/react-common";
import { firebaseSignOut } from "../../util/cookies/methods";
import { ASSETS_BASE_URL } from "../../util/constants/assetsBaseUrl";

export interface OnboardContextErrorModalProps {}

const OnboardContextErrorModal: FC<OnboardContextErrorModalProps> = props => {
  const { spacing } = useTheme<Theme>();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const {
    state: { contextError },
    setOnboardContextError,
  } = React.useContext(OnboardCtx);

  const [primaryText, secondaryText] = useMemo(
    () => getErrorText(contextError),
    [contextError]
  );

  return (
    <SquareModalBase
      open={!!contextError}
      onClose={handleClose}
      contentContainerProps={{
        p: spacing(1),
        width: "fit-content",
        height: "fit-content",
        maxWidth: "100vw",
        maxHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <div
            style={{
              height: smDown ? "400px" : "500px",
              width: smDown ? "400px" : "600px",
              backgroundImage: `url(${getBackgroundImageLink(contextError)})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />
          {!!primaryText && (
            <YZTypography variant="h4">{primaryText}</YZTypography>
          )}
          {!!secondaryText && (
            <YZTypography variant="h6">{secondaryText}</YZTypography>
          )}
        </Box>
        {/* YZButton `styles` was incorrectly implemented and does not
         *  follow material-ui component convention.
         *  So I must use a Box to apply styling..
         */}
        <Box style={{ float: "right", marginTop: spacing(3) }}>
          <YZButton variant="text" onClick={() => firebaseSignOut()}>
            Logout
          </YZButton>
        </Box>
      </Box>
    </SquareModalBase>
  );

  function handleClose() {
    setOnboardContextError(null);
  }

  function getErrorText(
    contextError: ContextErrorValue
  ): [string | null, string | null] {
    switch (contextError) {
      case "404":
        return [null, null];

      case "missingRecords":
        return [
          "Uh oh! Something is wrong on our end.",
          `Please message us using the 'help' button or email support@yardzen.com`,
        ];
      default:
        return [
          "An unknown error occured, please logout and back in.",
          "If you continue to see this message, please message us using the 'help' button or email support@yardzen.com",
        ];
    }
  }

  function getBackgroundImageLink(contextError: ContextErrorValue) {
    switch (contextError) {
      case "404":
        return `${ASSETS_BASE_URL}/public/onboard-404-graphic.png`;

      default:
        return "https://yardzen-public-assets.storage.googleapis.com/generic-question-mark-guy.png";
    }
  }
};

export { OnboardContextErrorModal };
export default OnboardContextErrorModal;
