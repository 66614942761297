import React, { FC, useContext, useState, useEffect, useCallback } from "react";
import { OnboardCtx, smartRedirect } from "../../util";
import { Box, CircularProgress } from "@material-ui/core";
import { Redirect } from "react-router";
import useAllowAccessToMyYardStep from "./useAllowAccessToMyYardStep";
import { useAllowAccessToBudgetStep } from "./budget";
import { useAllowAccessToExteriorDesignStep } from "./useAllowAccessToExteriorDesignStep";

export interface HandleOnboardRedirectProps {}

const HandleOnboardRedirect: FC<HandleOnboardRedirectProps> = props => {
  const allowAccessToMyYard = useAllowAccessToMyYardStep();
  const allowAccessToBudgetExercise = useAllowAccessToBudgetStep();
  const allowAccessToExteriorDesign = useAllowAccessToExteriorDesignStep();

  const {
    state: { id: onboardDocId, currentStep, projectId },
  } = useContext(OnboardCtx);

  const [loading, setLoading] = useState(true);
  const [redirectString, setRedirectString] = useState<
    "budget" | "my-yard" | "exterior-design" | null
  >(null);

  const handleRedirect = useCallback(() => {
    if (allowAccessToExteriorDesign && currentStep === "Exterior Design") {
      setRedirectString("exterior-design");
      return setLoading(false);
    }
    if (allowAccessToBudgetExercise) {
      if (currentStep === "Budget") {
        setRedirectString("budget");
        return setLoading(false);
      } else if (currentStep === "Yard") {
        setRedirectString("my-yard");
        return setLoading(false);
      } else {
        return smartRedirect("v1", `project/${currentStep}`);
      }
    } else if (allowAccessToMyYard) {
      if (currentStep === "Yard") {
        setRedirectString("my-yard");
        return setLoading(false);
      } else {
        return smartRedirect("v1", `project/${currentStep}`);
      }
    } else {
      return smartRedirect("v1", "project");
    }
  }, [
    currentStep,
    allowAccessToBudgetExercise,
    allowAccessToMyYard,
    allowAccessToExteriorDesign,
  ]);

  useEffect(() => {
    if (
      allowAccessToMyYard !== null &&
      allowAccessToBudgetExercise !== null &&
      allowAccessToExteriorDesign !== null &&
      !!currentStep
    ) {
      handleRedirect();
    }
  }, [
    allowAccessToBudgetExercise,
    allowAccessToMyYard,
    allowAccessToExteriorDesign,
    currentStep,
    handleRedirect,
    projectId,
    onboardDocId,
  ]);

  if (loading) {
    return (
      <Box mt="100px" width="100%" display="flex" justifyContent="center">
        <CircularProgress color="primary" size={75} />
      </Box>
    );
  }

  return <Redirect to={`/onboard/${redirectString}`} />;
};

export { HandleOnboardRedirect };
export default HandleOnboardRedirect;
