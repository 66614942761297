import React, { FC } from "react";
import { YZTypography } from "@yardzen-inc/react-common";
import { DesignProfileVersionId, Page } from "../../types/DesignProfile";
import { makeStyles } from "@material-ui/core";
import { useDesignProfileCtx } from "./DesignProfileCtx";
import { getDesignProfilePath } from "../../pages/designProfile/util/getDesignProfilePath";

type DesignProfileStepNumberProps = {
  page: Page;
};

const useStyles = makeStyles(() => ({
  pageCount: {
    letterSpacing: ".05em",
    opacity: "0.6",
  },
}));

/**
 * Design Profile Step Number component.
 * @param props The DesignProfileStepNumberProps to pass to the component
 * @returns The component.
 */
const DesignProfileStepNumber: FC<DesignProfileStepNumberProps> = (
  props: DesignProfileStepNumberProps
) => {
  const classes = useStyles();

  const { page } = props;

  const { designProfileVersionId } = useDesignProfileCtx();
  const { paths } = getDesignProfilePath(
    (designProfileVersionId ?? "") as DesignProfileVersionId
  );

  return (
    <YZTypography className={classes.pageCount}>
      {completedNumbers(paths)}
    </YZTypography>
  );

  /**
   * Returns a string representing the current quiz page number out of the total
   *
   * @return {string | null} A tuple to be used to render a page number (ex: "4 OF 8")
   */
  function completedNumbers(paths: Page[]): string | null {
    const total = paths.filter(path => {
      return path.completion_number;
    }).length;

    return page.completion_number
      ? `${page.completion_number} OF ${total}`
      : null;
  }
};

export { DesignProfileStepNumber };
