import React, { FC } from "react";
import { SplitClient, SplitTreatments } from "@splitsoftware/splitio-react";
import { TrafficTypes } from "@yardzen-inc/react-split";
import { userSplits } from "./splitTreatments";

interface NewSplitClientProps {
  children: React.ReactNode;
  trafficType: TrafficTypes;
  id: string;
}

const NewSplitClient: FC<NewSplitClientProps> = ({
  children,
  trafficType,
  id,
}) => {
  return id.length ? (
    <SplitClient splitKey={id} trafficType={trafficType}>
      <SplitTreatments names={userSplits}>
        {() => {
          return <>{children}</>;
        }}
      </SplitTreatments>
    </SplitClient>
  ) : (
    <>{children}</>
  );
};

export { NewSplitClient };
