import { DesignProfileQuestion } from "../../../api/apiTypes";
import {
  ExteriorTypes,
  LandscapingTypes,
} from "../../../util/constants/wishListTypes";
import { WISH_LIST_PATH } from "../constants/paths";

/**
 * Get the wish list responses from the design profile quiz
 *
 * @param {DesignProfileQuestion[]} responses Design profile responses
 * @returns {string[]} The user response(s)
 */
export const getWishListResponse = (
  responses: DesignProfileQuestion[]
): (LandscapingTypes | ExteriorTypes)[] => {
  const wishListResponses = responses.find(
    group => group.path === WISH_LIST_PATH
  )?.response;

  if (Array.isArray(wishListResponses)) {
    return wishListResponses.map(el => el.toUpperCase());
  }

  const landscapingResponses = wishListResponses?.landscaping ?? [];

  const homeExteriorResponses = wishListResponses?.home_exterior ?? [];

  return [
    ...(landscapingResponses as LandscapingTypes[]),
    ...(homeExteriorResponses as ExteriorTypes[]),
  ];
};
