export enum YZErrorCode {
  USER_LOGGED_IN,
}

class YZError extends Error {
  public code: YZErrorCode;

  constructor(message: string, code: YZErrorCode) {
    super(message);
    this.code = code;
  }
}

export { YZError };
export default YZError;
