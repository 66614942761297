import * as React from "react";
import { FullScreenLoadingPage } from "../loadingComponents";
import loadable from "@loadable/component";

export interface LazyProps<t = {}, j = {} | undefined> {
  getImport: () => Promise<any>;
  componentProps?: t;
  LoadingComponent?: React.FC<j>;
  loadingComponentProps?: j;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Lazy: React.FC<LazyProps> = <t extends object | { [key: string]: any }>({
  getImport,
  LoadingComponent,
  loadingComponentProps,
  componentProps,
}: LazyProps) => {
  const Component = React.useMemo(
    () =>
      getLoadableComponent(getImport, LoadingComponent, loadingComponentProps),
    [getImport, loadingComponentProps, LoadingComponent]
  );

  return <Component {...(componentProps ?? {})} />;
};

function getLoadableComponent(
  getImport: () => Promise<any>,
  Fallback?: React.FC<any>,
  fallbackProps?: {}
) {
  let loading = Fallback ? (
    <Fallback {...fallbackProps} />
  ) : (
    <FullScreenLoadingPage in={true} />
  );
  return loadable(getImport, { fallback: loading });
}

export { Lazy, getLoadableComponent };
export default Lazy;
