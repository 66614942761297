import React, { FC, useEffect, useRef, useState } from "react";
import { YZTypography, GenericLoadingSpinner } from "@yardzen-inc/react-common";
import {
  Box,
  FormControl,
  FormGroup,
  makeStyles,
  RadioGroup,
  Theme,
} from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import { DesignProfileBlackButton } from "../../components/designProfile/DesignProfileBlackButton";
import { Page } from "../../types/DesignProfile";
import { useDesignProfileCtx } from "../../components/designProfile/DesignProfileCtx";
import { DesignProfileStepNumber } from "../../components/designProfile/DesignProfileStepNumber";
import { isSelectionMade } from "./util/quizPage/isSelectionMade";
import { getResponseForCurrentQuestion } from "./util/quizPage/getResponseForCurrentQuestion";
import { QuizDropdown } from "../../components/designProfile/quizPage/QuizDropdown";
import { SelectionsList } from "../../components/designProfile/quizPage/SelectionsList";
import { QuizCheckbox } from "../../components/designProfile/quizPage/QuizCheckbox";
import { QuizRadio } from "../../components/designProfile/quizPage/QuizRadio";
import { DesignProfileRouteAccess } from "../../components/designProfile/DesignProfileRouteAccess";
import _ from "lodash";
import { CenterFullScreen } from "../../components";
import {
  SegmentClickTypes,
  SegmentFlows,
  useSegment,
} from "../../util/Segment";

type DesignProfileQuizPageProps = {
  page: Page;
  onNext: () => void;
  onPrevious: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: "100vh",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(12),
    },
  },
  quizContainer: {
    marginBottom: "30px",
    [theme.breakpoints.down("xs")]: {
      margin: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    },
  },
  subtitle: {
    width: "60%",
    margin: "0 auto",
  },
  errorMessageRef: {
    display: "none",
  },
  errorMessage: {
    display: "flex",
    justifyContent: "center",
    color: "rgba(208, 23, 23, 1)",
    "& > svg": {
      color: "rgba(208, 23, 23, 1)",
    },
    "& > p": {
      marginLeft: "5px",
    },
  },
  formControlContainer: {
    width: "100%",
    "& > div": {
      alignItems: "center",
    },
    "& > $nonDefaultSelectionContainer": {
      alignItems: "flex-start",
    },
  },
  nonDefaultSelectionContainer: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fit, 250px)`,
    justifyContent: "center",
    gap: "30px",
    "& label": {
      margin: "20px 0 0 0",
    },
  },
}));

/**
 * Design Profile Quiz page component.
 * @param props The DesignProfileQuizPageProps to pass to the component
 * @returns The page component.
 */
const DesignProfileQuizPage: FC<DesignProfileQuizPageProps> = ({
  page,
  onNext,
}) => {
  const {
    root,
    quizContainer,
    subtitle,
    errorMessageRef,
    errorMessage,
    formControlContainer,
    nonDefaultSelectionContainer,
  } = useStyles();

  const segment = useSegment();

  const {
    designProfile,
    updateDesignProfileDebounced,
    isFetching,
  } = useDesignProfileCtx();

  // error message needs to always be present in the markup for accessibility reasons
  // this ref will toggle the display of the message between "none" and "block"
  const errorMsgRef = useRef<HTMLDivElement | null>(null);

  // We need this so that there is not a delay between highlighting a selection
  // when the user selects an item. It needs to live here so we can properly
  // check for a required response.
  const [responses, setResponses] = useState<any>(
    getResponseForCurrentQuestion(designProfile?.questions ?? [], page.path)
  );

  const type = page.multiple_selections ? "checkbox" : "radio";
  const options = page.selections ?? {};

  const selectionComponent = type === "checkbox" ? QuizCheckbox : QuizRadio;
  const defaultSelections = options.default ? true : false;
  const formClassName = !defaultSelections ? nonDefaultSelectionContainer : "";

  // We need this to prevent concurrency issues that can cause a question
  // to get dropped by concurrent requests. This will only be true if we're
  // updating from a click of the 'Next' button.
  const [isUpdatingFromNextClick, setIsUpdatingFromNextClick] = useState(false);

  // We also need this prevent concurrency issues that can cause a question to
  // get dropped by concurrent requests. This will cause our page to load only if
  // we are still getting the most up-to-date data after making an update request.
  const [hasInitialFetchCompleted, setHasInitialFetchCompleted] = useState(
    !isFetching
  );

  // We need this so we're not unnecessarily updating our state
  // to check if the initial fetch has completed on subsequent
  // fetches.
  const hasCheckForInitialFetchRan = useRef(false);

  useEffect(() => {
    if (!isFetching && !hasCheckForInitialFetchRan.current) {
      setHasInitialFetchCompleted(true);

      hasCheckForInitialFetchRan.current = true;
    }
  }, [isFetching]);

  const renderSelectionsList = () => {
    return (
      <SelectionsList
        page={page}
        options={options}
        responses={responses}
        setResponses={(responses: any) => setResponses(responses)}
        defaultSelections={defaultSelections}
        SelectionComponent={selectionComponent}
      />
    );
  };

  const sendSegmentTracking = () => {
    segment.trackClicked({
      click_type: SegmentClickTypes.BUTTON,
      flow_name: SegmentFlows.DESIGN_PROFILE,
      button_name: "Design Profile Next Button",
      button_content: page.button_text || "",
    });
  };

  if (isUpdatingFromNextClick || !hasInitialFetchCompleted) {
    return (
      <CenterFullScreen>
        <GenericLoadingSpinner />
      </CenterFullScreen>
    );
  }

  return (
    <DesignProfileRouteAccess>
      <Box className={root}>
        <Box mt={6} className={quizContainer} textAlign="center">
          <YZTypography type="serif" variant="h3">
            {page.title}
          </YZTypography>
          <Box mt={4}>
            {page.subtitle && (
              <YZTypography className={subtitle}>{page.subtitle}</YZTypography>
            )}
          </Box>
          <Box mt={4}>
            {page.multiple_selections && (
              <YZTypography>Please select all that apply.</YZTypography>
            )}
          </Box>
          <Box mt={6}>
            {page.drop_down ? (
              <QuizDropdown
                page={page}
                options={options}
                // For a dropdown, this response should be a string value if
                // already answered. Otherwise if unanswered it will be an
                // empty object, and we should pass in an empty string so
                // the dropdown is initially empty.
                response={_.isEmpty(responses) ? "" : responses}
                setResponse={(response: string) => setResponses(response)}
              />
            ) : (
              <FormControl className={formControlContainer}>
                {type === "checkbox" ? (
                  <FormGroup className={formClassName}>
                    {renderSelectionsList()}
                  </FormGroup>
                ) : (
                  <RadioGroup className={formClassName}>
                    {renderSelectionsList()}
                  </RadioGroup>
                )}
              </FormControl>
            )}
          </Box>
          <Box mt={6}>
            <DesignProfileBlackButton
              text={page.button_text}
              onClick={() => {
                if (page.required && !isSelectionMade(responses)) {
                  errorMsgRef.current!.style.display = "block";
                } else {
                  (async () => {
                    sendSegmentTracking();

                    setIsUpdatingFromNextClick(true);

                    await updateDesignProfileDebounced.flush();

                    setIsUpdatingFromNextClick(false);

                    onNext();
                  })();
                }
              }}
            />
          </Box>
          <div ref={errorMsgRef} className={errorMessageRef} role="alert">
            <Box mt={4} className={errorMessage}>
              <Warning fontSize="small" />
              <YZTypography>
                Please respond to the question before moving on.
              </YZTypography>
            </Box>
          </div>
          <Box mt={4}>
            <DesignProfileStepNumber page={page} />
          </Box>
        </Box>
      </Box>
    </DesignProfileRouteAccess>
  );
};

export { DesignProfileQuizPage };
