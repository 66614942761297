import * as React from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import { howYZWorks } from "../../util/constants/howYZWorks";

type YZBuildProcessProps = {
  whiteBackground?: boolean;
};

const useStyles = makeStyles<Theme, YZBuildProcessProps>((theme: Theme) => ({
  buildRoot: {
    width: "100%",
    background: ({ whiteBackground }) =>
      whiteBackground ? "#ffffff" : "#F8F7F6",
    textAlign: "center",
  },
  howContainer: {
    justifyContent: "center",
  },
  img: {
    maxWidth: "100%",
  },
  howItem: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  description: {
    maxWidth: 250,
    margin: "auto",
  },
  learnMoreLink: {
    display: "inline-block",
    padding: "0.65rem 5rem",
    borderRadius: "4px",
    fontWeight: 600,
    letterSpacing: "1px",
    textTransform: "uppercase",
    textAlign: "center",
    color: "#fff",
    background: "#000",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(5),
    },
  },
}));

const YZBuildProcess: React.FC<YZBuildProcessProps> = ({ whiteBackground }) => {
  const {
    buildRoot,
    howContainer,
    howItem,
    description,
    img,
    learnMoreLink,
  } = useStyles({ whiteBackground });
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Box
      className={buildRoot}
      py={8}
      data-testid="YZ-Build-Process-Root-Container"
    >
      <Container>
        <Box mb={5}>
          <YZTypography variant="h2" type="serif" paragraph>
            How Yardzen works
          </YZTypography>
          <YZTypography color="textSecondary" variant="h6">
            A delightful, all-online process that starts in the design studio
            and ends with your dream yard
          </YZTypography>
        </Box>
        {smDown && <Divider />}
        <Box display="flex" justifyContent="center" mb={5}>
          <Link
            href={`${process.env.REACT_APP_WORDPRESS_BASE_URL}/how-it-works`}
            variant="button"
            underline="none"
            target="_blank"
            rel="noopener noreferrer"
            className={learnMoreLink}
          >
            Learn More
          </Link>
        </Box>
        <Grid container spacing={3} className={howContainer}>
          {howYZWorks.map(item => (
            <Grid key={item.id} item md={3} className={howItem}>
              <img src={item.imageSrc} alt={item.alt} className={img} />
              <Box mt={2} mb={4} textAlign="center">
                <YZTypography
                  color="textSecondary"
                  variant="overline"
                  type="uppercase"
                >
                  {item.day}
                </YZTypography>
                <YZTypography
                  variant={smDown ? "h3" : "h4"}
                  type="serif"
                  paragraph
                >
                  {item.title}
                </YZTypography>
                <YZTypography variant="body2" className={description}>
                  {item.description}
                </YZTypography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export { YZBuildProcess };
export default YZBuildProcess;
