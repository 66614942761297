import { StripeError } from "@stripe/stripe-js";
import LogRocket from "logrocket";

interface HandleStripeErrorArgs {
  error: StripeError;
  setError: (error: string) => void;
}

export const handleStripeError = async ({
  error,
  setError,
}: HandleStripeErrorArgs) => {
  error.message && setError(error.message);
  window.newrelic.noticeError(error);
  console.log(`Error processing order: `, error);
  await LogRocket.captureMessage(`Error processing order ${error}`);
};
