import * as React from "react";
import { Grid, Typography, LinearProgress, Theme } from "@material-ui/core";
import { SquareModalBase } from "../modals";
import { makeStyles } from "@material-ui/styles";

interface CustomDialogProps {
  progress: { [key: string]: any };
  open: boolean;
  files: File[];
}

const useStyles = makeStyles((theme: Theme) => ({
  gridRoot: {
    minWidth: "600px",
    maxHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      minWidth: 0,
    },
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}));
const UploadProgressDialog = (props: CustomDialogProps) => {
  const classes = useStyles();

  return (
    <SquareModalBase style={{ maxWidth: "80vw" }} open={!!props.open}>
      <div>
        <Typography className={classes.title} variant="h6">
          Uploading files...
        </Typography>
        <Grid
          container
          direction="column"
          spacing={1}
          className={classes.gridRoot}
        >
          {props.files.map((file: File, index) => (
            <Grid
              key={`${file.name}-${index}`}
              style={{ width: "100%", justifyContent: "center" }}
              item
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography style={{ maxWidth: "60%" }} noWrap>
                  {file.name}
                </Typography>
                <Typography noWrap>
                  {!!props.progress[file.name]
                    ? `${Math.floor(
                        Number(parseFloat(props.progress[file.name]).toFixed(2))
                      )}%`
                    : "waiting..."}
                </Typography>
              </div>
              <LinearProgress
                variant="determinate"
                // valueBuffer={props.progress[file.name] + Math.random() * 20}
                value={props.progress[file.name] || 0}
                style={{ height: "1.5rem" }}
                title={file.name}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </SquareModalBase>
  );
};

export { UploadProgressDialog };
export default UploadProgressDialog;
