import React, { FC, useMemo, useRef, useState } from "react";
import {
  Box,
  Fade,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  useBudgetChecklistResponsePriceAndStyleOptionsOnlybyResponseIdQuery,
  useDeleteBudgetChecklistResponseByIdMutation,
} from "@yardzen-inc/graphql";
import { useLogError } from "../../../util";
import { ChecklistItem, ChecklistItemResponse } from "./ItemResponseContext";
import { formatNumToUSD, useTriggerTimeout } from "@yardzen-inc/react-common";
import { DragHandle, MoreHoriz } from "@material-ui/icons";
import { useHistory } from "react-router";

export interface BudgetCartItemProps {
  itemData: ChecklistItem;
  responseData: ChecklistItemResponse;
  removeFunction: (id: string) => void;
  noMenu?: boolean;
  style?: React.CSSProperties;
  dndIcon?: boolean;
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    width: "100%",
    borderBottom: `1px solid ${palette.grey[200]}`,
    padding: spacing(1),
  },
  iconContainer: {
    height: "24px",
    minHeight: "24px",
    width: "24px",
    minWidth: "24px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  menuContainer: {
    position: "absolute",
    top: 0,
    right: 5,
    cursor: "pointer",
  },
  menu: {
    transform: "translate(-3.75rem, 0.5rem) !important",
  },
}));

// replace with real icon URIs
const dummyIconURI =
  "https://media.istockphoto.com/vectors/kitchen-furniture-line-icon-and-interior-vector-id673563702";

const BudgetCartItem: FC<BudgetCartItemProps> = ({
  itemData,
  responseData,
  removeFunction,
  noMenu,
  style,
  dndIcon,
}) => {
  const classes = useStyles();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const {
    data,
    loading,
    error,
  } = useBudgetChecklistResponsePriceAndStyleOptionsOnlybyResponseIdQuery({
    variables: { responseId: responseData.id },
    fetchPolicy: "cache-and-network",
  });

  const [
    deleteChecklistResponse,
  ] = useDeleteBudgetChecklistResponseByIdMutation();

  // TODO: there has to be a better way to do this, leaving
  // this here for now to continue development
  const { low_price: lowPrice, high_price: highPrice } = data
    ?.budget_checklist_response[0].budget_checklist_price_option || {
    low_price: 0,
    high_price: 0,
  };

  const anchorElementRef = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const priceMultiplier = useMemo(() => {
    if (itemData?.quantitative_item) {
      return 1;
    }

    const { left_yard, right_yard, front_yard, back_yard } = responseData;
    let multiplier = 0;

    void [left_yard, right_yard, front_yard, back_yard].forEach(
      booleanValue => {
        if (booleanValue) multiplier += 1;
      }
    );

    return multiplier || 1;
  }, [responseData, itemData]);

  const history = useHistory();
  const redirectToRef = useRef<string>("");
  const { triggerTimeout, triggered } = useTriggerTimeout(
    () => history.push(redirectToRef.current),
    100
  );

  useLogError(error as Error);

  return (
    <Box className={classes.root} style={{ ...style }}>
      <Fade
        in={!triggered && !loading}
        timeout={{ exit: 100, enter: 100 }}
        mountOnEnter
      >
        <>
          <Box width="100%" display="flex" alignItems="center">
            {!(smDown && dndIcon) && (
              <div
                className={classes.iconContainer}
                style={{
                  backgroundImage: `url(${itemData.medium?.public_uri ??
                    dummyIconURI})`,
                }}
              ></div>
            )}
            <Box pl={2} flex="1">
              <Typography>
                {itemData.name}{" "}
                {!itemData.quantitative_item
                  ? "X " + priceMultiplier.toString()
                  : null}
              </Typography>
            </Box>
            <Typography
              variant={mdDown ? "body2" : void 0}
              style={{ paddingRight: "1rem", textAlign: "right" }}
            >{`${formatNumToUSD(lowPrice * priceMultiplier)} - ${formatNumToUSD(
              highPrice * priceMultiplier
            )}`}</Typography>
            {!noMenu && (
              <div ref={anchorElementRef} className={classes.menuContainer}>
                <MoreHoriz onClick={() => setMenuOpen(true)} />
                <Menu
                  anchorEl={anchorElementRef.current}
                  open={menuOpen}
                  onClose={() => setMenuOpen(false)}
                  classes={{ paper: classes.menu }}
                >
                  <MenuItem onClick={() => handleEditClick(itemData.id)}>
                    edit
                  </MenuItem>
                  <MenuItem onClick={() => handleDeleteClick(itemData.id)}>
                    delete
                  </MenuItem>
                </Menu>
              </div>
            )}
            {dndIcon && <DragHandle />}
          </Box>
        </>
      </Fade>
    </Box>
  );

  function handleEditClick(id: string): void {
    redirectToRef.current = `/onboard/budget/checklist/item/${id}?back=review`;
    triggerTimeout();
  }

  // TODO: DELETE CONFIRMATION BEFORE DELETE
  function handleDeleteClick(id: string): void {
    deleteChecklistResponse({
      variables: { id: responseData.id },
      optimisticResponse: {
        delete_budget_checklist_response: {
          affected_rows: 1,
        },
      },
    });
    removeFunction(id);
  }
};

export { BudgetCartItem };
export default BudgetCartItem;
