/**
 * The name to pass in as the first argument to the impact radius function.
 */
type ImpactRadiusFunctionName = "identify" | "trackConversion";

/**
 * The acceptable codes to pass into the impact radius function.
 */
type ImpactRadiusCodes = 32230;

/**
 * The options that can be passed into the impact radius function.
 */
interface ImpactRadiusOptions {
  verifySiteDefinitionMatch: boolean;
}

/**
 * The properties to pass into the impact radius function when identifying
 * a user.
 */
interface ImpactRadiusIdentifyUserArgs {
  customerId: string;
  customerEmail: string;
}

interface ImpactRadiusTrackConversionArgs {
  orderId: string;
  customerId: string;
  customerEmail: string;
  customerStatus: string;
  currencyCode: string;
  orderPromoCode: string;
  orderDiscount: number;
  items: ImpactRadiusTrackConversionItems[];
}

interface ImpactRadiusTrackConversionItems {
  subTotal: number;
  category: string;
  sku: string;
  quantity: number;
  name: string;
}

/**
 * The method signature for the impact radius identify function.
 */
type ImpactRadiusIdentifyFunction = (
  name: ImpactRadiusFunctionName,
  data: ImpactRadiusIdentifyUserArgs | ImpactRadiusTrackConversionArgs
) => void;

/**
 * The method signature for the impact radius track conversion function.
 */
type ImpactRadiusTrackConversionFunction = (
  name: ImpactRadiusFunctionName,
  conversionCode: ImpactRadiusCodes,
  data: ImpactRadiusTrackConversionArgs,
  options: ImpactRadiusOptions
) => void;

/**
 * Type that extends the global window type with the impact radius function as
 * a property.
 */
export type WindowWithImpactRadiusIdentifyFunction = Window &
  typeof globalThis & {
    ire: ImpactRadiusIdentifyFunction | ImpactRadiusTrackConversionFunction;
  };

/**
 * Interface that describes the available impact radius functions
 */
interface ImpactRadiusFunctions {
  identifyForImpactRadius: (data: ImpactRadiusIdentifyUserArgs) => void;
  trackImpactRadiusConversion: (data: ImpactRadiusTrackConversionArgs) => void;
}

/**
 * Allows the caller to use the impact radius identify or track conversion
 * functions.
 * @returns An object with methods that call the impact radius identify
 * and track conversion methods.
 */
export const useImpactRadius = (): ImpactRadiusFunctions => {
  const impactRadiusFunction = (window as WindowWithImpactRadiusIdentifyFunction)
    .ire;

  if (!impactRadiusFunction) {
    console.error("The impact radius identify function is not defined.");
  }

  return {
    identifyForImpactRadius: (data: ImpactRadiusIdentifyUserArgs) => {
      if (!impactRadiusFunction) {
        console.warn("The impact radius identify function is not defined.");
        return;
      }

      const identify = impactRadiusFunction as ImpactRadiusIdentifyFunction;

      identify("identify", data);
    },
    trackImpactRadiusConversion: (data: ImpactRadiusTrackConversionArgs) => {
      if (!impactRadiusFunction) {
        console.warn(
          "The impact radius track conversion function is not defined."
        );
        return;
      }

      const trackConversion = impactRadiusFunction as ImpactRadiusTrackConversionFunction;

      trackConversion("trackConversion", 32230, data, {
        verifySiteDefinitionMatch: true,
      });
    },
  };
};
