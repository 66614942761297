import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PriceObject } from "../types/Prices";
import { Product } from "../types/Products";

interface ProductState {
  selectedProduct: Product | null;
  selectedSku: PriceObject | null;
  addOnSkus: PriceObject[];
  orderId: string | null;
  addOnsTotal: number;
  modifiedPrice: number | null; // price after promotional code / gift card applied. Calculated on front end (unfortunately) but recalculated on back end when payment intent is created
  products: Product[];
  packageStyle?: string | null;
}

export const productInitialState: ProductState = {
  selectedProduct: null,
  selectedSku: null,
  addOnSkus: [],
  orderId: null,
  addOnsTotal: 0,
  modifiedPrice: null,
  products: [],
  packageStyle: null,
};

export const productSlice = createSlice({
  name: "product",
  initialState: productInitialState,
  reducers: {
    setProductStyle: (
      state: ProductState,
      action: PayloadAction<{ packageStyle: string | null | undefined }>
    ) => {
      state.packageStyle = action.payload.packageStyle;
    },
    setSelectedProduct: (
      state: ProductState,
      action: PayloadAction<{ selectedProduct: Product | null }>
    ) => {
      state.selectedProduct = action.payload.selectedProduct;
    },
    setProducts: (
      state: ProductState,
      action: PayloadAction<{ products: Product[] }>
    ) => {
      state.products = action.payload.products;
    },
    setSelectedSku: (
      state: ProductState,
      action: PayloadAction<{ selectedSku: PriceObject | null }>
    ) => {
      state.selectedSku = action.payload.selectedSku;
    },
    addToAddOns: (
      state: ProductState,
      action: PayloadAction<{ addOn: PriceObject; repeatable?: boolean }>
    ) => {
      if (
        action.payload.repeatable === false &&
        state.addOnSkus.find(sku => sku.id === action.payload.addOn.id)
      ) {
        return;
      }

      const updatedAddOns = [...state.addOnSkus, action.payload.addOn];
      const totalAddOnsPrice = updatedAddOns.reduce((sum, addOn) => {
        sum += addOn.price;

        return sum;
      }, 0);
      state.addOnSkus = updatedAddOns;
      state.addOnsTotal = totalAddOnsPrice;
    },
    removeFromAddOns: (
      state: ProductState,
      action: PayloadAction<{ addOn: PriceObject }>
    ) => {
      const updatedAddOns = state.addOnSkus.filter(
        sku => sku.id !== action.payload.addOn.id
      );
      const totalAddOnsPrice = updatedAddOns.reduce((sum, addOn) => {
        sum += addOn.price;

        return sum;
      }, 0);
      state.addOnSkus = updatedAddOns;
      state.addOnsTotal = totalAddOnsPrice;
    },
    setOrderId: (
      state: ProductState,
      action: PayloadAction<{ orderId: string }>
    ) => {
      state.orderId = action.payload.orderId;
    },
    setAddOnsTotal: (
      state: ProductState,
      action: PayloadAction<{ addOnsTotal: number }>
    ) => {
      state.addOnsTotal = action.payload.addOnsTotal;
    },
    setModifiedPrice: (
      state: ProductState,
      action: PayloadAction<{ modifiedPrice: number | null }>
    ) => {
      state.modifiedPrice = action.payload.modifiedPrice;
    },
    resetProductCheckoutItems: state => {
      return {
        ...productInitialState,
        products: state.products,
        selectedProduct: state.selectedProduct,
        selectedSku: state.selectedSku,
      };
    },
    resetProduct: state => productInitialState,
  },
});

export const {
  setSelectedProduct,
  setProducts,
  setSelectedSku,
  setOrderId,
  addToAddOns,
  removeFromAddOns,
  setAddOnsTotal,
  setModifiedPrice,
  resetProduct,
  resetProductCheckoutItems,
  setProductStyle,
} = productSlice.actions;

export default productSlice.reducer;
