import {
  Box,
  CircularProgress,
  Slide,
  Theme,
  makeStyles,
} from "@material-ui/core";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import classnames from "classnames";
import React, { FC, useState } from "react";
import { PromotionalCode } from "../../api/apiTypes";
import { useDataLayer } from "../../data";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  setPromotionalCode,
  setRestrictChanges,
} from "../../slices/checkoutSlice";
import {
  setOrderPrice,
  setOriginalOrderPrice,
} from "../../slices/paymentSlice";
import { PriceObject } from "../../types/Prices";
import { UserCtx } from "../../util";
import { SegmentClickTypes, useSegment } from "../../util/Segment";
import { IAddressObject } from "../../util/functions/parseGeocodeLocationToAddress";
import { usePremiumPackage } from "../../util/hooks/usePremiumPackage";
import { useDesignProfileCtx } from "../designProfile";
import { AddressCheckBox } from "./AddressCheckBox";
import { AddressInput } from "./AddressInput";
import NameInput from "./NameInput";
import { constructOrder } from "./util/constructOrder";
import { getEmail } from "./util/getEmail";
import { getPaymentIntent } from "./util/getPaymentIntent";
import { getUserId } from "./util/getUserId";
import { recordBillingDetailsTracking } from "./util/recordBillingDetailsTracking";

export interface BillingDetailsProps {
  error: false | string; // this should not be this type
  propertyAddress: IAddressObject;
  setError: (err: string) => void;
  setPaymentIntentId: (id: string) => void;
  setClientSecret: (secret: string) => void;
  idToken: string;
  isAdditionalRevision: boolean;
  omitAddressFields?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    display: "flex",
    flexDirection: "column",
  },
  spacingTop: {
    marginTop: theme.spacing(4),
  },
}));

const BillingDetails: FC<BillingDetailsProps> = ({
  error,
  propertyAddress,
  setError,
  setPaymentIntentId,
  setClientSecret,
  idToken,
  isAdditionalRevision,
  omitAddressFields,
}) => {
  const segment = useSegment();
  const dataLayer = useDataLayer();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const dsContext = useDesignProfileCtx();
  const userContext = React.useContext(UserCtx);
  const { isPremium } = usePremiumPackage();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    inputReferralCode,
    billingAndPropertyAddressSame,
    firstName,
    lastName,
    address: { street, city, state, zip, aptNumber },
    promotionalCode,
  } = useAppSelector(state => state.checkout);
  const { addOnSkus, selectedSku } = useAppSelector(state => state.products);

  const billingAddress = {
    street,
    city,
    state,
    zip,
    country: "US",
    aptNumber,
    formattedAddress: `${street} ${city}, ${state}, ${zip}`,
  };

  const lowesErrorMessage = getLowesErrorMessage(promotionalCode, selectedSku);

  const [hasTriedSubmitting, setHasTriedSubmitting] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setHasTriedSubmitting(true);
    setLoading(true);

    recordBillingDetailsTracking(dataLayer);

    // Create Order
    const order = constructOrder({
      addOnSkus,
      skuId: selectedSku?.id || "",
      propertyAddress: billingAndPropertyAddressSame
        ? propertyAddress
        : billingAddress,
      userId: getUserId({ userContext, dsContext }) || "",
      email: getEmail({
        userContext,
        designProfile: dsContext.designProfile,
      }),
      inputReferralCode,
      isPremium,
      isAdditionalRevision,
      firstName,
      lastName,
      promotionalCode: promotionalCode?.code,
    });

    if (order) {
      // Get payment intent
      await getPaymentIntent({
        order,
        dsContext,
        setError,
        setPaymentIntentId,
        setClientSecret,
        idToken,
        setOrderPrice: orderPrice => dispatch(setOrderPrice({ orderPrice })),
        setOriginalOrderPrice: originalOrderPrice =>
          dispatch(setOriginalOrderPrice({ originalOrderPrice })),
        setPromotionalCode: code => dispatch(setPromotionalCode(code)),
      });
      setLoading(false);
    } else {
      console.error("There was a problem creating order");
      setLoading(false);
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box className={classnames(classes.column, classes.spacingTop)}>
          <YZTypography variant="h4" type="serif" style={{ fontSize: 22 }}>
            Billing Details
          </YZTypography>
          <NameInput hasTriedSubmitting={hasTriedSubmitting} />
          {!omitAddressFields && (
            <>
              <AddressCheckBox />
              <AddressInput hasTriedSubmitting={hasTriedSubmitting} />
            </>
          )}
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Slide unmountOnExit direction="down" in={true}>
            <div>
              {lowesErrorMessage && <Box>{lowesErrorMessage}</Box>}
              <Box
                style={{
                  marginBottom: "1rem",
                  display: "flex",
                }}
                className={classes.spacingTop}
              >
                {loading ? (
                  <Box
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    display="flex"
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <YZButton
                    disabled={
                      error === "payment_error" ||
                      loading ||
                      isInvalidLowesCode(promotionalCode, selectedSku)
                    }
                    id="confirm-purchase-YZ-Button"
                    onClick={() => {
                      segment.trackClicked({
                        flow_name: "",
                        button_name: "Confirm Order Next",
                        click_type: SegmentClickTypes.BUTTON,
                        button_content: "NEXT",
                      });
                      dispatch(setRestrictChanges({ restrictChanges: true }));
                    }}
                    type="submit"
                    color="primary"
                    fullWidth
                  >
                    NEXT
                  </YZButton>
                )}
              </Box>
            </div>
          </Slide>
        </div>
      </form>
    </Box>
  );
};

function getLowesErrorMessage(
  promoCode: PromotionalCode | null,
  selectedSku: PriceObject | null
) {
  if (!isInvalidLowesCode(promoCode, selectedSku)) {
    return "";
  }
  return (
    <>
      Please provide a valid MyLowe's Rewards Yardzen code. If you're having
      trouble, please reach out to{" "}
      <a href="mailto:hello@yardzen.com">hello@yardzen.com</a> for support.
    </>
  );
}

function isInvalidLowesCode(
  promoCode: PromotionalCode | null,
  selectedSku: PriceObject | null
) {
  if (!isLowesSku(selectedSku)) {
    return false;
  }
  if (!isLowesPromoCode(promoCode)) {
    return true;
  }
  return false;
}

function isLowesSku(sku: PriceObject | null) {
  return sku?.attributes.name.includes("Lowes");
}

function isLowesPromoCode(promoCode: PromotionalCode | null) {
  return promoCode?.code.toLowerCase().startsWith("yzlo");
}

export { BillingDetails };
export default BillingDetails;
