import {
  ApolloError,
  useBudgetMetadataAndPhasingByProjectIdQuery,
} from "@yardzen-inc/graphql";
import { useContext } from "react";
import { useLogError } from "../../../util";
import { BudgetStepCtx } from "./BudgetStepContext";
import useBudgetChecklistPriceEstimate from "./useBudgetChecklistPriceEstimate";

export interface BudgetComparisonData {
  lowEstimate: number;
  highEstimate: number;
  budget?: number;
  usingPhaseOneBudget?: boolean;
  estimateAgError?: ApolloError;
  budgetMetadataError?: ApolloError;
}

const useGetBudgetComparisonData: () => BudgetComparisonData = () => {
  const {
    project: { id: projectId },
  } = useContext(BudgetStepCtx);

  const [estimate, estimateError] = useBudgetChecklistPriceEstimate(projectId);

  const {
    data: budgetMetaData,
    error: budgetMetadataError,
  } = useBudgetMetadataAndPhasingByProjectIdQuery({
    variables: { projectId },
  });

  const lowEstimate: number = estimate?.[0] ?? 0;
  const highEstimate: number = estimate?.[1] ?? 0;

  const usingPhaseOneBudget: boolean | undefined =
    budgetMetaData?.budget_metadata?.[0]?.phasing &&
    !budgetMetaData?.budget_metadata?.[0]?.design_project_for_single_phase;

  const budget: number | undefined = usingPhaseOneBudget
    ? budgetMetaData?.budget_metadata?.[0]?.total_budget
    : undefined;

  useLogError(estimateError as Error);
  useLogError(budgetMetadataError as Error);

  return {
    lowEstimate,
    highEstimate,
    budget,
    usingPhaseOneBudget,
    estimateAgError: estimateError ?? void 0,
    budgetMetadataError,
  };
};

export { useGetBudgetComparisonData };
export default useGetBudgetComparisonData;
