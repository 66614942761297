import { DataLayer } from "@yardzen-inc/data-layer";
import LogRocket from "logrocket";
import { GoogleAnalyticsCheckoutObject } from "../../../util";

interface sendBeginCheckoutEventArgs {
  productObject: GoogleAnalyticsCheckoutObject;
  dataLayer: DataLayer;
}

export const sendBeginCheckoutEvent = ({
  productObject,
  dataLayer,
}: sendBeginCheckoutEventArgs) => {
  try {
    const onYardzenCom = window.location.host === "yardzen.com";
    const beginCheckoutEvent = {
      event: "begin_checkout",
      ecommerce: {
        currency: "USD",
        value: parseFloat(productObject.price),
        items: [
          {
            item_id: productObject.sku,
            item_name: `${productObject.name}${onYardzenCom ? "" : " TEST"}`,
            item_category: productObject.category,
            price: parseFloat(productObject.price),
            quantity: 1,
          },
        ],
      },
    };
    dataLayer.pushEvent(beginCheckoutEvent);
  } catch (error) {
    console.error(`Error sending to Google Analytics`);
    LogRocket.captureMessage(
      `Error sending order ${productObject.name} to Google Analytics`
    );
  }
};
