import {
  ExteriorTypes,
  LandscapingTypes,
} from "../../../util/constants/wishListTypes";
import { exteriorOptions } from "./quizPage/quizSelections";

export const wishListIncludesExterior = (
  wishList: (LandscapingTypes | ExteriorTypes)[]
) => {
  let res = false;

  (exteriorOptions as ExteriorTypes[]).forEach(item => {
    if (wishList.includes(item)) {
      res = true;
    }
  });

  return res;
};
