import * as React from "react";
import Dropzone from "react-dropzone";
import { Box, Link, Typography } from "@material-ui/core";
import UploadProgressDialog from "./FileUploadProgressDialog";
import uploadMedia from "../../util/functions/media/uploadMedia";
import { UserCtx } from "../../util";
import firebase from "firebase/compat/app";

export interface Props {
  projectId: string;
  fileTag: string;
  variant: string;
  children: React.ReactElement;
  onUploadStart?: Function;
  onUploadComplete?: (files: File[][]) => void;
  onUploadProgress?: Function;
  accept?: string[];
  maxFileCount?: number;
  currentFileCount?: number;
  maxConcurrentUploads?: number; // Defaults to 1
}

const FileUpload: React.FC<Props> = ({
  projectId,
  fileTag,
  variant,
  onUploadComplete,
  onUploadProgress,
  onUploadStart,
  accept,
  maxConcurrentUploads = 1,
  maxFileCount = 0,
  currentFileCount = 0,
  children,
}) => {
  const userContext = React.useContext(UserCtx);
  const [files, setFiles] = React.useState<File[]>([]);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [progress, setProgress] = React.useState<{ [key: string]: any }>({});
  const [error, setError] = React.useState<boolean>(false);

  const limitReached = !!maxFileCount
    ? maxFileCount - currentFileCount <= 0
    : false;

  return (
    <Box>
      {!!error && (
        <Typography color="error" variant="h6">
          There was a problem trying to upload one or more of your files. Please
          reload the web page and try again. If you continue to see this error,
          please reach out to{" "}
          <Link href="mailto:support@yardzen.com">support@yardzen.com</Link>
        </Typography>
      )}
      <Dropzone
        disabled={limitReached}
        onDropAccepted={onDrop}
        accept={accept || ["image/*", "video/*", ".pdf", ".dwg", ".heic"]}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: "dropzone" })}>
            {React.cloneElement(children, {
              isUploading,
              limitReached,
              getInputProps,
            })}
          </div>
        )}
      </Dropzone>
      <UploadProgressDialog
        progress={progress}
        files={files}
        open={!!files.length}
      />
    </Box>
  );

  async function onDrop(files: File[]) {
    if (
      maxFileCount &&
      maxFileCount > 0 &&
      typeof currentFileCount === "number"
    ) {
      files = files.slice(0, maxFileCount - currentFileCount);
    }

    setFiles(files);
    setIsUploading(true);

    if (onUploadStart) {
      onUploadStart(files);
    }

    // get an array of functions that will return a promise when called
    const promiseGenerators = files.map(file => () =>
      returnMediaStorePromise(
        projectId,
        file,
        fileTag,
        variant,
        (userContext as firebase.User).uid,
        handleUploadProgress
      )
    );

    // Split the array into "chunks" eg: [func(), func(), func()] -> [[func(), func()], [func()]]
    const chunkedPromiseGenerators = getChunkedPromises(
      promiseGenerators,
      maxConcurrentUploads
    );

    const filesUploaded = [];

    for await (const promiseGeneratorChunk of chunkedPromiseGenerators) {
      try {
        const mediaMetadataArr: any[] = await Promise.all(
          // call each function to get a promise, this will upload all files in a given
          // chunk at a time
          promiseGeneratorChunk.map((getPromise: any) => getPromise())
        );
        filesUploaded.push(mediaMetadataArr.flat());
      } catch (error) {
        window.newrelic.noticeError(error);
        console.error(`Error uploading file: ${error}`);
        setError(true);
      }
    }

    setFiles([]);
    setProgress({});
    setIsUploading(false);

    if (onUploadComplete) {
      onUploadComplete(filesUploaded);
    }
  }

  function getChunkedPromises(
    array: ((...args: File[]) => Promise<any>)[],
    chunkSize: number
  ) {
    if (!array || !array.length) return [];
    if (chunkSize === 0) {
      return [array];
    }
    const returnArr = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      returnArr.push(chunk);
    }
    return returnArr;
  }

  function handleUploadProgress(file: File, snapshot: any) {
    if (onUploadProgress) {
      onUploadProgress(file, snapshot);
    }

    const fileProgress: { [key: string]: any } = {};
    fileProgress[file.name] = Math.floor(
      (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    );

    setProgress(progress => {
      return { ...progress, ...fileProgress };
    });
  }

  function returnMediaStorePromise(
    projectId: string,
    file: File,
    tag: string,
    variant: string,
    userId: string,
    handleUploadProgress: any
  ): Promise<any> {
    return uploadMedia(
      file,
      userId,
      handleUploadProgress,
      tag,
      variant,
      projectId
    );
  }
};

export { FileUpload };
export default FileUpload;
