interface IsOptionCheckedArgs {
  responses: any;
  value: string;
}

/**
 * Returns the index of a selection in the context response body (-1 if not found)
 *
 * @param {DesignProfileQuestionResponse} responses The responses the user has already made
 * @param {string} value A selection the user has made
 * @return {boolean} An index
 */
export const isOptionChecked = ({
  responses,
  value,
}: IsOptionCheckedArgs): boolean => {
  if (Array.isArray(responses)) {
    return responses.includes(value);
  }

  if (typeof responses === "string" || responses instanceof String) {
    // We have to cast toString() here otherwise typescript will complain.
    return responses.toString() === value;
  }

  let selected = false;
  for (const key in responses) {
    if (Array.isArray(responses[key])) {
      const responsesForOption = responses[key] as string[];

      if (responsesForOption.includes(value)) {
        selected = true;
        break;
      }
    }
  }

  return selected;
};
