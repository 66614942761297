import { useTreatment } from "@yardzen-inc/react-split";
import { lotSizeDisplayNames } from "./constants/lotSizeTypes";
import { NO_RECOMMEND_ACREAGE_LOT_SIZE } from "./Split/splitTreatments";

export const useLotSizeDisplayText = (lotSize: string) => {
  if (!useTreatment(NO_RECOMMEND_ACREAGE_LOT_SIZE)) {
    return lotSize;
  }

  return (
    lotSizeDisplayNames[lotSize as keyof typeof lotSizeDisplayNames] ?? lotSize
  );
};
