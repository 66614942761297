import React, { FC, useContext, useState } from "react";
import {
  Typography,
  Box,
  makeStyles,
  Theme,
  Button,
  capitalize,
} from "@material-ui/core";
import { OnboardCtx, useSetTitle } from "../../util";
import YardCommentsGrid from "./YardCommentsGrid";
import { YardName } from "./stepData";
import StepWrapper from "./StepWrapper";
import transformYardStatusObjKey from "../../util/functions/transformYardStatusObjKey";
import UploadInstructionSequence from "./UploadInstructionSequence";
import { instructionSteps } from "./InstructionContainer";
import SectionTitle from "./helpers/SectionTitle";
import { YZTypography } from "@yardzen-inc/react-common";
import { YardUploadStatus } from "@yardzen-inc/models";
import { useHistory } from "react-router";
import SaveBar from "./helpers/SaveBar";
import {
  SegmentFlows,
  SegmentInputTypes,
  SegmentSubFlows,
  useSegment,
} from "../../util/Segment";
import { segmentTrackUploadHandler } from "../upload/segmentUtil";

export interface MyYardCommentsProps {
  yard: YardName;
}

const useStyles = makeStyles((theme: Theme) => ({
  instructionLink: {
    textDecoration: "underline",
    textTransform: "capitalize",
    color: theme.palette.primary.dark,
    fontSize: "0.9rem",
  },
}));

const MyYardComments: FC<MyYardCommentsProps> = ({ yard }) => {
  const segment = useSegment();
  useSetTitle(`Yardzen - ${yard} yard items to Keep / Remove`, false, [yard]);
  const classes = useStyles();
  const history = useHistory();
  const [keepCommentsComplete, setKeepCommentsComplete] = useState<
    boolean | undefined
  >(undefined);
  const [removeCommentsComplete, setRemoveCommentsComplete] = useState<
    boolean | undefined
  >(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [instructionModalOpen, setInstructionModalOpen] = useState<
    number | null
  >(null);

  const {
    state: { projectId },
    setYardStepStatus,
  } = useContext(OnboardCtx);

  React.useEffect(() => {
    function onExit() {
      if (
        keepCommentsComplete === void 0 ||
        removeCommentsComplete === void 0
      ) {
        return;
      }
      const allComplete = keepCommentsComplete && removeCommentsComplete;
      setYardStepStatus(
        yard,
        "keepRemove",
        allComplete ? "COMPLETE" : "INCOMPLETE"
      );
    }

    return () => onExit();
  }, [keepCommentsComplete, removeCommentsComplete, setYardStepStatus, yard]);

  return (
    <StepWrapper onErrorClose={() => setError(undefined)} errorMessage={error}>
      <UploadInstructionSequence
        instructionSteps={instructionSteps}
        open={!!instructionModalOpen}
        onClose={() => setInstructionModalOpen(null)}
        specificIndex={instructionModalOpen || 0}
        noStepper
      />
      <Box textAlign="center">
        <SectionTitle
          title={`Show us what to keep/remove in your
          ${transformYardStatusObjKey(yard).toLowerCase()}!`}
          subtitle="
            Please take a clear shot of the item and leave a short comment for each
            image. This is very helpful for your designer. (optional)
          "
          hasBack
        />
        <Button
          onClick={() => setInstructionModalOpen(3)}
          style={{ marginTop: "-2rem" }}
        >
          <Typography className={classes.instructionLink}>
            See detailed instructions
          </Typography>
        </Button>
        {!!error && <Typography color="error">{error}</Typography>}
        {!!projectId && (
          <>
            <Box p={2}>
              <YZTypography type="serif" variant="h5">
                What to keep
              </YZTypography>
              <YardCommentsGrid
                projectId={projectId}
                fileTag="property"
                variant={`${getVariantTagPrefix()}-keep`}
                setCommentsComplete={setKeepCommentsComplete}
                onCommentsComplete={(fileName: string, comment: string) => {
                  segment.trackFormFieldFilled({
                    flow_name: SegmentFlows.ONBOARD,
                    sub_flow_name: SegmentSubFlows.MY_YARD,
                    form_name: getFormName(),
                    file_name: fileName,
                    field_name: "Keep Comment",
                    input_type: SegmentInputTypes.TEXT,
                    option_selected: comment,
                  });
                }}
                yard={yard as YardName}
                action="keep"
                onDelete={media => {
                  segment.trackRemoved({
                    flow_name: SegmentFlows.ONBOARD,
                    sub_flow_name: SegmentSubFlows.MY_YARD,
                    removable_name: `${capitalize(yard)} Yard Keep`,
                    item_name: media.originalFileName,
                  });
                }}
                onUploadComplete={files =>
                  segmentTrackUploadHandler(segment, {
                    files,
                    form_name: getFormName(),
                    upload_name: `${capitalize(yard)} Yard Keep`,
                  })
                }
                deleteable
                allowUpload
              />
            </Box>
            <Box p={2}>
              <YZTypography type="serif" variant="h5">
                What to remove
              </YZTypography>
              <YardCommentsGrid
                projectId={projectId}
                fileTag="property"
                variant={`${getVariantTagPrefix()}-remove`}
                setCommentsComplete={setRemoveCommentsComplete}
                onDelete={media => {
                  segment.trackRemoved({
                    flow_name: SegmentFlows.ONBOARD,
                    sub_flow_name: SegmentSubFlows.MY_YARD,
                    item_name: media.originalFileName,
                    removable_name: `${capitalize(yard)} Yard Remove`,
                  });
                }}
                onCommentsComplete={(fileName: string, comment: string) => {
                  segment.trackFormFieldFilled({
                    flow_name: SegmentFlows.ONBOARD,
                    sub_flow_name: SegmentSubFlows.MY_YARD,
                    form_name: getFormName(),
                    file_name: fileName,
                    field_name: "Remove Comment",
                    input_type: SegmentInputTypes.TEXT,
                    option_selected: comment,
                  });
                }}
                onUploadComplete={files =>
                  segmentTrackUploadHandler(segment, {
                    files,
                    form_name: getFormName(),
                    upload_name: `${capitalize(yard)} Yard Remove`,
                  })
                }
                yard={yard as YardName}
                action="remove"
                deleteable
                allowUpload
              />
            </Box>
          </>
        )}
      </Box>
      <SaveBar
        label="Save and go to functions →"
        mdUpStyle={{ marginTop: 40, marginLeft: 120 }}
        smDownStyle={{ marginTop: 40 }}
        onClick={() => {
          handleNav("priorities");
          segment.trackFormSubmitted({
            flow_name: SegmentFlows.ONBOARD,
            form_name: getFormName(),
            sub_flow_name: SegmentSubFlows.MY_YARD,
          });
        }}
      />
    </StepWrapper>
  );

  function getFormName() {
    return `${capitalize(yard)} Yard Keep Remove`;
  }

  function handleNav(destination: keyof YardUploadStatus) {
    const subStepUrlMap = {
      keepRemove: "keep", // Keep and remove will be consolidated to one view/endpoint
    };

    history.push(
      `/onboard/my-yard/${yard}/${
        destination === "keepRemove" ? subStepUrlMap[destination] : destination
      }`
    );
  }

  function getVariantTagPrefix(): string {
    if (yard === "front") return "front-yard";
    if (yard === "back") return "back-yard";
    if (yard === "left") return "left-yard";
    if (yard === "right") return "right-yard";
    if (yard === "outdoor") return "outdoor-yard";
    return `${yard}-yard`;
  }
};

export { MyYardComments };
export default MyYardComments;
