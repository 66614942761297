import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { YZTypography } from "@yardzen-inc/react-common";
import { updateEmail, updateProfile } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { banyanAPI } from "../../api/banyanV1API";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  setCheckoutEmail,
  setFirstName,
  setLastName,
} from "../../slices/checkoutSlice";
import { RootState } from "../../store";
import { UserCtx, useUser } from "../../util";
import { useDesignProfileCtx } from "../designProfile";
import { GoogleLoginButton } from "./GoogleLoginButton";
import NameInput from "./NameInput";
import { useDebug } from "./useDebug";
import { signInWithGoogle } from "./util/auth";
import { DebugInfo } from "./util/DebugInfo";
import { getEmail } from "./util/getEmail";
import { useSegment } from "../../util/Segment";
import Cookies from "js-cookie";

export const Account: React.FC<{
  className?: string;
  isEditing: boolean;
  setIsEditing: (editing: boolean) => void;
  byProxy?: boolean;
}> = ({ className, setIsEditing, isEditing, byProxy }) => {
  const segment = useSegment();
  const [err, user] = useUser();
  const userContext = React.useContext(UserCtx);
  const { designProfile, updateDesignProfile } = useDesignProfileCtx();
  const checkoutEmail = useSelector(
    (state: RootState) => state.checkout.checkoutEmail
  );

  const email =
    getEmail({
      userContext,
      designProfile,
    }) || checkoutEmail;

  const dispatch = useAppDispatch();
  const { firstName, lastName } = useAppSelector(state => state.checkout);
  const { paymentIntentId } = useAppSelector(state => state.payment);
  const [showDebugInfo, setShowDebugInfo] = useState(false);
  const isDebugMode = useDebug();
  const isLoggedInWithEmail = !!user && !!email;

  const [
    createTermsOfService,
  ] = banyanAPI.useCreateUserTermsOfServiceMutation();

  useEffect(() => {
    if (user && user.email) {
      if (paymentIntentId && firstName && lastName) {
        setIsEditing(false);
      }
      const displayName = user.displayName || "";
      const [fetchedFirstName, fetchedLastName] = displayName.split(" ");
      dispatch(setFirstName({ firstName: fetchedFirstName || "" }));
      dispatch(setLastName({ lastName: fetchedLastName || "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dispatch, paymentIntentId]);

  const handleSubmit = async (newEmail: string) => {
    if (user) {
      await updateEmail(user, newEmail);
      await updateProfile(user, {
        displayName: `${firstName} ${lastName}`,
      });
      dispatch(setCheckoutEmail({ checkoutEmail: newEmail }));
      dispatch(setFirstName({ firstName }));
      dispatch(setLastName({ lastName }));

      // Update email in design profile
      updateDesignProfile({
        ...designProfile,
        contactInformation: {
          ...designProfile?.contactInformation,
          email: newEmail,
        },
      });

      if (!isLoggedInWithEmail && user.uid) {
        try {
          // Track account creation when an email is added to an existing user
          segment.trackAccountCreated({
            email: newEmail,
            fbp: Cookies.get("_fbp") || null,
            fbc: Cookies.get("_fbc") || null,
          });
          if (!byProxy) {
            await createTermsOfService({
              userId: user.uid,
              tosAgreed: true,
              dateOfAgree: new Date().toISOString(),
            });
          }
        } catch (error) {
          console.error("Error saving terms of service agreement", error);
        }
      }

      // Identify user in Segment with provided email (new or updated)
      segment.identify({
        email: newEmail,
        fbp: Cookies.get("_fbp") || null,
        fbc: Cookies.get("_fbc") || null,
      });

      setIsEditing(false);
    } else {
      throw new Error("No user is currently signed in.");
    }
  };

  if (err) {
    return <YZTypography>Error: {err.message}</YZTypography>;
  }

  return (
    <Box className={className}>
      <Box display="flex" alignItems="center">
        {isDebugMode && (
          <IconButton
            onClick={() => setShowDebugInfo(!showDebugInfo)}
            size="small"
          >
            <span role="img" aria-label="Debug">
              🐞
            </span>
          </IconButton>
        )}
      </Box>
      {showDebugInfo && (
        <DebugInfo
          data={{ user, email, firstName, lastName }}
          title="Account"
        />
      )}
      {isEditing || !email || !firstName || !lastName ? (
        <AccountEditForm
          initialEmail={email || ""}
          onSubmit={handleSubmit}
          onCancel={() => setIsEditing(false)}
          isLoggedInWithEmail={isLoggedInWithEmail}
          byProxy={byProxy}
        />
      ) : (
        <AccountInfo
          email={email}
          name={`${firstName} ${lastName}`}
          onEdit={() => setIsEditing(true)}
        />
      )}
    </Box>
  );
};

const AccountInfo: React.FC<{
  email: string;
  name: string;
  onEdit: () => void;
}> = ({ email, name, onEdit }) => (
  <Box>
    <Box
      style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
    >
      <YZTypography>Name: {name}</YZTypography>
    </Box>
    <Box style={{ display: "flex", alignItems: "center" }}>
      <YZTypography>Email: {email}</YZTypography>
      <Button
        variant="outlined"
        onClick={onEdit}
        style={{ marginLeft: "1rem" }}
      >
        Change
      </Button>
    </Box>
  </Box>
);

const useStyles = makeStyles((theme: Theme) => ({
  linkText: {
    cursor: "pointer",
    color: theme.palette.primary.dark,
    textDecoration: "underline",
    fontWeight: 500,
  },
  dividerContainer: {
    display: "flex",
    alignItems: "center",
    margin: "16px 0",
  },
  divider: {
    flexGrow: 1,
  },
  orText: {
    margin: "0 16px",
    color: "#C4C4C4",
    fontSize: "12px",
    letterSpacing: "2px",
  },
}));

const AccountEditForm: React.FC<{
  initialEmail: string;
  onSubmit: (email: string) => Promise<void>;
  onCancel: () => void;
  isLoggedInWithEmail: boolean;
  byProxy?: boolean;
}> = ({ initialEmail, onSubmit, onCancel, isLoggedInWithEmail, byProxy }) => {
  const classes = useStyles();

  const [email, setEmail] = useState(initialEmail);
  const [error, setError] = useState<string | null>(null);
  const [isEmailInUse, setIsEmailInUse] = useState<boolean>(false);
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
  const [
    sendPasswordResetEmail,
  ] = banyanAPI.useSendPasswordResetEmailMutation();

  useEffect(() => {
    // Check for user login status and redirect to login page if not logged in
    const userLoggedIn = document.cookie
      .split(";")
      .some(item => item.trim().endsWith("yz_access_token"));
    if (userLoggedIn && !email) {
      const callbackUrl = encodeURIComponent(window.location.href);
      window.location.href =
        window.location.hostname === "dashboard.yardzen.com"
          ? `https://dashboard.yardzen.com/login?callbackUrl=${callbackUrl}`
          : `https://staging.dashboard.dogfood.yardzen.com/login?callbackUrl=${callbackUrl}`;
    }
  }, [email]);

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithGoogle();
      if (result?.user) {
        await onSubmit(result.user.email ?? "");
      }
    } catch (error) {
      console.error("Error signing in with Google:", error);
      setError("Failed to sign in with Google. Please try again.");
    }
  };

  useEffect(() => {
    setEmail(initialEmail);
  }, [initialEmail]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsEmailInUse(false);
    try {
      await onSubmit(email);
      if (byProxy) {
        await sendPasswordResetEmail({ email });
      }
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setIsEmailInUse(true);
      } else {
        setError("Failed to update account information. Please try again.");
      }
      console.error("Error updating account information:", error);
    }
  };

  const termsText = (
    <YZTypography variant="body2">
      By creating an account, I agree to Yardzen's{" "}
      <a
        href="https://yardzen.com/electronic-signature-disclosures"
        rel="noreferrer"
        target="_blank"
        className={classes.linkText}
      >
        Consent to Electronic Signature and Disclosures
      </a>
      ,{" "}
      <a
        href="https://yardzen.com/terms-and-conditions"
        rel="noreferrer"
        target="_blank"
        className={classes.linkText}
      >
        Terms of Service
      </a>{" "}
      and{" "}
      <a
        href="https://yardzen.com/privacy-policy"
        rel="noreferrer"
        target="_blank"
        className={classes.linkText}
      >
        Privacy Policy
      </a>
      .
    </YZTypography>
  );

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NameInput hasTriedSubmitting={false} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter your email address"
            required
            label="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            type="email"
          />
        </Grid>
        {isEmailInUse && (
          <Grid item xs={12}>
            <Typography>
              An account with this email already exists. Please{" "}
              <Link
                href={`https://${
                  window.location.hostname === "dashboard.yardzen.com"
                    ? "project"
                    : "staging.project"
                }.yardzen.com/login?callback_url=${encodeURIComponent(
                  window.location.href
                )}`}
              >
                log in
              </Link>{" "}
              to continue, or use a different email address.
            </Typography>
          </Grid>
        )}
        {!isLoggedInWithEmail && !byProxy && (
          <Grid item xs={12}>
            <TosCheckbox
              setAgreedToTerms={setAgreedToTerms}
              agreedToTerms={agreedToTerms}
              termsText={termsText}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2} style={{ justifyContent: "flex-start" }}>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isLoggedInWithEmail && !agreedToTerms && !byProxy}
              >
                {isLoggedInWithEmail ? "Save" : "Next"}
              </Button>
            </Grid>
            {isLoggedInWithEmail && (
              <Grid item>
                <Button variant="outlined" color="secondary" onClick={onCancel}>
                  Cancel
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!isLoggedInWithEmail && !byProxy && (
          <Grid item xs={12}>
            <Box className={classes.dividerContainer}>
              <Divider className={classes.divider} />
              <Typography className={classes.orText}>OR</Typography>
              <Divider className={classes.divider} />
            </Box>
            <Box mt={2}>
              <GoogleLoginButton
                trackingData={{
                  flow_name: "ACCOUNT_UPDATE",
                }}
                variant="login"
                onClick={handleGoogleSignIn}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </form>
  );
};

const TosCheckbox: React.FC<{
  setAgreedToTerms: React.Dispatch<React.SetStateAction<boolean>>;
  agreedToTerms: boolean;
  termsText: JSX.Element;
}> = ({ setAgreedToTerms, agreedToTerms, termsText }) => {
  return (
    <Box pb={1}>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={agreedToTerms}
            onChange={e => setAgreedToTerms(e.target.checked)}
            id="tos-checkbox"
          />
        }
        label={termsText}
      />
    </Box>
  );
};
