import { DataLayer } from "@yardzen-inc/data-layer";
import LogRocket from "logrocket";
import {
  GoogleAnalyticsProductObject,
  sendGoogleAnalyticsPurchase,
} from "../../../util";
import sendTatariPurchaseEvent from "../../../util/functions/sendTatariPurchaseEvent";

interface HandleSendPurchaseEventsArgs {
  productObject: GoogleAnalyticsProductObject;
  dataLayer: DataLayer;
}

export const handleSendPurchaseEvents = ({
  productObject,
  dataLayer,
}: HandleSendPurchaseEventsArgs) => {
  try {
    const onYardzenCom = window.location.host === "yardzen.com";

    dataLayer.recordPurchaseEvent({
      category: productObject.category,
      name: `${productObject.name}${onYardzenCom ? "" : " TEST"}`,
      sku: productObject.sku,
      price: onYardzenCom ? parseFloat(productObject.price) : (0 as any),
      quantity: 1 as any,
      transactionId: productObject.id,
      transactionTotal: onYardzenCom
        ? parseFloat(productObject.price)
        : (0 as any),
      transactionProducts: productObject.name,
    });

    sendGoogleAnalyticsPurchase(productObject);

    sendTatariPurchaseEvent({
      total: Number(productObject.price),
      orderId: productObject.id,
    });
  } catch (error) {
    window.newrelic.noticeError(error);
    console.error(`Error sending to Google Analytics`);
    LogRocket.captureMessage(
      `Error sending order ${productObject.id} to Google Analytics`
    );
  }
};
