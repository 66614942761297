import { useEffect, useCallback } from "react";

const useGoogleAnalyticsPageview = (pageName: string) => {
  const ga = window["ga"];

  const setAndSendPageview = useCallback(() => {
    if (pageName) {
      ga("set", "page", pageName);
      ga("send", "pageview");
    }
  }, [ga, pageName]);

  useEffect(() => {
    if (
      !(process.env.NODE_ENV === "production") ||
      window.location.href.includes("dogfood") ||
      !ga
    ) {
      return;
    }

    setAndSendPageview();
  }, [ga, setAndSendPageview]);
};

export { useGoogleAnalyticsPageview };
export default useGoogleAnalyticsPageview;
