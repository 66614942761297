import { designProfilePaths } from "../../../designProfileNavigation";
import {
  DesignProfileNavigation,
  DesignProfileVersionId,
} from "../../../types/DesignProfile";

/**
 * Gets the design profile navigation object with the given id.
 * @param versionId The version id to search for.
 * @returns The design profile navigation object with the id.
 */
export const getDesignProfilePath = (
  versionId: DesignProfileVersionId
): DesignProfileNavigation => {
  return (
    designProfilePaths.find(path => path.versionId === versionId) ??
    designProfilePaths[0]
  );
};
