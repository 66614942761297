import "firebase/auth";
import { getAuth, signInWithCustomToken, UserCredential } from "firebase/auth";

/**
 * Logs in a user with a custom token.
 *
 * Note: This assumes that the firebase sdk has been initialized
 * @param token The custom token to log in with
 * @returns A promise that resolves with the user credential
 * @throws FirebaseError if login fails, or if the firebase sdk has not been initialized
 */
export async function loginWithCustomToken(
  token: string
): Promise<UserCredential> {
  const auth = getAuth();

  try {
    return await signInWithCustomToken(auth, token);
  } catch (error) {
    return error;
  }
}

/**
 * Logs in a user with a custom token from the URL query parameter `login_token`.
 *
 * @returns A promise that resolves with the user credential if the token is present in the URL query parameters, otherwise undefined
 * @throws FirebaseError if login fails, or if the firebase sdk has not been initialized
 */
export async function loginWithCustomTokenFromParams(): Promise<
  UserCredential | undefined
> {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("login_token");
  if (token) {
    return loginWithCustomToken(token);
  }

  return undefined;
}
