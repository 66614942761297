import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PaymentState {
  paymentIntentId: string;
  clientSecret: string;

  // these fields are used to store payment price info as reported from the backend
  orderPrice: number | null; // final price after getPaymentIntent.ts response
  originalOrderPrice: number | null; // original price (before any discounts) after getPaymentIntent.ts response
}

export const paymentInitialState: PaymentState = {
  paymentIntentId: "",
  clientSecret: "",
  orderPrice: null,
  originalOrderPrice: null,
};

export const payment = createSlice({
  name: "payment",
  initialState: paymentInitialState,
  reducers: {
    setPaymentIntentId: (
      state,
      action: PayloadAction<{ paymentIntentId: string }>
    ) => {
      state.paymentIntentId = action.payload.paymentIntentId;
    },
    setClientSecret: (
      state,
      action: PayloadAction<{ clientSecret: string }>
    ) => {
      state.clientSecret = action.payload.clientSecret;
    },
    setOrderPrice: (
      state,
      action: PayloadAction<{ orderPrice: number | null }>
    ) => {
      state.orderPrice = action.payload.orderPrice;
    },
    setOriginalOrderPrice: (
      state,
      action: PayloadAction<{ originalOrderPrice: number | null }>
    ) => {
      state.originalOrderPrice = action.payload.originalOrderPrice;
    },
    resetPayment: () => paymentInitialState,
  },
});

export const {
  setPaymentIntentId,
  setClientSecret,
  resetPayment,
  setOrderPrice,
  setOriginalOrderPrice,
} = payment.actions;

export default payment.reducer;
