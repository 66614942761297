import { FC, ReactNode, useEffect, useState } from "react";
import { SplitProvider, TrafficTypes } from "@yardzen-inc/react-split";
import { CenterFullScreen } from "../../components/utility/CenterFullScreen";
import { GenericLoadingSpinner } from "@yardzen-inc/react-common";
import { WaitForSplitClient } from "./WaitForSplitClient";
import Cookies from "js-cookie";
export interface SplitProviderWrapperProps {
  children: ReactNode;
}

export const SplitProviderWrapper: FC<SplitProviderWrapperProps> = ({
  children,
}) => {
  const [anonId, setAnonId] = useState("");
  const fallBackInitKey = "key";

  // check if segment anonymous id is on query params
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const segmentAnonId = urlParams.get("yzsa");
    if (segmentAnonId) {
      setAnonId(segmentAnonId);
    } else {
      // check if segment anonymous id is stored in cookie
      const segmentCookie = Cookies.get("yzsa");
      if (segmentCookie) {
        setAnonId(segmentCookie);
      }
    }
  }, []);

  // if not in cookie, then generate id
  useEffect(() => {
    (window as any)?.analytics?.ready(() => {
      if (!anonId.length) {
        setAnonId((window as any)?.analytics?.user?.()?.anonymousId?.() ?? "");
      }
    });
  }, [anonId]);

  // this is a fallback so split can still be initialized if we can't grab the segment anonymous id from the window
  useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      if (!anonId.length) {
        setAnonId(fallBackInitKey);
      }
    }, 3000);
    return () => window.clearTimeout(timeoutID);
  }, [anonId]);

  return anonId.length ? (
    <SplitProvider id={anonId} trafficType={TrafficTypes.ANONYMOUS}>
      <WaitForSplitClient>{children}</WaitForSplitClient>
    </SplitProvider>
  ) : (
    <CenterFullScreen>
      <GenericLoadingSpinner />
    </CenterFullScreen>
  );
};
