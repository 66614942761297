import { FormControlLabel, makeStyles, Radio, Theme } from "@material-ui/core";
import React, { FC, useCallback } from "react";
import { DesignProfileQuizSelectionsProps } from "../../../types/DesignProfile";
import classNames from "classnames";
import { handleKeyUpOnQuizOption } from "../util/handleKeyUpOnQuizOption";

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    border: "1px solid black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px 0",
    width: "250px",
    transition: "all 150ms",
    borderRadius: "5px",
    textTransform: "uppercase",
    "&:hover, &:focus-visible": {
      backgroundColor: theme.palette.primary.light,
    },
    "& span": {
      fontWeight: "bold",
      fontSize: "14px",
    },
  },
  control: {
    display: "none",
  },
  checked: {
    backgroundColor: "rgba(154, 168, 147, 1) !important",
    border: "1px solid transparent",
  },
}));

const QuizRadio: FC<DesignProfileQuizSelectionsProps> = ({
  selection,
  optionKey,
  handleSelect,
  checked,
}) => {
  const classes = useStyles();

  // The function is declared this way instead of an inline arrow function
  // because otherwise sonar cloud will complain about arrow functions
  // passed as props.
  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLLabelElement>) => {
      handleKeyUpOnQuizOption({
        event,
        selection,
        optionKey,
        handleSelect,
      });
    },
    [handleSelect, optionKey, selection]
  );

  return (
    <FormControlLabel
      control={
        <Radio
          className={classes.control}
          onChange={e => handleSelect(e.target.value, optionKey)}
          checked={checked}
        />
      }
      value={selection}
      label={selection}
      className={classNames(classes.label, checked ? classes.checked : "")}
      tabIndex={0}
      onKeyUp={handleKeyUp}
    />
  );
};

export { QuizRadio };
