/**
 * useFoundationalBudgetCostMultiplier returns fractional multiplier for the all-in
 * budget price ranges as to estimate the cost of installation and foundational work
 * @returns a tuple of size 2 that contains the cost increase multipliers of the
 * low and high price ranges respectively
 */
function useFoundationalBudgetCostMultiplier(): [number, number] {
  return [0.1, 0.2];
}

export { useFoundationalBudgetCostMultiplier };
