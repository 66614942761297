import * as React from "react";
import { ProfileCtx, UserCtx } from "../../util";
import { useMaps } from "../map/util/useMaps";
import "firebase/auth";
import { FullScreenLoadingPage } from "../loadingComponents";
import { smartRedirect } from "../../util";

const AnonymousUserControlWrapper: React.FC = props => {
  const user = React.useContext(UserCtx);
  const profile = React.useContext(ProfileCtx);

  useMaps();

  React.useEffect(handleRedirectIfPayedAccount, [profile]);

  return user ? <>{props.children}</> : <FullScreenLoadingPage in={true} />;

  function handleRedirectIfPayedAccount(): void {
    if (profile) {
      smartRedirect("v2", "onboard");
    }
  }
};

export { AnonymousUserControlWrapper };
