import LogRocket from "logrocket";

type identifyLogRocketUserProps = {
  userId?: string | null;
  email?: string | null;
};

const identifyLogRocketUser = ({
  userId,
  email,
}: identifyLogRocketUserProps) => {
  if (userId && email) {
    LogRocket.identify(userId, {
      email: email,
    });
  } else if (userId) {
    LogRocket.identify(userId);
  }
};

export { identifyLogRocketUser };
