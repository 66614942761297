import { ConsultCallInputs } from "../../../util/constants/consultCall";
import firebase from "firebase/compat/app";
import { IAddressObject } from "../../../util/functions/parseGeocodeLocationToAddress";
import { ContextErrorValue } from "../stepData/types";
import { updateProjectDoc } from "../../../util/firebase/updateProjectDoc";
import {
  DesignProfile,
  UpdateDesignProfileDto,
  UpdateProfileDto,
} from "../../../api/apiTypes";
import { Profile } from "@yardzen-inc/models";
import { SegmentClient } from "../../../util/Segment";

export type confirmInfoModalHandleSubmitProps = {
  user: firebase.User | null;
  requirementsComplete: boolean;
  referralSource: string;
  information: IAddressObject;
  firstName: string;
  lastName: string;
  phone: string;
  consultCallInput: ConsultCallInputs | "";
  projectId: string | null;
  hasUserAcceptedSMSCommunications: boolean;
  setInformationVerified: () => void;
  setError: (value: React.SetStateAction<string | null>) => void;
  setOnboardContextError: (err: ContextErrorValue) => void;
  designProfile: DesignProfile | undefined;
  profile: Profile;
  updateDesignProfile: (
    updatedDesignProfile: UpdateDesignProfileDto
  ) => Promise<boolean | undefined>;
  handleUpdateProfile: (
    pathParams: string,
    updateData: UpdateProfileDto
  ) => void;
  segment: SegmentClient;
  email: string;
  password: string;
  completeAccountCreation: boolean;
};

function getCredential(email: string, password: string) {
  return firebase.auth.EmailAuthProvider.credential(email, password);
}

async function linkAccounts(email: string, password: string) {
  const credential = getCredential(email, password);
  return (await firebase.auth().currentUser?.linkWithCredential(credential))
    ?.user?.uid;
}

export const confirmInfoModalHandleSubmit = async ({
  user,
  requirementsComplete,
  referralSource,
  information,
  firstName,
  lastName,
  phone,
  consultCallInput,
  projectId,
  hasUserAcceptedSMSCommunications,
  setInformationVerified,
  setError,
  setOnboardContextError,
  designProfile,
  profile,
  updateDesignProfile,
  handleUpdateProfile,
  segment,
  email,
  password,
  completeAccountCreation,
}: confirmInfoModalHandleSubmitProps): Promise<void> => {
  if (!!user) {
    if (requirementsComplete) {
      try {
        if (completeAccountCreation) await linkAccounts(email, password);

        await updateDesignProfile({
          ...designProfile,
          contactInformation: {
            ...designProfile?.contactInformation,
            email: completeAccountCreation
              ? email
              : designProfile?.contactInformation?.email ?? "",
            phoneNumber: phone.length
              ? phone
              : designProfile?.contactInformation?.phoneNumber,
          },
          referralSource,
        });

        const profileUpdate: Partial<Profile> = profile;
        if (profileUpdate) {
          delete profileUpdate.id;
          delete profileUpdate.createdAt;
          delete profileUpdate.answers;
        }
        const updateProfileData: UpdateProfileDto = {
          ...(profileUpdate as any),
          latLng: {
            lat: information.latLng?.lat
              ? (information.latLng?.lat as number)
              : profileUpdate.latLng?.lat,
            lng: information.latLng?.lng
              ? (information.latLng?.lng as number)
              : profileUpdate.latLng?.lng,
          },
          state: information.state,
          city: information.city,
          zip: information.zip,
          street: information.street,
          firstName,
          lastName,
          phone: phone.length
            ? phone
            : designProfile?.contactInformation?.phoneNumber,
          consultCall: consultCallInput,
          acceptedSMSCommunication: !!hasUserAcceptedSMSCommunications,
        };

        segment.identify({
          email: completeAccountCreation
            ? email
            : designProfile?.contactInformation?.email ?? "",
          phone: phone.length
            ? phone
            : designProfile?.contactInformation?.phoneNumber,
          referral_source: referralSource,
        });

        handleUpdateProfile(user.uid, updateProfileData);

        projectId && (await updateProjectDoc(projectId, { referralSource }));

        setInformationVerified();
      } catch (error) {
        if (error.code === "auth/email-already-in-use") {
          setError("Email already in use");
        } else {
          setError(error.message);
        }
        console.error(error);
      }
    } else {
      setError("Please complete missing fields");
    }
  } else {
    setOnboardContextError(
      "No user found in user context when trying to fetch profile data"
    );
  }
};
