import { DesignProfileQuestion } from "../../../api/apiTypes";
import { BudgetOptions } from "../../../util/constants/budgetOptions";
import { BUDGET_PATH } from "../constants/paths";

/**
 * Get the budget response from the design profile survey.
 *
 * @returns The budget response or empty string
 */
export const getBudgetResponse = (
  responses: DesignProfileQuestion[]
): BudgetOptions => {
  const budgetQuizResponse = responses.find(group => group.path === BUDGET_PATH)
    ?.response;

  if (Array.isArray(budgetQuizResponse)) {
    return budgetQuizResponse[0];
  }

  return (budgetQuizResponse?.toString() as BudgetOptions) || "";
};
