import { Link, makeStyles, Theme } from "@material-ui/core";
import { DataLayer } from "@yardzen-inc/data-layer";
import { YZTypography } from "@yardzen-inc/react-common";
import React from "react";
import { useLocation } from "react-router";
import { useDataLayer } from "../../data";
import {
  getSegmentFlowName,
  SegmentClickTypes,
  useSegment,
} from "../../util/Segment";

interface NavLinkProps {
  title: string;
  href: string;
  trackingConfig?: Parameters<DataLayer["recordTrackingEvent"]>;
}

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  linkText: {
    color: "black",
    fontSize: "12.9px",
    fontWeight: 500,
    letterSpacing: "2px",
    cursor: "pointer",
    opacity: 1,
    textAlign: "center",
    transition: "opacity 150ms",
    padding: "1rem",
    [theme.breakpoints.up("lg")]: {
      "&:hover": {
        opacity: 0.8,
      },
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "50vw",
      borderBottom: "1px solid #eee",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
}));

export const NavLink = ({ title, href, trackingConfig }: NavLinkProps) => {
  const location = useLocation();
  const segment = useSegment();
  const dataLayer = useDataLayer();

  const { link, linkText } = useStyles();

  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      data-testid={`nav-link-${title}`}
      onClick={() => {
        if (trackingConfig) {
          dataLayer.recordTrackingEvent(...trackingConfig);
        }

        segment.trackClicked({
          flow_name: getSegmentFlowName(location.pathname),
          button_name: `Dashboard Nav ${title}`,
          click_type: SegmentClickTypes.NAV,
          button_content: title,
        });
      }}
      className={link}
    >
      <YZTypography variant="body1" className={linkText} type="mono">
        {title}
      </YZTypography>
    </Link>
  );
};
