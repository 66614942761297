export const FIND_YOUR_PACKAGE_PATH = "find-your-package";
export const CONTACT_INFO_PATH = "contact-info";
export const HOME_ADDRESS_PATH = "home-address";
export const LOCATION_LOADING_PATH = "location-loading";
export const LOCATION_FOUND_PATH = "location-found";
export const HELP_NEEDED_PATH = "help-needed";
export const YARD_SELECTION_PATH = "yard-selection";
export const YARD_SIZE_PATH = "yard-size";
export const WISH_LIST_PATH = "wish-list";
export const UNIQUE_YARD_PATH = "unique-yard";
export const TIME_FRAME_PATH = "time-frame";
export const BUDGET_PATH = "budget";
export const RECOMMENDED_PACKAGE_PATH = "recommended-package";
export const CREATE_ACCOUNT_PATH = "create-account";
export const PURCHASE_PATH = "purchase";
export const PAYMENT_CONFIRMED_PATH = "payment-confirmed";
export const TYPEFORM_PATH = "typeform";
