import { PromotionalCodeStatus } from "../../../services/promotionalCodes";
import { PromotionalCode } from "../../../api/apiTypes";

export const promoCodeIsForUpgrade = (promotionalCode: PromotionalCode) => {
  const promoCodeIsActive =
    promotionalCode?.status === PromotionalCodeStatus.USABLE;

  return (
    promoCodeIsActive && promotionalCode?.code.toUpperCase().includes("UPGRADE")
  );
};
