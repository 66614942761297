import * as React from "react";
import { useAppSelector } from "../../hooks";
import { useLotSizeDisplayText } from "../../util/useLotSizeDisplayText";
import { ProductName } from "../../types/Products";
import InvoiceItem from "./InvoiceItem";

const RenderInvoiceItem: React.FC = () => {
  const {
    selectedProduct,
    selectedSku,
    modifiedPrice,
    packageStyle,
  } = useAppSelector(state => state.products);

  const lotSize = useLotSizeDisplayText(selectedSku?.attributes.name ?? "");
  if (!selectedProduct || !selectedSku) {
    return null;
  }
  const productName = selectedProduct.name.toLowerCase();

  if (productName === "upgrade package") {
    return (
      <InvoiceItem
        title={selectedSku.attributes.name}
        subtitle={""}
        quantity={1}
        price={selectedSku.price}
        modifiedPrice={modifiedPrice}
      />
    );
  }

  if (productName === "additional revision") {
    return (
      <InvoiceItem
        title={selectedProduct.name}
        subtitle={selectedProduct.description}
        price={selectedSku.price}
        quantity={1}
        modifiedPrice={modifiedPrice}
        hideExpeditedUpsell
        hideHOAExtraRevisionUpsell
        hideTotal
      />
    );
  }

  if (productName === "gift card") {
    return (
      <InvoiceItem
        title={selectedProduct.name}
        subtitle={""}
        quantity={1}
        price={selectedSku.price}
        modifiedPrice={modifiedPrice}
      />
    );
  }

  return (
    <InvoiceItem
      title={selectedProduct.name}
      titleOverride={packageStyle}
      subtitle={lotSize}
      price={selectedSku.price}
      quantity={1}
      modifiedPrice={modifiedPrice}
      hideHOAExtraRevisionUpsell={
        selectedProduct.name === ProductName.UberPremium
      }
      hideExpeditedUpsell={selectedProduct.name === ProductName.UberPremium}
    />
  );
};

export default RenderInvoiceItem;
