import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const hasProfile = async (userId: string) => {
  try {
    const profile = await firebase
      .firestore()
      .collection("profiles")
      .doc(userId)
      .get();

    return profile.exists;
  } catch (error) {
    window.newrelic.noticeError(error);
    console.error("error fetching profile: ", error);
    return false;
  }
};

export default hasProfile;
