export const useDebug = () => {
  const debugParam = new URLSearchParams(window.location.search).get("debug");

  if (debugParam === "true") {
    localStorage.setItem("debug", "true");
  }
  if (debugParam === "false") {
    localStorage.setItem("debug", "false");
  }

  return localStorage.getItem("debug") === "true";
};
