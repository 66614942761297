import React, { FC } from "react";
import { Button, Theme } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

type DesignProfileOutlineButtonProps = {
  text: string | null;
  onClick: (() => void) | undefined;
  fullWidth?: boolean;
};

const OutlineButton = withStyles((theme: Theme) => ({
  root: {
    padding: "11px 55px",
    fontWeight: "bold",
  },
}))(Button);

/**
 * Design Profile Outline Button component.
 * @param props The DesignProfileOutlineButtonProps to pass to the component
 * @returns The button component.
 */
const DesignProfileOutlineButton: FC<DesignProfileOutlineButtonProps> = (
  props: DesignProfileOutlineButtonProps
) => {
  const { text, onClick, fullWidth } = props;

  return (
    <OutlineButton fullWidth={fullWidth} variant="outlined" onClick={onClick}>
      {text}
    </OutlineButton>
  );
};

export { DesignProfileOutlineButton };
