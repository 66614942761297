import { DesignProfile } from "../../../api/apiTypes";
import { DesignProfileVersionId } from "../../../types/DesignProfile";
import { getFirstPageOfFlow } from "./getFirstPageOfFlow";

interface CheckForAddressArgs {
  designProfile: DesignProfile | undefined;
  history: {
    push: (path: string) => void;
  };
  isDesignProfileFetching: boolean;
  onlyZip?: boolean;
}

export const checkForAddress = ({
  designProfile,
  history,
  isDesignProfileFetching,
  onlyZip = false,
}: CheckForAddressArgs) => {
  let hasAddress;
  if (isDesignProfileFetching) {
    return;
  }
  if (onlyZip) {
    hasAddress = designProfile?.contactInformation?.zip;
  } else {
    hasAddress =
      designProfile?.contactInformation?.street &&
      designProfile?.contactInformation?.city &&
      designProfile?.contactInformation?.state &&
      designProfile?.contactInformation?.zip;
  }

  if (!hasAddress && !designProfile?.contactInformation?.email) {
    history.push(
      getFirstPageOfFlow(
        (designProfile?.versionId ?? "") as DesignProfileVersionId
      )
    );
  }

  if (!hasAddress && designProfile?.contactInformation?.email) {
    history.push("/design-profile/home-address");
  }
};
