import * as React from "react";
import {
  TextField,
  Theme,
  Box,
  Dialog,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Media } from "@yardzen-inc/models";
import { YZButton } from "../buttons";
import { usePageDimensions } from "../../util";
import HEICPlaceHolder from "./HEICPlaceHolder";
import { YZTypography } from "@yardzen-inc/react-common";

interface CommentModalProps {
  open: boolean;
  onClose: () => void;
  media: Media;
  description: string;
  updateDescription: (description: string) => void;
  action: "keep" | "remove";
  buttonDisabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      overflow: "auto",
    },
  },
  img: {
    width: "670px",
    height: "34vh",
    backgroundColor: "rgba(0,0,0,.75)",
    display: "inline-block",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      minHeight: "30vh",
    },
  },
  promptRoot: {
    padding: theme.spacing(3),
    textAlign: "center",
  },
  textField: {
    backgroundColor: "grey",
  },
  textFieldRoot: {
    height: "40%",
    width: "90%",
    margin: "auto",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      marginTop: theme.spacing(3),
    },
  },
  textFieldInput: {
    height: "100%",
    width: "98%",
    alignSelf: "center",
    margin: "auto",
    backgroundColor: "honeydew",
  },
  button: {
    alignSelf: "flex-end",
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const CommentModal: React.FunctionComponent<CommentModalProps> = ({
  open,
  onClose,
  media,
  description,
  updateDescription,
  action,
  buttonDisabled,
}) => {
  const classes = useStyles();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const h = usePageDimensions()[1];

  return (
    <Dialog
      fullScreen={!!smDown}
      style={{ height: `calc(${h} - 68%)` }}
      onClose={onClose}
      maxWidth="md"
      open={open}
    >
      <div className={classes.root}>
        <Box
          style={{
            backgroundImage: `url(${media.downloadURL})`,
          }}
          className={classes.img}
        >
          {media.fileType.includes("hei") && <HEICPlaceHolder />}
        </Box>
        <div className={classes.promptRoot}>
          <Box mb={1}>
            <YZTypography variant="h5" type="serif">
              What do you want to {action}?
            </YZTypography>
          </Box>
          <YZTypography color="textSecondary" variant="body2">
            Describe what items you want to stay in your design. <br /> A few
            words to describe the item should do the trick!
          </YZTypography>

          <TextField
            helperText={`${description.length}/100`}
            variant="outlined"
            className={classes.textFieldRoot}
            InputProps={{
              className: classes.textFieldInput,
            }}
            placeholder="Example: Keep the fruit tree"
            multiline
            rows={6}
            rowsMax={6}
            value={description}
            inputProps={{
              maxLength: 100,
            }}
            onChange={handleChange}
          />
          <YZButton
            onClick={() => {
              onClose();
            }}
            className={classes.button}
            disabled={buttonDisabled}
          >
            Save
          </YZButton>
        </div>
      </div>
    </Dialog>
  );

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    updateDescription(event.currentTarget.value);
  }
};

export default CommentModal;
