import React from "react";
import { Box } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";

interface SectionTitleProps {
  title: string;
  subtitle?: string;
  hasBack?: boolean;
  pre?: string;
  className?: string;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  subtitle,
  hasBack,
  pre,
  className,
}) => {
  return (
    <Box
      className={className}
      display="flex"
      alignItems="center"
      flexDirection="column"
      pt={hasBack ? 6 : 4}
      pb={4}
      textAlign="center"
    >
      {pre && (
        <YZTypography
          color="textSecondary"
          variant="body2"
          style={{
            fontWeight: 400,
            textTransform: "uppercase",
            letterSpacing: "1px",
          }}
        >
          {pre}
        </YZTypography>
      )}
      <YZTypography variant="h4" type="serif">
        {title}
      </YZTypography>
      {subtitle && (
        <Box mt={2} style={{ maxWidth: 600 }}>
          <YZTypography
            variant="body1"
            style={{ fontWeight: 400, opacity: 0.8 }}
          >
            {subtitle}
          </YZTypography>
        </Box>
      )}
    </Box>
  );
};

export default SectionTitle;
