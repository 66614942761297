import { useState } from "react";

import { banyanAPI } from "../../api/banyanV1API";
import {
  Checkbox,
  FormControlLabel,
  Theme,
  makeStyles,
} from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import { YZButton } from "../buttons";

interface AcceptTermsProps {
  userId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  linkText: {
    cursor: "pointer",
    color: theme.palette.primary.dark,
    textDecoration: "underline",
    fontWeight: 500,
  },
  button: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

export function AcceptTermsForm(props: AcceptTermsProps) {
  const classes = useStyles();
  const [tosChecked, setTosChecked] = useState(false);

  const [
    createTermsOfService,
  ] = banyanAPI.useCreateUserTermsOfServiceMutation();

  const handleSubmission = async () => {
    try {
      await createTermsOfService({
        userId: props.userId,
        tosAgreed: tosChecked,
        dateOfAgree: new Date().toISOString(),
      });
      window.location.reload();
    } catch (error) {
      console.error("Error saving terms of service agreement", error);
    }
  };

  const termsText = (
    <YZTypography variant="body2">
      I agree to Yardzen's{" "}
      <a
        href="https://yardzen.com/electronic-signature-disclosures"
        rel="noreferrer"
        target="_blank"
        className={classes.linkText}
      >
        Consent to Electronic Signature and Disclosures,
      </a>{" "}
      <a
        href="https://yardzen.com/terms-and-conditions"
        rel="noreferrer"
        target="_blank"
        className={classes.linkText}
      >
        Terms of Service
      </a>{" "}
      and{" "}
      <a
        href="https://yardzen.com/privacy-policy"
        rel="noreferrer"
        target="_blank"
        className={classes.linkText}
      >
        Privacy Policy
      </a>
      .
    </YZTypography>
  );

  return (
    <div className={classes.checkboxContainer}>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={tosChecked}
            onChange={e => setTosChecked(e.target.checked)}
            id="terms-checkbox"
            style={{ padding: "3px 9px" }}
          />
        }
        label={termsText}
      />
      <div className={classes.button}>
        <YZButton disabled={!tosChecked} onClick={handleSubmission}>
          Continue
        </YZButton>
      </div>
    </div>
  );
}
