import { Project } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// create an project document
// this function MUST be wrapped in a try/catch when implemented

async function createProjectDoc(profileId: string): Promise<Project> {
  try {
    /*
      Running into a known firebase issue with .add:
      Occasionally with get "FirebaseError: Document already exists" even though
      the document is successfully added. Workaround for the time being is to
      use .doc().set():

      Github issue: https://github.com/firebase/firebase-js-sdk/issues/5549

      If we change back to .add at some point, createdAt should be change to
      firebase.firestore.FieldValue.serverTimestamp() which instructs firebase
      to use the current server time as a value during a write operation.

    */
    const createdAt = firebase.firestore.Timestamp.now();
    await firebase
      .firestore()
      .collection("projects")
      .doc()
      .set({
        profileId,
        experienceVersion: "2.1",
        createdAt,
      });

    const querySnapshot = await firebase
      .firestore()
      .collection("projects")
      .where("profileId", "==", profileId)
      .where("createdAt", "==", createdAt)
      .get();

    const snap = querySnapshot.docs[0];

    return { ...snap.data(), id: snap.id, profileId } as Project;
  } catch (err) {
    window.newrelic.noticeError(err);
    throw new Error(
      `Error creating project document for profile with id of ${profileId} in Onboard Context`
    );
  }
}

/*
      Sorts first by experience version, then date if none found on Project.
      Favors higher expVer & more recent projects
*/
async function hasProjectDoc(profileId: string): Promise<null | Project> {
  const docs = (
    await firebase
      .firestore()
      .collection("projects")
      .where("profileId", "==", profileId)
      .get()
  ).docs;

  if (!docs.length) {
    return null;
  }

  const sortedByExpVer = docs.reduce((acc, curr) => {
    const currProject = curr.data();
    const accProject = acc.data();
    if (
      Number(currProject.experienceVersion) ===
      Number(accProject.experienceVersion)
    ) {
      return currProject.createdAt > accProject.createdAt ? curr : acc;
    }

    return Number(currProject.experienceVersion) >
      Number(accProject.experienceVersion)
      ? curr
      : acc;
  }, docs[0]);

  return Project.hydrate([sortedByExpVer])[0];
}

async function getProjectDoc(profileId: string): Promise<Project | undefined> {
  try {
    const doc = await hasProjectDoc(profileId);

    return doc || undefined;
  } catch (error) {
    window.newrelic.noticeError(error);
    console.error("Error fetching project doc: ", error);

    throw new Error(
      `Error fetching project document for user with id of ${profileId} in Onboard Context: ${error.message}`
    );
  }
}

export { getProjectDoc, createProjectDoc };
