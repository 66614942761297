import { IAddressObject } from "../../../util/functions/parseGeocodeLocationToAddress";
import { PriceObject } from "../../../types/Prices";
import {
  hasAddOnExpedited,
  hasAddOnHOAExtraRevision,
} from "../../../util/functions/hasAddOn";

interface ContructOrderArgs {
  addOnSkus: PriceObject[];
  skuId: string;
  propertyAddress: IAddressObject;
  userId: string | undefined;
  email: string;
  inputReferralCode: string;
  isPremium: boolean;
  isAdditionalRevision: boolean;
  firstName: string;
  lastName: string;
  promotionalCode?: string;
}

export const constructOrder = ({
  addOnSkus,
  skuId,
  propertyAddress,
  userId,
  email,
  inputReferralCode,
  isPremium,
  isAdditionalRevision,
  firstName,
  lastName,
  promotionalCode,
}: ContructOrderArgs) => {
  /*
        constructs a yardzen order object based on local state and/or
        a stripe response object
      */

  const addOns = addOnSkus.length ? addOnSkus.map(sku => sku.id).join(",") : "";

  const isExpedited = addOnSkus.filter(addOn => hasAddOnExpedited({ addOn }))
    .length
    ? "true"
    : "";

  const isHOA = addOnSkus.filter(addOn => hasAddOnHOAExtraRevision({ addOn }))
    .length
    ? "true"
    : "";

  return {
    sku: skuId,
    firstName,
    lastName,
    email,
    address: propertyAddress,
    userId,
    addOns,
    isExpedited,
    isHOA,
    isAdditionalRevision: isAdditionalRevision ? "true" : "false",
    referralCode: promotionalCode || inputReferralCode, // note that currently promotional codes take precedence over referral codes
    giftCardCode: "",
    isPremium: isPremium ? "true" : "",
    shipping: propertyAddress,
  };
};
