import { Box, Button, Divider, Link, Typography } from "@material-ui/core";
import { YZButton } from "@yardzen-inc/react-common";
import * as React from "react";
import { SquareModalBase } from "../../modals";
import capitalizeFirstLetter from "../../utility/capitalizeFirstLetter";
import { YardName } from "../stepData";

interface LockedYardSwapModalProps {
  open: boolean;
  onClose: () => void;
  onSwap: () => void;
  yard: YardName;
}

const LockedYardSwapModal: React.FunctionComponent<LockedYardSwapModalProps> = ({
  open,
  yard,
  onClose,
  onSwap,
}) => {
  const yardToSwap = capitalizeFirstLetter(yard || "");

  return (
    <>
      <SquareModalBase open={open} closeable onClose={onClose}>
        <Box m={2}>
          <Typography variant="h5">
            Do you want to design for your {yardToSwap} Yard instead?
          </Typography>
          <Divider />
          <Box mt={2}>
            <Typography style={{ marginTop: "0.5rem" }}>
              For your Front or Backyard Design Package, we'll create a design
              for your front yard or backyard. If you'd like a design for both
              yards, please email us to upgrade to a Full Yard Design Package at{" "}
              <Link
                style={{ color: "blue", textDecoration: "underline" }}
                href="mailto:design@yardzen.com"
              >
                design@yardzen.com
              </Link>
            </Typography>

            <Box mt={5} width="100%" display="flex" justifyContent="flex-end">
              <Button style={{ marginRight: "2.25rem" }} onClick={onClose}>
                Cancel
              </Button>
              <YZButton onClick={onSwap}>Switch to {yardToSwap} Yard</YZButton>
            </Box>
          </Box>
        </Box>
      </SquareModalBase>
    </>
  );
};

export default LockedYardSwapModal;
