import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import LogRocket from "logrocket";

const updateMediaDescription = async (mediaId: string, description: string) => {
  try {
    return firebase
      .firestore()
      .collection("media")
      .doc(mediaId)
      .update({
        description,
      });
  } catch (error) {
    window.newrelic.noticeError(error);
    const msg = `Error updating media: ${JSON.stringify(error)}`;
    console.error(msg);
    LogRocket.captureMessage(msg);
    throw error;
  }
};

export default updateMediaDescription;
