import {
  BACK_YARD_PACKAGE,
  CURB_APPEAL_PACKAGE,
  FRONT_YARD_PACKAGE,
} from "../../../util/constants/packageTypes";

export const getYardOptions = (packagePurchased: string | null | undefined) => {
  const front = {
      value: "front_yard",
      statusName: "FRONT",
      label: "Front Yard",
    },
    back = {
      value: "back_yard",
      statusName: "BACK",
      label: "Back Yard",
    },
    left = {
      value: "left_yard",
      statusName: "LEFT",
      label: "Left Yard",
    },
    right = {
      value: "right_yard",
      statusName: "RIGHT",
      label: "Right Yard",
    };
  switch (packagePurchased) {
    case BACK_YARD_PACKAGE:
      return [back, left, right];
    case FRONT_YARD_PACKAGE:
    case CURB_APPEAL_PACKAGE:
      return [front, left, right];
    default:
      return [front, back, left, right];
  }
};
