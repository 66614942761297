import { useEffect, useRef } from "react";
import { PriceObject } from "../../types/Prices";
import { useTikTokPixel } from "./useTikTokPixel";

interface UseTikTokTrackPaymentCompleteArgs {
  isPremium: boolean;
  sku: Partial<PriceObject>;
  premiumSku: Partial<PriceObject>;
  expeditedSku: Partial<PriceObject> | undefined;
}

/**
 * Runs the tiktok payment complete tracker.
 * @param args - The properties for tracking a completed payment.
 */
export const useTikTokTrackPaymentComplete = ({
  isPremium,
  sku,
  premiumSku,
  expeditedSku,
}: UseTikTokTrackPaymentCompleteArgs) => {
  const { trackPaymentCompleteEvent } = useTikTokPixel();
  // We'll use this to make sure the event only fires once, so that
  // we can avoid repeat tracking calls.
  const didEventAlreadyFire = useRef(false);

  useEffect(() => {
    const getProductData = ({
      isPremium,
      sku,
      premiumSku,
      expeditedSku,
    }: UseTikTokTrackPaymentCompleteArgs) => {
      if (isPremium && expeditedSku) {
        return {
          contents: [
            { id: sku.product ?? "", quantity: 1 },
            { id: premiumSku.product ?? "", quantity: 1 },
            { id: expeditedSku.product ?? "", quantity: 1 },
          ],
          content_category:
            "Landscape design with premium and expedited upgrade",
          content_name: `Premium Expedited ${sku.metadata?.product}`,
          description: `${sku.metadata?.product} ${sku.metadata?.type} with Premium Expedited`,
          value:
            ((sku.price ?? 0) +
              (premiumSku.price ?? 0) +
              (expeditedSku.price ?? 0)) /
            100,
          price: (sku.price ?? 0) / 100,
          quantity: 3,
        };
      }

      if (isPremium) {
        return {
          contents: [
            { id: sku.product ?? "", quantity: 1 },
            { id: premiumSku.product ?? "", quantity: 1 },
          ],
          content_category: "Landscape design with premium upgrade",
          content_name: `Premium ${sku.metadata?.product}`,
          description: `${sku.metadata?.product} ${sku.metadata?.type} Premium`,
          value: ((sku.price ?? 0) + (premiumSku.price ?? 0)) / 100,
          price: (sku.price ?? 0) / 100,
          quantity: 2,
        };
      }

      if (expeditedSku) {
        return {
          contents: [
            { id: sku.product ?? "", quantity: 1 },
            { id: expeditedSku.product ?? "", quantity: 1 },
          ],
          content_category: "Landscape design with expedited upgrade",
          content_name: `Expedited ${sku.metadata?.product}`,
          description: `${sku.metadata?.product} ${sku.metadata?.type} Expedited`,
          value: ((sku.price ?? 0) + (expeditedSku.price ?? 0)) / 100,
          price: (sku.price ?? 0) / 100,
          quantity: 2,
        };
      }

      return {
        content_id: sku.product,
        content_category: "package",
        content_name: sku.metadata?.product,
        description: `${sku.metadata?.product} ${sku.metadata?.type}`,
        value: (sku.price ?? 0) / 100,
        quantity: 1,
      };
    };

    const productData = getProductData({
      isPremium,
      sku,
      premiumSku,
      expeditedSku,
    });

    if (!didEventAlreadyFire.current) {
      trackPaymentCompleteEvent({
        ...productData,
        content_type: "product",
        currency: "USD",
      });

      didEventAlreadyFire.current = true;
    }
  }, [trackPaymentCompleteEvent, isPremium, premiumSku, sku, expeditedSku]);
};
