import React, { FC } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { YZButton } from "..";
import { ArrowForward } from "@material-ui/icons";
import { useHeaderHeight } from "../../util";
import {
  SegmentClickTypes,
  SegmentFlows,
  SegmentSubFlows,
  useSegment,
} from "../../util/Segment";

export interface NavButtonsProps {
  next?: () => void;
  previous?: () => void;
  nextDisabled?: boolean;
  previousDisabled?: boolean;
  nextButtonText?: string;
  previousButtonText?: string;
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  button: {
    background: "none",
    color: "#4C4C4C",
    "& span": {
      letterSpacing: "1px",
      fontSize: 12,
    },
    "&:hover": {
      background: "none!important",
    },
  },
  buttonContainer: {
    background: "linear-gradient(#FAFAFA, rgba(255, 255, 255, 0))",
    zIndex: 99,
    width: "100%",
    position: "fixed",
    top: 0,
    padding: spacing(2),
    marginLeft: "-8px",
  },
}));

const NavButtons: FC<NavButtonsProps> = props => {
  const segment = useSegment();
  const headerHeight = useHeaderHeight();
  const classes = useStyles(headerHeight);
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      {props.previous ? (
        <Box
          style={{ top: headerHeight + 5 }}
          className={classes.buttonContainer}
        >
          <YZButton
            className={classes.button}
            onClick={e => {
              if (props.previous) {
                props.previous();
              }
              segment.trackClicked({
                button_name: "My Yard Go Back",
                click_type: SegmentClickTypes.NAV,
                flow_name: SegmentFlows.ONBOARD,
                sub_flow_name: SegmentSubFlows.MY_YARD,
                button_content: "← SAVE AND GO BACK",
              });
            }}
          >
            {props.nextButtonText ? (
              props.nextButtonText
            ) : (
              <>{`← SAVE AND GO BACK`}</>
            )}
          </YZButton>
        </Box>
      ) : (
        <></>
      )}
      {props.next ? (
        <YZButton
          variant="outlined"
          // className={classes.button}
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            margin: "3rem",
            color: "black",
          }}
          onClick={props.next}
          disabled={props.nextDisabled}
        >
          {props.nextButtonText ? (
            props.nextButtonText
          ) : (
            <>
              next <ArrowForward />
            </>
          )}
        </YZButton>
      ) : (
        <div style={{ display: "inline-block" }} />
      )}
    </Box>
  );
};

export { NavButtons };
export default NavButtons;
