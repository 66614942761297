import React, { forwardRef } from "react";
import { Box, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import ChecklistSidebarContent from "./ChecklistSidebarContent";
import { useHeaderHeight } from "../../../util";

export interface ChecklistSidebarProps {
  onOpenClose: () => void;
}

export interface ChecklistSidebarStyleProps {
  headerHeight: number;
}

const useStyles = makeStyles<Theme, ChecklistSidebarStyleProps>(
  (theme: Theme) => ({
    root: props => ({
      display: "inline-block",
      width: "100%",
      height: "100%",
      zIndex: 5,
      [theme.breakpoints.up("lg")]: {
        minWidth: "300px",
      },
      [theme.breakpoints.up("xl")]: {
        minWidth: "400px",
      },
    }),
  })
);

const ChecklistSidebar = forwardRef<HTMLDivElement, ChecklistSidebarProps>(
  (props: any, ref) => {
    const mdDown = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down("md")
    );
    const headerHeight = useHeaderHeight();
    const classes = useStyles({ headerHeight });

    return (
      <div
        ref={ref}
        style={{
          width: mdDown ? "100%" : "min-content",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FBF7F2",
          flexShrink: 1,
        }}
      >
        <Box className={classes.root}>
          <ChecklistSidebarContent onOpenClose={props.onOpenClose} />
        </Box>
      </div>
    );
  }
);

export { ChecklistSidebar };
export default ChecklistSidebar;
