import { Box, makeStyles } from "@material-ui/core";
import { YZTypography, formatNumToUSD } from "@yardzen-inc/react-common";
import * as React from "react";
import { Link } from "react-router-dom";
import {
  useSegment,
  SegmentFlows,
  SegmentClickTypes,
  SegmentSubFlows,
} from "../../../util/Segment";

interface ISofiFinancingProps {
  estimate?: [number, number] | null;
}

const useStyles = makeStyles(({ palette }) => ({
  monthlyPaymentText: {
    marginBottom: 10,
  },
  box: {
    backgroundColor: palette.grey[300],
  },
  linkText: {
    color: palette.text.primary,
    textDecoration: "underline",
  },
}));

const SofiFinancing: React.FunctionComponent<ISofiFinancingProps> = ({
  estimate,
}) => {
  const { monthlyPaymentText, linkText, box } = useStyles();
  const segment = useSegment();

  return (
    <Box className={box} p={1}>
      <YZTypography
        variant="h6"
        color="textSecondary"
        className={monthlyPaymentText}
      >
        {formatNumToUSD(
          calculateInterestPayment(estimate?.[0] ?? 0, 0.0843, 60)
        )}
        -{" "}
        {formatNumToUSD(
          calculateInterestPayment(estimate?.[1] ?? 0, 0.1963, 60)
        )}
        /month
      </YZTypography>

      <YZTypography variant="body1">
        See your pre-qualified rate with{" "}
        <Link
          className={linkText}
          to={{ pathname: "https://www.sofi.com/refer/yardzen" }}
          target="_blank"
          onClick={handlePrequalifyLinkClicked}
        >
          Sofi
        </Link>
      </YZTypography>
    </Box>
  );

  function calculateInterestPayment(
    totalAmount: number,
    yearlyInterestRate: number,
    months: number
  ): number {
    return Math.floor(
      totalAmount *
        (((yearlyInterestRate / 12) *
          Math.pow(1 + yearlyInterestRate / 12, months)) /
          (Math.pow(1 + yearlyInterestRate / 12, months) - 1))
    );
  }

  function handlePrequalifyLinkClicked(): void {
    segment.trackClicked({
      flow_name: SegmentFlows.ONBOARD,
      button_name: "SoFi link",
      click_type: SegmentClickTypes.BUTTON,
      sub_flow_name: SegmentSubFlows.WISH_LIST,
    });
  }
};

export { SofiFinancing };
