/*
  orderPrice: Final price after backend request (getPaymentIntent)
  originalOrderPrice: Original price after backend request (getPaymentIntent)
  modifiedPrice: Price after discounts applied (calculated on front end)
 */

export function chooseDiscountDisplayPrice(
  orderPrice: number | null,
  originalOrderPrice: number | null,
  modifiedPrice: number | null
) {
  if (orderPrice !== null && orderPrice === originalOrderPrice) {
    return null;
  }

  return orderPrice ?? modifiedPrice;
}
