import "firebase/compat/firestore";
import "firebase/compat/functions";
import firebase from "firebase/compat/app";

type CartView = {
  packageName: string;
  lotSize?: string;
  sku: string;
  modifiedPrice?: number;
  promotionalCode?: {
    code: string;
    discountType: string;
    discount: string;
  };
  addOns?: {
    sku: string;
    name: string;
    price: number;
  }[];
};

export async function updateCartViewForUserDesignProfile(
  userId: string,
  cartView: CartView
) {
  const designProfileRef = await firebase
    .firestore()
    .collection("designProfiles")
    .where("userId", "==", userId)
    .orderBy("updatedAt", "desc")
    .limit(1)
    .get();

  if (!designProfileRef.empty) {
    const latestDoc = designProfileRef.docs[0];
    await latestDoc.ref.update({
      cartView,
    });
  } else {
    console.error("No design profile found for the user.");
  }
}
