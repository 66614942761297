import {
  Box,
  List,
  ListItem,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { TrackingEventCategories } from "@yardzen-inc/data-layer";
import React from "react";
import { NavLink } from "./NavLink";

const useStyles = makeStyles((theme: Theme) => ({
  linksList: {
    display: "inline-flex",
    alignItems: "center",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  linkListItem: {
    padding: 0,
    width: "auto",
  },
}));

export const NavLinkList = () => {
  const { linksList, linkListItem } = useStyles();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Box component="nav">
      <List aria-label="website navigation links" className={linksList}>
        {smDown && (
          <ListItem className={linkListItem}>
            <NavLink
              title="HOME"
              href={`${process.env.REACT_APP_WORDPRESS_BASE_URL}`}
              trackingConfig={[
                "dashboard_navbar_click_home",
                {
                  action: "Nav Bar Click - Home",
                  category: TrackingEventCategories.BUTTON_CLICK,
                  label: window.location.href,
                  value: null,
                },
              ]}
            />
          </ListItem>
        )}
        <ListItem className={linkListItem}>
          <NavLink
            title="PACKAGES"
            href={`${process.env.REACT_APP_WORDPRESS_BASE_URL}/packages`}
            trackingConfig={[
              "dashboard_navbar_click_packages",
              {
                action: "Nav Bar Click - Packages",
                category: TrackingEventCategories.BUTTON_CLICK,
                label: window.location.href,
                value: null,
              },
            ]}
          />
        </ListItem>
        <ListItem className={linkListItem}>
          <NavLink
            title="PORTFOLIO"
            href={`${process.env.REACT_APP_WORDPRESS_BASE_URL}/gallery`}
            trackingConfig={[
              "dashboard_navbar_click_portfolio",
              {
                action: "Nav Bar Click - Portfolio",
                category: TrackingEventCategories.BUTTON_CLICK,
                label: window.location.href,
                value: null,
              },
            ]}
          />
        </ListItem>
        <ListItem className={linkListItem}>
          <NavLink
            title="REVIEWS"
            href={`${process.env.REACT_APP_WORDPRESS_BASE_URL}/reviews`}
            trackingConfig={[
              "dashboard_navbar_click_reviews",
              {
                action: "Nav Bar Click - Reviews",
                category: TrackingEventCategories.BUTTON_CLICK,
                label: window.location.href,
                value: null,
              },
            ]}
          />
        </ListItem>
        <ListItem className={linkListItem}>
          <NavLink
            title="HOW IT WORKS"
            href={`${process.env.REACT_APP_WORDPRESS_BASE_URL}/how-it-works`}
            trackingConfig={[
              "dashboard_navbar_click_howitworks",
              {
                action: "Nav Bar Click - How It Works",
                category: TrackingEventCategories.BUTTON_CLICK,
                label: window.location.href,
                value: null,
              },
            ]}
          />
        </ListItem>
        <ListItem className={linkListItem}>
          <NavLink
            title="SHOP"
            href={`${process.env.REACT_APP_WORDPRESS_BASE_URL}/shop`}
            trackingConfig={[
              "dashboard_navbar_click_shop",
              {
                action: "Nav Bar Click - Shop",
                category: TrackingEventCategories.BUTTON_CLICK,
                label: window.location.href,
                value: null,
              },
            ]}
          />
        </ListItem>
      </List>
    </Box>
  );
};
