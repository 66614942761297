import firebase from "firebase/compat/app";
import "firebase/compat/auth";

interface CheckLoginStatusArgs {
  email: string;
  setErrorText: (errorText: string) => void;
}

export const checkLoginStatus = async ({
  setErrorText,
  email,
}: CheckLoginStatusArgs) => {
  try {
    const signInMethods = await firebase
      .auth()
      .fetchSignInMethodsForEmail(email);

    if (signInMethods.includes("password")) {
      return true;
    }
  } catch (err) {
    window.newrelic.noticeError(err);
    // TODO: check for network and other errors besides invalid email
    if (err.code === "auth/invalid-email") {
      setErrorText("Please enter a valid email address.");
    }

    throw err;
  }

  return false;
};
