import { Box, Grid, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useDataLayer } from "../../data";
import { useAppSelector } from "../../hooks";
import { setReferralCodesToStore } from "../../services/referralCodes";
import {
  resetCheckout,
  setInputReferralCode,
  setPromotionalCode,
} from "../../slices/checkoutSlice";
import {
  resetPayment,
  setClientSecret,
  setPaymentIntentId,
} from "../../slices/paymentSlice";
import { resetProductCheckoutItems } from "../../slices/productSlice";
import { UserCtx } from "../../util";
import {
  SegmentClickTypes,
  SegmentFlows,
  useSegment,
} from "../../util/Segment";
import { PackageTypes } from "../../util/constants/packageTypes";
import { getStripeKey } from "../../util/functions/getStripeKey";
import { IAddressObject } from "../../util/functions/parseGeocodeLocationToAddress";
import useSubscribeToAbandonedCartEmail from "../../util/hooks/useAbandonedCartEmail";
import { useApplyDiscountCode } from "../../util/hooks/useApplyDiscountCode";
import { usePremiumPackage } from "../../util/hooks/usePremiumPackage";
import { isStarterPackage } from "../../util/isStarterPackage";
import { useDesignProfileCtx } from "../designProfile/DesignProfileCtx";
import GenericSnackBar from "../utility/GenericSnackBar";
import { BillingDetails } from "./BillingDetails";
import { CheckoutFooter } from "./CheckoutFooter";
import { CheckoutFormProps } from "./CheckoutForm";
import CheckoutSidebar from "./CheckoutSidebar";
import GoBackButton from "./GoBack";
import { ReferralInput } from "./ReferralInput";
import RenderInvoiceItem from "./RenderInvoiceItem";
import { StripePaymentForm } from "./StripePaymentForm";
import { checkReferralCodes } from "./util/checkReferralCodes";
import { handleReferralCodeSubmit } from "./util/handleReferralCodeSubmit";
import { updateCartViewForUserDesignProfile } from "./util/updateCartViewForUserDesignProfile";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: "#fff",
    marginTop: "-24px",
  },
  formContainer: {
    background: "#fff",
    padding: "1.5rem 1rem",
    [theme.breakpoints.up("md")]: {
      padding: "1rem 2rem",
    },
  },
  formContent: {
    [theme.breakpoints.up("md")]: {
      maxWidth: 656,
    },
    marginLeft: "auto",
    marginRight: "auto",
  },
  formSidebar: {
    background: "#FAFAFA",
    padding: "0.5rem 0.5rem 6rem",
    [theme.breakpoints.up("md")]: {
      padding: "1rem 0 1rem 1.5rem",
    },
  },
  goBackContainer: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-start",
  },
  referralInputLabel: {
    margin: ".5rem auto",
  },
}));

export interface Order {
  sku: string | string[]; // Stripe SKU id
  userId?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  address: IAddressObject;
  giftCardCode: string;
  addOns: string;
  isExpedited: string;
  isHOA: string;
  referralCode: string;
  isPremium: string;
}

export const CheckoutForm = ({
  nextStep,
  noBackButton,
  propertyAddress,
  showSidebar,
}: CheckoutFormProps) => {
  const segment = useSegment();
  const dispatch = useDispatch();
  const dataLayer = useDataLayer();
  const classes = useStyles();
  const {
    root,
    formContainer,
    formContent,
    formSidebar,
    goBackContainer,
    referralInputLabel,
  } = classes;
  const { designProfile } = useDesignProfileCtx();
  const {
    selectedProduct,
    selectedSku,
    modifiedPrice,
    addOnSkus,
    products,
  } = useAppSelector(state => state.products);

  const isStarter = isStarterPackage(
    selectedProduct?.metadata?.package as PackageTypes
  );
  const isAdditionalRevision = selectedProduct?.name === "Additional Revision";
  useApplyDiscountCode();

  const segmentFlow = isAdditionalRevision
    ? SegmentFlows.PURCHASE_ADDITIONAL_REVISION
    : SegmentFlows.CHECKOUT;

  const [error, setError] = React.useState<false | string>(false);

  const {
    referralCodes: referralCodeCollection,
    inputReferralCode,
    promotionalCode,
  } = useAppSelector(state => state.checkout);
  const { clientSecret, paymentIntentId } = useAppSelector(
    state => state.payment
  );

  const [referralCodeError, setReferralCodeError] = useState<boolean>(false);
  const [invalidCodeMessage, setInvalidCodeMessage] = useState<string>("");
  const [orderEventError] = React.useState<boolean>(false);
  const [idToken, setIdToken] = React.useState<string>("");
  const { isPremium, premiumSku } = usePremiumPackage();
  const history = useHistory();

  const userContext = React.useContext(UserCtx);

  const stripe = loadStripe(getStripeKey());

  const handleGoBack = () => {
    dispatch(resetProductCheckoutItems());
    dispatch(resetCheckout());
    dispatch(resetPayment());
    history.goBack();
  };

  const {
    firstName,
    lastName,
    address: { city, state, street, zip, aptNumber },
    billingAndPropertyAddressSame,
  } = useAppSelector(state => state.checkout);

  const email =
    !!userContext && userContext.email
      ? userContext.email
      : designProfile?.contactInformation?.email || null;

  useSubscribeToAbandonedCartEmail(
    email,
    selectedSku?.id || "",
    firstName,
    lastName
  );

  React.useEffect(() => {
    const getIdToken = async () => {
      const token = await userContext?.getIdToken();

      token && setIdToken(token);
    };
    getIdToken();
  }, [userContext, setIdToken]);

  React.useEffect(() => {
    checkReferralCodes({ referralCodeCollection, setReferralCodesToStore });
  }, [referralCodeCollection]);

  const setPaymentId = (paymentIntentId: string) => {
    dispatch(setPaymentIntentId({ paymentIntentId }));
  };

  const setSecret = (clientSecret: string) => {
    dispatch(setClientSecret({ clientSecret }));
  };

  const handleReferralSubmitWithArgs = async (code: string) => {
    await handleReferralCodeSubmit({
      code,
      segment,
      segmentFlow,
      referralCodeCollection,
      setReferralCodeError,
      setInputReferralCode: code => {
        dispatch(setInputReferralCode({ inputReferralCode: code }));
      },
      setPromotionalCode: code => {
        dispatch(setPromotionalCode(code));
      },
      setInvalidCodeMessage,
      isStarterPackage: isStarter,
      products,
      selectedProduct,
    });
  };

  const billingAddress = {
    street,
    city,
    state,
    zip,
    aptNumber,
    formattedAddress: `${street} ${city} ${state} ${zip}`,
    country: "US",
  };

  if (userContext?.uid && selectedProduct?.name && selectedSku?.id) {
    updateCartViewForUserDesignProfile(userContext.uid, {
      packageName: selectedProduct.name,
      sku: selectedSku.id,
      ...(selectedSku.nickname && { lotSize: selectedSku.nickname }),
      ...(modifiedPrice && { modifiedPrice }),
      ...(promotionalCode?.status === "USABLE" && {
        discountCode: {
          ...(promotionalCode?.code && { code: promotionalCode?.code }),
          ...(promotionalCode?.discountType && {
            discountType: promotionalCode?.discountType,
          }),
          ...(promotionalCode?.discount && {
            discount: promotionalCode?.discount,
          }),
        },
      }),
      addOns:
        addOnSkus?.map(sku => ({
          sku: sku.id,
          name: sku.attributes.name,
          price: sku.price,
        })) ?? [],
    });
  }

  return (
    <Grid container className={root}>
      <Grid item md className={formContainer}>
        <Box className={formContent}>
          {!noBackButton && (
            <Box mb={1} className={goBackContainer}>
              <GoBackButton
                goBack={() => {
                  handleGoBack();
                  segment.trackClicked({
                    button_name: "Confirm Order Go Back",
                    flow_name: segmentFlow,
                    click_type: SegmentClickTypes.BUTTON,
                    button_content: "Go Back",
                  });
                }}
              />
            </Box>
          )}
          {selectedProduct && selectedSku && (
            // Package Name | Package Image | Package Price
            <RenderInvoiceItem />
          )}
          {!isAdditionalRevision && !paymentIntentId && (
            <>
              <Box className={referralInputLabel}>
                <Typography>
                  {selectedSku?.attributes.name.includes("Lowes")
                    ? "Enter your MyLowe's Rewards Yardzen code"
                    : "Enter referral or promo code (optional)"}
                </Typography>
              </Box>
              <ReferralInput
                handleReferralCodeSubmit={handleReferralSubmitWithArgs}
                referralCodeError={referralCodeError}
                customSuccessMessage={
                  promotionalCode?.applicationSuccessMessage ?? undefined
                }
                validReferralCodeEntered={
                  inputReferralCode?.length ? true : false
                }
                invalidCodeMessage={invalidCodeMessage}
              />
            </>
          )}
          {!paymentIntentId && (
            // Name and Address Inputs
            <BillingDetails
              error={error}
              propertyAddress={propertyAddress}
              setError={setError}
              setPaymentIntentId={setPaymentId}
              setClientSecret={setSecret}
              idToken={idToken}
              isAdditionalRevision={isAdditionalRevision}
            />
          )}
          {paymentIntentId && selectedProduct && selectedSku && (
            // Payment Input
            <Elements
              stripe={stripe}
              options={{
                clientSecret,
              }}
            >
              <StripePaymentForm
                isAdditionalRevision={isAdditionalRevision}
                dispatch={dispatch}
                setError={setError}
                email={email}
                billingAddress={
                  billingAndPropertyAddressSame
                    ? propertyAddress
                    : billingAddress
                }
                premiumSku={premiumSku}
                isPremium={isPremium}
                addOnSkus={addOnSkus}
                modifiedPrice={modifiedPrice}
                dataLayer={dataLayer}
                nextStep={nextStep}
              />
            </Elements>
          )}
          {orderEventError && (
            <Typography color="primary">
              We've received your payment. We can't wait to get started on your
              your your project! Watch out for an email from us with
              instructions instructions on next on next on next steps. Reach
              support@yardzen.com questions.
            </Typography>
          )}
          <GenericSnackBar
            variant="error"
            message={error ? error : undefined}
            onClose={() => setError(false)}
            in={!!error}
            maxWidth="480px"
          />
          <CheckoutFooter />
        </Box>
      </Grid>
      {showSidebar && (
        <Grid item md={5} lg={4} className={formSidebar}>
          <CheckoutSidebar />
        </Grid>
      )}
    </Grid>
  );
};
