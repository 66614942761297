import { useState, FC } from "react";
import { Box, Checkbox, Theme, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { YZTypography } from "@yardzen-inc/react-common";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addToAddOns, removeFromAddOns } from "../../slices/productSlice";
import { hasAddOnHOAExtraRevision } from "../../util/functions/hasAddOn";
import { ProductName } from "../../types/Products";
import { Info } from "@material-ui/icons";
import { isStarterPackage } from "../../util/isStarterPackage";
import { PackageTypes } from "../../util/constants/packageTypes";

export const HOAExtraRevisionUpsell: FC = () => {
  const useStyles = makeStyles((theme: Theme) => ({
    upsellWrapper: {
      width: "102%",
      backgroundColor: "#F8F7F6",
      borderRadius: 10,
      display: "flex",
      flexFlow: "row",
      alignItems: "flex-start",
      padding: 10,
    },
    priceWrapper: {
      width: "30%",
      justifyContent: "flex-end",
      alignItems: "center",
      display: "flex",
      height: 50,
      [theme.breakpoints.down("sm")]: {
        width: "15%",
      },
    },
    invisible: {
      visibility: "hidden",
    },
    upsellText: {
      width: "70%",
      paddingTop: 10,
      [theme.breakpoints.down("sm")]: {
        width: "85%",
      },
    },
    boldText: {
      fontWeight: "bold",
    },
    infoIcon: {
      fontSize: "1rem",
      verticalAlign: "middle",
    },
    price: {
      fontSize: "1.2rem",
    },
  }));
  const classes = useStyles();
  const { products, addOnSkus, selectedProduct } = useAppSelector(
    state => state.products
  );
  const { restrictChanges } = useAppSelector(state => state.checkout);
  const dispatch = useAppDispatch();
  const [hoaExtraRevisionIsSelected, setHoaExtraRevisionIsSelected] = useState(
    addOnSkus.findIndex(addOn => hasAddOnHOAExtraRevision({ addOn })) >= 0
  );

  const HOAProductName = isStarterPackage(
    selectedProduct?.metadata?.package as PackageTypes
  )
    ? ProductName.HOAExtraRevisionStarter
    : ProductName.HOAExtraRevision;
  const priceObject = products.find(product => product.name === HOAProductName);
  const hoaExtraRevisionUpsellPrice = (
    (priceObject?.skus.data[0].price || 29500) / 100
  ).toFixed(0);

  const handleChange = () => {
    if (!hoaExtraRevisionIsSelected) {
      priceObject &&
        dispatch(addToAddOns({ addOn: priceObject?.skus.data[0] }));
    } else {
      priceObject &&
        dispatch(removeFromAddOns({ addOn: priceObject?.skus.data[0] }));
    }
    setHoaExtraRevisionIsSelected(!hoaExtraRevisionIsSelected);
  };

  // if changes are restricted, only show component if the checkbox is checked
  const hideComponent = restrictChanges && !hoaExtraRevisionIsSelected;

  return (
    <Box
      className={`${classes.upsellWrapper} ${
        hideComponent ? classes.invisible : null
      }`}
    >
      <Checkbox
        id="hoa-extra-revision-checkbox"
        checked={hoaExtraRevisionIsSelected}
        onChange={handleChange}
        disabled={restrictChanges}
        inputProps={{
          "aria-label": "homeowners association extra revision checkbox",
        }}
      />
      <YZTypography
        color="textSecondary"
        variant="body2"
        className={classes.upsellText}
      >
        +${hoaExtraRevisionUpsellPrice}, submit
        <span className={classes.boldText}> HOA guidelines </span>for your
        designer and receive an
        <span className={classes.boldText}> extra round of revisions </span>
        <Tooltip
          title="Live in an HOA community? This add-on allows you to submit notes
          to your designer on your HOA guidelines. With this add-on, you are
          responsible for sharing applicable HOA Guidelines for your designer to implement.
          An extra revision is included to help ensure that you're able to incorporate
          any final feedback from your HOA."
          leaveTouchDelay={10000}
          enterTouchDelay={0}
        >
          <Info className={classes.infoIcon} data-testid="hoa-tooltip" />
        </Tooltip>
      </YZTypography>
      <Box className={classes.priceWrapper}>
        {hoaExtraRevisionIsSelected ? (
          <YZTypography className={classes.price}>
            ${hoaExtraRevisionUpsellPrice}
          </YZTypography>
        ) : null}
      </Box>
    </Box>
  );
};
