import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BudgetIntent } from "../pages/onboard/budget/WishListPrioritizePageBudgetIntent";

interface OnboardSlice {
  budgetIntent: BudgetIntent;
  budgetIntentErrorText: string;
  submittedInstallationPlans: boolean;
}

export const onboardInitialState: OnboardSlice = {
  budgetIntent: null,
  budgetIntentErrorText: "",
  submittedInstallationPlans: false,
};

export const onboard = createSlice({
  name: "onboard",
  initialState: onboardInitialState,
  reducers: {
    setBudgetIntent: (
      state,
      action: PayloadAction<{ budgetIntent: BudgetIntent }>
    ) => {
      state.budgetIntent = action.payload.budgetIntent;
    },
    setBudgetIntentErrorText: (
      state,
      action: PayloadAction<{ budgetIntentErrorText: string }>
    ) => {
      state.budgetIntentErrorText = action.payload.budgetIntentErrorText;
    },
    setSubmittedInstallationPlans: (
      state,
      action: PayloadAction<{ submittedInstallationPlans: boolean }>
    ) => {
      state.submittedInstallationPlans =
        action.payload.submittedInstallationPlans;
    },
    resetOnboard: () => onboardInitialState,
  },
});

export const {
  setBudgetIntent,
  setBudgetIntentErrorText,
  setSubmittedInstallationPlans,
  resetOnboard,
} = onboard.actions;

export default onboard.reducer;
