import React, { FC, useState } from "react";
import { updateProfileDoc, useProfile, useUser } from "../../../util";
import { Box, makeStyles, Snackbar, Theme } from "@material-ui/core";
import { GenericLoadingSpinner } from "@yardzen-inc/react-common";
import ExteriorDesignPaintYesNo from "./ExteriorDesignPaintYesNo";
import { ProfileProperties } from "@yardzen-inc/models";
import GoBackButton from "../../checkout/GoBack";
import ExteriorDesignPaintPicker from "./ExteriorDesignPaintPicker";
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  Redirect,
} from "react-router";
import { useEffect } from "react";
import BottomCenterContentContainer from "../../onboardLayout/BottomCenterContentContainer";
import SaveBar from "../helpers/SaveBar";

export interface ExteriorDesignPaintProps {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      minHeight: "calc(100vh - 40px)",
    },
  },
  container: {
    width: "100%",
    maxWidth: "1220px",
  },
  navButtonsContainer: {
    height: "60px",
    width: "90%",
    maxWidth: "1080px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  nextBtn: {
    "&:hover": {
      background: "none",
    },
  },
}));

const ExteriorDesignPaint: FC<ExteriorDesignPaintProps> = props => {
  const classes = useStyles();
  const profile = useProfile(useUser()[1])[1];
  const history = useHistory();
  const match = useRouteMatch();

  const [loadTimeoutExpired, setLoadTimeoutExpired] = useState<boolean>(false);

  useEffect(handleProfileLoadTimeout, [profile]);

  if (!profile) {
    return (
      <>
        <Box className={classes.root}>
          <GenericLoadingSpinner />
        </Box>

        <Snackbar
          open={loadTimeoutExpired}
          color="error"
          onClose={() => setLoadTimeoutExpired(false)}
          message="It's taking longer to load your data than expected. Please try reloading the page."
        ></Snackbar>
      </>
    );
  }

  return (
    <>
      <div className={classes.root}>
        <Box className={classes.navButtonsContainer}>
          <GoBackButton goBack={handleGoBack} label="back" />
        </Box>

        <Switch>
          <Route path={`${match.url}/picker`} exact>
            <div className={classes.container}>
              <Box display="flex" justifyContent="center" pb={4}>
                <ExteriorDesignPaintPicker />
              </Box>
            </div>
          </Route>

          <Route path={`${match.url}/`} exact>
            <ExteriorDesignPaintYesNo
              handleYesClick={handleYesClick}
              handleNoClick={handleNoClick}
              profile={profile as ProfileProperties}
            />
          </Route>

          <Redirect to={`${match.url}/`} />
        </Switch>
      </div>
      <BottomCenterContentContainer
        style={{
          zIndex: 2,
        }}
      >
        <SaveBar label="Next →" mobileLabel="Next →" onClick={handleNext} />
      </BottomCenterContentContainer>
    </>
  );

  function handleProfileLoadTimeout(): () => void {
    let timeout: any = null;

    if (!profile) {
      timeout = setTimeout(() => {
        setLoadTimeoutExpired(true);
      }, 10000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }

  async function handleYesClick() {
    if (!profile) {
      return;
    }

    await updateProfileDoc(profile.id, { exteriorPaintDesired: true });
    history.push(`${match.url}/picker`);
  }

  async function handleNoClick() {
    if (!profile) {
      return;
    }

    await updateProfileDoc(profile.id, { exteriorPaintDesired: false });
    handleNext();
  }

  function handleNext() {
    history.push("/onboard/exterior-design/inspiration");
  }

  function handleGoBack() {
    if (history.location.pathname.includes("picker")) {
      return history.push(match.url);
    } else {
      return history.push("/onboard/exterior-design/");
    }
  }
};

export { ExteriorDesignPaint };
export default ExteriorDesignPaint;
