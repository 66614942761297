import * as React from "react";
import { Media, MediaProperties } from "@yardzen-inc/models";
import {
  makeStyles,
  Theme,
  Tooltip,
  Box,
  Button,
  IconButton,
} from "@material-ui/core";
import { Add, Close, Edit } from "@material-ui/icons";
import CommentModal from "./CommentModal";
import deleteMedia from "../../util/functions/media/deleteMedia";
import updateMediaDescription from "../../util/functions/media/updateMediaDescription";
import GenericSnackBar from "../utility/GenericSnackBar";
import HEICPlaceHolder from "./HEICPlaceHolder";
import { YZTypography } from "@yardzen-inc/react-common";

interface CommentMediaTileProps {
  media: Media;
  deleteable?: boolean;
  action: "keep" | "remove";
  modalOpen?: boolean;
  onDelete?: (media: MediaProperties) => void;
  onCommentsComplete?: (comment: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  required: {
    border: "1px solid #E0ABAB",
    boxShadow: "0 5px 10px rgba(0,0,0,.05)",
    borderRadius: 4,
    overflow: "hidden",
  },
  requiredBox: {
    background: "#F9EEEE",
  },
  completed: {
    border: "1px solid #eee",
    boxShadow: "0 5px 10px rgba(0,0,0,.05)",
    borderRadius: 4,
    overflow: "hidden",
  },
  imgContainer: {
    height: "245px",
    width: "262px",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    position: "relative",
  },
  img: {
    height: "245px",
    width: "262px",
    backgroundColor: "grey",
    gridRow: 1,
    gridColumn: 1,
    display: "inline-block",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  deleteContainer: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    background: "#fff",
    boxShadow: "0 8px 20px rgba(0,0,0,.15)",
    transition: "all ease-in 0.15s",
    "&:hover": {
      background: "#fff",
      transform: "translate(0, -2px)",
    },
  },
  deleteIcon: {
    color: "#C05757",
    width: 18,
    height: 18,
  },
  confirmDeleteContainer: {
    background: "rgba(0,0,0,.85)",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: 1,
  },
  alwaysOnControlPane: {
    opacity: 1,
  },
}));

const CommentMediaTile: React.FunctionComponent<CommentMediaTileProps> = ({
  media,
  deleteable,
  action,
  modalOpen,
  onDelete,
  onCommentsComplete,
}) => {
  const classes = useStyles();
  const [description, setDescription] = React.useState<string>(
    media.description ?? ""
  );
  const commentLength = React.useMemo(() => {
    return description.length ?? 0;
  }, [description]);

  const [confirmingDelete, setConfirmingDelete] = React.useState<boolean>(
    false
  );
  const [commentModalOpen, setCommentModalOpen] = React.useState<boolean>(
    modalOpen || false
  );
  const [error, setError] = React.useState<null | string>(null);
  const [imageURL, setImageURL] = React.useState<string>("");

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(handleDescriptionUpdate, [description]);

  React.useEffect(() => {
    const { thumbnailURL, downloadURL } = media;
    setImageURL(!!thumbnailURL ? thumbnailURL : downloadURL);
  }, [media]);

  return (
    <div className={!commentLength ? classes.required : classes.completed}>
      <div className={classes.imgContainer}>
        {deleteable && confirmingDelete && (
          <div className={classes.confirmDeleteContainer}>
            <YZTypography type="serif">Delete media?</YZTypography>
            <Box display="flex" mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleDelete(media.id);
                }}
                style={{ marginRight: "1rem" }}
              >
                YES
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setConfirmingDelete(false)}
              >
                NO
              </Button>
            </Box>
          </div>
        )}
        <Box
          className={classes.img}
          style={{
            backgroundImage: `url(${imageURL})`,
          }}
        >
          {media.fileType.includes("hei") && <HEICPlaceHolder />}
        </Box>
        {deleteable && (
          <Tooltip title="Delete" arrow>
            <IconButton
              onClick={() => setConfirmingDelete(true)}
              className={classes.deleteContainer}
              size="small"
            >
              <Close className={classes.deleteIcon} />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <Box
        display="flex"
        alignItems="center"
        p={1}
        onClick={() => setCommentModalOpen(true)}
        className={!commentLength ? classes.requiredBox : undefined}
      >
        {commentLength ? (
          <>
            <Box flex="1" textAlign="left">
              <YZTypography variant="body2">{description}</YZTypography>
              <YZTypography variant="caption" color="textSecondary">
                {commentLength}/100
              </YZTypography>
            </Box>
            <Box p={1}>
              <IconButton size="small">
                <Edit style={{ fontSize: 17 }} />
              </IconButton>
            </Box>
          </>
        ) : (
          <>
            <Add />
            Comment Required! *
          </>
        )}
      </Box>
      <CommentModal
        onClose={() => {
          setCommentModalOpen(false);
          if (onCommentsComplete) {
            onCommentsComplete(description);
          }
        }}
        open={commentModalOpen}
        media={media}
        description={description}
        updateDescription={desc => setDescription(desc)}
        action={action}
        buttonDisabled={!description.length}
      />

      {!!error && (
        <GenericSnackBar
          onClose={() => setError(null)}
          variant="error"
          message={error}
        />
      )}
    </div>
  );

  function handleDescriptionUpdate(): () => void {
    const timeout = setTimeout(async () => {
      try {
        await updateMediaDescription(media.id, description);
      } catch (error) {
        window.newrelic.noticeError(error);
        setError("Error updating comment");
      }
    }, 700);

    return () => {
      clearTimeout(timeout);
    };
  }

  async function handleDelete(mediaId: string) {
    try {
      await deleteMedia(mediaId);
      if (media) {
        if (onDelete) {
          onDelete(media as MediaProperties);
        }
      }
    } catch (error) {
      window.newrelic.noticeError(error);
      setError("Error deleting file");
    }
  }
};

export { CommentMediaTile };
export default CommentMediaTile;
