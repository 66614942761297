import React, { FC } from "react";
import { Dialog, DialogTitle, Box } from "@material-ui/core";
import { YZButton } from "../buttons";

export interface ConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  buttonText: string;
  onButtonClick: () => void;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  open,
  onClose,
  title,
  buttonText,
  onButtonClick,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box p={2} display="flex" flexDirection="column" alignItems="center">
        <DialogTitle>{title}</DialogTitle>
        <Box display="flex">
          <YZButton onClick={onButtonClick}>{buttonText}</YZButton>
          <Box p={1} />
          <YZButton color="secondary" onClick={onClose}>
            CANCEL
          </YZButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export { ConfirmDialog };
export default ConfirmDialog;
