import * as React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { initializeFirebase } from "./util";
import "./index.css";
import TagManager from "react-gtm-module";
import "./util/Segment/init";
import { loginWithCustomTokenFromParams } from "./util/firebase/loginWithCustomToken";

const tagManagerArgs = { gtmId: "GTM-NGDTCSR" };
TagManager.initialize(tagManagerArgs);

initializeFirebase();
loginWithCustomTokenFromParams().catch(error => {
  console.error("Error logging in with custom token from URL params", error);
});

ReactDOM.render(<App />, document.getElementById("root"));

declare global {
  interface TatariPurchaseObject {
    orderId: string;
    total: number; // in dollars and cents
  }
}

declare global {
  interface Window {
    tatari: {
      identify: (uid: string) => void;
      track: (
        event: "purchase" | "logged_out",
        purchaseObject: TatariPurchaseObject
      ) => void;
    };
    RB: {
      track: (
        e:
          | "view"
          | "identify"
          | "conv.purchase"
          | "conv.account_creation"
          | "conv.email_signup"
          | "conv.package_view"
          | "conv.lead_submit",
        o?: any
      ) => void;
    };
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
