import { DataLayer, TrackingEventCategories } from "@yardzen-inc/data-layer";
import Cookies from "js-cookie";
import React from "react";
import {
  SegmentClickTypes,
  SegmentClient,
  SegmentFlows,
  SegmentForms,
  SegmentSubFlows,
} from "../../../util/Segment";
import { sha256ToHexString } from "../../../util/sha256";
import { checkLoginStatus } from "./checkLoginStatus";
import { validateEmailAddress } from "./validateEmailAddress";
import { validatePhoneNumber } from "./validatePhoneNumber";

interface HandleEmailPhoneNextClickArgs {
  e?: React.FormEvent;
  segment: SegmentClient;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setErrorText: (errorText: string) => void;
  setPhoneErrorText: (errorText: string) => void;
  dl: DataLayer;
  history: {
    push: (path: string, state?: Record<string, string>) => void;
  };
  submit: () => Promise<void>;
  email: string;
  phone: string;
  referralSource?: string;
  nextPath: string;
}

export const handleEmailPhoneNextClick = async ({
  e,
  segment,
  loading,
  setLoading,
  setErrorText,
  setPhoneErrorText,
  history,
  submit,
  email,
  phone,
  referralSource,
  dl,
  nextPath,
}: HandleEmailPhoneNextClickArgs) => {
  e?.preventDefault();
  setLoading(true);

  segment.trackClicked({
    click_type: SegmentClickTypes.BUTTON,
    flow_name: SegmentFlows.DESIGN_PROFILE,
    email,
    phone,
    referral_source: referralSource,
    button_name: "Design Profile Email Submit",
    button_content: document.getElementById("email-submit-button")?.innerText,
  });

  const _email = email.trim().toLowerCase();
  let hasError = false;

  if (!validateEmailAddress(email)) {
    setErrorText("Please enter a valid email address.");
    hasError = true;
  }

  if (!validatePhoneNumber(phone)) {
    setPhoneErrorText("Please enter a valid phone number");
    hasError = true;
  }

  if (hasError) {
    setLoading(false);
    return;
  }

  try {
    const hasLogin = await checkLoginStatus({ email, setErrorText });

    if (hasLogin) {
      setLoading(false);
      history.push(`/login?email=${email}`, { from: nextPath });
      return;
    }
  } catch (err) {
    window.newrelic.noticeError(err);
    if (err) {
      console.error(err);
      return setLoading(false);
    }
  }

  try {
    await (async function() {
      await segment.identify({
        email: email,
        phone: phone,
        referral_source: referralSource,
      });

      await segment.trackFormSubmitted({
        flow_name: SegmentFlows.DESIGN_PROFILE,
        form_name: SegmentForms.DESIGN_PROFILE_EMAIL,
        sub_flow_name: SegmentSubFlows.LEAD_CAPTURE,
        email,
        phone,
        referral_source: referralSource,
        fbp: Cookies.get("_fbp") || null,
        fbc: Cookies.get("_fbc") || null,
      });

      dl.tracking_id = await sha256ToHexString(_email);
      dl.recordTrackingEvent("client_email_given", {
        category: TrackingEventCategories.ACCOUNT,
        action: "New Design Profile - Email Provided",
        label: window.location.href,
        value: null,
        referralSource,
      });
      dl.pushEvent({
        event: "design_profile_complete",
      });
    })().catch(console.error);
    submit();
  } finally {
    if (loading) {
      setLoading(false);
    }
  }
};
