import React, { FC, useContext, useEffect } from "react";
import { Switch, useRouteMatch, Route, Redirect } from "react-router-dom";
import ChecklistPage from "./ChecklistPage";
import BudgetStepProvider from "./BudgetStepContext";
import { WishListPrioritizePageContainer } from "../../../pages/onboard/budget/WishListPrioritizePageContainer";
import { ThemeSelectionCtx } from "../../../util/contexts/ThemeSelectionContext";
import BugdgetCompletePage from "./BudgetCompletePage";
import { OnboardCtx } from "../../../util";
import {
  SegmentFlows,
  SegmentSubFlows,
  useSegment,
} from "../../../util/Segment";

export interface BudgetStepRouterProps {}

const BudgetStepRouter: FC<BudgetStepRouterProps> = props => {
  const segment = useSegment();
  const match = useRouteMatch();

  const { switchToAnimationTheme, switchToNoAnimationTheme } = useContext(
    ThemeSelectionCtx
  );

  const {
    state: { budgetStepComplete },
  } = useContext(OnboardCtx);

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onMount, []);

  return (
    <BudgetStepProvider>
      <>
        <Switch>
          <Route path={`${match.url}/checklist`} component={ChecklistPage} />
          <Route
            path={`${match.url}/priorities`}
            render={params => (
              <Redirect
                to={`${match.url}/prioritize${params.location.search}`}
              />
            )}
          />
          <Route
            path={`${match.url}/prioritize`}
            component={WishListPrioritizePageContainer}
          />
          <Route
            path={`${match.url}/complete`}
            component={BugdgetCompletePage}
          />
          <Route
            render={() => {
              if (budgetStepComplete) {
                return <Redirect to={`${match.url}/complete`} />;
              }
              return <Redirect to={`${match.url}/checklist`} />;
            }}
          />
        </Switch>
      </>
    </BudgetStepProvider>
  );

  function onMount() {
    switchToAnimationTheme();
    segment.trackSubFlowStarted({
      flow_name: SegmentFlows.ONBOARD,
      sub_flow_name: SegmentSubFlows.BUDGET_CHECKLIST,
    });

    return () => {
      switchToNoAnimationTheme();
      segment.trackSubFlowCompleted({
        flow_name: SegmentFlows.ONBOARD,
        sub_flow_name: SegmentSubFlows.BUDGET_CHECKLIST,
      });
    };
  }
};

export { BudgetStepRouter };
export default BudgetStepRouter;
