import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getSHA1HashOfString } from "../getSHA1HashOfString";
import { useImpactRadius } from "./useImpactRadius";

interface useImpactRadiusIdentifierArgs {
  customerId: string | undefined;
  customerEmail: string | null | undefined;
}

/**
 * Runs the impact radius identify function whenever navigating to a new page.
 * @param useImpactRadiusIdentifierArgs The user id and user email to send to
 * impact radius if they exist.
 */
export const useImpactRadiusIdentifier = ({
  customerId,
  customerEmail,
}: useImpactRadiusIdentifierArgs): void => {
  const location = useLocation();

  const { identifyForImpactRadius } = useImpactRadius();

  useEffect(() => {
    const identify = async (): Promise<void> => {
      identifyForImpactRadius({
        customerId: customerId ?? "",
        customerEmail: customerEmail
          ? await getSHA1HashOfString(customerEmail)
          : "",
      });
    };

    identify();
  }, [location, customerEmail, customerId, identifyForImpactRadius]);
};
