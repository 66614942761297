import {
  Box,
  makeStyles,
  Typography,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import * as React from "react";
import { ASSETS_BASE_URL } from "../../../util/constants/assetsBaseUrl";
import useHeaderVariant from "../../../util/hooks/useHeaderVariant";
import UploadTitle from "../helpers/UploadTitle";
import { YardName } from "../stepData";
import YardUploadGrid from "../YardUploadGrid";

interface IYardSlopeQuestionAndUploadProps {
  yard: YardName;
  variantTagPrefix: string;
  projectId: string | null;
  isSloped: boolean;
  setIsSloped: (isSloped: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  instructionLink: {
    textDecoration: "underline",
    color: theme.palette.primary.dark,
    "&:hover": {
      cursor: "pointer",
    },
  },
  slopeButtons: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "40%",
  },
  slopeContainer: {
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  slopeRow: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    objectFit: "contain",
    height: "170px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "auto",
    },
  },
  slopeImage: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      objectFit: "contain",
      maxHeight: "30vh",
      width: "auto",
    },
  },
  slopeInstructions: {
    textAlign: "left",
    padding: "5px",
    marginLeft: "1rem",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingTop: theme.spacing(2),
    },
  },
  photoUploadGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "1rem",
    gridAutoFlow: "row",
    justifyContent: "center",
    "& :first-child": {
      gridColumn: "1 / -1",
    },
  },
  uploadClassName: {
    height: "275px !important",
    width: "100% !important",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(3),
  },
  slopeTitleClassName: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const YardSlopeQuestionAndUpload: React.FunctionComponent<IYardSlopeQuestionAndUploadProps> = ({
  yard,
  variantTagPrefix,
  isSloped,
  projectId,
  setIsSloped,
}) => {
  const classes = useStyles();
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const subHeader = useHeaderVariant()[1];
  const [slopedUploadCount, setSlopedUploadCount] = React.useState<
    number | undefined
  >(undefined);

  return (
    <Box>
      <YZTypography type="serif" variant={subHeader} id="sloped-upload">
        Is your {yard} yard sloped?
      </YZTypography>
      <Box display="flex" justifyContent="space-between">
        <YZButton
          color={isSloped ? "primary" : "default"}
          className={classes.slopeButtons}
          onClick={() => setIsSloped(true)}
        >
          Yes
        </YZButton>
        <YZButton
          color={isSloped ? "default" : "primary"}
          className={classes.slopeButtons}
          onClick={() => setIsSloped(false)}
        >
          No
        </YZButton>
      </Box>
      {!!isSloped && (
        <UploadTitle
          className={classes.slopeTitleClassName}
          id="sloped-title-upload"
          title="Slope Photos"
          warning={slopedUploadCount === 0 ? "At least 1 required" : undefined}
        />
      )}

      {!!isSloped && !!projectId && (
        <div className={classes.slopeContainer}>
          <div className={classes.slopeRow}>
            <img
              className={classes.slopeImage}
              src={`${ASSETS_BASE_URL}/public/upload-instructions-slope.png`}
              alt="sloped yard example"
            />
            <Typography variant="body1" className={classes.slopeInstructions}>
              Take photos from the perimeter of the property facing the side of
              the slope, so we’re able to determine the angle and length of the
              slope. Make sure to have your phone in landscape (horizontal) mode
              at 1x (no zoom or panoramic).
            </Typography>
          </div>
          <YardUploadGrid
            projectId={projectId}
            fileTag="property"
            setFileCount={setSlopedUploadCount}
            variant={`${variantTagPrefix}-slope`}
            accept={[".jpg", ".jpeg", ".png", ".svg", ".heic"]}
            className={!mobile ? classes.photoUploadGrid : undefined}
            uploadClassName={!mobile ? classes.uploadClassName : undefined}
          />
        </div>
      )}
    </Box>
  );
};

export { YardSlopeQuestionAndUpload };
