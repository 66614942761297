import firebase from "firebase/compat/app";
import { initializeFirestore } from "firebase/firestore";

import firebaseConfig from "./firebaseConfig";

const initializeFirebase = (): void => {
  if (!firebase.apps.length) {
    const app = firebase.initializeApp(firebaseConfig);

    // firestore can't be reached on dogfood/prod without this setting
    initializeFirestore(app, {
      experimentalForceLongPolling: true,
    });
  }
};

export { initializeFirebase };
