import React, { FC } from "react";
import { TrafficTypes } from "@yardzen-inc/react-split";
import { SplitAlertBanner } from "./SplitAlertBanner";

interface SplitAlertBannerByTrafficTypeProps {
  splitTreatment: string;
  trafficType: TrafficTypes;
}

export const SplitAlertBannerByTrafficType: FC<SplitAlertBannerByTrafficTypeProps> = ({
  splitTreatment,
  trafficType,
}) => {
  const userInOnboard = window.location.pathname.includes("onboard");

  // return nothing if trafficType does not match the user logged in status
  if (!userInOnboard && trafficType === TrafficTypes.USER) return <></>;
  if (userInOnboard && trafficType === TrafficTypes.ANONYMOUS) return <></>;

  return <SplitAlertBanner splitTreatment={splitTreatment} />;
};
