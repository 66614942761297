import { YZTypography } from "@yardzen-inc/react-common";
import { useAppSelector } from "../../hooks";
import { PriceObject } from "../../types/Prices";
import { ItemizedPaymentDetail } from "./ItemizedPaymentDetail";
import { formatPrice } from "./util/formatPrice";

export interface DiscountDetailProps {
  discountPrice: number | null;
  skuObject: PriceObject;
}

export function DiscountDetail(props: DiscountDetailProps) {
  const { originalOrderPrice, orderPrice } = useAppSelector(
    state => state.payment
  );

  if (originalOrderPrice && orderPrice && originalOrderPrice !== orderPrice) {
    return (
      <ItemizedPaymentDetail title="Discounts">
        <YZTypography variant="body1" style={{ fontWeight: 600 }}>
          {`- ${formatPrice(Math.max(originalOrderPrice - orderPrice, 0))}`}
        </YZTypography>
      </ItemizedPaymentDetail>
    );
  }
  const hasDiscountedValue =
    originalOrderPrice &&
    props.discountPrice &&
    originalOrderPrice !== props.discountPrice;
  return (
    (props.discountPrice !== null && hasDiscountedValue && (
      <ItemizedPaymentDetail title="Gift Card">
        <YZTypography variant="body1" style={{ fontWeight: 600 }}>
          {`- ${formatPrice(originalOrderPrice - props.discountPrice)}`}
        </YZTypography>
      </ItemizedPaymentDetail>
    )) ||
    null
  );
}
