import { Box, makeStyles } from "@material-ui/core";
import * as React from "react";
import { OnboardCtx, ProfileCtx } from "../../../util";
import { YardName, YardsStatusObj } from "../stepData";
import DisabledYardChip from "./DisabledYardChip";
import LockedYardInfoModal from "./LockedYardInfoModal";
import { yardIsLocked } from "../util/yardIsLocked";
import { PackageTypes } from "../../../util/constants/packageTypes";
import { Profile } from "@yardzen-inc/models";

interface IDisabledYardSelectContainerProps {
  addYard: (yard: YardName) => void;
  swapYards: (
    yardToActivate: Exclude<YardName, "left" | "right" | "outdoor">
  ) => void;
  yards: YardName[];
}

const useStyles = makeStyles(({ breakpoints }) => ({
  buttonsContainer: {
    width: "75%",
    [breakpoints.down("xs")]: {
      width: "100%",
    },
    margin: "0 auto",
    marginTop: "1rem",
  },
}));

const DisabledYardSelectContainer: React.FunctionComponent<IDisabledYardSelectContainerProps> = props => {
  const classes = useStyles();
  const clientProfile = React.useContext(ProfileCtx) as Profile;
  const pkg = clientProfile?.package as PackageTypes;
  const [swapYardModalOpen, setSwapYardModalOpen] = React.useState<
    false | Exclude<YardName, "left" | "right" | "outdoor">
  >(false);
  const {
    state: { yardsStatusObj },
  } = React.useContext(OnboardCtx);

  if (!props.yards.length || !pkg) {
    return <></>;
  }

  return (
    <Box>
      <LockedYardInfoModal
        yard={swapYardModalOpen as YardName}
        open={!!swapYardModalOpen}
        onClose={() => setSwapYardModalOpen(false)}
        onSwap={handleYardSwap}
      />
      <Box className={classes.buttonsContainer}>
        {getYardChip(props.yards, pkg, yardsStatusObj)}
      </Box>
    </Box>
  );

  function handleYardSwap() {
    const yardToActivate = swapYardModalOpen;
    props.swapYards(yardToActivate as "front" | "back");
    setSwapYardModalOpen(false);
  }

  function handleClick(yard: YardName) {
    props.addYard(yard);
  }

  function handleLockedYardClick(yard: YardName) {
    setSwapYardModalOpen(
      yard as Exclude<YardName, "left" | "right" | "outdoor">
    );
  }

  function getYardChip(
    yards: YardName[],
    pkg: PackageTypes | undefined,
    yardsStatusObj: YardsStatusObj
  ) {
    const activeYardKeys = Object.keys(yardsStatusObj) as YardName[];

    return (
      <>
        {yards.map((yard, i) => {
          const locked = yardIsLocked(pkg, yard, activeYardKeys);
          return (
            <DisabledYardChip
              key={`disabled-${yard}-${i}`}
              yard={yard}
              locked={locked}
              onClick={locked ? handleLockedYardClick : handleClick}
            />
          );
        })}
      </>
    );
  }
};

export default DisabledYardSelectContainer;
