/**
 * Interface for ErrorConstructor.
 * This interface is used to define a new Error object with a specific message.
 */
export interface ErrorConstructor {
  new (message?: string): Error;
}

/**
 * Class HRError extends the built-in Error class.
 * It provides a constructor to create a new error object with a specific message and a display message.
 * It also provides a static method to create a new HRError object from an existing error object.
 */
export class HRError extends Error {
  /**
   * Constructor for HRError.
   * @param {string} message - The error message.
   * @param {string} displayMessage - The message to be displayed. Defaults to the error message.
   */
  constructor(
    message: string,
    public readonly displayMessage: string = message
  ) {
    super(message);
  }

  /**
   * Static method to create a new HRError object from an existing error object.
   * If the error object is already an instance of HRError, it is returned as is.
   * If the error object is an instance of Error, a new HRError object is created with the same message and stack trace.
   * If the error object is not an instance of Error, a new HRError object is created with the string representation of the error object.
   * @param {unknown} error - The error object.
   * @param {string} displayMessage - The message to be displayed.
   * @return {HRError} - The new HRError object.
   */
  public static fromError(error: unknown, displayMessage: string): HRError {
    console.assert(error === undefined || error === null, "error is nullish");

    if (error instanceof HRError) {
      return error;
    }

    if (error instanceof Error) {
      const herror = new HRError(error.message, displayMessage);
      herror.stack = error.stack;
      return herror;
    }

    return new HRError(String(error));
  }
}
