import _ from "lodash";
import { DesignProfileCtxValue } from "../../../../components/designProfile/DesignProfileCtx";
import { Page, Selections } from "../../../../types/DesignProfile";
import { getNewResponse } from "./getNewResponse";
import { getUpdatedResponse } from "./getUpdatedResponse";

type handleQuizPageSelectParams = {
  existingResponse: any;
  selectionValue: string;
  dsContext: DesignProfileCtxValue;
  page: Page;
  selectionKey?: keyof Selections;
  setUserResponses?: (responses: any) => void;
};

/**
 * Takes in info after a user has made a selection on a quiz page and sends it to updateContext
 *
 * @param {string} selectionValue A selection the user has made
 * @param {string} selectionKey A key in the Selections object
 * @param {DesignProfileCtxValue} dsContext Design profile Context
 * @param {Page} page current Page info
 * @return {void}
 */
export const handleQuizPageSelect = async ({
  existingResponse,
  selectionValue,
  dsContext,
  page,
  selectionKey = "default",
  setUserResponses,
}: handleQuizPageSelectParams) => {
  const { designProfile, updateDesignProfileDebounced } = dsContext;

  const existingQuestionsWithoutDuplicateResponse =
    designProfile?.questions?.filter(response => response.path !== page.path) ??
    [];

  // There's a chance that a response can exist here but this clause will be
  // true if all the answers were unselected, so we'll need to append to
  // the array with duplicates removed.
  if (!existingResponse || _.isEmpty(existingResponse)) {
    const newResponse = getNewResponse({ page, selectionValue, selectionKey });

    if (setUserResponses) {
      setUserResponses(newResponse.response);
    }

    await updateDesignProfileDebounced({
      questions: [...existingQuestionsWithoutDuplicateResponse, newResponse],
    } as any);

    return;
  }

  const updatedResponse = getUpdatedResponse({
    existingResponse,
    page,
    selectionValue,
    selectionKey,
  });

  if (setUserResponses) {
    setUserResponses(updatedResponse.response);
  }

  await updateDesignProfileDebounced({
    questions: [...existingQuestionsWithoutDuplicateResponse, updatedResponse],
  } as any);
};
