export interface PixelInitParameters {
  em?: string;
  fn?: string;
  ln?: string;
  ph?: string;
  external_id?: string;
  ge?: string;
  db?: string;
  ct?: string;
  st?: string;
  zp?: string;
  country?: string;
  fbc?: string;
  fbp?: string;
}

export type PixelEvents =
  | "AddPaymentInfo"
  | "AddToCart"
  | "AddToWishList"
  | "CompleteRegistration"
  | "Contact"
  | "CustomizeProduct"
  | "InitiateCheckout"
  | "Lead"
  | "Purchase"
  | "StartTrial"
  | "Search"
  | "SubmitApplication"
  | "Subscribe"
  | "ViewContent";

let initValues: PixelInitParameters = {};

const sendFbPixelEvent = async (
  event: PixelEvents,
  opts: facebook.Pixel.ViewContentParameters = {}
): Promise<void> => {
  try {
    if (process.env.NODE_ENV === "development") {
      console.log(`FB PIXEL EVENT ${event} ${JSON.stringify(opts)}`);
    }

    return fbq("track", event, opts);
  } catch (error) {
    window.newrelic.noticeError(error);
    console.error("failed to send facebook pixel event:");
    console.error(error);
  }
};

const fbPixelInit = async (opts: PixelInitParameters = {}): Promise<void> => {
  const sanitizedValues: PixelInitParameters = {};

  Object.entries(opts).forEach(([k, v]) => {
    if (v) {
      sanitizedValues[k as keyof PixelInitParameters] = v;
    }
  });

  initValues = { ...initValues, ...sanitizedValues };
  try {
    if (process.env.NODE_ENV === "production") {
      return fbq("init", "1551816664965248", initValues as any);
    }
  } catch (error) {
    window.newrelic.noticeError(error);
    console.error("failed to send facebook pixel init event:");
    console.error(error);
  }
};

const defaultExport = {
  sendFbPixelEvent,
  fbPixelInit,
};

export { sendFbPixelEvent, fbPixelInit };
export default defaultExport;
