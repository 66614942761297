import { useContext, useEffect } from "react";
import { PriceObject } from "../../types/Prices";
import { UserCtx } from "../contexts";
import { getSHA1HashOfString } from "../getSHA1HashOfString";
import { useImpactRadius } from "./useImpactRadius";

interface useImpactRadiusConversionArgs {
  orderId: string;
  customerEmail: string;
  packageSubTotal: number;
  packageSku: PriceObject;
  packageDisplayName: string;
  expeditedSku: PriceObject | undefined;
  premiumSku: PriceObject;
  isPremium: boolean;
}

/**
 * Runs the impact radius trackConversion function whenever called.
 * @param useImpactRadiusConversionArgs
 */
export const useImpactRadiusConversion = ({
  orderId,
  customerEmail,
  packageSubTotal,
  packageSku,
  packageDisplayName,
  expeditedSku,
  premiumSku,
  isPremium,
}: useImpactRadiusConversionArgs): void => {
  const user = useContext(UserCtx);
  const { trackImpactRadiusConversion } = useImpactRadius();

  // TODO: remove disable comment and fix warning next time this hook is updated
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const trackConversion = async (): Promise<void> => {
      if (user) {
        const hashedEmail = customerEmail
          ? await getSHA1HashOfString(customerEmail.trim().toLowerCase())
          : "";

        let args = {
          orderId: orderId ?? "",
          customerId: user.uid,
          customerEmail: hashedEmail,
          customerStatus: "New",
          currencyCode: "USD",
          orderPromoCode: "",
          orderDiscount: 0,
          items: [
            {
              subTotal: packageSubTotal / 100,
              category: "Landscape Design Package",
              sku: packageSku.id,
              quantity: 1,
              name: packageDisplayName,
            },
          ],
        };

        if (expeditedSku) {
          args.items.push({
            subTotal: expeditedSku.price / 100,
            category: "Landscape Design Package",
            sku: expeditedSku.id,
            quantity: 1,
            name: "Expedited Upgrade",
          });
        }

        if (isPremium) {
          args.items.push({
            subTotal: premiumSku.price / 100,
            category: "Landscape Design Package",
            sku: premiumSku.id,
            quantity: 1,
            name: "Premium Upgrade",
          });
        }

        trackImpactRadiusConversion(args);
      }
    };

    trackConversion();
  }, [user]);
  /* eslint-enable react-hooks/exhaustive-deps */
};
