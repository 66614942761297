import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import productReducer from "./slices/productSlice";
import checkoutReducer from "./slices/checkoutSlice";
import paymentReducer from "./slices/paymentSlice";
import onboardReducer from "./slices/onboardSlice";
import storage from "redux-persist/lib/storage";
import { persistConfig } from "./store";
import { pangaeaV1API } from "./api/pangaeaV1API";
import { banyanAPI } from "./api/banyanV1API";

const appReducer = combineReducers({
  products: productReducer,
  checkout: checkoutReducer,
  payment: paymentReducer,
  onboard: onboardReducer,
  [pangaeaV1API.reducerPath]: pangaeaV1API.reducer,
  [banyanAPI.reducerPath]: banyanAPI.reducer,
});

export const CLEAR_STORE = "CLEAR_STORE";

// The purpose of this rootReducer is to add on an action that will reset the store and clear all storage
// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: AnyAction
) => {
  // This action will clear the store when called
  if (action.type === CLEAR_STORE) {
    // for redux-persist, we also need to clean our storage
    for (const key in persistConfig) {
      storage.removeItem(`persist:${key}`);
    }

    // Reducers return the initial state when they are called with undefined as the first argument, no matter the action.
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
