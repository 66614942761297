import * as React from "react";
import { UserCtx } from "../../util";
import { Redirect } from "react-router";

interface HandleIncorrectRouteProps {}

// TODO: Create proper 404 page
const HandleIncorrectRoute: React.FunctionComponent<HandleIncorrectRouteProps> = props => {
  const user = React.useContext(UserCtx);

  if (user === null) {
    return null;
  }

  if (!!user && !user.isAnonymous) {
    return <Redirect to="/onboard" />;
  }
  return <Redirect to="/design-profile" />;
};

export default HandleIncorrectRoute;
