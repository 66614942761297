import React, { createContext, FC, useState } from "react";
import { isSafari } from "..";
import { getTheme } from "../Providers/theme";
import { ThemeProvider } from "@material-ui/core";

const noAnimationTheme = getTheme(true);
const regularTheme = getTheme(false);

export interface ThemeSelectionProviderProps {}

export interface ThemeSelectionContextValue {
  theme: typeof noAnimationTheme;
  switchToAnimationTheme: () => void;
  switchToNoAnimationTheme: () => void;
}

const ThemeSelectionCtx = createContext<ThemeSelectionContextValue>(
  null as any
);

const ThemeSelectionProvider: FC<ThemeSelectionProviderProps> = props => {
  const [selectedTheme, setSelectedTheme] = useState<typeof noAnimationTheme>(
    isSafari ? noAnimationTheme : regularTheme
  );

  return (
    <ThemeSelectionCtx.Provider
      value={{
        theme: selectedTheme,
        switchToAnimationTheme,
        switchToNoAnimationTheme,
      }}
    >
      <ThemeProvider theme={selectedTheme}>{props.children}</ThemeProvider>
    </ThemeSelectionCtx.Provider>
  );

  function switchToAnimationTheme() {
    setSelectedTheme(regularTheme);
  }

  function switchToNoAnimationTheme() {
    if (!isSafari) return;

    setSelectedTheme(noAnimationTheme);
  }
};

export { ThemeSelectionProvider, ThemeSelectionCtx };
