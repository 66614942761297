import { useContext, useMemo } from "react";
import { OnboardStep } from "./stepData";
import { useAllowAccessToBudgetStep } from "./budget";
import { OnboardCtx, useProfile, useUser } from "../../util";
import { useAllowAccessToExteriorDesignStep } from "./useAllowAccessToExteriorDesignStep";
import {
  BACK_YARD_PACKAGE,
  BOTANICAL_PACKAGE,
  CURB_APPEAL_PACKAGE,
  FRONT_YARD_PACKAGE,
  FULL_YARD_PACKAGE,
  OUTDOOR_TRANSFORMATION_PACKAGE,
  SMALL_SPACE_PACKAGE,
  UBER_PREMIUM_PACKAGE,
} from "../../util/constants/packageTypes";

export interface MenuItem {
  label: string;
  slug: string;
  title: string;
  hideForPkg?: string | string[];
  onboardTwoSlug?: string;
  step: OnboardStep;
}

export interface UseMenuItems {
  (): MenuItem[];
}

const MENU_ITEM_STORAGE_KEY = "ONBOARD_SIDEBAR_MENU_ITEMS";

const useMenuItems: UseMenuItems = () => {
  const allowAccessToBudgetStep = useAllowAccessToBudgetStep();
  const allowAccesstoExteriorDesignStep = useAllowAccessToExteriorDesignStep();
  const profile = useProfile(useUser()[1])[1];
  const {
    state: { contextError },
    setOnboardContextError,
  } = useContext(OnboardCtx);

  return useMemo(() => {
    if (!profile) {
      const menuItemsInStorageString = localStorage.getItem(
        MENU_ITEM_STORAGE_KEY
      );

      if (menuItemsInStorageString) {
        try {
          const parsed = JSON.parse(menuItemsInStorageString) as MenuItem[];
          return parsed;
        } catch {}
      }
    }

    if (allowAccessToBudgetStep === "error" && !contextError) {
      setOnboardContextError("Unable to process menuItems");
    }

    const menuItems = getMenuItems(
      allowAccessToBudgetStep === "error" ? false : allowAccessToBudgetStep,
      allowAccesstoExteriorDesignStep === null
        ? false
        : allowAccesstoExteriorDesignStep,
      profile ? profile.package : void 0
    );

    try {
      setTimeout(() =>
        localStorage.setItem(MENU_ITEM_STORAGE_KEY, JSON.stringify(menuItems))
      );
    } catch (error) {
      window.newrelic.noticeError(error);
      console.error(error);
    }

    return menuItems;
  }, [
    allowAccessToBudgetStep,
    allowAccesstoExteriorDesignStep,
    contextError,
    profile,
    setOnboardContextError,
  ]);
};

function getMenuItems(
  allowAccessToBudgetStep: boolean | null,
  allowAccessToExteriorDesignStep: boolean | null,
  pkg?: string
): MenuItem[] {
  let menuItems: MenuItem[] = [
    {
      label: "My Yard",
      slug: "Media",
      onboardTwoSlug: "my-yard",
      title: "My Yard",
      step: "Yard",
    },
    allowAccessToBudgetStep !== false
      ? {
          label: "Wish List",
          slug: "budget",
          onboardTwoSlug: "budget",
          hideForPkg: BOTANICAL_PACKAGE,
          title: "Budget",
          step: "Budget",
        }
      : {
          label: "Design Elements",
          slug: "Elements",
          hideForPkg: BOTANICAL_PACKAGE,
          title: "Elements",
          step: "Elements",
        },
  ];
  if (allowAccessToExteriorDesignStep !== false) {
    menuItems.push({
      label: "Exterior Design",
      slug: "exterior-design",
      onboardTwoSlug: "exterior-design",
      hideForPkg: [BOTANICAL_PACKAGE, FULL_YARD_PACKAGE, "partial yard"],
      title: "Exterior Design",
      step: "Exterior Design",
    });
  }
  if (pkg === BOTANICAL_PACKAGE) {
    menuItems.push({
      label: "Installation Details",
      slug: "Elements",
      title: "Design Elements",
      hideForPkg: [
        "partial yard",
        OUTDOOR_TRANSFORMATION_PACKAGE,
        CURB_APPEAL_PACKAGE,
        FULL_YARD_PACKAGE,
        BACK_YARD_PACKAGE,
        FRONT_YARD_PACKAGE,
        UBER_PREMIUM_PACKAGE,
        SMALL_SPACE_PACKAGE,
      ],
      step: "Budget",
    });
  }

  return menuItems
    .concat([
      {
        label: "My Goals",
        slug: "Goals",
        title: "My Goals",
        step: "Goals",
      },
      {
        label: "My Inspiration",
        slug: "Inspiration",
        title: "My Inspiration",
        step: "Inspiration",
      },
      {
        label: "Plans & Drawings",
        slug: "Plans",
        title: "Plans & Drawings",
        step: "Plans",
      },
      {
        label: "My Preferences",
        slug: "Style",
        title: "My Preferences",
        step: "Preferences",
      },
      {
        label: "My Plants",
        slug: "Plants",
        title: "My Plants",
        step: "Plants",
      },
      {
        label: "Start My Project",
        slug: "Start",
        title: "Start My Project",
        step: "Complete",
      },
    ])
    .filter(
      item => !!item && (!pkg || (!!pkg && !item.hideForPkg?.includes(pkg)))
    ) as MenuItem[];
}

export { useMenuItems, getMenuItems };
export default useMenuItems;
