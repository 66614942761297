import {
  SegmentClickTypes,
  SegmentFlows,
  segment,
} from "../../../util/Segment";

/**
 * Runs when the clicks the button to confirm their address is correct
 * @param onNext A function that directs the user to the next step in the design profile
 * @param buttonContent A string that reflects the text of the button clicked
 * @returns void
 */
export function handleConfirmAddress(
  onNext: () => void,
  buttonContent: string | null
): void {
  segment.trackClicked({
    click_type: SegmentClickTypes.BUTTON,
    flow_name: SegmentFlows.DESIGN_PROFILE,
    button_name: "Design Profile Address Confirmation Yes",
    button_content: buttonContent ?? "",
  });

  onNext();
}
