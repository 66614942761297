import { setUseMediaDomain } from "@yardzen-inc/models";
import { useEffect } from "react";

function useSetMediaDomain() {
  useEffect(() => {
    setUseMediaDomain(true);
  }, []);
}

export { useSetMediaDomain };
