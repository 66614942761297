import { Box, Link, Theme } from "@material-ui/core";
import { Lock } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { YZTypography } from "@yardzen-inc/react-common";

const useStyles = makeStyles((theme: Theme) => ({
  lock: {
    fontSize: 14,
    marginRight: theme.spacing(2),
  },
  textWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const CheckoutFooter = () => {
  const classes = useStyles();
  const { textWrapper, lock } = classes;
  return (
    <>
      <Box className={textWrapper}>
        <Lock className={lock} />
        <YZTypography color="textSecondary">
          We use{" "}
          <Link
            href="https://stripe.com"
            target="_blank"
            rel="noopener noreferrer"
            color="textPrimary"
          >
            Stripe
          </Link>
          , one of the most secure and trusted online payment processing
          services available.
        </YZTypography>
      </Box>
    </>
  );
};
