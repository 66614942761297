export enum SegmentFlows {
  DESIGN_PROFILE = "Design Profile",
  CHECKOUT = "Checkout",
  ONBOARD = "Onboard",
  SHOP_PACKAGES = "Shop Packages",
  PURCHASE_ADDITIONAL_REVISION = "Purchase Additional Revision",
}

export enum SegmentSubFlows {
  MY_YARD = "My Yard",
  BUDGET_CHECKLIST = "Budget Checklist",
  EXTERIOR_DESIGN = "Exterior Design",
  WISH_LIST = "Wish List",
  DESIGN_PROFILE_QUIZ = "Design Profile Quiz",
  LEAD_CAPTURE = "lead_capture",
}

export enum SegmentForms {
  DESIGN_PROFILE_EMAIL = "Design Profile Email",
  DESIGN_PROFILE_PHONE = "Design Profile Phone",
  DESIGN_PROFILE_ADDRESS = "Design Profile Address",
  DESIGN_PROFILE_QUIZ = "Design Profile Quiz",
  CHECKOUT_ACCOUNT_SIGNUP = "Checkout Account Signup",
  CHECKOUT_ADDRESS = "Checkout Address",
  CHECKOUT_BILLING_DETAILS = "Checkout Billing Details",
  CHECKOUT_PAYMENT_INFO = "Checkout Payment Info",
  CHECKOUT_PACKAGE_DETAIL = "Checkout Package Detail",
  REFERRAL_SUBMISSION = "Referral Submission",
}

export enum SegmentInputTypes {
  TEXT = "Text",
  SELECT = "Select",
  MULTI_SELECT = "Multi Select",
  RADIO_GROUP = "Radio Group",
  CHECKBOX = "Checkbox",
}

export enum SegmentClickTypes {
  BUTTON = "Button",
  NAV = "Nav",
  CONTACT = "Contact",
  FOOTER = "Footer",
  CAROUSEL = "Carousel",
  HYPERLINK = "Hyperlink",
  BREADCRUMB = "Breadcrumb",
}

export enum SegmentExperimentTypes {
  FULL_YARD_PREMIUM = "full_yard_premium",
  UBER_PREMIUM = "uber_premium",
  DISPLAY_SMALL_LOT_SIZE = "display_small_lot_size",
  DISPLAY_SMALL_LOT_SIZE_AS_DEFAULT_VALUE = "display_small_lot_as_default_value",
  HOA_EXTRA_REVISION_UPSELL = "hoa_extra_revision_upsell",
}
