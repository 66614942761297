import React, { FC, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Box, Fade, Theme, useMediaQuery } from "@material-ui/core";
import ChecklistPageRouter from "./ChecklistPageRouter";
import ChecklistSidebar from "./ChecklistSidebar";
import { useHeaderHeight, usePageDimensions } from "../../../util";
import { useLocation } from "react-router";

export interface ChecklistPageProps {}

const ChecklistPage: FC<ChecklistPageProps> = props => {
  const containerRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const location = useLocation();

  const [sidebarHeight, setSidebarHeight] = useState<number>(0);

  const h = useHeaderHeight();
  const [pageWidth, pageHeight] = usePageDimensions();

  const actualContainerHeight = useMemo(
    () => (pageHeight - h).toString() + "px",
    [h, pageHeight]
  );

  useLayoutEffect(determineSidebarHeight, [
    pageHeight,
    pageWidth,
    sidebarRef,
    mdDown,
  ]);
  useLayoutEffect(onRedirect, [location.pathname]);

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection={mdDown ? "column" : "row"}
      maxHeight={actualContainerHeight}
      height={actualContainerHeight}
    >
      <div
        ref={containerRef}
        style={{
          flexGrow: 1,
          overflow: "auto",
          maxHeight: smDown
            ? `calc(${actualContainerHeight} - ${sidebarHeight}px)`
            : void 0,
        }}
      >
        <ChecklistPageRouter />
      </div>

      <Fade in={true} timeout={{ enter: 100 }}>
        <ChecklistSidebar
          ref={sidebarRef}
          onOpenClose={() => determineSidebarHeight()}
        />
      </Fade>
    </Box>
  );

  function onRedirect() {
    try {
      window.scrollTo({ top: 0 });
    } catch {}
    try {
      document.documentElement.scrollTo({ top: 0 });
    } catch {}
    if (containerRef.current) {
      try {
        containerRef.current.scrollTo({ top: 0 });
      } catch {}
    }
  }

  function determineSidebarHeight(): void | (() => void) {
    const timeout = setTimeout(() => {
      if (sidebarRef.current) {
        const rect = sidebarRef.current.getBoundingClientRect();
        setSidebarHeight(rect.height);
      }
    }, 150);

    return () => clearTimeout(timeout);
  }
};

export { ChecklistPage };
export default ChecklistPage;
