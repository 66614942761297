import { getYardzenCookies, setYardzenCookies } from "./clients";
import { YardzenCookies } from "./types";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { store } from "../../store";
import { CLEAR_STORE } from "../../rootReducer";
import { logout } from "../logout";

function isUserIdMismatch(cookies: YardzenCookies) {
  return (
    !!cookies.dashboard.userId &&
    !!cookies.account.userId &&
    cookies.dashboard.userId !== cookies.account.userId
  );
}

function isAppInLogoutState(cookies: YardzenCookies) {
  return (
    cookies.dashboard.state === "logout" || cookies.account.state === "logout"
  );
}

function isUserInLogoutState(cookies: YardzenCookies) {
  return cookies.dashboard.state === "logout";
}

function isUserMismatchAtInitState(
  cookies: YardzenCookies,
  user: firebase.User
) {
  return (
    cookies.account.state === "ready" &&
    !!cookies.account.userId &&
    cookies.account.userId !== user.uid
  );
}

async function firebaseSignOut(shouldDisplayMessage: boolean = false) {
  const cookies = getYardzenCookies();
  cookies.dashboard.userId = "";
  cookies.dashboard.state = shouldDisplayMessage ? "displayMessage" : "";
  setYardzenCookies(cookies);

  localStorage.clear();
  await logout();
}

function forceLogout(cookies: YardzenCookies) {
  cookies.dashboard.state = "logout";
  cookies.account.state = "logout";
  setYardzenCookies(cookies);
}

function clearStorage() {
  store.dispatch({ type: CLEAR_STORE });
}

function forceLogoutViaPath() {
  forceLogout(getYardzenCookies());
  clearStorage();
  firebaseSignOut();
}

function updateStateAtUserMismatch(cookies: YardzenCookies) {
  if (isUserIdMismatch(cookies) && !isAppInLogoutState(cookies)) {
    forceLogout(cookies);
  }
}

function forceLogoutWhenInconsistentUserDetected() {
  const cookies = getYardzenCookies();
  updateStateAtUserMismatch(cookies);
  if (isUserInLogoutState(cookies)) {
    firebaseSignOut();
  }
}

function shouldDisplayForceLogoutMessage(resetState: boolean = false) {
  const myCookie = getYardzenCookies();
  const shouldDisplayMessage = myCookie.dashboard.state === "displayMessage";

  // @ts-ignore
  if (resetState && shouldDisplayForceLogoutMessage) {
    myCookie.dashboard.state = "";
    setYardzenCookies(myCookie);
  }

  return shouldDisplayMessage;
}

function setUserIdInCookies(user: firebase.User | false | null) {
  if (user && !user.isAnonymous) {
    const myCookies = getYardzenCookies();

    if (isUserMismatchAtInitState(myCookies, user)) {
      forceLogout(myCookies);
      firebaseSignOut().then(() => {
        window.location.href = "login?force-logout=True";
      });
    } else {
      myCookies.dashboard.userId = user.uid;
      myCookies.dashboard.state = "ready";

      setYardzenCookies(myCookies);
    }
  }
}

export {
  setUserIdInCookies,
  shouldDisplayForceLogoutMessage,
  forceLogoutWhenInconsistentUserDetected,
  firebaseSignOut,
  forceLogoutViaPath,
};
