import React from "react";
import { useHistory } from "react-router";
import { designProfileComponents } from "./designProfileComponents";
import { DesignProfileVersionId, Page } from "../types/DesignProfile";
import { HeaderBase, DefaultHeader } from "../components/header";
import YZFooter from "./footer/YZFooter";
import { ThemeProvider } from "@material-ui/styles";
import { GenericLoadingSpinner, theme } from "@yardzen-inc/react-common";
import { AnonymousUserControlWrapper } from "./designProfile/AnonymousUserControlWrapper";
import { DesignProfileProvider } from "./designProfile/DesignProfileCtx";
import { CenterFullScreen } from "./utility";
import { DesignProfileProgressBar } from "./designProfile/header/DesignProfileProgressBar";
import { DesignProfileContextLoading } from "./designProfile/DesignProfileContextLoading";
import firebase from "firebase/compat/app";
import { DesignProfileContextError } from "./designProfile/DesignProfileContextError";
import { SegmentFlows, useSegment } from "../util/Segment";
import { getFirstPageOfFlow } from "../pages/designProfile/util/getFirstPageOfFlow";
import { DesignProfileBackButton } from "./designProfile/DesignProfileBackButton";
import { isQuizPage } from "../pages/designProfile/util/isQuizPage";
import { Box } from "@material-ui/core";
import Cookies from "js-cookie";

type DesignProfleFlowContainerProps = {
  page: Page;
  user: firebase.User | null;
  designProfileVersionId: DesignProfileVersionId;
};

export const DesignProfileFlowContainer = ({
  page,
  user,
  designProfileVersionId,
}: DesignProfleFlowContainerProps) => {
  const history = useHistory();
  const segment = useSegment();
  const { next, previous, component } = page;
  const isFirstPage =
    `/design-profile/${page.path}` ===
    getFirstPageOfFlow(designProfileVersionId);

  segment.page(window.location.pathname, {
    fbp: Cookies.get("_fbp") || null,
    fbc: Cookies.get("_fbc") || null,
  });
  if (isFirstPage) {
    segment.trackFlowStarted({ flow_name: SegmentFlows.DESIGN_PROFILE });
  }

  const handleNext = () => {
    if (next) {
      history.push(`/design-profile/${next}`);
    }
  };

  const handlePrev = () => {
    if (previous) {
      history.push(`/design-profile/${previous}`);
    }
  };

  const props = {
    onNext: handleNext,
    onPrevious: handlePrev,
    page,
  };

  const Component = designProfileComponents[component];

  if (!user) {
    return (
      <CenterFullScreen>
        <GenericLoadingSpinner />
      </CenterFullScreen>
    );
  }

  return (
    <AnonymousUserControlWrapper>
      <ThemeProvider theme={theme}>
        <DesignProfileProvider
          user={user}
          designProfileVersionId={designProfileVersionId}
        >
          <HeaderBase>
            <DefaultHeader />
          </HeaderBase>
          <DesignProfileContextError>
            <DesignProfileContextLoading>
              {isQuizPage(page.path) && (
                <Box
                  position="relative"
                  display="flex"
                  justifyContent="center"
                  mt={4}
                >
                  <DesignProfileProgressBar
                    activeStep={page.header_page}
                    disableOnMobile={true}
                  />

                  {!isFirstPage && (
                    <DesignProfileBackButton onPrevious={handlePrev} />
                  )}
                </Box>
              )}
              <Component {...props} />
            </DesignProfileContextLoading>
          </DesignProfileContextError>
          <YZFooter />
        </DesignProfileProvider>
      </ThemeProvider>
    </AnonymousUserControlWrapper>
  );
};
