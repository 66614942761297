import React, { FC, useContext, useEffect, useRef } from "react";
import { Box, Fade, makeStyles } from "@material-ui/core";
import {
  SelectOption,
  ToggleSelectWrapper,
  useTriggerTimeout,
  YZButton,
  YZTypography,
} from "@yardzen-inc/react-common";
import { useHistory } from "react-router";
import { OnboardCtx, smartRedirect } from "../../../util";
import { useAllowAccessToExteriorDesignStep } from "../useAllowAccessToExteriorDesignStep";
import { ASSETS_BASE_URL } from "../../../util/constants/assetsBaseUrl";

const useStyles = makeStyles(({ breakpoints }) => ({
  successBG: {
    background: `url('${ASSETS_BASE_URL}/public/budget-bg.jpg')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    minHeight: "100%",
    padding: "3rem 2rem 4rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [breakpoints.down("sm")]: {
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "initial",
      minHeight: "calc(100vh - 40px)",
    },
  },
  successCard: {
    background: "#fff",
    borderRadius: 8,
    boxShadow: "0 10px 30px rgba(0,0,0,0.30)",
    maxWidth: 700,
    padding: "3rem",
    [breakpoints.down("sm")]: {
      padding: "1.5rem 1rem",
    },
  },
}));

export interface BugdgetCompletePageProps {}

const navOptions: SelectOption[] = [
  {
    value: "checklist/list",
    label: "Element Selection",
  },
  {
    value: "prioritize",
    label: "Let's Prioritize",
  },
];

const BugdgetCompletePage: FC<BugdgetCompletePageProps> = props => {
  const classes = useStyles();
  const allowAccessToExteriorDesign = useAllowAccessToExteriorDesignStep();

  const {
    state: { currentStep, budgetStepComplete },
    setCurrentStep,
    setBudgetStepComplete,
  } = useContext(OnboardCtx);

  const history = useHistory();
  const redirectToRef = useRef<string>("");
  const { triggerTimeout, triggered } = useTriggerTimeout(
    () => history.push(redirectToRef.current),
    200
  );

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onMount, []);

  return (
    <Fade mountOnEnter in={!triggered} timeout={{ enter: 200, exit: 200 }}>
      <Box className={classes.successBG}>
        <Box className={classes.successCard}>
          <YZTypography paragraph variant="h4" type="serif" align="center">
            Your Wish List is ready to go!
          </YZTypography>
          <YZTypography align="center">
            You can edit your Wish List any time before starting your design.
          </YZTypography>
          <ToggleSelectWrapper
            prompt={<></>}
            selectVariant="SINGLE"
            selected=""
            inheritFont
            onSelected={handleNavClick}
            options={navOptions}
            // orientation="horizontal"
            containerProps={{ width: "100%" }}
          />
          <Box textAlign="center" mt={3}>
            <YZButton color="primary" onClick={handleNextClick}>
              {allowAccessToExteriorDesign
                ? "Go to Exterior Design →"
                : "Go to My Goals →"}
            </YZButton>
          </Box>
        </Box>
      </Box>
    </Fade>
  );

  function onMount(): void {
    if (!budgetStepComplete) {
      setBudgetStepComplete(true);
    }
  }

  function handleNavClick(val: string): void {
    redirectToRef.current = `/onboard/budget/${val}?back=complete`;
    triggerTimeout();
  }

  function handleNextClick(): void {
    if (allowAccessToExteriorDesign) {
      setCurrentStep("Exterior Design");
      history.push("/onboard/exterior-design");
    } else {
      setOnboardStateCurrentStep();
      return smartRedirect("v1", "project/Goals");
    }
  }

  function setOnboardStateCurrentStep(): void {
    if (currentStep !== "Yard" && currentStep !== "Budget") return;
    setCurrentStep("Goals");
  }
};

export { BugdgetCompletePage };
export default BugdgetCompletePage;
