import React, { FC } from "react";
import { Box, useMediaQuery, Theme } from "@material-ui/core";
import { useHeaderHeight, usePageDimensions } from "../../util";

export interface OnboardContentProps {}

const OnboardContent: FC<OnboardContentProps> = props => {
  const headerHeight = useHeaderHeight();
  const pageHeight = usePageDimensions()[1];

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Box
      id="onboardContent-root"
      flexGrow={1}
      maxHeight={
        smDown
          ? `${pageHeight - headerHeight}px`
          : `calc(${pageHeight}px - ${headerHeight}px)`
      }
      overflow={"auto"}
      style={{ background: "#FAFAFA" }}
    >
      {props.children}
    </Box>
  );
};

export { OnboardContent };
export default OnboardContent;
