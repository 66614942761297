export type OnboardStep =
  | "Yard"
  | "Budget"
  | "Exterior Design"
  | "Goals"
  | "Inspiration"
  | "Plans"
  | "Preferences"
  | "Plants"
  | "Complete"
  | "Elements";

export type YardName = "front" | "back" | "left" | "right" | "outdoor";

export type YardTaskStatus = "NOT_STARTED" | "INCOMPLETE" | "COMPLETE";

export type ContextErrorValue = "404" | "missingRecords" | string | null;

export interface YardUploadStatus {
  upload: YardTaskStatus;
  keepRemove: YardTaskStatus;
  priorities: YardTaskStatus;
  isSloped: boolean | null;
}

export enum YardUploadStep {
  upload = "upload",
  keepRemove = "keepRemove",
  priorities = "priorities",
}

export type LegacyYardUploadStatus =
  | "NOT_STARTED"
  | "UPLOADS_COMPLETE"
  | "KEEP_COMMENTS_COMPLETE"
  | "REMOVE_COMMENTS_COMPLETE"
  | "COMPLETE";

export interface LegacyYardsStatusObj {
  front?: LegacyYardUploadStatus;
  back?: LegacyYardUploadStatus;
  left?: LegacyYardUploadStatus;
  right?: LegacyYardUploadStatus;
}

export interface YardsStatusObj {
  front?: YardUploadStatus;
  back?: YardUploadStatus;
  left?: YardUploadStatus;
  right?: YardUploadStatus;
  outdoor?: YardUploadStatus;
}

export interface YardPriorities {
  front?: string[];
  back?: string[];
  left?: string[];
  right?: string[];
  outdoor?: string[];
}

export interface MyYardContextState {
  id: string | null;
  projectId: string | null;
  selectedYard: YardName | null;
  yardsStatusObj: YardsStatusObj;
  priorities: YardPriorities;
  contextError: ContextErrorValue;
  informationVerified: boolean;
  currentStep: OnboardStep | null;
  inactiveYardsStatusObj: YardsStatusObj; // Yards 'removed' will be transferred from yardsStatusObj to inactiveYardsStatusObj
  budgetStepComplete: boolean | null;
  exteriorDesignStepComplete: boolean | null;
}

export interface OnboardContextValue {
  state: MyYardContextState;
  _reset: () => Promise<void>;
  fetchOnboardMetadata: (userId: string) => Promise<string | void>;
  setYardsStatusObj: (
    primaryYard: "front" | "back" | "both",
    secondaryYards: ("left" | "right")[],
    isFullYard: boolean
  ) => void;
  setYardActiveState: (yard: YardName, active: boolean) => void;
  setSelectedYard: (yard: YardName | null) => void;
  setYardStepStatus: (
    yard: YardName,
    step: keyof YardUploadStatus,
    status: YardTaskStatus
  ) => Promise<void>;
  setYardPriorities: (yard: YardName, prioritySet: string[]) => void;
  setOnboardContextError: (err: ContextErrorValue) => void;
  setInformationVerified: () => void;
  setCurrentStep: (nextStep: OnboardStep) => void;
  allYardStepsComplete: (yard?: YardName) => boolean;
  swapPrimaryYards: (
    yardToActivate: Exclude<YardName, "left" | "right">
  ) => Promise<void>;
  setBudgetStepComplete: (status: boolean) => void;
  setExteriorDesignStepComplete: (status: boolean) => void;
}

export interface SelectOption {
  value: string;
  mask: string;
  live: boolean;
}
