import * as React from "react";
import { Typography, Theme, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

interface UploadInstructionTemplateProps {
  title: string;
  imgURL: string;
  subtitle: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // margin: theme.spacing(3),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  },
  img: {
    // backgroundColor: "gray",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "28vh",
    minWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      minWidth: "100%",
      minHeight: "28vh",
    },
  },
}));

const UploadInstructionTemplate: React.FunctionComponent<UploadInstructionTemplateProps> = ({
  title,
  imgURL,
  subtitle,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h5">
        {title}
      </Typography>
      <Box
        className={classes.img}
        style={{ backgroundImage: `url(${imgURL})` }}
      />
      <Typography className={classes.subtitle}>{subtitle}</Typography>
    </div>
  );
};

export default UploadInstructionTemplate;
