import React, { FC, useEffect, useContext, useState } from "react";
import useHeaderVariant from "../../util/hooks/useHeaderVariant";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import { OnboardCtx, smartRedirect } from "../../util";
import { YardName } from "./stepData";
import YardCommentsGrid from "./YardCommentsGrid";
import { useHistory } from "react-router";
import transformYardStatusObjKey from "../../util/functions/transformYardStatusObjKey";
import StepWrapper from "./StepWrapper";
import { useAllowAccessToBudgetStep } from "./budget";

export interface OverviewPageTwoProps {}

const OverviewPageTwo: FC<OverviewPageTwoProps> = props => {
  const history = useHistory();
  const userHasAccessToNewBudgetElements = useAllowAccessToBudgetStep();

  const header = useHeaderVariant()[0];
  const subHeader = useHeaderVariant()[1];

  const [loading, setLoading] = useState(true);
  const {
    state: { yardsStatusObj, projectId },
    setCurrentStep,
  } = useContext(OnboardCtx);

  useEffect(() => {
    if (!!projectId) {
      setLoading(false);
    }
  }, [projectId]);

  if (loading) {
    return (
      <Box width="100%" display="flex" justifyContent="center">
        <CircularProgress color="primary" size={75} />
      </Box>
    );
  }

  return (
    <StepWrapper
      previous={() => history.push("/onboard/my-yard/review/1")}
      next={() => {
        if (!userHasAccessToNewBudgetElements) {
          setCurrentStep("Goals");
          smartRedirect("v1", "project/Goals");
          return;
        }

        setCurrentStep("Budget");
        history.push("/onboard/budget");
      }}
      nextButtonText="CONFIRM"
    >
      <Typography variant={header}>My Yard Overview - Page 2/2</Typography>
      <Typography variant={subHeader}>
        Review your submissions to make sure you've covered all the bases!
      </Typography>
      <Box pt={4}>
        <Box>
          <Typography variant={subHeader}>
            <strong>What To Keep:</strong>
          </Typography>
          {Object.keys(yardsStatusObj).map(yard =>
            renderCommentsGrid(yard as YardName, "keep")
          )}
        </Box>
        <Box pt={4}>
          <Typography variant={subHeader}>
            <strong>What To Remove:</strong>
          </Typography>
          {Object.keys(yardsStatusObj).map(yard =>
            renderCommentsGrid(yard as YardName, "remove")
          )}
        </Box>
      </Box>
    </StepWrapper>
  );

  // dynamically render comment grids with ability to upload new images
  function renderCommentsGrid(
    yard: YardName,
    variantSuffix: "keep" | "remove"
  ) {
    if (!projectId) {
      return <></>;
    }
    return (
      <Box key={`${yard}-${variantSuffix}`}>
        <Typography variant={subHeader}>
          {transformYardStatusObjKey(yard)}
        </Typography>
        <YardCommentsGrid
          action={variantSuffix}
          projectId={projectId}
          fileTag="property"
          variant={`${yard}-yard-${variantSuffix}`}
          accept={[".jpg", ".png", ".svg", ".heic"]}
          allowUpload
          deleteable={true}
        />
      </Box>
    );
  }
};

export { OverviewPageTwo };
export default OverviewPageTwo;
