import {
  FormControl,
  InputLabel,
  MenuItemProps,
  Select,
  SelectProps,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { FC } from "react";
import { useMemo } from "react";
import { ReactElement } from "react";
import { useState } from "react";

export interface PaintPickerSelectProps extends SelectProps {
  idPrefix: string;
  children: ReactElement<MenuItemProps>[];
  label: string;
}

const useStyles = makeStyles(theme => ({
  selectControl: {
    minWidth: "120px",
    margin: ".5rem",
  },
  selectInput: {
    backgroundColor: "#fff",
    paddingLeft: "4px",
    letterSpacing: ".3rem",
    borderRadius: 2,
    border: "1px solid #eee",
  },
  selectLabel: {
    cursor: "pointer",
    pointerEvents: "all",
    zIndex: 2,
    paddingLeft: "4px",
    color: "#000000EF",
  },
}));

const PaintPickerSelect: FC<PaintPickerSelectProps> = ({
  children,
  idPrefix,
  label,
  ...props
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const labelId = useMemo(() => `${idPrefix}-select-label`, [idPrefix]);

  return (
    <>
      <FormControl classes={{ root: classes.selectControl }}>
        <InputLabel
          className={classes.selectLabel}
          onClick={() => setOpen(true)}
          focused={open}
          id={labelId}
        >
          {label}
        </InputLabel>
        <Select
          {...props}
          labelId={labelId}
          onOpen={() => setOpen(true)}
          onClose={() => {
            setOpen(false);
          }}
          style={{ borderRadius: 0 }}
          disableUnderline
          classes={{
            select: classes.selectInput,
          }}
          IconComponent={KeyboardArrowDown}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: undefined,
          }}
        >
          {children}
        </Select>
      </FormControl>
    </>
  );
};

export { PaintPickerSelect };
export default PaintPickerSelect;
