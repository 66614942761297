import { setProducts } from "../slices/productSlice";
import { store } from "../store";
import { PriceObject } from "../types/Prices";
import { Product } from "../types/Products";
import { formatProducts } from "./formatProducts";
import { getPriceObjects } from "./getPriceObjects";

export const getProducts = async () => {
  try {
    const res = await fetch(
      `https://us-central1-${process.env.REACT_APP_PROJECTID}.cloudfunctions.net/getStripeProducts`
    );
    const products = await res.json();

    return products;
  } catch (err) {
    window.newrelic.noticeError(err);
    // There needs to be a discussion on global error handling
    // The result would go here
    console.error(err);
  }
};

export const setProductsToStore = async () => {
  try {
    const products: Product[] = await getProducts();
    const priceObjects: PriceObject[] = await getPriceObjects();
    if (
      !products ||
      !products.length ||
      !priceObjects ||
      !priceObjects.length
    ) {
      throw new Error("Failed to retrieve stripe products/prices");
    }

    const formattedProducts = formatProducts({ products, priceObjects });
    store.dispatch(setProducts({ products: formattedProducts }));
  } catch (error) {
    console.error(error);
    throw new Error("Failed to set stripe products/prices to store");
  }
};
