import LogRocket from "logrocket";
import { DesignProfileCtxValue } from "../../designProfile";

interface UpdateDesignProfilePostOrderArgs {
  dsContext: DesignProfileCtxValue | null;
  firstName: string;
  lastName: string;
  email?: string;
}

export const updateDesignProfilePostOrder = async ({
  dsContext,
  firstName,
  lastName,
  email,
}: UpdateDesignProfilePostOrderArgs): Promise<void> => {
  try {
    await dsContext?.updateDesignProfile({
      contactInformation: {
        ...dsContext.designProfile?.contactInformation,
        firstName,
        lastName,
        email: dsContext?.designProfile?.contactInformation?.email,
      },
    } as any);
  } catch (error) {
    window.newrelic.noticeError(error);
    console.error(`Error updating design profile post order: ${error}`);
    await LogRocket.captureMessage(
      `Error updating design profile post order: ${error}`
    );
  }
};
