import { Cookies } from "react-cookie";
import { YardzenCookies } from "./types";

const COOKIE_NAME = `${process.env.REACT_APP_YARDZEN_COOKIES_NAME}`;
const COOKIE_MAX_AGE = 365 * 24 * 60 * 60;
const COOKIE_DOMAIN = `${process.env.REACT_APP_YARDZEN_COOKIES_DOMAIN}`;

function getYardzenCookies(): YardzenCookies {
  const cookies = new Cookies();
  return (
    cookies.get(COOKIE_NAME) || {
      dashboard: { userId: "", state: "" },
      account: { userId: "", state: "" },
    }
  );
}

function setYardzenCookies(value: YardzenCookies) {
  const cookies = new Cookies();
  cookies.set(
    COOKIE_NAME,
    { ...value },
    {
      path: "/",
      domain: COOKIE_DOMAIN,
      secure: true,
      maxAge: COOKIE_MAX_AGE,
    }
  );
}

export { getYardzenCookies, setYardzenCookies };
