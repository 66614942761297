import React from "react";
import { useSelector } from "react-redux";
import { StripeProvider, Elements } from "react-stripe-elements";
import Purchase from "../../components/checkout/Purchase";
import { RootState } from "../../store";
import { Page } from "../../types/DesignProfile";
import { useHistory } from "react-router";
import { getStripeKey } from "../../util/functions/getStripeKey";
import { GenericLoadingSpinner } from "@yardzen-inc/react-common";
import { CenterFullScreen } from "../../components";
import { useDesignProfileCtx } from "../../components/designProfile";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { useAppSelector } from "../../hooks";

interface PurchasePageProps {
  page: Page;
  onNext: () => void;
  onPrevious: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(9),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(4),
    },
  },
}));

export const PurchasePage: React.FC<PurchasePageProps> = ({
  page,
  onNext,
  onPrevious,
}) => {
  const { selectedProduct, selectedSku } = useSelector(
    (state: RootState) => state.products
  );

  const history = useHistory();

  const { isLoading: isDesignProfileLoading } = useDesignProfileCtx();
  const { products } = useAppSelector(state => state.products);

  const { root } = useStyles();

  if (!selectedProduct || !selectedSku) {
    history.push("/design-profile/recommended-package");
    return null;
  }

  // need to make sure products are loaded for purchase page
  if (isDesignProfileLoading || !products || !products.length) {
    return (
      <CenterFullScreen>
        <GenericLoadingSpinner />
      </CenterFullScreen>
    );
  }

  return (
    <StripeProvider apiKey={getStripeKey()}>
      <Box mt={16} className={root}>
        <Elements>
          <Purchase nextStep={onNext} />
        </Elements>
      </Box>
    </StripeProvider>
  );
};
