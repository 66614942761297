import * as React from "react";
import { Snackbar } from "@material-ui/core";
import GenericSnackbarContent from "./GenericSnackbarContent";

interface Props {
  message?: string;
  className?: string;
  in?: boolean;
  orientation?: {
    vertical: "bottom" | "top";
    horizontal: "right" | "left" | "center";
  };
  variant: "success" | "warning" | "error" | "info";
  onClose: () => void;
  timeout?: number | null;
  maxWidth?: string;
}

function GenericSnackBar({
  orientation = {
    vertical: "bottom",
    horizontal: "left",
  },
  message,
  className,
  variant,
  onClose,
  in: _in,
  timeout,
  maxWidth,
}: Props): React.ReactElement {
  const [open, setOpen] = React.useState(_in === undefined ? true : _in);
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    onClose();
    setOpen(false);
  };

  React.useEffect(() => {
    setOpen(_in === undefined ? true : _in);
  }, [_in]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: orientation.vertical,
        horizontal: orientation.horizontal,
      }}
      style={{ maxWidth: maxWidth }}
      open={!!open}
      autoHideDuration={timeout === null ? null : timeout ?? 6000}
      onClose={handleClose}
    >
      <GenericSnackbarContent
        message={message}
        variant={variant}
        className={className}
        onClose={handleClose}
      />
    </Snackbar>
  );
}

export default GenericSnackBar;
