import { makeStyles, Theme, Box } from "@material-ui/core";
import React from "react";
import CreateAccountForm from "../../components/checkout/CreateAccountForm";
import { useDesignProfileCtx } from "../../components/designProfile";

type CreateAccountPageProps = {
  onNext: () => void;
  onPrevious: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "500px",
    margin: "0 auto",
    paddingTop: 50,
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(10),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2),
    },
  },
}));

export const CreateAccountPage: React.FC<CreateAccountPageProps> = ({
  onNext,
  onPrevious,
}) => {
  const classes = useStyles();
  const { designProfile } = useDesignProfileCtx();
  return (
    <Box className={classes.root}>
      <CreateAccountForm
        prefilledEmail={designProfile?.contactInformation?.email || ""}
        goBack={onPrevious}
        nextStep={onNext}
      />
    </Box>
  );
};
