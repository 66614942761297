import { ProductName } from "../../types/Products";
import { ASSETS_BASE_URL } from "./assetsBaseUrl";

export type PackageTypes =
  | "botanical"
  | "whole home"
  | "curb appeal"
  | "full yard"
  | "back yard"
  | "partial yard"
  | "front yard"
  | "uber premium"
  | "small space"
  | "front or backyard starter"
  | "entire yard starter"
  | "lowes";

// Map package type to package url
export const packageUrlMap: Record<PackageTypes, string> = {
  botanical: "botanical",
  "whole home": "outdoor-transformation",
  "curb appeal": "curb-appeal",
  "full yard": "full-yard",
  "back yard": "back-yard",
  "partial yard": "partial-yard",
  "front yard": "front-yard",
  "uber premium": "premium",
  "small space": "small-space",
  "front or backyard starter": "front-or-backyard-starter",
  "entire yard starter": "entire-yard-starter",
  lowes: "lowes",
};

export const BOTANICAL_PACKAGE: PackageTypes = "botanical";
export const OUTDOOR_TRANSFORMATION_PACKAGE: PackageTypes = "whole home";
export const CURB_APPEAL_PACKAGE: PackageTypes = "curb appeal";
export const FULL_YARD_PACKAGE: PackageTypes = "full yard";
export const PARTIAL_YARD_PACKAGE: PackageTypes = "partial yard"; //deprecated for purchase, may still have clients accessing onboarding with partial yard package
export const BACK_YARD_PACKAGE: PackageTypes = "back yard";
export const FRONT_YARD_PACKAGE: PackageTypes = "front yard";
export const UBER_PREMIUM_PACKAGE: PackageTypes = "uber premium";
export const SMALL_SPACE_PACKAGE: PackageTypes = "small space";
export const FRONT_OR_BACKYARD_STARTER_PACKAGE: PackageTypes =
  "front or backyard starter";
export const ENTIRE_YARD_STARTER_PACKAGE: PackageTypes = "entire yard starter";
export const LOWES_PACKAGE: PackageTypes = "lowes";

//display name
export const UBER_PREMIUM_DISPLAY_NAME = "Yardzen Premium";

// Display image URLs for payment confirmed page or invoice item page
export const OUTDOOR_TRANSFORMATION_DISPLAY_URL: string = `${ASSETS_BASE_URL}/public/recommended-package-whole-home.jpg`;
export const CURB_APPEAL_DISPLAY_URL: string = `${ASSETS_BASE_URL}/public/recommended-package-curb-appeal.jpg`;
export const UBER_PREMIUM_DISPLAY_URL: string = `${ASSETS_BASE_URL}/public/recommended-package-uber-premium.png`;
export const SMALL_SPACE_DISPLAY_URL: string = `${ASSETS_BASE_URL}/public/recommended-package-small-space.jpg`;
export const STARTER_FULL_YARD_DISPLAY_URL: string = `${ASSETS_BASE_URL}/public/starter-full-yard.png`;
export const STARTER_FRONT_OR_BACKYARD_DISPLAY_URL: string = `${ASSETS_BASE_URL}/public/starter-front-or-backyard.png`;

// The names of the packages used in the WordPress URLS
export const BACK_YARD_WORDPRESS_NAME: string = "back-yard";
export const BOTANICAL_WORDPRESS_NAME: string = "botanical";
export const CURB_APPEAL_WORDPRESS_NAME: string = "curb-appeal";
export const FRONT_YARD_WORDPRESS_NAME: string = "front-yard";
export const FULL_YARD_WORDPRESS_NAME: string = "full-yard";
export const UBER_PREMIUM_WORDPRESS_NAME: string = "premium";
export const WHOLE_HOME_WORDPRESS_NAME: string = "outdoor-transformation";
export const SMALL_SPACE_WORDPRESS_NAME: string = "small-space";
export const FRONT_OR_BACKYARD_STARTER_WORDPRESS_NAME: string =
  "front-or-backyard-starter";
export const ENTIRE_YARD_STARTER_WORDPRESS_NAME: string = "entire-yard-starter";

export const displayNameForStripePackage: Record<string, string> = {
  [UBER_PREMIUM_PACKAGE]: UBER_PREMIUM_DISPLAY_NAME,
  [OUTDOOR_TRANSFORMATION_PACKAGE]: "Outdoor Transformation",
  [CURB_APPEAL_PACKAGE]: "Curb Appeal",
  [FULL_YARD_PACKAGE]: "Full Yard",
  [BACK_YARD_PACKAGE]: "Back Yard",
  [FRONT_YARD_PACKAGE]: "Front Yard",
  [SMALL_SPACE_PACKAGE]: "Small Space",
  [FRONT_OR_BACKYARD_STARTER_PACKAGE]: "Starter - Front or Backyard",
  [ENTIRE_YARD_STARTER_PACKAGE]: "Starter - Full Yard",
  [BOTANICAL_PACKAGE]: "Botanical",
  "additional revision": "Additional Revision",
};

export const imageUrlForPackage: Record<string, string> = {
  [UBER_PREMIUM_PACKAGE]: UBER_PREMIUM_DISPLAY_URL,
  [OUTDOOR_TRANSFORMATION_PACKAGE]: OUTDOOR_TRANSFORMATION_DISPLAY_URL,
  [CURB_APPEAL_PACKAGE]: CURB_APPEAL_DISPLAY_URL,
  [FULL_YARD_PACKAGE]: `${ASSETS_BASE_URL}/public/recommended-package-full-yard.jpg`,
  [BACK_YARD_PACKAGE]: `${ASSETS_BASE_URL}/public/recommended-package-back-yard.jpg`,
  [FRONT_YARD_PACKAGE]: `${ASSETS_BASE_URL}/public/recommended-package-front-yard.jpg`,
  [SMALL_SPACE_PACKAGE]: SMALL_SPACE_DISPLAY_URL,
  "starter - front or backyard": STARTER_FRONT_OR_BACKYARD_DISPLAY_URL,
  "starter - full yard": STARTER_FULL_YARD_DISPLAY_URL,
  [BOTANICAL_PACKAGE]: `${ASSETS_BASE_URL}/public/recommended-package-botanical.jpg`,
  premium: `${ASSETS_BASE_URL}/public/recommended-package-premium.jpg`,
  "additional revision": `${ASSETS_BASE_URL}/public/additional_revision.jpg`,
  [LOWES_PACKAGE]: `${ASSETS_BASE_URL}/public/lowes.png`,
};

export const packageNameForWordpressRedirect: Record<string, string> = {
  [ProductName.BackYardPackage]: BACK_YARD_WORDPRESS_NAME,
  [ProductName.FrontYardPackage]: FRONT_YARD_WORDPRESS_NAME,
  [ProductName.WholeHomePackage]: WHOLE_HOME_WORDPRESS_NAME,
  [ProductName.FullYardPackage]: FULL_YARD_WORDPRESS_NAME,
  [ProductName.CurbAppealPackage]: CURB_APPEAL_WORDPRESS_NAME,
  [ProductName.BotanicalPackage]: BOTANICAL_WORDPRESS_NAME,
  [ProductName.UberPremium]: UBER_PREMIUM_WORDPRESS_NAME,
  [ProductName.StarterFrontOrBackyardPackage]: FRONT_OR_BACKYARD_STARTER_WORDPRESS_NAME,
  [ProductName.StarterFullYardPackage]: ENTIRE_YARD_STARTER_WORDPRESS_NAME,
  [ProductName.SmallSpacePackage]: SMALL_SPACE_WORDPRESS_NAME,
};
