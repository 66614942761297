import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export interface IOffsetImagesProps {
  bottomImageURI: string;
  topImageURI: string;
  style?: React.CSSProperties;
  horitizontalOffset?: number; // Top image, grid column
  verticalOffset?: number; // Bottom image, padding-top percentage
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    position: "relative",
    maxWidth: "500px",
  },
  bottomImageContainer: {
    gridColumn: "1 / span 8",
    gridRow: 1,
    maxWidth: "100%",
  },
  topImageContainer: {
    gridRow: 1,
    zIndex: 1,
    maxWidth: "100%",
  },
}));

export function OffsetImages({
  bottomImageURI,
  topImageURI,
  style,
  horitizontalOffset = 3,
  verticalOffset = 20,
}: IOffsetImagesProps) {
  const classes = useStyles();

  return (
    <div style={{ ...style }} className={classes.root}>
      <div
        style={{ gridColumn: `${horitizontalOffset} / -1` }}
        className={classes.topImageContainer}
      >
        <img
          style={{
            width: style?.width,
            maxWidth: style?.maxWidth,
          }}
          src={topImageURI}
          alt="Design rendering"
        />
      </div>
      <div
        style={{ paddingTop: `${verticalOffset}%` }}
        className={classes.bottomImageContainer}
      >
        <img
          style={{ width: style?.width, maxWidth: style?.maxWidth }}
          src={bottomImageURI}
          alt="Design rendering"
        />
      </div>
    </div>
  );
}

export default OffsetImages;
