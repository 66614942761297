import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  makeStyles,
  Theme,
  IconButton,
  Tooltip,
  Divider,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Media } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import HEICPlaceHolder from "../../../mediaDisplay/HEICPlaceHolder";

export interface MediaItemProps {
  media: Media;
  removeMediaFromArray: (id: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  inspirationCommentField: {
    width: "100%",
  },
  mediaGridContainer: {
    marginTop: 30,
  },
  deleteIconButton: {
    backgroundColor: "#fff",
    boxShadow: "1px 2px #f5f5f5",
  },
  deleteIcon: {
    color: "#c28282",
  },
  checkIcon: {
    color: "#B8C7B8",
  },
  imageModal: {
    width: "100%",
  },
  uploadedMedia: {
    width: "90%",
    height: 120,
    objectFit: "cover",
    border: "1px solid #B8C7B8",
    borderRadius: "5px",
  },
  mediaItemContainer: {
    marginTop: 30,
    marginBottom: 30,
  },
  gridItemContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textFieldIconButtonContainer: {
    position: "absolute",
    right: "20%",
  },
}));

const MediaItem: React.FC<MediaItemProps> = ({
  media,
  removeMediaFromArray,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(media.description || "");

  useEffect(() => {
    let timeout: any = null;

    if (value) {
      timeout = setTimeout(() => {
        firebase
          .firestore()
          .collection("media")
          .doc(media.id)
          .set({ description: value }, { merge: true });
      }, 500);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div>
      <Divider />
      <div className={classes.mediaItemContainer}>
        <Grid
          container
          spacing={3}
          alignItems="center"
          alignContent="center"
          justify="center"
        >
          <Grid item xs={12} sm={3} className={classes.gridItemContainer}>
            {media.fileType.includes("hei") ? (
              <HEICPlaceHolder />
            ) : (
              <div className={classes.gridItemContainer}>
                <img
                  className={classes.uploadedMedia}
                  src={media.downloadURL}
                  alt="Inspiration"
                />
              </div>
            )}
          </Grid>
          <Grid item xs={11} sm={8} className={classes.gridItemContainer}>
            <TextField
              className={classes.inspirationCommentField}
              label="What do you like about this photo?"
              variant="outlined"
              multiline
              rows={3}
              value={value}
              helperText={`${value.length}/154`}
              onChange={({ target: { value } }) => {
                if (value.length <= 154) setValue(value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={1} className={classes.gridItemContainer}>
            <div>
              <Tooltip title="Delete media">
                <IconButton
                  className={classes.deleteIconButton}
                  onClick={() => handleDeleteMediaButtonClick(media.id)}
                >
                  <DeleteOutlineIcon className={classes.deleteIcon} />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );

  function handleDeleteMediaButtonClick(mediaId: string) {
    media.delete();
    removeMediaFromArray(mediaId);
  }
};

export default MediaItem;
