import * as React from "react";
import GeocodeBar, { ObjWithGeocode, Geocode } from "./GeocodeBar";
import { Box, BoxProps } from "@material-ui/core";
import { useMaps } from "./util/useMaps";

export interface IndependantGeocodeBarProps {
  width: string;
  boxPadding?: number;
  containerBoxProps?: BoxProps;
  autoFocus?: boolean;
  immutableLocations?: boolean;
  initialQuery?: string;
  focused?: boolean;
  setFocused?: (l: boolean) => void;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onSelectedLocationChange?: (
    location: google.maps.GeocoderResult,
    geocode: Geocode
  ) => Promise<any>;
}

const IndependantGeocodeBar: React.FC<IndependantGeocodeBarProps> = (
  props: IndependantGeocodeBarProps
) => {
  const google = useMaps();

  const geocoder = React.useMemo(() => {
    if (google) {
      return new google.maps.Geocoder();
    }

    return null;
  }, [google]);

  return (
    <Box width={props.width} p={props.boxPadding}>
      <GeocodeBar
        onChange={props.onChange}
        initialQuery={props.initialQuery}
        autoFocus={props.autoFocus}
        focused={props.focused}
        setFocused={props.setFocused}
        containerBoxProps={{
          position: "static",
          width: props.width,
          maxWidth: "100vw !important",
          ...props.containerBoxProps,
        }}
        mpController={getMpController()}
        onSelectedLocationChange={props.onSelectedLocationChange}
        immutableLocations={props.immutableLocations}
      />
    </Box>
  );

  function getMpController(): ObjWithGeocode | null {
    if (!geocoder) {
      return null;
    }

    return { geocode };
  }

  async function geocode(
    req: google.maps.GeocoderRequest
  ): Promise<{
    results: google.maps.GeocoderResult[];
    status: google.maps.GeocoderStatus;
  }> {
    if (!geocoder) {
      return {
        results: [],
        status: (google as any).maps.GeocoderStatus.ERROR,
      };
    }

    return new Promise(resolve => {
      geocoder.geocode(req, results => {
        return resolve({ results } as any);
      });
    });
  }
};

IndependantGeocodeBar.defaultProps = {
  boxPadding: 1,
};

export { IndependantGeocodeBar };
export default IndependantGeocodeBar;
