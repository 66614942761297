import { DesignProfileQuestion } from "../../../api/apiTypes";
import {
  STANDARD_YARD,
  YardSizeTypes,
} from "../../../util/constants/yardSizeTypes";
import { YARD_SIZE_PATH } from "../constants/paths";

/**
 * Get the initial yard size value from the design profile survey.
 * Display the fallbackYardSize string if a value can't be found.
 * @param questionResponses An array of DesignProfileQuestions to search for
 * a response in.
 * @returns The initial yard size value
 */
export const getYardSizeResponse = (
  questionResponses: DesignProfileQuestion[]
): YardSizeTypes => {
  // We expect this response to be a string, but the response can be
  // type any, so we'll toString it first.
  const yardSizeQuizResponses = questionResponses
    .find(group => group.path === YARD_SIZE_PATH)
    ?.response?.toString()
    .toUpperCase();

  return yardSizeQuizResponses?.length
    ? (yardSizeQuizResponses as YardSizeTypes)
    : STANDARD_YARD;
};
