import { Theme, makeStyles } from "@material-ui/core";
import { AcceptTermsForm } from "./AcceptTermsForm";

interface UserTermsOfServiceProps {
  userId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  linkText: {
    cursor: "pointer",
    color: theme.palette.primary.dark,
    textDecoration: "underline",
    fontWeight: 500,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "1rem",
  },
  container: {
    maxWidth: "40rem",
    margin: "1.25rem",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "20rem",
    },
  },
  header: {
    fontSize: "1.5rem",
    fontWeight: 500,
    textAlign: "center",
  },
  paragraph: {
    paddingTop: "1rem",
  },
}));

export const TermsOfService = ({ userId }: UserTermsOfServiceProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <h1 className={classes.header}>
          Changes to Yardzen's Terms & Privacy Policy
        </h1>
        <p className={classes.paragraph}>
          Please review our Terms of Service and Privacy Policy which govern
          your access to and use of our services, including our website, mobile
          applications, and any other products or services offered by Yardzen.
          By accessing the services, you agree to Yardzen's{" "}
          <a
            href="https://yardzen.com/electronic-signature-disclosures"
            target="_blank"
            rel="noreferrer"
            className={classes.linkText}
          >
            Consent to Electronic Signature and Disclosures
          </a>
          ,{" "}
          <a
            href="https://yardzen.com/terms-and-conditions"
            target="_blank"
            rel="noreferrer"
            className={classes.linkText}
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            href="https://yardzen.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className={classes.linkText}
          >
            Privacy Policy
          </a>
          .
        </p>
        <div className={classes.paragraph}>
          <AcceptTermsForm userId={userId} />
        </div>
      </div>
    </div>
  );
};
