export class FetchError extends Error {
  public readonly statusMessage: string;
  public readonly statusCode: number;

  constructor(public readonly response: Response, message?: string) {
    super(
      `${message ? message + ": " : ""}${response.status.toString()} - ${
        response.statusText
      }`
    );

    this.statusMessage = response.statusText;
    this.statusCode = response.status;
  }
}
