import availableStatesConstant from "../constants/availableStatesConstant";

const useAvailableStates = (): string[] => {
  const availableStates = availableStatesConstant;

  return availableStates.reduce((acc: string[], st) => {
    if (st.live) {
      return [...acc, st.value];
    }
    return acc;
  }, []);
};

export { useAvailableStates };
