import React, { FC } from "react";
import {
  Box,
  useMediaQuery,
  Theme,
  Drawer,
  makeStyles,
} from "@material-ui/core";
import { useHeaderHeight, usePageDimensions } from "../../util";
import OnboardStepMenu from "../OnboardStepMenu";

export interface OnboardSidebarProps {
  sidebarOpen: boolean;
  onClose: () => void;
  setHelpModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  sideBarWidth: {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    background: "#fff",
    [theme.breakpoints.up("sm")]: {
      width: "250px",
      minWidth: "250px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "60vw",
      minWidth: "60vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "92vw",
      minWidth: "92vw",
    },
  },
}));

const OnboardSidebar: FC<OnboardSidebarProps> = props => {
  const headerHeight = useHeaderHeight();
  const pageHeight = usePageDimensions()[1];
  const classes = useStyles();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  if (smDown) {
    return (
      <Drawer keepMounted open={!!props.sidebarOpen} onClose={props.onClose}>
        <Box
          display="inline-flex"
          flexDirection="column"
          className={classes.sideBarWidth}
        >
          <OnboardStepMenu
            closeSidebar={props.onClose}
            setHelpModalOpen={props.setHelpModalOpen}
          />
        </Box>
      </Drawer>
    );
  }

  return (
    <Box
      bgcolor="#F7F7F7"
      height={`calc(${pageHeight}px - ${headerHeight}px)`}
      className={classes.sideBarWidth}
      boxShadow={2}
      pt="4px"
    >
      <OnboardStepMenu />
    </Box>
  );
};

export { OnboardSidebar };
export default OnboardSidebar;
