import { FormFieldFilledProps, SegmentClient } from "../../../util/Segment";

interface SegmentBlurHandlerArgs {
  segment: SegmentClient;
  formFieldProps: FormFieldFilledProps;
  value: any;
}

export const segmentBlurHandler = ({
  segment,
  formFieldProps,
  value,
}: SegmentBlurHandlerArgs): (() => any) | undefined => {
  if (value) {
    return () => {
      segment.trackFormFieldFilled(formFieldProps);
    };
  }

  return undefined;
};
