import { useMemo } from "react";
import { useProfile, useUser } from "../../util";
import {
  CURB_APPEAL_PACKAGE,
  OUTDOOR_TRANSFORMATION_PACKAGE,
  UBER_PREMIUM_PACKAGE,
} from "../../util/constants/packageTypes";

export interface UseAllowAccessToExteriorDesignStep {
  (): boolean | null;
}

const useAllowAccessToExteriorDesignStep: UseAllowAccessToExteriorDesignStep = () => {
  const profile = useProfile(useUser()[1])[1];

  const allowedAccess = useMemo(allowAccess, [profile]);
  return allowedAccess;

  function allowAccess(): boolean | null {
    // If profile is not loaded yet, return null
    if (!profile) return null;

    // If the package is neither whole home nor curb appeal, return false
    if (
      profile.package !== OUTDOOR_TRANSFORMATION_PACKAGE &&
      profile.package !== CURB_APPEAL_PACKAGE &&
      profile.package !== UBER_PREMIUM_PACKAGE
    ) {
      return false;
    }

    // Else return true
    return true;
  }
};

export { useAllowAccessToExteriorDesignStep };
export default useAllowAccessToExteriorDesignStep;
