export class MapController {
  private readonly _map: google.maps.Map;
  private _geocoder?: google.maps.Geocoder;
  private _markers: google.maps.Marker[] = [];

  constructor(map: google.maps.Map) {
    this._map = map;
    this._init();
  }

  public destroy = (): void => {
    this.map.unbindAll();
  };

  public get markers(): google.maps.Marker[] {
    return [...this._markers];
  }

  public get map(): google.maps.Map {
    return this._map;
  }

  public get geocoder(): google.maps.Geocoder {
    if (!this._geocoder) {
      this._geocoder = new google.maps.Geocoder();
    }

    return this._geocoder;
  }

  public geocode = async (
    opts: google.maps.GeocoderRequest
  ): Promise<{
    results: google.maps.GeocoderResult[];
    status: google.maps.GeocoderStatus;
  }> => {
    return await new Promise<{
      results: google.maps.GeocoderResult[];
      status: google.maps.GeocoderStatus;
    }>(resolve => {
      this.geocoder.geocode(opts, (results, status) => {
        return resolve({ results: results ?? [], status });
      });
    });
  };

  public addMarker = (
    latLng: google.maps.LatLngLiteral | google.maps.LatLng,
    title?: string
  ): google.maps.Marker => {
    const marker = new google.maps.Marker({
      position: latLng,
      map: this.map,
      title,
    });

    this._markers.push(marker);
    return marker;
  };

  public removeMarker = (m: google.maps.Marker): void => {
    if (!m || !(m instanceof google.maps.Marker)) {
      throw new Error("m must be of type google.maps.Marker");
    }

    const markIndex = this._markers.findIndex(ma => ma === m);

    if (markIndex >= 0) {
      this._markers.splice(markIndex, 1);
    }

    m.setMap(null);
  };

  private _init = () => {
    this.map.addListener("click", this._handleClick);
  };

  private _handleClick = (
    e: google.maps.MouseEvent | google.maps.IconMouseEvent
  ) => {};
}

export default MapController;
