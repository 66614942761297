import { useEffect, useState } from "react";
import { ProfileProperties } from "@yardzen-inc/models";
import { fetchExperienceVersionByProfileProps } from "../firebase";
import { ExperienceVersionType } from "@yardzen-inc/react-common";

const useGetProjectExperienceVersion = (profileProps: ProfileProperties) => {
  const [projectExpVersion, setProjectExpVersion] = useState<
    ExperienceVersionType | null | false
  >(null);

  useEffect(() => {
    if (!profileProps || !!projectExpVersion) return;

    async function fetchExpVersion(profileProps: ProfileProperties) {
      const fetchedExpVersion = await fetchExperienceVersionByProfileProps(
        profileProps
      );

      if (fetchedExpVersion) {
        setProjectExpVersion(fetchedExpVersion);
      } else {
        setProjectExpVersion(false);
      }
    }
    fetchExpVersion(profileProps);
  }, [projectExpVersion, profileProps]);

  return projectExpVersion;
};

export { useGetProjectExperienceVersion };
export default useGetProjectExperienceVersion;
