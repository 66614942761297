import { DesignProfileQuizSelectionsProps } from "../../../types/DesignProfile";

interface HandleKeyUpOnQuizOptionArgs
  extends Pick<
    DesignProfileQuizSelectionsProps,
    "selection" | "optionKey" | "handleSelect"
  > {
  event: React.KeyboardEvent<HTMLLabelElement>;
}

/**
 * Fires the given handleSelect function with the given arguments
 * if the enter key is pressed.
 * @param event The key up event from the DOM
 * @param selection The selection value for the option
 * @param optionKey The key for the selection value
 * @param handleSelect A function to fire if the appropriate key is pressed
 */
export const handleKeyUpOnQuizOption = ({
  event,
  selection,
  optionKey,
  handleSelect,
}: HandleKeyUpOnQuizOptionArgs) => {
  if (event.key.toLowerCase() === "enter") {
    handleSelect(selection, optionKey);
  }
};
