import React, { createContext, useReducer, useContext } from "react";
import {
  MyYardContextState,
  YardName,
  OnboardContextValue,
  YardsStatusObj,
  OnboardStep,
} from "../../components/onboard/stepData";
import { UserCtx } from "./UserContext";
import {
  fetchOnboardStateDocByUserId,
  fetchProfileDocByUserId,
  createOnboardStateDoc,
  updateOnboardStateDoc,
} from "../firebase";
import { getProjectDoc } from "../firebase/getProjectDoc";
import LogRocket from "logrocket";
import {
  ContextErrorValue,
  LegacyYardsStatusObj,
  YardTaskStatus,
  YardUploadStatus,
} from "../../components/onboard/stepData/types";
import genericAlert, {
  CANNOT_ONBOARD_ALERT_STRING,
} from "../functions/genericAlert";
import { Profile } from "@yardzen-inc/models";
import { CURB_APPEAL_PACKAGE } from "../constants/packageTypes";

interface OnboardProviderProps {
  children: React.ReactNode;
}

const initialYardUploadState: YardUploadStatus = {
  upload: "NOT_STARTED",
  keepRemove: "NOT_STARTED",
  priorities: "NOT_STARTED",
  isSloped: false,
};

const initialYardStatusObj: YardsStatusObj = {
  front: initialYardUploadState,
  back: initialYardUploadState,
  left: initialYardUploadState,
  right: initialYardUploadState,
};

const curbAppealInitialYardStatusObj: YardsStatusObj = {
  front: initialYardUploadState,
};

const OnboardCtx = createContext<OnboardContextValue>(
  {} as OnboardContextValue
);
const OnboardConsumer = OnboardCtx.Consumer;

// initial state value to be provided to reducer
const initialState: MyYardContextState = {
  id: null,
  projectId: null,
  selectedYard: null,
  yardsStatusObj: {},
  inactiveYardsStatusObj: {},
  priorities: {},
  contextError: null,
  informationVerified: false,
  currentStep: null,
  budgetStepComplete: null,
  exteriorDesignStepComplete: null,
};

const OnboardProvider: React.FC<OnboardProviderProps> = (
  props: OnboardProviderProps
) => {
  const Component = OnboardCtx.Provider;
  const user = useContext(UserCtx);

  // this reducer executes dispatch function calls
  // all state modifications are to be done here via functions that call dispatch
  const onboardReducer = (
    state: MyYardContextState,
    action: { payload: any; type: string }
  ): MyYardContextState => {
    switch (action.type) {
      case "SET_PRIORITIES":
        if (!action.payload) return state;
        return { ...state, priorities: action.payload };

      case "FETCH_ONBOARD_METADATA":
        if (!action.payload) return state;
        return { ...state, ...action.payload };

      case "SET_YARD_STATUS_OBJ":
        if (!action.payload) return state;
        return { ...state, yardsStatusObj: action.payload };
      case "SET_INACTIVE_YARD_STATUS_OBJ":
        if (!action.payload) return state;
        return { ...state, inactiveYardsStatusObj: action.payload };

      case "SELECT_YARD":
        if (!action.payload) return state;
        return { ...state, selectedYard: action.payload };

      case "UPDATE_YARD_STATUS":
        if (!action.payload.yard) return state;

        return {
          ...state,
          yardsStatusObj: {
            ...state.yardsStatusObj,

            [action.payload.yard]: {
              ...state.yardsStatusObj[
                action.payload.yard as keyof YardsStatusObj
              ],
              [action.payload.subStep]: action.payload.status,
            },
          },
        };

      case "SET_INFORMATION_VERIFIED":
        if (!action.payload) return state;
        return { ...state, informationVerified: action.payload };

      case "SET_CURRENT_STEP":
        if (!action.payload) return state;
        return { ...state, currentStep: action.payload };

      case "SET_ERROR":
        if (!action.payload) return state;
        return { ...state, contextError: action.payload };
      case "SET_BUDGET_STEP_COMPLETE":
        if (!action.payload) return state;
        return { ...state, budgetStepComplete: action.payload.status };
      case "SET_EXTERIOR_DESIGN_STEP_COMPLETE":
        if (!action.payload) return state;
        return { ...state, exteriorDesignStepComplete: action.payload.status };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(onboardReducer, initialState);

  // context state and all functions that call dispatch to be placed here
  // so that all context consumers have access to them
  const onboardContextValue: OnboardContextValue = {
    state,
    fetchOnboardMetadata,
    setYardsStatusObj,
    setYardActiveState,
    setSelectedYard,
    setYardPriorities,
    setOnboardContextError,
    setInformationVerified,
    allYardStepsComplete,
    setCurrentStep,
    setYardStepStatus,
    swapPrimaryYards,
    setBudgetStepComplete,
    setExteriorDesignStepComplete,
    _reset,
  };

  return <Component value={onboardContextValue}>{props.children}</Component>;

  // fetches onboardState from firebase, updates context state, and
  // returns onboardState document id
  // if no onboardState found, creates one
  // TODO: extract all calls to firebase to API file
  async function fetchOnboardMetadata(userId: string): Promise<string | void> {
    if (!userId) {
      throw new Error(
        "No userId found in UserCtx when trying to fetch onboardState doc"
      );
    }

    try {
      const snap = await fetchOnboardStateDocByUserId(userId);

      if (snap === false) {
        throw new Error(
          `Error fetching onboard state doc for user with id ${userId}`
        );
      }

      if (snap.empty) {
        // get profile data to make sure it exists
        const profileDocRef = await fetchProfileDocByUserId(userId);

        if (!profileDocRef.exists) {
          throw new Error(
            `No profile document found in firebase for user with id ${userId} when creating onboardState`
          );
        }

        let projectDoc = await getProjectDoc(profileDocRef.id);

        if (!projectDoc) {
          throw new Error(
            `No project document found in firebase for user ${userId}`
          );
        }

        const profile = profileDocRef.data() as Profile;
        const packagePurchased = profile ? profile.package : null;

        // create new onboardState in firebase
        const newOnboardDoc = await createOnboardStateDoc({
          userId,
          projectId: projectDoc.id,
          priorities: {},
          yardsStatusObj:
            packagePurchased === CURB_APPEAL_PACKAGE
              ? curbAppealInitialYardStatusObj
              : {},
          inactiveYardsStatusObj:
            packagePurchased === CURB_APPEAL_PACKAGE
              ? {}
              : initialYardStatusObj,
          selectedYard:
            packagePurchased === CURB_APPEAL_PACKAGE ? "front" : null,
          informationVerified: false,
          currentStep: "Yard",
        });

        dispatch({
          type: "FETCH_ONBOARD_METADATA",
          payload: {
            id: newOnboardDoc.id,
            projectId: projectDoc.id,
            yardsStatusObj: {},
            priorities: {},
            selectedYard: null,
            currentStep: "Yard",
          },
        });

        return newOnboardDoc.id;
      } else {
        const id = snap.docs[0].id;
        const onboardMetadata = snap.docs[0].data() as MyYardContextState;

        const {
          projectId,
          yardsStatusObj,
          selectedYard,
          priorities,
          inactiveYardsStatusObj,
          informationVerified,
          currentStep,
          budgetStepComplete,
          exteriorDesignStepComplete,
        } = onboardMetadata;

        const updatedYardStatusObj = transformLegacyYardsStatusObj(
          yardsStatusObj
        );

        dispatch({
          type: "FETCH_ONBOARD_METADATA",
          payload: {
            id,
            projectId,
            yardsStatusObj: updatedYardStatusObj,
            inactiveYardsStatusObj: inactiveYardsStatusObj || {},
            selectedYard,
            priorities,
            informationVerified,
            currentStep,
            budgetStepComplete,
            exteriorDesignStepComplete,
          },
        });

        return snap.docs[0].id;
      }
    } catch (err) {
      window.newrelic.noticeError(err);
      await reportError(err, "missingRecords");
    }
  }

  function transformLegacyYardsStatusObj(
    legacyStatusObj: LegacyYardsStatusObj | YardsStatusObj
  ): YardsStatusObj {
    const legacyObjKeys = Object.keys(legacyStatusObj);

    const isLegacyStatus = legacyObjKeys.some(key => {
      return (
        typeof legacyStatusObj[key as keyof LegacyYardsStatusObj] === "string"
      );
    });

    if (!isLegacyStatus) return legacyStatusObj as YardsStatusObj;

    const transformMap = {
      NOT_STARTED: initialYardUploadState,
      UPLOADS_COMPLETE: {
        ...initialYardUploadState,
        priorities: "COMPLETE",
        upload: "COMPLETE",
      },
      KEEP_COMMENTS_COMPLETE: {
        ...initialYardUploadState,
        upload: "COMPLETE",
        priorities: "COMPLETE",
        keep: "COMPLETE",
      },
      REMOVE_COMMENTS_COMPLETE: {
        upload: "COMPLETE",
        priorities: "COMPLETE",
        keep: "COMPLETE",
        remove: "COMPLETE",
      },
      COMPLETE: {
        upload: "COMPLETE",
        priorities: "COMPLETE",
        keep: "COMPLETE",
        remove: "COMPLETE",
      },
    };

    let updatedYardStatusObj = {};

    legacyObjKeys.forEach(key => {
      const legacyYardState =
        legacyStatusObj[key as keyof LegacyYardsStatusObj];
      if (typeof legacyYardState !== "string") return;
      const updatedYardState =
        transformMap[legacyYardState as keyof typeof transformMap];

      updatedYardStatusObj = {
        ...updatedYardStatusObj,
        [key]: updatedYardState,
      };
    });

    return updatedYardStatusObj;
  }

  async function setBudgetStepComplete(status: boolean = true) {
    await updateOnboardStateDoc(state.id as string, {
      budgetStepComplete: status,
    });

    dispatch({ type: "SET_BUDGET_STEP_COMPLETE", payload: { status } });
  }

  async function setExteriorDesignStepComplete(status: boolean = true) {
    await updateOnboardStateDoc(state.id as string, {
      exteriorDesignStepComplete: status,
    });

    dispatch({
      type: "SET_EXTERIOR_DESIGN_STEP_COMPLETE",
      payload: { status },
    });
  }

  // once a user selects the yards that will be included in the design, this function
  // sets the yards status object to be used to track their progress
  async function setYardsStatusObj(
    primaryYard: "front" | "back" | "both",
    secondaryYards: YardName[],
    isFullYard: boolean
  ): Promise<void> {
    try {
      const newYardStatusObj: YardsStatusObj = {};

      if (isFullYard || primaryYard === "both") {
        newYardStatusObj["front"] =
          state.yardsStatusObj.front || initialYardUploadState;
        newYardStatusObj["back"] =
          state.yardsStatusObj.back || initialYardUploadState;
      } else {
        newYardStatusObj[primaryYard] =
          state.yardsStatusObj[primaryYard] || initialYardUploadState;
      }

      secondaryYards.forEach(yard => {
        newYardStatusObj[yard] =
          state.yardsStatusObj[yard] || initialYardUploadState;
      });

      await updateOnboardStateDoc(state.id as string, {
        yardsStatusObj: newYardStatusObj,
      });

      dispatch({ type: "SET_YARD_STATUS_OBJ", payload: newYardStatusObj });
    } catch (err) {
      window.newrelic.noticeError(err);
      await reportError(err);
    }
  }

  async function swapPrimaryYards(
    yardToActivate: Exclude<YardName, "left" | "right">
  ) {
    const yardToDeactivate = yardToActivate === "front" ? "back" : "front";
    const yardStateToActivate = state?.inactiveYardsStatusObj[yardToActivate];

    const yardStateToDeactivate = state?.yardsStatusObj[yardToDeactivate];

    const newYardStatusObj = {
      ...state.yardsStatusObj,
      [yardToActivate]: yardStateToActivate || initialYardUploadState,
    };
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete newYardStatusObj[yardToDeactivate];

    const newInactiveYardStatusObj = {
      ...state.inactiveYardsStatusObj,
      [yardToDeactivate]: yardStateToDeactivate,
    };

    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete newInactiveYardStatusObj[yardToActivate];

    await updateOnboardStateDoc(state.id as string, {
      yardsStatusObj: newYardStatusObj,
      inactiveYardsStatusObj: newInactiveYardStatusObj,
    });

    dispatch({
      type: "SET_INACTIVE_YARD_STATUS_OBJ",
      payload: newInactiveYardStatusObj,
    });

    dispatch({
      type: "SET_YARD_STATUS_OBJ",
      payload: newYardStatusObj,
    });
  }

  function setYardActiveState(yard: YardName, active: boolean) {
    const yardsStateFromStatusObj = state?.yardsStatusObj[yard];
    const yardFromInactiveStatusObj = state?.inactiveYardsStatusObj[yard];

    const yardIsAlreadyActive = active && !!yardsStateFromStatusObj;
    const yardIsAlreadyInactive = !active && !!yardFromInactiveStatusObj;

    if (yardIsAlreadyActive || yardIsAlreadyInactive) {
      return;
    }

    if (active) {
      return activateYard(yard);
    } else {
      return deactivateYard(yard);
    }
  }

  async function activateYard(yard: YardName) {
    const yardState = state?.inactiveYardsStatusObj[yard];

    const newYardStatusObj = {
      ...state.yardsStatusObj,
      [yard]: yardState || initialYardUploadState,
    };

    const newInactiveYardStatusObj = {
      ...state.inactiveYardsStatusObj,
    };
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete newInactiveYardStatusObj[yard];

    await updateOnboardStateDoc(state.id as string, {
      yardsStatusObj: newYardStatusObj,
      inactiveYardsStatusObj: newInactiveYardStatusObj,
    });

    dispatch({
      type: "SET_INACTIVE_YARD_STATUS_OBJ",
      payload: newInactiveYardStatusObj,
    });

    dispatch({
      type: "SET_YARD_STATUS_OBJ",
      payload: newYardStatusObj,
    });
  }

  async function deactivateYard(yard: YardName) {
    const yardState = state?.yardsStatusObj[yard];

    const newInactiveYardStatusObj = {
      ...state.inactiveYardsStatusObj,
      [yard]: yardState || initialYardUploadState,
    };

    const newYardStatusObj = {
      ...state.yardsStatusObj,
    };

    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete newYardStatusObj[yard];

    await updateOnboardStateDoc(state.id as string, {
      yardsStatusObj: newYardStatusObj,
      inactiveYardsStatusObj: newInactiveYardStatusObj,
    });

    dispatch({
      type: "SET_INACTIVE_YARD_STATUS_OBJ",
      payload: newInactiveYardStatusObj,
    });

    dispatch({
      type: "SET_YARD_STATUS_OBJ",
      payload: newYardStatusObj,
    });
  }

  async function setYardPriorities(
    yard: string,
    prioritySet: string[]
  ): Promise<void> {
    try {
      const newPrioritiesObj = {
        ...state.priorities,
        [yard]: prioritySet,
      };

      await updateOnboardStateDoc(state.id as string, {
        priorities: newPrioritiesObj,
      });

      dispatch({ type: "SET_PRIORITIES", payload: newPrioritiesObj });
    } catch (err) {
      window.newrelic.noticeError(err);
      await reportError(err);
    }
  }

  // selects a yard for uploading/commenting
  async function setSelectedYard(yard: YardName | null): Promise<void> {
    try {
      await updateOnboardStateDoc(state.id as string, {
        selectedYard: yard,
      });

      dispatch({ type: "SELECT_YARD", payload: yard });
    } catch (err) {
      window.newrelic.noticeError(err);
      await reportError(err);
    }
  }

  async function setYardStepStatus(
    yard: YardName,
    step: keyof YardUploadStatus,
    status: YardTaskStatus
  ): Promise<void> {
    const yardState = state.yardsStatusObj[yard];
    if (!yardState || yardState[step] === status) {
      return;
    }

    try {
      await updateOnboardStateDoc(state.id as string, {
        yardsStatusObj: {
          ...state.yardsStatusObj,
          [yard]: {
            ...yardState,
            [step]: status,
          },
        },
      });

      dispatch({
        type: "UPDATE_YARD_STATUS",
        payload: {
          yard,
          subStep: step,
          status: status,
        },
      });
    } catch (err) {
      window.newrelic.noticeError(err);
      await reportError(err);
    }
  }

  async function setInformationVerified(): Promise<void> {
    try {
      await updateOnboardStateDoc(state.id as string, {
        informationVerified: true,
      });

      dispatch({ type: "SET_INFORMATION_VERIFIED", payload: true });
    } catch (err) {
      window.newrelic.noticeError(err);
      await reportError(err);
    }
  }

  async function setCurrentStep(nextStep: OnboardStep): Promise<void> {
    try {
      await updateOnboardStateDoc(state.id as string, {
        currentStep: nextStep,
      });

      dispatch({ type: "SET_CURRENT_STEP", payload: nextStep });
    } catch (err) {
      window.newrelic.noticeError(err);
      await reportError(err);
    }
  }

  async function _reset(): Promise<void> {
    try {
      await updateOnboardStateDoc(state.id as string, {
        yardsStatusObj: {},
        selectedYard: null,
      });

      if (user) {
        fetchOnboardMetadata(user.uid);
      }

      window.location.replace(
        window.location.host + "/onboard/onboard/my-yard/choose-yards"
      );
    } catch (err) {
      window.newrelic.noticeError(err);
      await reportError(err);
    }
  }

  function setOnboardContextError(err: string | null) {
    dispatch({ type: "SET_ERROR", payload: err });
  }

  async function reportError(err: Error, contextError?: ContextErrorValue) {
    console.error(err);
    setOnboardContextError(contextError || err.message);
    LogRocket.captureMessage(err.message);
    await genericAlert(err.message + CANNOT_ONBOARD_ALERT_STRING);
  }

  function allYardStepsComplete(yard?: YardName): boolean {
    if (!!yard) {
      const yardStatus = state.yardsStatusObj[yard];
      if (!yardStatus) {
        return false;
      } else {
        return !Object.keys(yardStatus).some(key => {
          return key === "COMPLETE";
        });
      }
    } else {
      return !(Object.keys(state.yardsStatusObj) as YardName[]).some(
        (yardKey: YardName) => {
          const yardStatus = state.yardsStatusObj[yardKey];
          if (!yardStatus) {
            return false;
          }
          return !Object.keys(yardStatus).some(stepKey => {
            return yardStatus[stepKey as keyof YardUploadStatus] === "COMPLETE";
          });
        }
      );
    }
  }
};

export { OnboardCtx, OnboardProvider, OnboardConsumer };
export default OnboardCtx;
