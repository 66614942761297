import { BaseClient, IdentifyProps } from "./BaseClient";

export * from "./BaseClient"

export class SegmentClient extends BaseClient {
  private readonly maxRetries = 10;
  private userId: string | null = null;
  private anonymousId: string | null = null;

  public constructor(private apiUrl: string, private writeKey: string) {
    super();
  }

  public setUserId(userId: string | null): void {
    this.userId = userId;
  }

  public setAnonymousId(anonymousId: string | null): void {
    this.anonymousId = anonymousId;
  }

  public setWriteKey(key: string): void {
    this.writeKey = key;
  }

  public setApiUrl(url: string): void {
    this.apiUrl = url;
  }

  // eslint-disable-next-line prettier/prettier
  protected override track(e: string, p: any, o?: any, cb?: any) {
    return this.send("track", { event: e, properties: p, options: o }, cb);
  }

  public override identify(
    userId: string,
    traits?: IdentifyProps,
    options?: { [key: string]: any }
  ): any
  public override identify(traits: IdentifyProps, options?: { [key: string]: any }): any
  public override identify(...args: any[]): any {
    if (typeof args[0] === "string") {
      const [userId, traits, options, callback] = args;
      return this.send("identify", { userId, traits, options }, callback);
    } else {
      const [traits, options, callback] = args;
      return this.send("identify", { traits, options }, callback);
    }
  }

  public override page(
    page?: string,
    properties?: {
      [key: string]: any;
      title?: string;
      path?: string;
      url?: string;
      referrer?: string;
    }
  ): any {
    if (page) {
      this.lastPage = page;
    }

    return this.send("page", {
      name: this.lastPage,
      properties: properties,
    }, () => void 0);
  }

  private async send(
    method: "identify" | "track" | "page",
    message: Record<string, any>,
    callback: ((err: Error) => void) | undefined,
    context = this.getBrowserInfo(),
    retries = 0
  ): Promise<void> {
    if (!("userId" in message) && this.userId) {
      message["userId"] = this.userId;
    }

    if (!("anonymousId" in message) && this.anonymousId) {
      message["anonymousId"] = this.anonymousId;
    }

    const payload = {
      ...message,
      writeKey: this.writeKey,
      context: {
        ...context,
        ...message["context"],
      },
      ...(method === "page"
        ? { properties: { ...message["properties"], ...context.page } }
        : { properties: { ...message["properties"] } }),
    }

    try {
      await fetch(this.apiUrl + "/" + method, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Failed to send message to cdp: " + res.statusText);
        }
        callback?.(undefined as any)
      }).catch((callback))
    } catch (err) {
      retries++;
      if (retries >= this.maxRetries) {
        console.error(
          "Retry limit exceeded trying to send message to cdp",
          err
        );
        return callback?.(err as Error);
      }

      await new Promise(resolve => setTimeout(resolve, 5 ** retries));

      return this.send(method, message, callback, context, retries);
    }
  }

  private getBrowserInfo() {
    if (typeof window === "undefined" && typeof navigator === "undefined") {
      return {};
    }

    const searchParams = new URLSearchParams(window.location.search);

    return {
      userAgent: window.navigator.userAgent,
      locale: window.navigator.language,
      referrer: {
        type: document.referrer ? "internal" : "external",
        url: document.referrer,
        name: document.referrer,
        link: document.referrer,
      },
      page: {
        path: window.location.pathname,
        title: document.title,
        url: window.location.href,
        referrer: document.referrer,
        search: window.location.search,
        hash: window.location.hash,
      },
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      campaign: {
        source: searchParams.get("utm_source"),
        medium: searchParams.get("utm_medium"),
        term: searchParams.get("utm_term"),
        content: searchParams.get("utm_content"),
        campaign: searchParams.get("utm_campaign"),
      },
    };
  }
}
