import { useEffect, useState } from "react";
import { ProfileProperties } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Falsey from "../types/Falsey";
import { fbPixelInit } from "../functions/sendFbPixelEvent";

export const useProfile = (
  user: firebase.User | Falsey
): [Error | null, ProfileProperties | Falsey] => {
  const [profile, setProfile] = useState<ProfileProperties | null | false>(
    null
  );
  const [error, setError] = useState<Error | null>(null);

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(listenOnProfileSnapshot, [user]);

  return [error, profile];

  function listenOnProfileSnapshot(): (() => void) | void {
    if (!user || !user.uid) {
      return;
    }

    try {
      return firebase
        .firestore()
        .collection("profiles")
        .doc(user.uid)
        .onSnapshot(async snap => {
          if (!snap.exists && profile !== false) {
            return setProfile(false);
          }

          const data = snap.data() as ProfileProperties;

          fbPixelInit({
            em: data.email || undefined,
            fn: data.firstName || undefined,
            ct: data.city || undefined,
            ln: data.lastName || undefined,
            st: data.state || undefined,
            zp: data.zip || undefined,
            ph: data.phone || undefined,
            external_id: snap.id || undefined,
          });

          return setProfile({
            ...(snap.data() as ProfileProperties),
            id: snap.id,
          });
        });
    } catch (err) {
      window.newrelic.noticeError(err);
      setError(err);
    }
  }
};
