import {
  BACK_YARD_PACKAGE,
  CURB_APPEAL_PACKAGE,
  FRONT_YARD_PACKAGE,
  SMALL_SPACE_PACKAGE,
} from "../../../util/constants/packageTypes";
import { YardName } from "../stepData";

export const getAvailableYards = (
  packagePurchased: string | null | undefined
): YardName[] => {
  switch (packagePurchased) {
    case SMALL_SPACE_PACKAGE:
      return ["outdoor"];
    case BACK_YARD_PACKAGE:
      return ["back", "left", "right"];
    case FRONT_YARD_PACKAGE:
    case CURB_APPEAL_PACKAGE:
      return ["front", "left", "right"];
    default:
      return ["front", "back", "left", "right"];
  }
};
