import { useState, FC, useEffect } from "react";
import { Box, Checkbox, Theme, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { YZTypography } from "@yardzen-inc/react-common";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addToAddOns, removeFromAddOns } from "../../slices/productSlice";
import { hasAddOnExpedited } from "../../util/functions/hasAddOn";
import { ProductName } from "../../types/Products";
import { Info } from "@material-ui/icons";

interface Props {
  promoCodeIsExpedited: boolean;
}

export const ExpeditedUpsell: FC<Props> = ({ promoCodeIsExpedited }) => {
  const useStyles = makeStyles((theme: Theme) => ({
    expeditedUpsellWrapper: {
      width: "102%",
      backgroundColor: "#F8F7F6",
      borderRadius: 10,
      display: "flex",
      flexFlow: "row",
      alignItems: "flex-start",
      padding: 10,
      marginBottom: 10,
    },
    priceWrapper: {
      width: "30%",
      justifyContent: "flex-end",
      alignItems: "center",
      display: "flex",
      height: 50,
      [theme.breakpoints.down("sm")]: {
        width: "15%",
      },
    },
    invisible: {
      visibility: "hidden",
    },
    upsellText: {
      width: "70%",
      paddingTop: 10,
      [theme.breakpoints.down("sm")]: {
        width: "85%",
      },
    },
    boldText: {
      fontWeight: "bold",
    },
    infoIcon: {
      fontSize: "1rem",
      verticalAlign: "middle",
    },
  }));
  const classes = useStyles();
  const { products, addOnSkus } = useAppSelector(state => state.products);
  const { restrictChanges } = useAppSelector(state => state.checkout);
  const dispatch = useAppDispatch();
  const [showExpeditedUpsell, setShowExpeditedUpsell] = useState(
    addOnSkus.findIndex(addOn => hasAddOnExpedited({ addOn })) >= 0
  );
  const expeditedPriceObject = products.find(
    product => product.name === ProductName.ExpeditedUpsell
  );
  const expeditedUpsellPrice = (
    (expeditedPriceObject?.skus.data[0].price || 39500) / 100
  ).toFixed(0);

  useEffect(() => {
    if (promoCodeIsExpedited && !showExpeditedUpsell) {
      expeditedPriceObject &&
        dispatch &&
        dispatch(addToAddOns({ addOn: expeditedPriceObject?.skus.data[0] }));
      setShowExpeditedUpsell(true);
    }
  }, [
    promoCodeIsExpedited,
    expeditedPriceObject,
    dispatch,
    showExpeditedUpsell,
  ]);

  const handleChange = () => {
    if (!showExpeditedUpsell) {
      expeditedPriceObject &&
        dispatch(addToAddOns({ addOn: expeditedPriceObject?.skus.data[0] }));
    } else {
      expeditedPriceObject &&
        dispatch(
          removeFromAddOns({ addOn: expeditedPriceObject?.skus.data[0] })
        );
    }
    setShowExpeditedUpsell(!showExpeditedUpsell);
  };

  const displayPrice = () => {
    if (!showExpeditedUpsell) return null;

    return (
      <YZTypography style={{ fontSize: "1.2rem" }}>
        ${expeditedUpsellPrice}
      </YZTypography>
    );
  };

  // if changes are restricted, only show component if the checkbox is checked
  const hideComponent = restrictChanges && !showExpeditedUpsell;

  return (
    <Box
      className={`${classes.expeditedUpsellWrapper} ${
        hideComponent ? classes.invisible : null
      }`}
    >
      <Checkbox
        id="expedited-checkbox"
        checked={showExpeditedUpsell || promoCodeIsExpedited}
        onChange={handleChange}
        disabled={restrictChanges || promoCodeIsExpedited}
        inputProps={{
          "aria-label": "add expedited service checkbox",
        }}
      />

      {promoCodeIsExpedited ? (
        <>
          <YZTypography
            color="textSecondary"
            variant="body2"
            className={classes.upsellText}
          >
            Expedited service is included in your order!
          </YZTypography>
          <Box className={classes.priceWrapper}>
            <s>{displayPrice()}</s>
          </Box>
        </>
      ) : (
        <>
          <YZTypography
            color="textSecondary"
            variant="body2"
            className={classes.upsellText}
          >
            +${expeditedUpsellPrice}, upgrade to
            <span className={classes.boldText}> expedited service</span> to skip
            the line and get your design faster{" "}
            <Tooltip
              title="With an expedited upgrade, we will fast track your project. 
                    Otherwise, our design process takes up to 6 weeks. We've built 
                    one business day into the timeline at each stage where we need 
                    feedback from you to keep going. You're welcome to take longer 
                    on your feedback, just know that will increase the time to 
                    complete your design."
              leaveTouchDelay={10000}
              enterTouchDelay={0}
            >
              <Info
                className={classes.infoIcon}
                data-testid="expedited-tooltip"
              />
            </Tooltip>
          </YZTypography>
          <Box className={classes.priceWrapper}>{displayPrice()}</Box>
        </>
      )}
    </Box>
  );
};
