import { Page, Selections } from "../../../../types/DesignProfile";
import { WISH_LIST_PATH } from "../../constants/paths";

interface GetUpdatedResponseArgs {
  existingResponse: any;
  page: Pick<Page, "multiple_selections" | "path" | "quiz_step_number">;
  selectionValue: string;
  selectionKey: keyof Selections;
}

interface GetUpdatedMultipleSelectionValueArgs {
  selectionValue: string;
  responses: string[];
}

/**
 * Returns an updated response to insert into the design profile questions array.
 * @param {any} existingResponse The existing response for the question.
 * @param {Pick<Page, "multiple_selections" | "path" | "completion_number">} page The page that the response is for.
 * @param {string} selectionValue The value to add for the selection response.
 * @param {keyof Selections} selectionKey The key for the selection.
 * @returns An updated response to insert into the the design profile questions array.
 */
export const getUpdatedResponse = ({
  existingResponse,
  page,
  selectionValue,
  selectionKey,
}: GetUpdatedResponseArgs) => {
  if (page.path === WISH_LIST_PATH) {
    // We need to create the new response in this way otherwise we'll run into
    // errors on read-only properties.
    const existingSelectionForKey = existingResponse[selectionKey] ?? [];

    const newResponse = {
      path: WISH_LIST_PATH,
      sequence: page.quiz_step_number ?? -1,
      response: {
        ...existingResponse,
        [selectionKey]: [...existingSelectionForKey],
      },
    };

    const responses = existingSelectionForKey as string[];

    newResponse.response[selectionKey] = getUpdatedMultipleSelectionValue({
      selectionValue,
      responses,
    });

    return newResponse;
  }

  const newResponse = {
    response: existingResponse,
    path: page.path,
    sequence: page.quiz_step_number ?? -1,
  };

  if (page.multiple_selections) {
    const responses = existingResponse as string[];

    newResponse.response = getUpdatedMultipleSelectionValue({
      selectionValue,
      responses,
    });

    return newResponse;
  }

  newResponse.response = selectionValue;

  return newResponse;
};

const getUpdatedMultipleSelectionValue = ({
  selectionValue,
  responses,
}: GetUpdatedMultipleSelectionValueArgs): string[] => {
  return responses.includes(selectionValue)
    ? responses.filter(item => item !== selectionValue)
    : [...responses, selectionValue];
};
