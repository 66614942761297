import React, { FC } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { usePageDimensions, useHeaderHeight } from "../../util";
import { makeStyles, Theme, useMediaQuery } from "@material-ui/core";

export interface BottomCenterContentContainerProps extends BoxProps {}

const useStyles = makeStyles(theme => ({
  container: {
    position: "fixed",
    [theme.breakpoints.up("sm")]: {
      width: "calc(100vw - 280px)",
      minWidth: "calc(100vw - 280px)",
      left: "280px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100vw",
      left: "0px",
    },
  },
}));

const BottomCenterContentContainer: FC<BottomCenterContentContainerProps> = ({
  children,
  ...containerProps
}) => {
  const classes = useStyles();
  const pageY = usePageDimensions()[1];
  const headerHeight = useHeaderHeight();

  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));

  return (
    <Box
      left={0}
      top={`calc(${pageY}px - ${headerHeight}px - ${xs ? 35 : 0}px)`}
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      p={2}
      pb={3}
      className={classes.container}
      {...containerProps}
    >
      {children}
    </Box>
  );
};

export { BottomCenterContentContainer };
export default BottomCenterContentContainer;
