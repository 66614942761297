import { useEffect } from "react";
import * as LogRocket from "logrocket";

/**
 * Initializes log rocket for the app
 */
const useLogRocket = () => {
  useEffect(() => {
    async function init() {
      try {
        await LogRocket.init("snab0e/liisa");
      } catch (e) {
        window.newrelic.noticeError(e);
        console.error("Error initiating LogRocket! ", e);
      }
    }
    if (process.env.NODE_ENV === "production") {
      init();
    }
  }, []);
};

export { useLogRocket };
