import * as React from "react";

export interface AbandonedCartContext {
  hasBeenSubscribed: HasBeenSubscribed;
  setHasBeenSubscribed: React.Dispatch<
    React.SetStateAction<{
      email: string | null;
      sku: string | null;
      firstName: string | null;
      lastName: string | null;
    }>
  >;
}

interface HasBeenSubscribed {
  email: string | null;
  sku: string | null;
  firstName: string | null;
  lastName: string | null;
}

const AbandonedCartCtx = React.createContext<AbandonedCartContext>({
  hasBeenSubscribed: {
    email: null,
    sku: null,
    firstName: null,
    lastName: null,
  },
  setHasBeenSubscribed: () => {},
});

const useAbandonedCartContext = () => React.useContext(AbandonedCartCtx);

const AbandonedCartProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [hasBeenSubscribed, setHasBeenSubscribed] = React.useState<
    HasBeenSubscribed
  >({
    email: null,
    sku: null,
    firstName: null,
    lastName: null,
  });

  return (
    <AbandonedCartCtx.Provider
      value={{ hasBeenSubscribed, setHasBeenSubscribed }}
    >
      {children}
    </AbandonedCartCtx.Provider>
  );
};

export { useAbandonedCartContext, AbandonedCartProvider };
export default useAbandonedCartContext;
