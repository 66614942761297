import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { Media } from "@yardzen-inc/models";
import MediaItem from "./MediaItem";

export interface MediaGridProps {
  media: Media[];
  removeMediaItemFromParentArray: (id: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  inspirationCommentField: {
    width: "100%",
  },
  mediaGridContainer: {
    marginTop: 30,
  },
  deleteIconButton: {
    backgroundColor: "#fff",
    boxShadow: "1px 2px #f5f5f5",
  },
  deleteIcon: {
    color: "#C05757",
  },
}));

const MediaGrid: React.FC<MediaGridProps> = ({
  media,
  removeMediaItemFromParentArray,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.mediaGridContainer}>
      {media.map(mediaItem => {
        return (
          <MediaItem
            removeMediaFromArray={deleteMediaItem}
            key={mediaItem.id}
            media={mediaItem}
          />
        );
      })}
    </div>
  );
  function deleteMediaItem(deletedItem: string) {
    removeMediaItemFromParentArray(deletedItem);
  }
};

export default MediaGrid;
