import * as React from "react";
import { CssBaseline } from "@material-ui/core";
import { BrowserRouter } from "react-router-dom";
import { UserProvider, ProfileProvider, OnboardProvider } from "../contexts";
import { initializeFirebase } from "../firebase";
import { AbandonedCartProvider } from "../hooks/abandonedCartContext";
import FirebaseJwtProvider from "../contexts/FirebaseJwtContext";
import FirebaseJwtApolloProvider from "../contexts/FirebaseJwtApolloProvider";
import { ThemeSelectionProvider } from "../contexts/ThemeSelectionContext";
import { SplitProviderWrapper } from "../Split/SplitProviderWrapper";
import DataLayerProvider from "../../data/DataLayerProvider";
import { GTMDataLayer } from "@yardzen-inc/data-layer";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useEffect } from "react";

interface Props {
  children: React.ReactNode;
}

initializeFirebase();

const dl = new GTMDataLayer();

export const Providers: React.FC<Props> = React.memo((props: Props) => {
  useEffect(handleAssignTrackingId);

  return (
    <ThemeSelectionProvider>
      <CssBaseline>
        <DataLayerProvider dataLayer={dl}>
          <UserProvider>
            <SplitProviderWrapper>
              <ProfileProvider>
                <FirebaseJwtProvider>
                  <FirebaseJwtApolloProvider>
                    <AbandonedCartProvider>
                      <OnboardProvider>
                        <BrowserRouter>{props.children}</BrowserRouter>
                      </OnboardProvider>
                    </AbandonedCartProvider>
                  </FirebaseJwtApolloProvider>
                </FirebaseJwtProvider>
              </ProfileProvider>
            </SplitProviderWrapper>
          </UserProvider>
        </DataLayerProvider>
      </CssBaseline>
    </ThemeSelectionProvider>
  );
});

function handleAssignTrackingId() {
  return firebase.auth().onAuthStateChanged(user => {
    if (user?.email && !user?.isAnonymous) {
      dl.tracking_id = user.email.trim().toLowerCase();
    }
  });
}

export default Providers;
