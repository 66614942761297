import * as React from "react";
import { Fade, CircularProgress } from "@material-ui/core";

export interface FullScreenLoadingPageProps {
  in: boolean;
  style?: React.CSSProperties;
  children?: React.ReactElement;
}

export const FullScreenLoadingPage: React.FC<FullScreenLoadingPageProps> = (
  props: FullScreenLoadingPageProps
) => {
  // TODO: write this code lol
  return (
    <Fade in={!!props.in}>
      <div
        style={{
          position: "fixed",
          top: "50vh",
          left: "50vw",
          transform: "translate(-50%, -50%)",
          ...(props.style || {}),
        }}
      >
        <CircularProgress
          style={{
            height: "6rem",
            width: "6rem",
          }}
        />
        {!!props.children && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {props.children}
          </div>
        )}
      </div>
    </Fade>
  );
};
