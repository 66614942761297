import React, { useEffect, useState, FC } from "react";
import { Box, makeStyles, Theme } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { YZTypography } from "@yardzen-inc/react-common";
import { useTreatmentWithConfig } from "@yardzen-inc/react-split";

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: { bgColor: string; fontColor: string }) => ({
    width: "100%",
    color: props.fontColor,
    background: props.bgColor,
    position: "sticky",
    top: 0,
    left: 0,
    minHeight: "50px",
    display: "flex",
    alignItems: "center",
    padding: "0 16px",
    zIndex: 99999,
  }),

  message: {
    marginLeft: "16px",
    marginRight: "22px",
  },
  close: {
    position: "absolute",
    right: "16px",
    cursor: "pointer",
  },
}));

interface SplitAlertBannerProps {
  splitTreatment: string;
}

export const SplitAlertBanner: FC<SplitAlertBannerProps> = ({
  splitTreatment,
}) => {
  const [bannerOpen, setBannerOpen] = useState(false);
  const displayAlertBannerInfo = useTreatmentWithConfig(splitTreatment);

  useEffect(() => {
    if (displayAlertBannerInfo.treatmentOn) {
      setBannerOpen(true);
    }
  }, [displayAlertBannerInfo.treatmentOn]);
  const text = displayAlertBannerInfo.config?.text ?? null;
  const bgColor = displayAlertBannerInfo.config?.bg_color ?? "#BECBBE";
  const fontColor = displayAlertBannerInfo.config?.font_color ?? "black";
  const { root, message, close } = useStyles({ bgColor, fontColor });

  return (
    <>
      {bannerOpen && text && (
        <Box className={root}>
          <ErrorOutlineIcon />
          <YZTypography className={message}>{text}</YZTypography>
          <HighlightOffIcon
            id={"close-banner"}
            className={close}
            onClick={() => setBannerOpen(false)}
          />
        </Box>
      )}
    </>
  );
};
