import { PriceObject } from "../../types/Prices";
import { ProductName } from "../../types/Products";

export const hasAddOnExpedited = ({ addOn }: { addOn: PriceObject }) => {
  return addOn.attributes.name.includes(ProductName.ExpeditedUpsell);
};

export const hasAddOnHOAExtraRevision = ({ addOn }: { addOn: PriceObject }) => {
  return (
    addOn.attributes.name.includes(ProductName.HOAExtraRevision) ||
    addOn.attributes.name.includes(ProductName.HOAExtraRevisionStarter)
  );
};
