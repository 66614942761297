import { useEffect } from "react";
import { PriceObject } from "../../types/Prices";

interface UseFriendBuyPaymentCompleteParams {
  customerEmail: string | undefined;
  expeditedSku?: PriceObject | undefined;
  isPremium: boolean;
  orderId: string | undefined;
  orderTotal: number | null;
  packageDisplayName: string;
  packageSku: PriceObject;
  premiumSku?: PriceObject | undefined;
  referralCode: string;
}

interface FriendBuyPrice {
  name: string;
  price: number;
  quantity: number;
}

type WindowWithFriendBuy = Window &
  typeof globalThis & {
    friendbuyAPI?: { push: any };
  };

// Track the purchase in friendbuy
export const useFriendBuyPaymentComplete = ({
  customerEmail,
  expeditedSku,
  isPremium,
  orderId,
  packageDisplayName,
  packageSku,
  premiumSku,
  referralCode,
}: UseFriendBuyPaymentCompleteParams) => {
  const friendBuy = (window as WindowWithFriendBuy)?.friendbuyAPI;

  useEffect(() => {
    const orderTotal = (skuArray: FriendBuyPrice[]): number => {
      return skuArray.reduce((total, sku) => {
        return total + sku.price * sku.quantity;
      }, 0);
    };

    const buildProductArray = (): FriendBuyPrice[] => {
      const productArray = [];
      // Push the initial product onto the array as it will always be present
      productArray.push({
        name: packageDisplayName,
        quantity: 1,
        price: packageSku.price / 100,
      });

      // Expedited or Premium may not be present, but add them to the array if they are
      if (expeditedSku) {
        productArray.push({
          name: expeditedSku.attributes.name,
          quantity: 1,
          price: expeditedSku.price / 100,
        });
      }

      if (premiumSku && isPremium) {
        productArray.push({
          name: premiumSku.attributes.name,
          quantity: 1,
          price: premiumSku.price / 100,
        });
      }

      return productArray;
    };

    if (friendBuy) {
      const productList = buildProductArray();

      friendBuy.push([
        "track",
        "purchase",
        {
          id: orderId,
          amount: orderTotal(productList),
          currency: "USD",
          customer: {
            email: customerEmail,
            id: customerEmail,
          },
          products: productList,
          couponCode: referralCode,
        },
      ]);
    }
  });
};
