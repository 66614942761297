import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Fade,
  InputAdornment,
  makeStyles,
  Slide,
  TextField,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import {
  ToggleSelectWrapper,
  useTriggerTimeout,
  YZButton,
  YZTypography,
} from "@yardzen-inc/react-common";
import { useHistory } from "react-router";
import {
  BudgetMetadataAndPhasingByProjectIdQuery,
  InsertBudgetMetadataMutationVariables,
  InsertBudgetPhaseMetadataMutationVariables,
  UpdateBudgetMetadataMutationVariables,
  UpdateBudgetPhaseMetadataMutationVariables,
  useInsertBudgetMetadataMutation,
  useMutation,
  useUpdateBudgetMetadataMutation,
} from "@yardzen-inc/graphql";
import { BudgetStepCtx } from "./BudgetStepContext";
import { useLogError } from "../../../util";
import useGetBudgetRedirectUrlAndBackText from "./useGetBudgetRedirectUrl";
import SaveBar from "../helpers/SaveBar";
import {
  INSERT_BUDGET_PHASE_METADATA,
  UPDATE_BUDGET_PHASE_METADATA,
} from "../../../pages/onboard/budget/budgetMutations";

export interface ChecklistBudgetPhasingPageProps {
  budgetMetadataQueryResult?: BudgetMetadataAndPhasingByProjectIdQuery;
  reloadMetadataQuery: () => Promise<void>;
}

const notNumbers = /[^0-9]/g;
const notNumbersOrStartsWithZero = /([^0-9]|^0)/g;

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    alignSelf: "flex-start",
    fontWeight: "bold",
  },
  toggleContainer: {
    maxWidth: 500,
    marginBottom: theme.spacing(3),
    margin: "0 auto",
    "& > div > div": {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  backContainer: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: "100%",
    bgcolor: "inherit",
    zIndex: 999,
    position: "sticky",
    top: 0,
  },
}));

const ChecklistBudgetPhasingPage: FC<ChecklistBudgetPhasingPageProps> = ({
  budgetMetadataQueryResult,
  reloadMetadataQuery,
}) => {
  // context information
  const {
    project: { id: projectId },
  } = useContext(BudgetStepCtx);
  const budgetMetadata = useMemo(
    () => budgetMetadataQueryResult?.budget_metadata?.[0],
    [budgetMetadataQueryResult]
  );

  const classes = useStyles();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const history = useHistory();

  const [redirectUrl, backText] = useGetBudgetRedirectUrlAndBackText(
    "checklist/list",
    "element selection"
  );
  const { triggerTimeout, triggered } = useTriggerTimeout(
    () => history.push(redirectUrl),
    200
  );

  // local state
  const [fieldErrorMessages, setFieldErrorMessages] = useState<{
    phaseoneBudget?: string;
    allInBudget?: string;
  }>({});

  const [phasingDesired, setPhasingDesired] = useState<"yes" | "no" | "">(
    () => {
      if (budgetMetadata?.phasing === void 0 || budgetMetadata?.phasing == null)
        return "";
      if (budgetMetadata.phasing === true) return "yes";
      return "no";
    }
  );
  const [phaseOneBudget, setPhaseOneBudget] = useState<string>(() => {
    if (
      !budgetMetadata?.budget_phase_metadata?.[0]?.budget ||
      Number.isNaN(budgetMetadata?.budget_phase_metadata?.[0]?.budget)
    ) {
      return "0";
    }
    return Math.floor(
      (parseInt(budgetMetadata?.budget_phase_metadata?.[0]?.budget) ?? 100) /
        100
    ).toString();
  });

  const [allInBudget, setAllInBudget] = useState<string>(() => {
    if (!budgetMetadata || Number.isNaN(budgetMetadata?.total_budget)) {
      return "0";
    }
    return Math.floor(
      (parseInt(budgetMetadata?.total_budget) ?? 100) / 100
    ).toString();
  });

  const [designBudget, setDesignBudget] = useState<
    "allIn" | "phaseOne" | undefined
  >(
    budgetMetadata?.design_project_for_single_phase === void 0
      ? void 0
      : !!budgetMetadata?.design_project_for_single_phase
      ? "allIn"
      : "phaseOne"
  );

  // mutation state
  const [
    insertBudgetMetadata,
    { loading: insertingBudgetMetadata, error: insertBudgetMetadataError },
  ] = useInsertBudgetMetadataMutation();
  const [
    updateBudgetMetadata,
    { loading: updatingBudgetMetadata, error: updateBudgetMetadataError },
  ] = useUpdateBudgetMetadataMutation();
  const [
    insertBudgetPhaseMetadata,
    {
      loading: insertingBudgetPhaseMetadata,
      error: insertBudgetPhaseMetadataError,
    },
  ] = useMutation(INSERT_BUDGET_PHASE_METADATA);
  const [
    updateBudgetPhaseMetadata,
    {
      loading: updatingBudgetPhaseMetadata,
      error: updateBudgetPhaseMetadataError,
    },
  ] = useMutation(UPDATE_BUDGET_PHASE_METADATA);

  useLogError(updateBudgetMetadataError as Error);
  useLogError(insertBudgetMetadataError as Error);
  useLogError(updateBudgetPhaseMetadataError as Error);
  useLogError(insertBudgetPhaseMetadataError as Error);

  const userReadyToContinue: boolean = useMemo(() => {
    if (!phasingDesired) return false;

    if (phasingDesired === "yes") {
      return (
        !!phaseOneBudget?.length &&
        !!allInBudget?.length &&
        !!designBudget?.length
      );
    }

    return !!allInBudget?.length;
  }, [phasingDesired, phaseOneBudget, allInBudget, designBudget]);

  // effects

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onScalarInput, [phaseOneBudget, allInBudget, phasingDesired]);
  useEffect(onPhaseResponseChange, [phasingDesired]);

  return (
    <Slide
      mountOnEnter
      direction="left"
      in={!triggered}
      timeout={{ enter: 200, exit: 200 }}
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box className={classes.backContainer}>
          <YZButton
            variant="text"
            onClick={triggerTimeout}
            // @ts-ignore
            slim
            size="small"
          >
            {`< Back to ${backText}`}
          </YZButton>
        </Box>
        <Box
          width="99%"
          maxWidth="1080px"
          alignItems="center"
          p={mdDown ? 0 : 2}
          pt={2}
          textAlign="center"
        >
          <YZTypography variant="h4" type="serif">
            Let's talk budget!
          </YZTypography>

          <Box width="100%" p={mdDown ? 2 : 4}>
            <YZTypography variant="h5" type="serif">
              Do you want to phase your project?
            </YZTypography>
            <Box className={classes.toggleContainer}>
              <ToggleSelectWrapper
                onSelected={ans => setPhasingDesired(ans as "yes" | "no")}
                prompt={<></>}
                selectVariant="SINGLE"
                selected={phasingDesired ?? ""}
                orientation="horizontal"
                inheritFont
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
              />
            </Box>

            {renderPhasingAccordion()}

            <Box paddingBottom={4} width="100%">
              {renderPhaseOneBudgetInput()}

              {renderAllInBudgetInput()}

              {renderDesignForBudgetOptions()}
            </Box>
          </Box>
        </Box>
        <SaveBar
          label="Save and go to review  →"
          disabled={
            !!(
              !userReadyToContinue ||
              fieldErrorMessages.phaseoneBudget ||
              fieldErrorMessages.allInBudget
            ) || isLoading()
          }
          onClick={handleSubmit}
        />
      </Box>
    </Slide>
  );

  function renderPhasingAccordion(): React.ReactNode {
    return (
      <Box
        p={3}
        style={{
          border: "1px dashed #e2e2e2",
          maxWidth: 500,
          margin: "0 auto 2rem",
        }}
      >
        <YZTypography
          variant="body2"
          type="uppercase"
          style={{ fontWeight: 600, marginBottom: 8, display: "block" }}
        >
          What is phasing?
        </YZTypography>
        <YZTypography variant="body2">
          A phased design approach is a way to plan out your project over the
          span of a few installation periods. This is especially helpful if you
          have a long-term vision for the space that you aren’t able to
          accomplish in the first install.
        </YZTypography>
        <br />
        <YZTypography variant="body2">
          We can take this information to show you the full potential of your
          space while also making sure your contractor knows your budget for the
          first installation.
        </YZTypography>
      </Box>
    );
  }

  function renderPhaseOneBudgetInput(): React.ReactNode {
    return (
      phasingDesired === "yes" && (
        <Box
          display="flex"
          flexDirection={smDown ? "column" : "row"}
          py={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box width={smDown ? "100%" : "50%"}>
            <YZTypography type="serif" variant="body1">
              What is your phase 1 budget?
            </YZTypography>
          </Box>
          <TextField
            variant="outlined"
            value={phaseOneBudget}
            onChange={e =>
              setPhaseOneBudget(
                parseInt(e.target.value) > 9223372036854775807
                  ? "9223372036854775807"
                  : e.target.value.replace(notNumbersOrStartsWithZero, "") ||
                      "0"
              )
            }
            error={!!fieldErrorMessages.phaseoneBudget}
            helperText={fieldErrorMessages.phaseoneBudget}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Box>
      )
    );
  }

  function renderAllInBudgetInput(): React.ReactNode {
    return (
      !!(phasingDesired === "yes" || phasingDesired === "no") && (
        <Box
          display="flex"
          flexDirection={smDown ? "column" : "row"}
          py={2}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box width={smDown ? "100%" : "50%"}>
            <YZTypography type="serif" variant="body1">
              What is your all-in budget?
            </YZTypography>
          </Box>
          <TextField
            variant="outlined"
            value={allInBudget}
            error={!!fieldErrorMessages.allInBudget}
            helperText={fieldErrorMessages.allInBudget}
            onChange={e =>
              setAllInBudget(
                parseInt(e.target.value) > 9223372036854775807
                  ? "9223372036854775807"
                  : e.target.value.replace(notNumbersOrStartsWithZero, "") ||
                      "0"
              )
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Box>
      )
    );
  }

  function onPhaseResponseChange(): void {
    if (phasingDesired === "no") setPhaseOneBudget("0");
  }

  function renderDesignForBudgetOptions() {
    return (
      <Fade
        in={phasingDesired === "yes"}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 500, exit: 200 }}
      >
        <Box
          py={4}
          display="flex"
          flexDirection={"column"}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <YZTypography type="serif" variant="h5">
            Do you want your design to match your phase 1 or all-in budget?
          </YZTypography>
          <Box className={classes.toggleContainer}>
            <ToggleSelectWrapper
              onSelected={ans => setDesignBudget(ans as "allIn" | "phaseOne")}
              prompt={<></>}
              selectVariant="SINGLE"
              selected={designBudget ?? ""}
              orientation="horizontal"
              containerProps={{ width: "100%" }}
              inheritFont
              options={[
                { value: "phaseOne", label: "Phase 1" },
                { value: "allIn", label: "All-in" },
              ]}
            />
          </Box>
        </Box>
      </Fade>
    );
  }

  function transformInputIntoBudgetMetadataProperties(): InsertBudgetMetadataMutationVariables &
    UpdateBudgetMetadataMutationVariables {
    return {
      design_project_for_single_phase: designBudget === "allIn",
      phasing: phasingDesired === "yes",
      project_id: projectId,
      total_budget: allInBudget.replace(notNumbers, "") + "00",
    };
  }

  function transformInputIntoBudgetPhasingMetadataProperties(
    budgetMetadataId: string
  ): InsertBudgetPhaseMetadataMutationVariables &
    UpdateBudgetPhaseMetadataMutationVariables {
    return {
      budget: (
        (!phaseOneBudget ||
        phaseOneBudget.toString() === "0" ||
        phasingDesired !== "yes"
          ? allInBudget
          : phaseOneBudget) + "00"
      ).replace(notNumbers, ""),
      budget_metadata_id: budgetMetadataId,
    };
  }

  function onScalarInput(): () => void {
    const timeout = setTimeout(async () => validateScalarInput(), 400);

    return () => clearTimeout(timeout);
  }

  function isLoading(): boolean {
    return !!(
      insertingBudgetMetadata ||
      insertingBudgetPhaseMetadata ||
      updatingBudgetMetadata ||
      updatingBudgetPhaseMetadata
    );
  }

  // validate user input and update error object
  // returns true if violations are found
  function validateScalarInput(): boolean {
    const messages: typeof fieldErrorMessages = {};

    if (
      phaseOneBudget !== "0" &&
      allInBudget !== "0" &&
      parseInt(phaseOneBudget) > parseInt(allInBudget)
    ) {
      messages.allInBudget =
        "Your all in budget should not be less than your phase one budget.";
    }

    setFieldErrorMessages(messages);

    return !!Object.values(messages).find(m => !!m);
  }

  // handle (in|up)sert of budget_[phase_]metadata
  async function handleSubmit(): Promise<void> {
    if (validateScalarInput()) return;

    let possiblyNewBudgetMetadataId: string;

    if (!allInBudget || allInBudget === "0") {
      return setFieldErrorMessages({
        ...fieldErrorMessages,
        allInBudget: "Please provide a total budget for your project",
      });
    }

    if (
      phasingDesired === "yes" &&
      (!phaseOneBudget || phaseOneBudget === "0")
    ) {
      return setFieldErrorMessages({
        ...fieldErrorMessages,
        phaseoneBudget: "Please provide a first phase budget for your project",
      });
    }

    if (budgetMetadata) {
      // if wrapper passes a metadata object
      const result = await updateBudgetMetadata({
        variables: {
          ...transformInputIntoBudgetMetadataProperties(),
          id: budgetMetadata.id,
        },
      });

      possiblyNewBudgetMetadataId =
        result?.data?.update_budget_metadata?.returning[0].id;
    } else {
      // no exist :(
      const result = await insertBudgetMetadata({
        variables: {
          ...transformInputIntoBudgetMetadataProperties(),
        },
      });

      possiblyNewBudgetMetadataId =
        result?.data?.insert_budget_metadata?.returning?.[0].id;
    }

    const phasingMetadata = budgetMetadata?.budget_phase_metadata?.[0];

    if (phasingMetadata) {
      await updateBudgetPhaseMetadata({
        variables: {
          ...transformInputIntoBudgetPhasingMetadataProperties(
            possiblyNewBudgetMetadataId
          ),
          id: phasingMetadata.id,
        },
      });
    } else {
      await insertBudgetPhaseMetadata({
        variables: {
          ...transformInputIntoBudgetPhasingMetadataProperties(
            possiblyNewBudgetMetadataId
          ),
        },
      });
    }

    await reloadMetadataQuery();

    history.push("/onboard/budget/review");
  }
};

export { ChecklistBudgetPhasingPage };
export default ChecklistBudgetPhasingPage;
