import { createMuiTheme } from "@material-ui/core";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";

const palette: PaletteOptions = {
  primary: {
    main: "#B8C7B8",
  },
  secondary: {
    main: "#FBCFB1",
  },
  grey: {
    50: "#F8F8F6",
  },
};

const typography: TypographyOptions = {
  fontFamily: '"Apercu", "Raleway", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: 14,
  allVariants: {
    fontWeight: 500,
  },
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
};

const defaultTheme = createMuiTheme();

const getTheme = (isSafari: boolean) => {
  return createMuiTheme({
    palette,
    typography,
    transitions: isSafari
      ? {
          duration: {
            shortest: 0,
            shorter: 0,
            short: 0,
            standard: 0,
            complex: 0,
            enteringScreen: 0,
            leavingScreen: 0,
          },
          create: () => "none",
        }
      : defaultTheme.transitions,
    overrides: isSafari
      ? {
          MuiCssBaseline: {
            "@global": {
              "*, *::before, *::after": {
                transition: "none !important",
                animation: "none !important",
              },
            },
          },
        }
      : defaultTheme.overrides,
  });
};

export { getTheme };
export default getTheme;
