import React, { FC, useContext, useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import {
  Route,
  useRouteMatch,
  Switch,
  RouteComponentProps,
  Redirect,
} from "react-router-dom";
import { OnboardCtx } from "../../util";
import MyYardSelect from "./MyYardSelect";
import StepRouter from "./StepRouter";
import OverviewRouter from "./OverviewRouter";
import { ConfirmInformationForm } from "./ConfirmInformationForm";
import FirebaseClientUtil from "@yardzen-inc/firebase-client-util";
import { YZTypography } from "@yardzen-inc/react-common";
import { SegmentFlows, SegmentSubFlows, useSegment } from "../../util/Segment";
import { useCreateUserProjectInPostgres } from "./budget";
import { useUpdateProfileMutation } from "../../services/pangaeaService";
import { UpdateProfileDto } from "../../api/apiTypes";

export interface MyYardStepProps extends RouteComponentProps {}

const MyYardStep: FC<MyYardStepProps> = ({ location }) => {
  const segment = useSegment();
  let { path } = useRouteMatch();
  const useMarginTop =
    window.location.pathname === "/onboard/my-yard/select-yard";

  const {
    state: { id: onboardDocId, contextError, informationVerified },
  } = useContext(OnboardCtx);
  const [loading, setLoading] = useState(true);

  // We want to create a user and a project in hasura at this step for all
  // packages so that we can ensure compatibility with the budget and CDM
  // step for all packages.
  useCreateUserProjectInPostgres();

  useEffect(() => {
    if (onboardDocId) {
      setLoading(false);
    }
  }, [onboardDocId]);

  useEffect(() => {
    if (!loading && !contextError) {
      FirebaseClientUtil.setWebRolesForUser();
    }
  }, [loading, informationVerified, contextError]);

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onMount, []);

  const [updateDesignProfileMutation] = useUpdateProfileMutation();

  async function onUpdateProfile(id: string, updateData: UpdateProfileDto) {
    await updateDesignProfileMutation({
      id,
      ...updateData,
    });
  }

  return (
    <Box mb={4} mt={!!useMarginTop ? 4 : 0} width="100%" p={1}>
      <Dialog open={!loading && !informationVerified && !contextError}>
        <DialogTitle>
          <Box textAlign="center">
            <YZTypography type="serif" variant="h5">
              Confirm Your Information
            </YZTypography>
            <Box pt={1}>
              <Typography variant="body2" color="textSecondary">
                Make sure that this is the address we are designing for
              </Typography>
            </Box>
          </Box>
        </DialogTitle>
        <ConfirmInformationForm handleUpdateDesignProfile={onUpdateProfile} />
      </Dialog>
      {myYardRouter()}
    </Box>
  );

  function myYardRouter() {
    return !loading ? (
      <div>
        <Switch>
          <Route exact path={`${path}/`} component={MyYardSelect} />
          <Route path={`${path}/review`} component={OverviewRouter} />
          <Route path={`${path}/:yard`} component={StepRouter} />
          <Route
            path={`${path}/*`}
            component={() => <Redirect to={`${path}/:yard`} />}
          />
        </Switch>
      </div>
    ) : (
      <Box
        mt="250px"
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" size={75} />
      </Box>
    );
  }

  function onMount() {
    segment.trackSubFlowStarted({
      flow_name: SegmentFlows.ONBOARD,
      sub_flow_name: SegmentSubFlows.MY_YARD,
    });

    return () => {
      segment.trackSubFlowCompleted({
        flow_name: SegmentFlows.ONBOARD,
        sub_flow_name: SegmentSubFlows.MY_YARD,
      });
    };
  }
};

export { MyYardStep };
export default MyYardStep;
