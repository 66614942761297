import { Page } from "../../../../types/DesignProfile";

/**
 * Gets the path to the first quiz page in the design profile.
 * @param pages An array of pages for the design profile
 * @returns The path to the first quiz page.
 */
export const getPathToFirstQuizPage = (
  pages: Pick<Page, "path" | "quiz_step_number">[]
): string => {
  const path = pages.find(page => page.quiz_step_number === 0)?.path;

  if (!path) {
    throw new Error(
      "Could not find a design profile page with a quiz_step_number of 0"
    );
  }

  return path;
};
