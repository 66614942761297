import React, { FC } from "react";
import { useContext } from "react";
import { DataLayer } from "@yardzen-inc/data-layer";

const DataLayerCtx = React.createContext<DataLayer>(null as any);

export interface DataLayerProviderProps {
  dataLayer: DataLayer;
}

const DataLayerProvider: FC<DataLayerProviderProps> = props => {
  return (
    <DataLayerCtx.Provider value={props.dataLayer}>
      <>{props.children}</>
    </DataLayerCtx.Provider>
  );
};

const useDataLayer = () => {
  return useContext(DataLayerCtx);
};

export { DataLayerProvider, DataLayerCtx, useDataLayer };
export default DataLayerProvider;
