import React, { FC } from "react";
import OverviewPageOne from "./OverviewPageOne";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import OverviewPageTwo from "./OverviewPageTwo";
import { useSetTitle } from "../../util";

export interface OverviewRouterProps {}

const OverviewRouter: FC<OverviewRouterProps> = props => {
  useSetTitle("Yardzen - Overview", true, []);
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/1`} component={OverviewPageOne} />
      <Route exact path={`${path}/2`} component={OverviewPageTwo} />
      <Redirect to={`${path}/1`} />
    </Switch>
  );
};

export { OverviewRouter };
export default OverviewRouter;
