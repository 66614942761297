import { Box, Typography } from "@material-ui/core";
import { useDebug } from "../useDebug";

export const DebugInfo: React.FC<{ data: any; title: string }> = ({
  data,
  title,
}) => {
  const showDebugInfo = useDebug();

  if (!showDebugInfo) return null;

  return (
    <Box>
      <Typography>{title} Debug Info</Typography>
      <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>
        {JSON.stringify(data, null, 2)}
      </pre>
    </Box>
  );
};
