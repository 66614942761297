import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import {
  YardsStatusObj,
  YardName,
  YardPriorities,
  OnboardStep,
} from "../../components/onboard/stepData";
import { PackageTypes } from "../constants/packageTypes";

export interface UpdateOnboardStateObj {
  selectedYard?: YardName | null;
  yardsStatusObj?: YardsStatusObj;
  inactiveYardsStatusObj?: YardsStatusObj;
  priorities?: YardPriorities;
  yzPackage?: PackageTypes;
  informationVerified?: boolean;
  currentStep?: OnboardStep;
  budgetStepComplete?: boolean;
  exteriorDesignStepComplete?: boolean;
}

// update onboardState doc by onboard document id
//
// this function MUST be wrapped in a try/catch to handle possible error in the UI
async function updateOnboardStateDoc(
  onboardDocId: string,
  data: UpdateOnboardStateObj
) {
  try {
    return firebase
      .firestore()
      .collection("onboardStates")
      .doc(onboardDocId)
      .update(data);
  } catch (err) {
    window.newrelic.noticeError(err);
    console.error("Error: ", err);

    throw new Error(
      `Error updating onboard state document with id of ${onboardDocId} in Onboard Context`
    );
  }
}

export { updateOnboardStateDoc };
export default updateOnboardStateDoc;
