import React, { FC, useEffect, useContext, useState } from "react";
import useHeaderVariant from "../../util/hooks/useHeaderVariant";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import { OnboardCtx } from "../../util";
import { YardName } from "./stepData";
import YardUploadGrid from "./YardUploadGrid";
import { useHistory } from "react-router";
import transformYardStatusObjKey from "../../util/functions/transformYardStatusObjKey";
import StepWrapper from "./StepWrapper";

export interface OverviewPageOneProps {}

const OverviewPageOne: FC<OverviewPageOneProps> = props => {
  const history = useHistory();

  const header = useHeaderVariant()[0];
  const subHeader = useHeaderVariant()[1];

  const [loading, setLoading] = useState(true);
  const {
    state: { yardsStatusObj, projectId },
  } = useContext(OnboardCtx);

  useEffect(() => {
    if (!!projectId) {
      setLoading(false);
    }
  }, [projectId]);

  if (loading) {
    return (
      <Box width="100%" display="flex" justifyContent="center">
        <CircularProgress color="primary" size={75} />
      </Box>
    );
  }

  return (
    <StepWrapper
      previous={() => history.push("/onboard/my-yard/select-yard")}
      next={() => history.push("/onboard/my-yard/review/2")}
    >
      <Typography variant={header}>My Yard Overview - Page 1/2</Typography>
      <Typography variant={subHeader}>
        Review your submissions to make sure you've covered all the bases!
      </Typography>
      <Box pt={4}>
        <Box>
          <Typography variant={subHeader}>
            <strong>Wide Angle Photos:</strong>
          </Typography>
          {Object.keys(yardsStatusObj).map(yard =>
            renderMediaGrid(yard as YardName, "wide-angle")
          )}
        </Box>
        <Box pt={4}>
          <Typography variant={subHeader}>
            <strong>Slow Pan Videos:</strong>
          </Typography>
          {Object.keys(yardsStatusObj).map(yard =>
            renderMediaGrid(yard as YardName, "slow-pan", true)
          )}
        </Box>
        <Box pt={4}>
          <Typography variant={subHeader}>
            <strong>Chatty Videos:</strong>
          </Typography>
          {Object.keys(yardsStatusObj).map(yard =>
            renderMediaGrid(yard as YardName, "chatty", true)
          )}
        </Box>
      </Box>
    </StepWrapper>
  );

  // dynamically render media grids
  function renderMediaGrid(
    yard: YardName,
    variantSuffix: "wide-angle" | "slow-pan" | "chatty",
    video?: boolean
  ) {
    if (!projectId) {
      return <></>;
    }
    return (
      <Box key={`${yard}-${variantSuffix}`}>
        <Typography variant={subHeader}>
          {transformYardStatusObjKey(yard)}
        </Typography>
        <YardUploadGrid
          video={video}
          projectId={projectId}
          fileTag="property"
          variant={`${yard}-yard-${variantSuffix}`}
          accept={video ? ["video/*"] : [".jpg", ".png", ".svg", ".heic"]}
        />
      </Box>
    );
  }
};

export { OverviewPageOne };
export default OverviewPageOne;
