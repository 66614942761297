import * as React from "react";
import { google } from "google-maps";
import load from "./load";

export const useMaps = (): google | null => {
  const [maps, setMaps] = React.useState<google | null>((window as any).g);

  const ref = React.useRef<any>(null);

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(initMaps, []);
  React.useEffect(clearGCheckInterval, []);

  return maps ?? (window as any).g;

  function initMaps() {
    if ((window as any).loadingG) {
      ref.current = setInterval(checkForG, 200);
      return;
    }

    (window as any).loadingG = true;

    if (maps) {
      return;
    }

    void (async function() {
      if ((window as any).g) {
        return setMaps((window as any).g);
      }

      ref.current = setInterval(checkForG, 200);

      (window as any).g = await load();
      setMaps((window as any).g);
    })();
  }

  function checkForG() {
    if ((window as any).g) {
      setMaps((window as any).g);
      clearGCheckInterval();
    }
  }

  function clearGCheckInterval() {
    if (ref.current) {
      clearInterval(ref.current);
    }
  }
};
