import * as React from "react";
import {
  Box,
  Theme,
  makeStyles,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import { ExpandMore, Star } from "@material-ui/icons";
import { checkoutFAQs } from "../../util/constants/checkoutFAQs";
import { ASSETS_BASE_URL } from "../../util/constants/assetsBaseUrl";

const useStyles = makeStyles((theme: Theme) => ({
  circleNumber: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(191, 204, 191, 0.20)",
    fontSize: 10,
    fontWeight: 500,
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "1rem",
    lineHeight: "100%",
    color: theme.palette.text.secondary,
    marginRight: 8,
    paddingTop: 2,
  },
  starIcon: {
    color: "#F2C94C",
    fontSize: 14,
    margin: 4,
  },
  divider: {
    backgroundColor: "rgba(0,0,0,0.05)",
  },
  exp: {
    background: "none",
  },
  expSummary: {
    background: "none",
    borderBottom: "1px solid rgba(0,0,0,0.05)",
  },
  expDetail: {
    background: "none",
    paddingTop: "0.5rem",
  },
  questionText: {
    fontSize: "0.8rem!important",
  },
}));

const CheckoutSidebar: React.FunctionComponent = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel1");
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <>
      <Box textAlign="center" mb={2} mt={2}>
        <YZTypography
          type="mono"
          variant="overline"
          style={{ fontWeight: 400 }}
          color="textSecondary"
        >
          What will happen next
        </YZTypography>
      </Box>
      {checkoutFAQs.map(question => (
        <Accordion
          key={question.index}
          elevation={0}
          className={classes.exp}
          expanded={expanded === `panel${question.index}`}
          onChange={() => setExpanded(`panel${question.index}`)}
        >
          <AccordionSummary
            className={classes.expSummary}
            expandIcon={<ExpandMore style={{ fontSize: 12 }} />}
          >
            <Box display="flex" alignItems="center">
              <Box className={classes.circleNumber}>{question.index}</Box>
              <YZTypography
                variant="body2"
                style={{ fontWeight: 500 }}
                className={classes.questionText}
              >
                {question.title}
              </YZTypography>
            </Box>
          </AccordionSummary>
          <AccordionDetails className={classes.expDetail}>
            <YZTypography variant="body2" className={classes.questionText}>
              {question.description}
            </YZTypography>
          </AccordionDetails>
        </Accordion>
      ))}
      <Box pr={smDown ? 0 : 4}>
        <Box p={3} pt={5}>
          <YZTypography
            style={{
              fontSize: 18,
              fontWeight: 300,
              textAlign: "center",
              letterSpacing: 0,
            }}
          >
            “The Yardzen process was fast and easy... and there was very little
            thinking required on my end. I was pleased.”
          </YZTypography>
          <Box p={3} textAlign="center">
            <img
              src={`${ASSETS_BASE_URL}/public/checkout-nytimes-logo.png`}
              alt="New York Times Logo"
              width="180px"
            />
          </Box>
        </Box>
        <Divider className={classes.divider} />
        <Box textAlign="center" pt={4}>
          <YZTypography
            paragraph
            style={{ fontSize: 14, fontWeight: 300, letterSpacing: 0 }}
          >
            Exactly what we needed. We're super demanding and particular about
            our use of space and have envisioned our front yard for several
            years.
          </YZTypography>
          <Box display="flex" justifyContent="center" mb={1}>
            <Star className={classes.starIcon} />
            <Star className={classes.starIcon} />
            <Star className={classes.starIcon} />
            <Star className={classes.starIcon} />
            <Star className={classes.starIcon} />
          </Box>
          <YZTypography
            type="mono"
            variant="body2"
            style={{ fontWeight: 500, display: "block" }}
            color="textSecondary"
          >
            Taylor Black
          </YZTypography>
          <YZTypography
            type="mono"
            variant="caption"
            color="textSecondary"
            style={{ opacity: 0.75 }}
          >
            Verified buyer
          </YZTypography>
        </Box>
      </Box>
    </>
  );
};

export { CheckoutSidebar };
export default CheckoutSidebar;
