import firebase from "firebase/compat";
import { DesignProfile } from "../../../api/apiTypes";

interface GetEmailArgs {
  userContext: firebase.User | null;
  designProfile: DesignProfile | undefined;
}

export const getEmail = ({
  userContext,
  designProfile,
}: GetEmailArgs): string => {
  // Get correct email for order event and stripe payment
  if (userContext && userContext.email) {
    return userContext.email;
  }

  if (designProfile?.contactInformation?.email) {
    return designProfile.contactInformation.email;
  }

  return "";
};
