import * as React from "react";
import ProgressIcon, { ProgressIconProps } from "./ProgressIcon";

import {
  Typography,
  useMediaQuery,
  Theme,
  TypographyProps,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export interface ProgressIconWithTextProps extends ProgressIconProps {
  text: string;
  variant?: "vertical" | "horizontal";
  fontSize?: string;
  iconStyle?: React.CSSProperties;
  iconSize?: string;
  typographyProps?: TypographyProps;
}

// use styles at the top of the file
const useStyles = makeStyles((theme: Theme) => ({
  text: {
    maxWidth: "30%",
    width: "fit-content",
    margin: "none",
    [theme.breakpoints.down("xs")]: {
      margin: "unset",
      textAlign: "center",
      maxWidth: "unset",
    },
  },
}));

const ProgressIconWithText: React.FC<ProgressIconWithTextProps> = ({
  text,
  fontSize,
  variant = "horizontal",
  style,
  iconStyle,
  iconSize,
  typographyProps,
  ...props
}: ProgressIconWithTextProps) => {
  const classes = useStyles();
  const xsDown = useMediaQuery(theme => (theme as any).breakpoints.down("xs"));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: getFlexDirection(variant),
        justifyContent: "center",
        alignItems: "center",
        width: "fit-content",
        ...style,
      }}
    >
      <ProgressIcon
        {...props}
        style={{ ...iconStyle, height: iconSize, width: iconSize }}
      />
      <Box pl={4} width="fit-content" className={classes.text}>
        <Typography
          className={classes.text}
          {...typographyProps}
          style={{
            fontSize: fontSize,
            minWidth: "200px",
            ...typographyProps?.style,
          }}
        >
          {text}
        </Typography>
      </Box>
    </div>
  );

  function getFlexDirection(propVariant: "horizontal" | "vertical") {
    if (xsDown) {
      return "column";
    }
    return propVariant === "horizontal" ? "row" : "column";
  }
};

ProgressIconWithText.defaultProps = {
  iconSize: "100px",
};

export { ProgressIconWithText };
export default ProgressIconWithText;
