export interface GoogleAnalyticsProductObject {
  // id = orderId
  id: string;
  sku: string;
  // name = package, category = lot size
  name: string;
  category: string;
  price: string;
}

export interface GoogleAnalyticsCheckoutObject {
  sku: string;
  // name = package, category = lot size
  name: string;
  category: string;
  price: string;
}

function sendGoogleAnalyticsPurchase(
  productObject: GoogleAnalyticsProductObject
) {
  const ga = window["ga"];
  if (
    !(process.env.NODE_ENV === "production") ||
    window.location.href.includes("dogfood") ||
    !ga
  ) {
    return;
  }

  const transactionObject = {
    id: productObject.id,
    revenue: productObject.price,
  };

  ga("ecommerce:addTransaction", transactionObject);
  ga("ecommerce:addItem", productObject);
  ga("ecommerce:send");
}

export { sendGoogleAnalyticsPurchase };
export default sendGoogleAnalyticsPurchase;
