import React, { FC, useContext } from "react";
import {
  Typography,
  Grid,
  Theme,
  CardActionArea,
  Box,
  capitalize,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { OnboardCtx, useSetTitle } from "../../util";
import { YardName } from "./stepData";
import StepWrapper from "./StepWrapper";
import transformYardStatusObjKey from "../../util/functions/transformYardStatusObjKey";
import SectionTitle from "./helpers/SectionTitle";
import { theme } from "@yardzen-inc/react-common";
import SaveBar from "./helpers/SaveBar";
import { useHistory } from "react-router";
import {
  SegmentClickTypes,
  SegmentFlows,
  SegmentInputTypes,
  SegmentSubFlows,
  useSegment,
} from "../../util/Segment";
import { ASSETS_BASE_URL } from "../../util/constants/assetsBaseUrl";

export interface Props {
  yard: YardName;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "800px",
    margin: "0 auto",
  },
  cardActionArea: {
    padding: 25,
    background: "#F2F2F2",
    borderRadius: 4,
    minHeight: 150,
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    marginBottom: "1.5rem",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "row nowrap",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column nowrap",
    },
  },
  formContainer: {
    textAlign: "center",
  },
  title: {
    letterSpacing: "0.2rem",
    flexGrow: 1,
  },
}));

const possiblePriorities = [
  "relax",
  "entertain",
  "kids play",
  "eat",
  "garden",
  "beautify",
  "work",
  "cook",
];

const Priorities: FC<Props> = ({ yard }) => {
  const segment = useSegment();
  useSetTitle(`Yardzen - ${yard} yard priorities`, false, [yard]);
  const history = useHistory();

  const classes = useStyles();
  const {
    state: { priorities },
    setYardPriorities,
    setYardStepStatus,
  } = useContext(OnboardCtx);

  React.useEffect(() => {
    setYardStepStatus(yard, "priorities", "INCOMPLETE");
  }, [setYardStepStatus, yard]);

  return (
    <StepWrapper>
      <SectionTitle
        title={`${transformYardStatusObjKey(yard)} Functions`}
        subtitle={`Choose the main functions of your dream${" "}
        ${transformYardStatusObjKey(yard).toLowerCase()}`}
        hasBack
      />
      <Grid container spacing={3}>
        {possiblePriorities.map((priority: any, index: number) => {
          const isChecked = priorities[yard]?.includes(priority) || false;
          const priorityUrl = priority.replace(/\s+/g, "-");
          const imageUrl = `${ASSETS_BASE_URL}/public/function-${priorityUrl}.svg`;
          return (
            <Grid item md={4} xs={6} key={`${priority}-${index}`}>
              <CardActionArea
                className={classes.cardActionArea}
                id={priority}
                onClick={e => {
                  handlePrioritiesChange(yard, priority, !isChecked);
                  segment.trackClicked({
                    button_name: `${capitalize(yard)} Yard Functions Item`,
                    click_type: SegmentClickTypes.BUTTON,
                    flow_name: SegmentFlows.ONBOARD,
                    sub_flow_name: SegmentSubFlows.MY_YARD,
                    button_content: priority,
                    function_name: priority,
                    on: !isChecked,
                  });
                }}
                style={
                  isChecked
                    ? { background: theme.palette.primary.main }
                    : { opacity: 0.8 }
                }
              >
                <Box mb={2}>
                  <img src={imageUrl} alt={priority} />
                </Box>
                <Typography align="center" style={{ letterSpacing: "1px" }}>
                  {priority.toUpperCase()}
                </Typography>
              </CardActionArea>
            </Grid>
          );
        })}
      </Grid>
      <SaveBar
        label="Save and complete →"
        onClick={handleSubmit}
        mdUpStyle={{ marginTop: 40, marginLeft: 130 }}
        smDownStyle={{ marginTop: 40 }}
      />
    </StepWrapper>
  );

  function handleSubmit() {
    history.push("/onboard/my-yard");
    const formName = `${capitalize(yard)} Yard Functions`;

    segment.trackFormFieldFilled({
      form_name: formName,
      flow_name: SegmentFlows.ONBOARD,
      sub_flow_name: SegmentSubFlows.MY_YARD,
      field_name: "Functions",
      input_type: SegmentInputTypes.MULTI_SELECT,
      options_selected: priorities?.[yard] ?? [],
    });

    segment.trackFormSubmitted({
      form_name: formName,
      flow_name: SegmentFlows.ONBOARD,
      sub_flow_name: SegmentSubFlows.MY_YARD,
    });
  }

  function handlePrioritiesChange(
    yard: YardName,
    priority: string,
    toBeChecked: boolean
  ) {
    if (toBeChecked) {
      if (!priorities[yard]?.includes(priority)) {
        const newPrioritySet = [...(priorities[yard] || []), priority];
        setYardPriorities(yard, newPrioritySet);
        return;
      }
    }

    const i = priorities[yard]?.indexOf(priority);
    if (i === undefined) return;
    const newPrioritySet = priorities[yard];
    newPrioritySet?.splice(i, 1);
    setYardPriorities(yard, newPrioritySet || []);
  }
};

export { Priorities };
export default Priorities;
