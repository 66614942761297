import React, { FC } from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";

export interface ChecklistListTitleProps extends BoxProps {}

const ChecklistListTitle: FC<ChecklistListTitleProps> = ({
  ...containerProps
}) => {
  return (
    <Box {...containerProps}>
      <Box textAlign="center" mt={2}>
        <YZTypography type="serif" variant="h4">
          What are your must-haves?
        </YZTypography>
        <Box pt={1}>
          <Typography variant="body1" style={{ marginBottom: 4 }}>
            Select items that you'd like to include in your design
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export { ChecklistListTitle };
export default ChecklistListTitle;
