import {
  FRONT_OR_BACKYARD_STARTER_PACKAGE,
  PARTIAL_YARD_PACKAGE,
  PackageTypes,
} from "../../../util/constants/packageTypes";
import { YardName } from "../stepData/types";

export function yardIsLocked(
  pkg: PackageTypes | undefined,
  yard: YardName,
  activeYards: YardName[]
): boolean {
  const primaryYards = ["front", "back"];
  switch (pkg) {
    case PARTIAL_YARD_PACKAGE:
    case FRONT_OR_BACKYARD_STARTER_PACKAGE:
      if (
        primaryYards.includes(yard) &&
        activeYards.some(actYard => primaryYards.includes(actYard))
      ) {
        return true;
      } else {
        return false;
      }
    default:
      return false;
  }
}
