import { Box, Theme, makeStyles, useMediaQuery } from "@material-ui/core";
import { ProfileProperties } from "@yardzen-inc/models";
import {
  GenericLoadingSpinner,
  YZButton,
  YZTypography,
} from "@yardzen-inc/react-common";
import React, { FC } from "react";

export interface ExteriorDesignPaintYesNoProps {
  handleYesClick: () => Promise<void>;
  handleNoClick: () => Promise<void>;
  profile: ProfileProperties;
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonTypographyProps: {
    minWidth: "180px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "90px",
    },
  },
}));

const ExteriorDesignPaintYesNo: FC<ExteriorDesignPaintYesNoProps> = ({
  handleYesClick,
  handleNoClick,
  profile,
}) => {
  const classes = useStyles();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  if (!profile) {
    return <GenericLoadingSpinner />;
  }

  return (
    <Box
      height="100%"
      textAlign="center"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      style={{ maxWidth: 880 }}
    >
      <Box p={4} pb={0}>
        <YZTypography variant={smDown ? "h5" : "h4"} type="serif">
          Would you like to include new paint colors in your exterior design
          project?
        </YZTypography>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="center" mt={4}>
        <Box p={1}>
          <YZButton
            typographyProps={{
              className: classes.buttonTypographyProps,
            }}
            color={profile.exteriorPaintDesired ? "primary" : undefined}
            onClick={handleYesClick}
          >
            Yes
          </YZButton>
        </Box>
        <Box p={1}>
          <YZButton
            typographyProps={{
              className: classes.buttonTypographyProps,
            }}
            color={
              profile.exteriorPaintDesired === false ? "primary" : undefined
            }
            onClick={handleNoClick}
          >
            No
          </YZButton>
        </Box>
      </Box>
    </Box>
  );
};

export { ExteriorDesignPaintYesNo };
export default ExteriorDesignPaintYesNo;
