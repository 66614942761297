import React, { FC, ReactNode, useContext } from "react";
import { HeaderBase } from "../header";
import {
  Box,
  useMediaQuery,
  Theme,
  IconButton,
  Link as MuiLink,
  makeStyles,
  Button,
} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { OnboardCtx, ProfileCtx } from "../../util";
import { useHistory } from "react-router";
import useMenuItems from "../onboard/useMenuItems";
import { YZTypography } from "@yardzen-inc/react-common";

export interface OnboardHeaderProps {
  onHelp: () => void;
  toggleMenu: () => void;
  menuOpen?: boolean;
  progress: number;
  setHelpModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles(theme => ({
  talkToUsBtn: {
    border: "2px solid black",
    borderRadius: "6px",
    padding: "4px 10px",
    cursor: "pointer",
  },
}));

const OnboardHeader: FC<OnboardHeaderProps> = props => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { talkToUsBtn } = useStyles();

  const onboardValues = useContext(OnboardCtx);
  const history = useHistory();
  const menuItems = useMenuItems();

  const clientProfile = React.useContext(ProfileCtx);

  return (
    <HeaderBase address={getHeaderTitle()} progress={props.progress}>
      {renderContent()}
    </HeaderBase>
  );

  function renderContent(): ReactNode {
    if (smDown) {
      return (
        <Box p={1}>
          <IconButton onClick={props.toggleMenu}>
            <Menu
              style={{
                transition: "transform 250ms",
                transform: props.menuOpen ? "rotate(30deg)" : undefined,
              }}
            ></Menu>
          </IconButton>
        </Box>
      );
    }

    return (
      <>
        {(process.env.NODE_ENV === "development" ||
          window.location.href.includes("dogfood")) && (
          <Box p={1}>
            <MuiLink
              onClick={async () => {
                if (onboardValues) {
                  await onboardValues._reset();
                  history.push("/onboard/my-yard/choose-yards");
                }
              }}
            >
              Reset
            </MuiLink>
          </Box>
        )}
        <Box px={2} style={{ borderRight: "1px solid #eee" }}>
          <YZTypography
            type="uppercase"
            style={{ fontWeight: 500 }}
            variant="caption"
          >
            {clientProfile && <>{clientProfile.firstName}'s Yard</>}
          </YZTypography>
        </Box>
        <Box p={1}>
          <Button
            className={talkToUsBtn}
            onClick={() => props.setHelpModalOpen(true)}
          >
            Talk to us
          </Button>
        </Box>
      </>
    );
  }

  function getHeaderTitle() {
    let indexOfItem;

    const filteredMenuItems = menuItems.filter(item => {
      if (
        !!clientProfile &&
        item.hideForPkg &&
        clientProfile.package === item.hideForPkg
      ) {
        return false;
      }
      return true;
    });

    const currentItem = filteredMenuItems.find((menuItem, index) => {
      if (
        !!menuItem.onboardTwoSlug &&
        window.location.href.includes(menuItem.onboardTwoSlug)
      ) {
        indexOfItem = index;
        return true;
      }
      return false;
    });

    if (!currentItem || indexOfItem === undefined) {
      return undefined;
    }

    // "Exterior Design (Step 3 of 10)" is too long of a phrase
    // to show in the header and causes the header to be
    // over 100vw wide which causes display overflow issues

    if (smDown && currentItem.title === "Exterior Design") {
      return `Exterior (Step ${indexOfItem + 1} of ${
        filteredMenuItems.length
      })`;
    }

    return `${currentItem?.title} (Step ${indexOfItem + 1} of ${
      filteredMenuItems.length
    })`;
  }
};

export { OnboardHeader };
export default OnboardHeader;
