import React, { FC, ReactNode, memo } from "react";
import {
  BudgetItem,
  MultipleImageSelectItemContainer,
  PriceOptionSelectItem,
} from "@yardzen-inc/react-common";
import { useBudgetChecklistItemPriceOptionsMinimalByChecklistItemIdQuery } from "@yardzen-inc/graphql";
import { useLogError } from "../../../util";
import { CircularProgress } from "@material-ui/core";

export interface PriceOptionSelectProps
  extends Pick<BudgetItem, "id" | "name" | "iconUri" | "description"> {
  onSelect: (id: string) => void;
  selectedItem: string;
}

const PriceOptionSelect: FC<PriceOptionSelectProps> = memo(
  ({ id: checklistItemId, selectedItem, onSelect }) => {
    const {
      data: optionData,
      error: optionError,
      loading,
    } = useBudgetChecklistItemPriceOptionsMinimalByChecklistItemIdQuery({
      variables: {
        checklistItemId,
      },
      fetchPolicy: "cache-and-network",
    });

    useLogError(optionError as Error);

    if (loading) {
      return <CircularProgress></CircularProgress>;
    }

    return (
      <MultipleImageSelectItemContainer>
        {renderItems()}
      </MultipleImageSelectItemContainer>
    );

    function renderItems(): ReactNode {
      if (!optionData?.budget_checklist_price_option) return null;

      return [...optionData.budget_checklist_price_option]
        .sort((a, b) => a.low_price - b.low_price)
        .map(item => {
          let image: string = "";
          let thumbnail: string | undefined = void 0;

          if (item.image?.image_variants.length) {
            for (const variant of item.image.image_variants) {
              if (!!image && !!thumbnail) break;

              if (variant.type === "ORIGINAL") {
                image = variant.medium.public_uri ?? "";
              } else if (variant.type === "THUMBNAIL") {
                thumbnail = variant.medium.public_uri ?? void 0;
              }
            }
          }

          return (
            <PriceOptionSelectItem
              highPrice={item.high_price}
              lowPrice={item.low_price}
              key={item.id}
              imageUri={image}
              thumbnailUri={thumbnail}
              itemId={item.id}
              onSelect={() => onSelect(item.id)}
              selected={selectedItem === item.id}
              description={item.description ?? void 0}
            />
          );
        });
    }
  },
  (oldp, newp) => {
    return oldp.id === newp.id && oldp.selectedItem === newp.selectedItem;
  }
);

export { PriceOptionSelect };
export default PriceOptionSelect;
