import * as React from "react";
import {
  Typography,
  FormControlLabel,
  makeStyles,
  Theme,
  FormControl,
  FormLabel,
  Box,
  useMediaQuery,
  RadioGroup,
  Radio,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import { OnboardCtx, fetchProfileDocByUserId, UserCtx } from "../../util";
import { useHistory } from "react-router";
import NavButtons from "./NavButtons";
import useHeaderVariant from "../../util/hooks/useHeaderVariant";
import classnames from "classnames";
import useSetTitle from "../../util/hooks/useSetTitle";
import {
  BOTANICAL_PACKAGE,
  FULL_YARD_PACKAGE,
  PackageTypes,
} from "../../util/constants/packageTypes";

interface ChooseYardRadioButtonsProps {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "960px",
    maxWidth: "100%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  inputs: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  inputBoxRow: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  checkBoxRow: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  checkBox: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  fullYardPrimaryLabel: {
    textDecoration: "line-through",
  },
}));

const ChooseYardRadioButtons: React.FunctionComponent<ChooseYardRadioButtonsProps> = props => {
  useSetTitle("Yardzen - Choose Yard", true, []);

  const classes = useStyles();
  const history = useHistory();
  const {
    state: { yardsStatusObj },
    setYardsStatusObj,
  } = React.useContext(OnboardCtx);

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));

  const [primaryYard, setPrimaryYard] = React.useState<
    "front" | "back" | "both"
  >();
  const [secondaryYards, setSecondaryYards] = React.useState<
    ("left" | "right")[]
  >([]);

  const user = React.useContext(UserCtx);
  const [pkg, setPkg] = React.useState<PackageTypes>();

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<boolean>(false);

  const [headerVariant, subHeaderVariant] = useHeaderVariant();

  React.useEffect(() => {
    if (!!user && !pkg) {
      getPurchasedPackage(user.uid);
    }
  }, [pkg, user]);

  React.useEffect(() => {
    if (
      !primaryYard &&
      !secondaryYards.length &&
      !!Object.keys(yardsStatusObj).length &&
      !!pkg
    ) {
      prefillSelections();
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yardsStatusObj, pkg]);

  if (loading) {
    return (
      <Box width="100%" mt="150px" display="flex" justifyContent="center">
        <CircularProgress color="primary" size={75} />
      </Box>
    );
  }

  return (
    <Box className={classes.root} px={xsDown ? 2 : 5}>
      <Typography variant={headerVariant as any}>
        We can’t wait to get started!
      </Typography>
      <Typography variant={subHeaderVariant as any}>
        But first, which yards are we designing?
      </Typography>

      <div className={classes.inputs}>
        <FormControl error={!!error}>
          <FormLabel color="primary" component="legend">
            <span
              className={
                pkg === FULL_YARD_PACKAGE
                  ? classes.fullYardPrimaryLabel
                  : undefined
              }
            >
              Please choose one:
            </span>
            {pkg === FULL_YARD_PACKAGE && (
              <Typography> You purchased a full yard design!</Typography>
            )}
          </FormLabel>
          <Box pl={smDown ? 2 : 4} className={classes.inputBoxRow}>
            <RadioGroup
              aria-label="primary yard"
              name="primaryYard"
              value={!!primaryYard ? primaryYard : null}
              onChange={handlePrimaryChange}
              style={{ display: "flex" }}
            >
              <FormControlLabel
                value="front"
                control={<Radio color="primary" />}
                label="Front yard"
                checked={pkg === FULL_YARD_PACKAGE ? true : undefined}
                disabled={pkg === FULL_YARD_PACKAGE}
              />
              <FormControlLabel
                value="back"
                control={<Radio color="primary" />}
                label="Back yard"
                checked={pkg === FULL_YARD_PACKAGE ? true : undefined}
                disabled={pkg === FULL_YARD_PACKAGE}
              />
              {pkg === BOTANICAL_PACKAGE && (
                <FormControlLabel
                  value="both"
                  control={<Radio color="primary" />}
                  label="Front and Back yards"
                />
              )}
            </RadioGroup>
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel>Please select all that apply:</FormLabel>
          <Box
            pl={smDown ? 2 : 4}
            className={classnames(classes.checkBoxRow, classes.inputBoxRow)}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={secondaryYards.includes("left")}
                  onChange={handleSecondaryChange}
                  name="left"
                  color="primary"
                  value="left"
                />
              }
              label="Left side yard"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={secondaryYards.includes("right")}
                  onChange={handleSecondaryChange}
                  name="right"
                  color="primary"
                  value="right"
                  className={classes.checkBox}
                />
              }
              label="Right side yard"
            />
          </Box>
        </FormControl>
      </div>
      <NavButtons next={handleSubmit}></NavButtons>
    </Box>
  );

  function handleSecondaryChange(event: React.ChangeEvent<HTMLInputElement>) {
    const yardIdx = secondaryYards.indexOf(
      event.target.value as "left" | "right"
    );

    if (yardIdx >= 0) {
      setSecondaryYards([
        ...secondaryYards.slice(0, yardIdx),
        ...secondaryYards.slice(yardIdx + 1),
      ]);
    } else {
      setSecondaryYards([
        ...secondaryYards,
        event.target.value as "left" | "right",
      ]);
    }
  }

  function handlePrimaryChange(evt: React.ChangeEvent<HTMLInputElement>) {
    if (primaryYard === evt.target.value) {
      setPrimaryYard(undefined);
    } else {
      setPrimaryYard(evt.target.value as "front" | "back");
    }
  }

  function prefillSelections(): void {
    if (!Object.keys(yardsStatusObj).length) return;

    const yards = Object.keys(yardsStatusObj);
    const tempSecondaryYards = [];

    if (
      pkg === BOTANICAL_PACKAGE &&
      yards.includes("front") &&
      yards.includes("back")
    ) {
      setPrimaryYard("both");
    } else {
      prefillPrimaryYard(yards);
    }

    if (yards.includes("left")) {
      tempSecondaryYards.push("left");
    }

    if (yards.includes("right")) {
      tempSecondaryYards.push("right");
    }

    if (!!tempSecondaryYards.length) {
      setSecondaryYards(tempSecondaryYards as ("left" | "right")[]);
    }
  }

  function prefillPrimaryYard(yards: string[]) {
    if (yards.includes("front")) {
      setPrimaryYard("front");
    } else if (yards.includes("back")) {
      setPrimaryYard("back");
    } else {
      setPrimaryYard(undefined);
    }
  }

  async function getPurchasedPackage(userId: string) {
    try {
      const profileDoc = await fetchProfileDocByUserId(userId);
      if (!profileDoc.exists) {
        // TODO: HANDLE NO PROFILE FOUND
        return;
      }
      const profileData = profileDoc.data();
      const pkgFromProfile = profileData?.package as PackageTypes;
      setPkg(pkgFromProfile);
    } catch (err) {
      window.newrelic.noticeError(err);
      // TODO: HANDLE ERROR
      console.error(err.message);
    }
  }

  function handleSubmit(): void {
    if (!primaryYard && pkg !== FULL_YARD_PACKAGE) {
      return setError(true);
    }
    setYardsStatusObj(
      primaryYard as "front" | "back" | "both",
      secondaryYards,
      pkg === FULL_YARD_PACKAGE
    );

    history.push("/onboard/my-yard/instructions");
  }
};

export { ChooseYardRadioButtons };
export default ChooseYardRadioButtons;
