import React, { CSSProperties } from "react";
import { Theme, useMediaQuery } from "@material-ui/core";
import { YZButton } from "@yardzen-inc/react-common";
import { makeStyles } from "@material-ui/styles";

interface SaveBarProps {
  label: string;
  mobileLabel?: string;
  lastStep?: boolean;
  disabled?: boolean;
  onClick: () => void;
  mdUpStyle?: CSSProperties;
  smDownStyle?: CSSProperties;
}

const useStyles = makeStyles((theme: Theme) => ({
  btn: {
    background: "#000",
    color: "#fff",
    boxShadow: "0 10px 30px rgba(0,0,0,0.15)",
    border: 0,
    transition: "all ease-in 0.15s",
    zIndex: 1000,
    position: "absolute",
    bottom: "1.5rem",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "relative",
    },
    "& span": {
      fontWeight: 400,
      [theme.breakpoints.down("sm")]: {
        "& p": {
          fontSize: "14px !important",
        },
      },
    },
    "&:hover": {
      color: "#000",
      transform: "translate(0, -2px)",
    },
  },
}));

export const SaveBar: React.FC<SaveBarProps> = ({
  label,
  lastStep = false,
  disabled = false,
  onClick,
  mdUpStyle,
  smDownStyle,
  mobileLabel = "Save and Continue →",
}) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();

  return (
    <YZButton
      variant="contained"
      color="primary"
      className={classes.btn}
      onClick={onClick}
      disabled={disabled}
      style={
        !smDown
          ? // add custom margin left or right margin to mdUpStyle if button is not centered
            { ...mdUpStyle, left: `50%`, transform: "translateX(-50%)" }
          : { ...smDownStyle, marginBottom: 60 }
      }
    >
      {smDown && !lastStep ? mobileLabel : label}
    </YZButton>
  );
};

export default SaveBar;
