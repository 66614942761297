import * as React from "react";
import { ArrowBack } from "@material-ui/icons";
import { makeStyles, Theme } from "@material-ui/core";
import { YZButton } from "@yardzen-inc/react-common";

interface GoBackButtonProps {
  goBack: () => void;
  label?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&:hover": {
      background: "none",
    },
  },
}));

const GoBackButton: React.FunctionComponent<GoBackButtonProps> = ({
  goBack,
  label,
}) => {
  const classes = useStyles();
  return (
    <YZButton
      onClick={goBack}
      className={classes.root}
      variant="text"
      startIcon={<ArrowBack style={{ fontSize: 13, marginRight: "-1rem" }} />}
      typographyProps={{
        color: "textSecondary",
        style: { fontSize: 13 },
      }}
    >
      {label ?? "Go Back"}
    </YZButton>
  );
};

export default GoBackButton;
