import firebase from "firebase/compat";
import { DesignProfileCtxValue } from "../../designProfile";

interface GetUserIdArgs {
  userContext: firebase.User | null;
  dsContext: DesignProfileCtxValue | null;
}

export const getUserId = ({
  userContext,
  dsContext,
}: GetUserIdArgs): string | undefined => {
  // Get correct user id for order event and stripe payment
  if (userContext) {
    return userContext.uid;
  }

  if (dsContext?.user.uid) {
    return dsContext.user.uid;
  }

  throw new Error("No user ID found!");
};
