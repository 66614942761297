export const getPriceObjects = async () => {
  try {
    const response = await fetch(
      `https://us-central1-${process.env.REACT_APP_PROJECTID}.cloudfunctions.net/getAllStripePriceObjects`
    );
    const json = await response.json();
    return json.data;
  } catch (err) {
    window.newrelic.noticeError(err);
    // There needs to be a discussion on global error handling
    // The result would go here
    console.error(err);
  }
};
