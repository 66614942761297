import * as React from "react";
import { Theme, makeStyles, Box } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";

export interface DesignProfileLayoutProps {
  children: React.ReactNode;
  backAction?: () => void;
  title?: string;
  subtitle?: string;
  desktopWidth?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: "0 auto",
    padding: "3rem 2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1.5rem 1rem 6rem",
    },
  },
  withBackAction: {
    margin: "0 auto",
    padding: "1.5rem 2rem 6rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem 1rem 6rem",
    },
  },
  backButton: {
    "&:hover": {
      background: "none",
    },
  },
}));

const DesignProfileLayout: React.FC<DesignProfileLayoutProps> = ({
  children,
  title,
  subtitle,
  backAction,
  desktopWidth,
}) => {
  const classes = useStyles();

  return (
    <div
      className={backAction ? classes.withBackAction : classes.root}
      style={{ maxWidth: desktopWidth || "800px" }}
    >
      {backAction && (
        <Box mb={3}>
          <YZButton
            onClick={backAction}
            className={classes.backButton}
            variant="text"
            startIcon={
              <ArrowBack style={{ fontSize: 13, marginRight: "-1rem" }} />
            }
            typographyProps={{
              color: "textSecondary",
              style: { fontSize: 13 },
            }}
            tabIndex={0}
          >
            Back
          </YZButton>
        </Box>
      )}
      <Box>
        {title && (
          <YZTypography align="center" variant="h2" type="serif">
            {title}
          </YZTypography>
        )}
        {subtitle && (
          <Box pt={2}>
            <YZTypography align="center" color="textSecondary">
              {subtitle}
            </YZTypography>
          </Box>
        )}
        {children}
      </Box>
    </div>
  );
};

export { DesignProfileLayout };
export default DesignProfileLayout;
