import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { DesignProfileQuestionResponse } from "../../../api/apiTypes";
import { isOptionChecked } from "../../../pages/designProfile/util/quizPage/isOptionChecked";
import { handleQuizPageSelect } from "../../../pages/designProfile/util/quizPage/handleQuizPageSelect";
import {
  Selections,
  DesignProfileQuizSelectionsProps,
  Page,
} from "../../../types/DesignProfile";
import { useDesignProfileCtx } from "../DesignProfileCtx";

export type SelectionsListProps = {
  page: Page;
  options: Selections;
  responses: DesignProfileQuestionResponse;
  setResponses: (responses: any) => void;
  defaultSelections: boolean;
  SelectionComponent: FC<DesignProfileQuizSelectionsProps>;
};

const useStyles = makeStyles(() => ({
  defaultSelectionContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    maxWidth: "900px",
    "& label": {
      margin: "10px",
    },
  },
  selectionsHeader: {
    fontSize: "20px",
    textTransform: "uppercase",
    marginBottom: "25px",
  },
}));

/**
 * Loops through page selection options and renders either Checkbox or Radio selection options for user
 *
 * @param props The SelectionsListProps to pass to the component
 * @returns The SelectionsList component.
 */
const SelectionsList: FC<SelectionsListProps> = ({
  page,
  options,
  responses,
  setResponses,
  defaultSelections,
  SelectionComponent,
}) => {
  const { defaultSelectionContainer, selectionsHeader } = useStyles();
  const dsContext = useDesignProfileCtx();

  return (
    <>
      {Object.keys(page.selections || {}).map(
        (optionKey: string, colIndex: number) => {
          return (
            <div
              key={colIndex}
              className={defaultSelections ? defaultSelectionContainer : ""}
            >
              {!defaultSelections && (
                <div className={selectionsHeader}>
                  {optionKey.replace(/_/i, " ")}
                </div>
              )}
              {(options?.[optionKey as keyof Selections] ?? []).map(
                (selection: string, selectionIndex: number) => (
                  <SelectionComponent
                    key={selectionIndex}
                    selection={selection}
                    optionKey={optionKey as keyof Selections}
                    handleSelect={(selectionValue, selectionKey) =>
                      handleQuizPageSelect({
                        existingResponse: responses,
                        selectionValue,
                        dsContext,
                        page,
                        selectionKey,
                        setUserResponses: setResponses,
                      })
                    }
                    checked={isOptionChecked({
                      responses: responses,
                      value: selection,
                    })}
                  />
                )
              )}
            </div>
          );
        }
      )}
    </>
  );
};

export { SelectionsList };
