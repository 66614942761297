import React, { FC, useState } from "react";
import {
  Grow,
  Box,
  Typography,
  Hidden,
  CircularProgress,
} from "@material-ui/core";
import { PlacesAddressForm } from "../designProfile/PlacesAddressForm";
import { IAddressObject } from "../../util/functions/parseGeocodeLocationToAddress";
import { formatAddress } from "../../util/functions/formatAddress";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import { useDesignProfileCtx } from "../designProfile/DesignProfileCtx";
import { getParsedAddressFromPlaceId } from "../map/util/getParsedAddressFromPlaceId";

export interface PropertyAddressFormProps {
  propertyAddress: IAddressObject;
  setAddress: (address: IAddressObject) => void;
  handleSubmitPropertyAddress: () => void;
  errorMessage?: string | false | null;
  userEmail: string | null;
}

const PropertyAddressForm: FC<PropertyAddressFormProps> = ({
  propertyAddress,
  setAddress,
  handleSubmitPropertyAddress,
  errorMessage,
  userEmail,
}) => {
  const { designProfile, updateDesignProfile } = useDesignProfileCtx();
  const [processingAddress, setProcessingAddress] = useState<boolean>(false);

  return (
    <Box width="100%">
      <Grow
        mountOnEnter
        unmountOnExit
        in={true} // Always true for apple pay customers
      >
        <Box mb={2} mt={2}>
          <Typography>What address are we designing for?</Typography>
          <PlacesAddressForm
            onSubmitAddress={async address =>
              updateDesignProfileOnChange(address)
            }
            defaultAddress={formatAddress(propertyAddress)}
            handleAddressSelection={handleAddressSelection}
          />
          <YZButton
            fullWidth
            color="primary"
            onClick={handleSubmitPropertyAddress}
            disabled={processingAddress}
          >
            {processingAddress ? <CircularProgress /> : "Submit"}
          </YZButton>
          <Hidden>
            <YZTypography style={{ textAlign: "center" }} color="error">
              {errorMessage}
            </YZTypography>
          </Hidden>
        </Box>
      </Grow>
    </Box>
  );

  async function handleAddressSelection({ value }: { value: any; label: any }) {
    setProcessingAddress(true);
    const parsedAddress = await getParsedAddressFromPlaceId(value.place_id);

    await updateDesignProfileOnChange(parsedAddress);

    setProcessingAddress(false);
  }

  async function updateDesignProfileOnChange(address: IAddressObject) {
    if (designProfile) {
      await updateDesignProfile({
        contactInformation: {
          ...designProfile?.contactInformation,
          ...address,
          latLng: {
            lat: (address.latLng?.lat as number) ?? 0,
            lng: (address.latLng?.lng as number) ?? 0,
          },
          email: userEmail ?? "",
        },
      });
    }

    setAddress(address);
  }
};

export { PropertyAddressForm };
export default PropertyAddressForm;
