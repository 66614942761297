import * as React from "react";
import {
  IconButton,
  IconButtonProps,
  BoxProps,
  Box,
  SvgIconProps,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";

export interface HelpIconProps extends IconButtonProps {
  iconProps?: SvgIconProps;
  containerProps?: BoxProps;
}

const HelpIcon: React.FC<HelpIconProps> = ({
  iconProps,
  containerProps,
  ...props
}) => {
  return (
    <Box p={1} {...containerProps}>
      <IconButton {...props}>
        <Info {...iconProps} />
      </IconButton>
    </Box>
  );
};

HelpIcon.defaultProps = {
  size: "small",
};

export { HelpIcon };
export default HelpIcon;
