import { Box, Typography } from "@material-ui/core";
import React, { FC } from "react";

export interface CrashPageProps {
  error: unknown;
}

const CrashPage: FC<CrashPageProps> = props => {
  const COOKIES_DISABLED_ERROR = "The operation is insecure.";
  const isCookiesDisabledError =
    (props.error as Error)?.message === COOKIES_DISABLED_ERROR;

  const title = isCookiesDisabledError
    ? "Please check your browser settings to ensure that cookies are enabled."
    : "We're sorry, but something went wrong.";
  const subtitle = isCookiesDisabledError
    ? "Uncheck the option to block cookies or use a different browser."
    : "Our engineering team has been alerted and is looking into the problem. Please check back soon.";
  return (
    <>
      <Box display="flex" justifyContent="center" pt={4} px={2}>
        <Box>
          <Typography variant="h3" align="center">
            {title}
          </Typography>
          <Typography variant="h5" align="center">
            {subtitle}
          </Typography>
          <Typography align="center">
            Contact us:{" "}
            <a href="mailto:hello@yardzen.com" type="em">
              hello@yardzen.com
            </a>{" "}
            <a href="tel:8889273936">(888) 927-3936</a>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export { CrashPage };
