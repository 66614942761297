import { Box } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import React, { ReactNode } from "react";

type ItemizedPaymentDetailProps = {
  title: string;
  children?: ReactNode;
};

export const ItemizedPaymentDetail = ({
  title,
  children,
}: ItemizedPaymentDetailProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      style={{ borderBottom: "1px solid #eee" }}
      py={2}
      px={1}
    >
      <YZTypography
        variant="body1"
        color="textSecondary"
        style={{ marginRight: 4 }}
      >
        {title}
      </YZTypography>
      {children}
    </Box>
  );
};
