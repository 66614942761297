import React from "react";
import { Box, TextField, makeStyles, Theme, Grid } from "@material-ui/core";
import classnames from "classnames";
import {
  SegmentForms,
  SegmentInputTypes,
  useSegment,
} from "../../util/Segment";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setFirstName, setLastName } from "../../slices/checkoutSlice";
import { segmentBlurHandler } from "./util/segmentBlurHandler";

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    display: "flex",
    flexDirection: "column",
  },
  spacingTop: {
    marginTop: theme.spacing(4),
  },
}));

const NameInput = ({ hasTriedSubmitting }: { hasTriedSubmitting: boolean }) => {
  const segment = useSegment();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { firstName, lastName } = useAppSelector(state => state.checkout);

  const handleSetFirstName = (firstName: string) => {
    dispatch(setFirstName({ firstName }));
  };

  const handleSetLastName = (lastName: string) => {
    dispatch(setLastName({ lastName }));
  };

  return (
    <Box>
      <Box className={classnames(classes.column, classes.spacingTop)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="billing-first-name-input"
              error={hasTriedSubmitting && !firstName.length}
              label="First name"
              value={firstName}
              onChange={e => handleSetFirstName(e.target.value)}
              required
              onBlur={segmentBlurHandler({
                formFieldProps: {
                  field_name: "First Name",
                  form_name: SegmentForms.CHECKOUT_BILLING_DETAILS,
                  input_type: SegmentInputTypes.TEXT,
                },
                value: firstName,
                segment,
              })}
              variant="outlined"
              fullWidth
              autoComplete="given-name"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="billing-last-name-input"
              error={hasTriedSubmitting && !lastName.length}
              label="Last name"
              onBlur={segmentBlurHandler({
                formFieldProps: {
                  field_name: "Last Name",
                  form_name: SegmentForms.CHECKOUT_BILLING_DETAILS,
                  input_type: SegmentInputTypes.TEXT,
                },
                value: lastName,
                segment,
              })}
              value={lastName}
              onChange={e => handleSetLastName(e.target.value)}
              required
              variant="outlined"
              fullWidth
              autoComplete="family-name"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export { NameInput };
export default NameInput;
