import * as React from "react";
import {
  Box,
  Theme,
  Paper,
  TextField,
  CircularProgress,
  useTheme,
  BoxProps,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Falsey } from "../../util";
import { Search } from "@material-ui/icons";
import MapController from "./util/MapController";
import GeocodeResultsPopup from "./GeocodeResultsPopup";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import {
  SegmentFlows,
  SegmentInputTypes,
  SegmentForms,
} from "../../util/Segment/constants";
import { useSegment } from "../../util/Segment";

const numbers = /[0-9]/;
const letters = /[a-z]/i;

export type Geocode = (req: google.maps.GeocoderRequest) => Promise<any>;
export interface ObjWithGeocode {
  geocode: Geocode;
}

export interface GeocodeBarProps {
  mpController?: MapController | ObjWithGeocode | Falsey;
  containerBoxProps?: BoxProps;
  inputContainerStyles?: React.CSSProperties;
  autoFocus?: boolean;
  permanent?: boolean;
  focused?: boolean;
  setFocused?: (l: boolean) => void;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onSelectedLocationChange?: (
    location: google.maps.GeocoderResult,
    geocode: Geocode
  ) => Promise<any>;
  immutableLocations?: boolean;
  initialQuery?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  inputContainer: {
    flexGrow: 1,
    maxWidth: "99vw",
    width: "100%",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    borderRadius: "1px",
    transition: "border-radius 200ms",
    backgroundColor: "#fff",
  },
  input: {
    "& div": {
      "&::before": {
        borderBottom: "none!important",
      },
      "&::after": {
        borderBottom: "none!important",
      },
    },
  },
}));

const GeocodeBar: React.FC<GeocodeBarProps> = React.memo(
  ({
    mpController,
    onSelectedLocationChange,
    containerBoxProps,
    inputContainerStyles,
    onChange,
    autoFocus,
    permanent,
    immutableLocations,
    initialQuery,
    ...props
  }: GeocodeBarProps) => {
    const segment = useSegment();
    const classes = useStyles();
    const theme = useTheme();

    const barRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const focusTimeoutRef = React.useRef<any>(null);

    const [
      selectedLocation,
      setSelectedLocation,
    ] = React.useState<google.maps.GeocoderResult | null>(null);
    const [query, setQuery] = React.useState<string>(initialQuery ?? "");
    // BUUG: "searching" is not always set to false after a search completes.
    const [searching, setSearching] = React.useState<boolean>(false);
    const [focused, setFocused] = React.useState<boolean>(false);
    const [results, setResults] = React.useState<
      google.maps.GeocoderResult[] | null
    >(null);
    const smDown = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down("sm")
    );

    const iconStyle = React.useMemo(
      () => ({
        height: smDown ? theme.spacing(4) : theme.spacing(3),
        width: smDown ? theme.spacing(4) : theme.spacing(3),
        alignSelf: "center",
      }),
      [theme, smDown]
    );

    // TODO: remove disable comment and fix warning next time these hooks are updated
    /* eslint-disable react-hooks/exhaustive-deps */
    React.useEffect(onQueryChange, [query]);
    React.useEffect(handleSelectedLocationChange, [
      immutableLocations
        ? selectedLocation
        : selectedLocation?.place_id || null,
    ]);
    /* eslint-enable react-hooks/exhaustive-deps */

    return (
      <>
        <GeocodeResultsPopup
          open={focused || !!(permanent && results?.length)}
          searching={searching}
          anchor={barRef}
          onSelect={r => {
            if (!r) {
              return;
            }

            setSelectedLocation(immutableLocations ? { ...r } : r);
          }}
          results={results}
        />
        <Box
          p={1}
          zIndex={2}
          width="100vw"
          maxWidth="98vw"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="flex-end"
          {...containerBoxProps}
        >
          <Paper className={classes.inputContainer} ref={barRef}>
            {searching ? (
              <CircularProgress style={iconStyle} />
            ) : (
              <Search style={iconStyle} />
            )}
            <Box flexGrow={1} p={smDown ? 2 : 0} px={1}>
              <TextField
                color="primary"
                autoComplete="off"
                spellCheck="false"
                autoFocus={!!autoFocus}
                className={classes.input}
                onFocus={() => {
                  setFocused(true);
                  if (props.setFocused) {
                    props.setFocused(true);
                  }

                  if (query) {
                    onQueryChange();
                  }
                }}
                onBlur={() => {
                  focusTimeoutRef.current = setTimeout(() => {
                    if (props.setFocused) {
                      props.setFocused(false);
                    }
                  }, 250);

                  setFocused(false);
                }}
                inputRef={inputRef}
                inputProps={{
                  id: "address-input",
                }}
                onKeyDown={e => {
                  if (
                    e.key === "ArrowDown" ||
                    e.key === "ArrowUp" ||
                    e.key === "Enter"
                  ) {
                    e.preventDefault();
                  }
                }}
                fullWidth
                value={query}
                onChange={e => {
                  if (onChange) {
                    onChange(e);
                  }

                  setQuery(e.currentTarget.value);
                }}
              />
            </Box>
          </Paper>
        </Box>
      </>
    );

    function onQueryChange(): (() => void) | void {
      if (!query || !focused) {
        if (searching !== false) {
          setSearching(false);
        }

        return;
      }

      if (!mpController) {
        throw new Error("tried to query google maps without initializing");
      }

      let timeout: any = null;

      if (!searching) {
        setSearching(true);
      }

      timeout = setTimeout(timoutHandler, 700);

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };

      async function timoutHandler() {
        timeout = null;

        let _query = query.toLowerCase();

        try {
          if (
            _query.length > 5 &&
            numbers.test(_query) &&
            letters.test(_query)
          ) {
            let results!: google.maps.GeocoderResult[];

            const encoder = new TextEncoder();
            const buffer = await crypto.subtle.digest(
              "SHA-256",
              encoder.encode(_query)
            );
            const key = Array.from(new Uint8Array(buffer))
              .map(b => b.toString(16).padStart(2, "0"))
              .join("");

            do {
              const cached = await getCachedGeocoderResult(key);

              if (cached) {
                results = cached;
                break;
              }

              results = await geocodeAddress(
                _query,
                mpController as MapController
              );

              if (results.length) {
                setCachedGeocoderResult(key, results);
              }
            } while (false);

            setResults(results);

            segment.trackFormFieldFilled({
              field_name: "Geocoder Address",
              flow_name: SegmentFlows.DESIGN_PROFILE,
              input_type: SegmentInputTypes.TEXT,
              form_name: SegmentForms.DESIGN_PROFILE_ADDRESS,
            });
          }
        } catch (err) {
          window.newrelic.noticeError(err);
          console.error(err);
        } finally {
          setSearching(false);
        }
      }
    }

    function handleSelectedLocationChange(): void {
      void (function(): void {
        if (mpController && selectedLocation) {
          if (onSelectedLocationChange) {
            inputRef.current?.blur();
            setTimeout(() => {
              onSelectedLocationChange(
                { ...selectedLocation },
                mpController.geocode
              );
              setQuery(selectedLocation.formatted_address);
            });
            return;
          }

          if (!(mpController instanceof MapController)) {
            return;
          }

          for (const marker of mpController.markers) {
            mpController.removeMarker(marker);
          }

          mpController.map.setCenter(selectedLocation.geometry.location);
          mpController.addMarker(
            selectedLocation.geometry.location,
            selectedLocation.formatted_address
          );
          setQuery(selectedLocation.formatted_address);
          inputRef.current?.blur();
        }
      })();
    }
  },
  (n, p) => n.mpController === p.mpController
);

async function setCachedGeocoderResult(
  key: string,
  val: google.maps.GeocoderResult[] | string,
  localOnly: boolean = false
) {
  const stringifiedValue = JSON.stringify(val);

  localStorage.setItem(
    key,
    typeof val === "string"
      ? val
      : JSON.stringify({
          value: val,
          createdAt: new Date().getTime(),
        })
  );

  if (localOnly) {
    return;
  }

  await firebase
    .firestore()
    .collection("gmapsGeocodeCache")
    .doc(key)
    .set({
      value: stringifiedValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
}

async function getCachedGeocoderResult(
  key: string,
  only?: "local" | "remote"
): Promise<google.maps.GeocoderResult[] | null> {
  let cached!: any;

  do {
    const localCached = localStorage.getItem(key);

    if (localCached && only !== "remote") {
      cached = JSON.parse(localCached).value as google.maps.GeocoderResult[];

      if (process.env.NODE_ENV === "development") {
        console.log("cache from local");
      }
      break;
    }

    if (only === "local") {
      break;
    }

    const cachedDoc = await firebase
      .firestore()
      .collection("gmapsGeocodeCache")
      .doc(key)
      .get();

    try {
      if (cachedDoc.exists) {
        cached = JSON.parse(`{"cached":${cachedDoc.get("value")}}`).cached;
        if (process.env.NODE_ENV === "development") {
          console.log("cache from network");
        }

        if (!localCached) {
          await setCachedGeocoderResult(key, cached, true);
        }
      }
    } catch (err) {
      window.newrelic.noticeError(err);
      console.error(err);
    }
  } while (false);

  return (!!cached && cached) || null;
}

async function geocodeAddress(
  query: string,
  mpController: MapController
): Promise<google.maps.GeocoderResult[]> {
  const _results = await mpController.geocode({
    address: query,
  });

  const results = _results.results.filter(result => {
    /*
     * Docs on possible address & location types and what they mean:
     * https://developers.google.com/maps/documentation/geocoding/overview#reverse-restricted
     *
     * Docs on why I couldn't do this through the SDK:
     * https://developers.google.com/maps/documentation/javascript/geocoding#ComponentFiltering
     */
    return (
      result.geometry.location_type.includes("ROOFTOP") ||
      result.types.includes("street_address")
    );
  });

  return results || [];
}

GeocodeBar.defaultProps = {
  containerBoxProps: {
    position: "fixed",
    top: 0,
  },
};

export { GeocodeBar };
export default GeocodeBar;
