import { budgetOptions } from "../../pages/designProfile/util/quizPage/quizSelections";

export type BudgetOptions =
  | "Less than $10,000"
  | "$10,000 - $25,000"
  | "$25,000 - $50,000"
  | "$50,000 - $75,000"
  | "$75,000 - $100,000"
  | "$100,000 - $150,000"
  | "$150,000+";

export const uberPremiumBudgets: BudgetOptions[] | typeof budgetOptions = [
  "$50,000 - $75,000",
  "$75,000 - $100,000",
  "$100,000 - $150,000",
  "$150,000+",
  "$50,000 - $100,000",
  "$100,000+",
];

export const starterBudgets: BudgetOptions[] = ["Less than $10,000"];
