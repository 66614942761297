import { TimeoutError } from "./errors/TimeoutError";

/**
 * `withTimeout`  will await the resolution of a promise but throw a
 * `TimeoutError` it does not happen within the specified timeout.
 * @param promise Promise to be resolved
 * @param timeout milliseconds to wait before rejecting
 * @throws `TimeoutError` if the promise does not resolve within `timeout` milliseconds
 * @returns the resolved value of `promise`
 */
export async function withTimeout<T>(
  promise: Promise<T>,
  timeout: number
): Promise<T> {
  let done = false;

  const timeoutPromise = new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      if (!done) {
        reject(new TimeoutError("Timed out"));
      }

      resolve();
    }, timeout);
  });

  const result = (await Promise.race([promise, timeoutPromise])) as T;
  done = true;

  return result;
}
