import { DesignProfile } from "../../../api/apiTypes";
import firebase from "firebase/compat/app";

interface GetInitialEmailForCreateAccountFormArgs {
  prefilledEmail?: string;
  designProfile?: DesignProfile;
  user?: Pick<firebase.User, "email"> | null;
}

/**
 * Retrieves the initial value for email for the create account form.
 * @param prefilledEmail A string with a prefilled email
 * @param designProfile A design profile for the user
 * @param user A user record for the user
 * @returns The initial value for email for the create account form.
 */
export const getInitialEmailForCreateAccountForm = ({
  prefilledEmail,
  designProfile,
  user,
}: GetInitialEmailForCreateAccountFormArgs): string => {
  if (prefilledEmail) {
    return prefilledEmail?.trim();
  }

  if (designProfile?.contactInformation?.email) {
    return designProfile.contactInformation.email?.trim();
  }

  if (user?.email) {
    return user.email?.trim();
  }

  return "";
};
