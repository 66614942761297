import { Box, LinearProgress } from "@material-ui/core";
import {
  BudgetMetadataAndPhasingByProjectIdQueryResult,
  useBudgetMetadataAndPhasingByProjectIdQuery,
} from "@yardzen-inc/graphql";
import React, { FC, useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { useLogError } from "../../../util";
import { BudgetStepCtx } from "./BudgetStepContext";
import { ChecklistBudgetPhasingPage } from "./ChecklistBudgetPhasingPage";

export interface ChecklistBudgetPhasingPageWrapperProps
  extends RouteComponentProps {}

export interface BudgetMetadat
  extends BudgetMetadataAndPhasingByProjectIdQueryResult {}

const ChecklistBudgetPhasingPageWrapper: FC<ChecklistBudgetPhasingPageWrapperProps> = props => {
  const {
    project: { id },
  } = useContext(BudgetStepCtx);

  const {
    data: budgetMetadata,
    loading,
    error,
    fetchMore,
  } = useBudgetMetadataAndPhasingByProjectIdQuery({
    variables: {
      projectId: id,
    },
    fetchPolicy: "network-only",
  });

  // TODO: add error handling for checklist budget phasing page wrapper
  useLogError(error as Error);

  useEffect(handleRetry, [error]);

  if (loading) {
    return (
      <Box width="100%">
        <LinearProgress variant="indeterminate" />
      </Box>
    );
  }

  return (
    <ChecklistBudgetPhasingPage
      budgetMetadataQueryResult={budgetMetadata}
      reloadMetadataQuery={async () =>
        void (await fetchMore({ variables: { projectId: id } }))
      }
    />
  );

  function handleRetry(): void | (() => void) {
    if (error) {
      if (error.networkError) {
        console.error(error.networkError);
      }
    }
  }
};

export { ChecklistBudgetPhasingPageWrapper };
export default ChecklistBudgetPhasingPageWrapper;
