import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useEffect, useState } from "react";

const useAuthToken = () => {
  const [token, setToken] = useState<string>("");

  useEffect(() => {
    getFirebaseToken();
  }, []);

  return token;

  async function getFirebaseToken() {
    try {
      const _token = await firebase.auth().currentUser?.getIdToken();

      if (!_token) return console.error("Failed to get bearer token");

      setToken(_token);
    } catch (err) {
      window.newrelic.noticeError(err);
      console.error("Failed to get bearer token");
      console.error(err);
    }
  }
};

export { useAuthToken };
export default useAuthToken;
