import * as React from "react";
import { useMaps } from "./useMaps";
import { useLogError } from "../../../util";

export const useLoadMap = (
  containerRef: React.RefObject<HTMLDivElement>,
  options?: google.maps.MapOptions,
  initialLatLng: google.maps.LatLng | google.maps.LatLngLiteral = {
    lat: 37.868436,
    lng: -122.49935,
  },
  id: string = "MAAP"
): [Error | null, google.maps.Map | null] => {
  const [err, setErr] = React.useState<Error | null>(null);
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const g = useMaps();
  useLogError(err);

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(handleContainerRefChange, [containerRef.current, g]);

  return [err, map];

  function handleContainerRefChange(): void | (() => void) {
    try {
      if (!containerRef.current || !g) {
        return;
      }

      containerRef.current.id = id;

      const map = new g.maps.Map(containerRef.current, {
        center: initialLatLng,
        zoom: 20,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        ...options,
      });

      setMap(map);
    } catch (err) {
      window.newrelic.noticeError(err);
      setErr(err);
    }
  }
};

export default useLoadMap;
