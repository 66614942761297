import * as React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { OnboardLayout } from "../onboardLayout";
import HandleOnboardRedirect from "./HandleOnboardRedirect";
import { UserCtx, OnboardCtx } from "../../util";
import { useContext } from "react";
import { OnboardContextErrorModal } from "./OnboardContextErrorModal";
import BudgetStepRouter from "./budget/BudgetStepRouter";
import ExteriorDesignRouter from "./exteriorDesign/ExteriorDesignRouter";
import useMenuItems, { MenuItem } from "./useMenuItems";
import MyYardStep from "./MyYardStep";
import { useDispatch } from "react-redux";
import { resetCheckout } from "../../slices/checkoutSlice";
import { resetPayment } from "../../slices/paymentSlice";
import { resetProduct } from "../../slices/productSlice";
import { NewSplitClient } from "../../util/Split/NewSplitClient";
import { TrafficTypes } from "@yardzen-inc/react-split";
import { SplitAlertBannerByTrafficType } from "../SplitAlertBannerByTrafficType";
import { USER_DISPLAY_BANNER } from "../../util/Split/splitTreatments";

export interface OnboardRouterProps {}

const OnboardRouter: React.FC<OnboardRouterProps> = (
  props: OnboardRouterProps
) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = React.useContext(UserCtx);
  const {
    state: { id: onboardDocId, contextError },
    fetchOnboardMetadata,
  } = useContext(OnboardCtx);

  const menuItems = useMenuItems();

  React.useEffect(() => {
    if (!!user && !onboardDocId && !contextError) {
      fetchOnboardMetadata(user.uid);
      dispatch(resetCheckout());
      dispatch(resetProduct());
      dispatch(resetPayment());
    }
  }, [onboardDocId, user, fetchOnboardMetadata, contextError, dispatch]);

  return (
    <NewSplitClient id={user?.uid ?? ""} trafficType={TrafficTypes.USER}>
      <>
        <SplitAlertBannerByTrafficType
          splitTreatment={USER_DISPLAY_BANNER}
          trafficType={TrafficTypes.USER}
        />
        <OnboardLayout onboardProgress={getProgress()}>
          <Switch>
            <Route path={`/onboard/my-yard`} component={MyYardStep}></Route>
            <Route
              path={`/onboard/budget`}
              component={BudgetStepRouter}
            ></Route>
            <Route
              path={`/onboard/exterior-design`}
              component={ExteriorDesignRouter}
            ></Route>

            <Route
              render={() => {
                return <HandleOnboardRedirect />;
              }}
            ></Route>
          </Switch>
          <OnboardContextErrorModal />
        </OnboardLayout>
      </>
    </NewSplitClient>
  );

  function getProgress() {
    /*
      TODO: Adjust menuItem length for botanical
      packages (they are not show Design Elements)
      so that progress is accurate

      If we care that much, that is...

    */
    const active = menuItems.find(menuItem => {
      return !!menuItem.onboardTwoSlug
        ? location.pathname.includes(menuItem.onboardTwoSlug)
        : false;
    });

    return (
      ((menuItems.indexOf(active as MenuItem) + 1) / menuItems.length) * 100
    );
  }
};

export { OnboardRouter };
export default OnboardRouter;
