import { getAuth } from "firebase/auth";
import { initializeFirebase } from "../firebase";
import { SegmentClient } from "./SegmentClient";

function getFbCookies() {
  let fbc = null,
    fbp = null;

  const fbcRes = getCookieValue("fbc") || getCookieValue("_fbc");
  const fbpRes = getCookieValue("fbp") || getCookieValue("_fbp");

  if (fbcRes && (!Array.isArray(fbcRes) || fbcRes.length > 0)) {
    fbc = Array.isArray(fbcRes) ? fbcRes[0] : fbcRes;
  }
  if (fbpRes && (!Array.isArray(fbpRes) || fbpRes.length > 0)) {
    fbp = Array.isArray(fbpRes) ? fbpRes[0] : fbpRes;
  }

  return [fbc, fbp];
}

function setFbCookie(key: any, value: any, expirationDate: any, retries = 0) {
  try {
    document.cookie = `${key}=${value};expires=${expirationDate.toUTCString()};path=/;domain=${getDomainString()}`;
  } catch (err) {
    if (retries > 5) {
      throw err;
    }

    ++retries;
    setTimeout(
      () => setFbCookie(key, value, expirationDate, retries),
      retries * 1000
    );
  }
}

function lookupCapiFbcAndFbpParams() {
  const url = window.location.href;
  const urlParams = url.split("?")[1];
  if (!urlParams) return [null, null];

  const queryParams = url
    .split("?")[1]
    .split("&")
    .map(param => {
      const keyVal = param.split("=");
      return [keyVal[0], keyVal[1]];
    });

  const fbclid = queryParams.find(param => param[0] === "fbclid");
  const fbp = queryParams.find(param => param[0] === "fbp");
  let fbcValue, fbpValue;

  if (fbclid) {
    fbcValue = `fb.1.${Date.now()}.${fbclid[1]}`;
  }

  if (fbp) {
    fbpValue = `fb.1.${Date.now()}.${fbp[1]}`;
  }

  return [fbcValue, fbpValue];
}

function setCapiFbcAndFbpCookies(fbcValue: any, fbpValue: any) {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 7);

  if (fbcValue) {
    setFbCookie("_fbc", fbcValue, expirationDate);
  }

  if (fbpValue) {
    setFbCookie("_fbp", fbpValue, expirationDate);
  } else {
    const currentFbp = getCookieValue("_fbp");
    if (!currentFbp) {
      const randNum = Math.floor(1000000000 + Math.random() * 9000000000);
      setFbCookie("_fbp", `fb.1.${Date.now()}.${randNum}`, expirationDate);
    }
  }
}
function getCookieValue(name: string) {
  let matches = document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)");
  return matches ? matches[2] : undefined;
}

function getParamValue(key: string) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key) ?? "";
}

function getSServiceUrl() {
  const isProd = !/(localhost|dogfood|staging)/.test(window.location.host);
  const url = `https://${isProd ? "" : "staging."}s.yardzen.com`;
  return url;
}

function getAnonId() {
  const anonymousId = getParamValue("yzsa") || getCookieValue("yzsa");

  if (!anonymousId) {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", getSServiceUrl() + "/rand", false);
    xhr.send();
    if (xhr.status === 200) {
      const data = xhr.responseText;
      const domain = getDomainString();
      document.cookie = `yzsa=${data};max-age=${60 * 60 * 24 * 400};path=/;${
        domain ? `domain=${domain}` : ""
      }`;
    } else {
      console.error("Xhr to /rand failed");
    }
  }

  return anonymousId;
}

function getBrowserInfo() {
  const searchParams = new URLSearchParams(window.location.search);

  return {
    userAgent: window.navigator.userAgent,
    locale: window.navigator.language,
    referrer: {
      type: document.referrer ? "internal" : "external",
      url: document.referrer,
      name: document.referrer,
      link: document.referrer,
    },
    page: {
      path: window.location.pathname,
      title: document.title,
      url: window.location.href,
      search: window.location.search,
      hash: window.location.hash,
    },
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    campaign: {
      source: searchParams.get("utm_source"),
      medium: searchParams.get("utm_medium"),
      term: searchParams.get("utm_term"),
      content: searchParams.get("utm_content"),
      campaign: searchParams.get("utm_campaign"),
    },
  };
}

function getDomainString() {
  if (window.location.host.includes("localhost")) {
    return "";
  }

  return "yardzen.com";
}

let writeKey: string = "";
let anonId: string = "";

try {
  if (location.origin === "https://dashboard.yardzen.com") {
    writeKey = "V7AtyI8zx5tDy7a5gtpdr81ZhCsNG8CD";
  } else if (location.origin === "https://dashboard.dogfood.yardzen.com") {
    writeKey = "0LGoE17LaeAoHvGC5WZEmnVS7VkAmo0W";
  } else {
    writeKey = "zM3vJEI7H7lHxaoLFFXqb2awI15Vy4Fr";
  }

  try {
    (window as any)._anonymousId = getAnonId();
    console.log();
  } catch (err) {
    console.error("Failed to set segment anonymous id", err);
  }

  const [fbcValue, fbpValue] = lookupCapiFbcAndFbpParams();
  setCapiFbcAndFbpCookies(fbcValue, fbpValue);
  const [fbcCookieVal, fbpCookieVal] = getFbCookies();

  anonId = getAnonId() ?? "";

  if (fbcCookieVal || fbpCookieVal || fbcValue || fbpValue) {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", getSServiceUrl() + "/identify", true);
    xhr.send(
      JSON.stringify({
        anonymousId: anonId,
        writeKey: writeKey,
        context: getBrowserInfo(),
        traits: {
          fbc: fbcValue || fbcCookieVal,
          fbp: fbpValue || fbpCookieVal,
        },
      })
    );
  }
} catch (err) {
  console.error("Failed to set segment fbc and fbp cookies", err);
}

const segment = new SegmentClient(
  process.env["REACT_APP_SEGMENT_API_URL"]!,
  writeKey
);

try {
  console.debug("initing segment");
  anonId && segment.setAnonymousId(anonId);
  initializeFirebase();
  const auth = getAuth();
  if (auth.currentUser) {
    segment.setUserId(auth.currentUser?.uid ?? null);
  }
} catch (err) {
  console.error(err);
}

export { segment };
