import { DataLayer, TrackingEventCategories } from "@yardzen-inc/data-layer";

export const recordBillingDetailsTracking = (dataLayer: DataLayer) => {
  try {
    dataLayer.recordTrackingEvent("billing_details_form_submit", {
      category: TrackingEventCategories.BUTTON_CLICK,
      action: "Next - billing",
      label: window.location.href,
      value: null,
    });
  } catch (err) {
    window.newrelic.noticeError(err);
    console.error(err);
  }
};
