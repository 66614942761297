import * as React from "react";
import useLoadMap from "./util/useLoadMap";

export interface Props {
  style?: React.CSSProperties;
  className?: string;
  onMapInitialize?: (map: google.maps.Map) => void;
  onError?: (err: Error) => void;
  mapOptions?: google.maps.MapOptions;
}

const Map: React.FC<Props> = React.memo((props: Props) => {
  const mapBoxRef = React.useRef<HTMLDivElement>(null);

  const [err, map] = useLoadMap(mapBoxRef, props.mapOptions);

  // TODO: remove disable comment and fix warning next time these hooks are updated
  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(handleErrChange, [err]);
  React.useEffect(handleMapChange, [map]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div
      style={{
        width: "inherit",
        height: "inherit",
        display: "inline-block",
        ...(props.style ?? {}),
      }}
      className={props.className}
      ref={mapBoxRef}
    />
  );

  function handleErrChange(): void {
    if (props.onError && err) {
      props.onError(err);
    }
  }

  function handleMapChange(): void {
    if (props.onMapInitialize && map) {
      props.onMapInitialize(map);
    }
  }
});

export { Map };
export default Map;
