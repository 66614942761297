import React from "react";
import TypeformEmbed from "../../components/onboard/TypeformEmbed";
import { useDesignProfileCtx } from "../../components/designProfile";
import { CenterFullScreen } from "../../components";
import { GenericLoadingSpinner } from "@yardzen-inc/react-common";
import { SegmentFlows, SegmentSubFlows, useSegment } from "../../util/Segment";

export const DesignProfileTypeform: React.FC = () => {
  const { designProfile, isLoading, isFetching } = useDesignProfileCtx();
  const originUrl = window.location.origin;
  const typeformId = process.env.REACT_APP_SHORTER_DESIGN_PROFILE_TYPEFORM_ID;
  const segment = useSegment();

  if (isLoading || isFetching) {
    return (
      <CenterFullScreen>
        <GenericLoadingSpinner />
      </CenterFullScreen>
    );
  }

  segment.trackSubFlowStarted({
    flow_name: SegmentFlows.DESIGN_PROFILE,
    sub_flow_name: SegmentSubFlows.DESIGN_PROFILE_QUIZ,
    state: designProfile?.contactInformation?.state,
    city: designProfile?.contactInformation?.city,
    zip: designProfile?.contactInformation?.zip,
  });

  return (
    <TypeformEmbed
      formId={typeformId || ""}
      open={true}
      widgetStyle={{
        width: "100%",
        height: "90vh",
      }}
      hiddenFields={{
        user_id: designProfile?.userId || "",
        version_id: designProfile?.versionId || "",
        origin_url: originUrl,
      }}
      onSubmit={() => {
        segment.trackSubFlowCompleted({
          flow_name: SegmentFlows.DESIGN_PROFILE,
          sub_flow_name: SegmentSubFlows.DESIGN_PROFILE_QUIZ,
        });
      }}
    />
  );
};
