import { useEffect } from "react";
import { applyPromotionalCodes } from "../../components/checkout/util/applyDiscountCode";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { PromotionalCodeStatus } from "../../services/promotionalCodes";
import { setModifiedPrice } from "../../slices/productSlice";

export interface UseApplyDiscountCode {
  (): void;
}

const useApplyPromotionalCode: UseApplyDiscountCode = () => {
  const dispatch = useAppDispatch();
  const { promotionalCode } = useAppSelector(state => state.checkout);
  const { addOnsTotal, selectedSku, addOnSkus } = useAppSelector(
    state => state.products
  );
  const itemTotal = selectedSku?.price;

  useEffect(() => {
    if (itemTotal) {
      let total = itemTotal + addOnsTotal;
      if (promotionalCode?.status === PromotionalCodeStatus.USABLE) {
        const modifiedPrice = applyPromotionalCodes(
          total,
          [promotionalCode],
          selectedSku,
          addOnSkus
        );
        dispatch(
          setModifiedPrice({
            modifiedPrice,
          })
        );
      } else {
        dispatch(setModifiedPrice({ modifiedPrice: total }));
      }
    }
  }, [
    addOnsTotal,
    promotionalCode,
    itemTotal,
    dispatch,
    selectedSku,
    addOnSkus,
  ]);
};

export { useApplyPromotionalCode as useApplyDiscountCode };
