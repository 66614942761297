export const isPhoneNumberFormatable = (phoneNumber: string) => {
  if (
    phoneNumber.length > 14 ||
    (!phoneNumber.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g) &&
      phoneNumber !== "")
  ) {
    return false;
  }

  return true;
};
