import { DiscountType } from "../../../services/promotionalCodes";
import { PromotionalCode } from "../../../api/apiTypes";
import { PriceObject } from "../../../types/Prices";

/**
 * `applyPromotionalCodes` applies discount codes to the provided initial price.
 * @param initialPrice the price before any discounts are applied
 * @param codes the promotional codes to apply
 * @param selectedSku the selected SKU
 * @param addOnSkus the add-on SKUs selected
 * @returns the price after all discounts are applied
 */
export function applyPromotionalCodes(
  initialPrice: number,
  codes: Pick<
    PromotionalCode,
    "code" | "discount" | "discountType" | "additionalDiscounts"
  >[],
  selectedSku: PriceObject,
  addOnSkus: PriceObject[]
) {
  let discountedPrice = initialPrice;

  const fixedDiscounts: string[] = [];
  const percentDiscounts: string[] = [];

  for (let code of codes) {
    (code.discountType === DiscountType.PERCENTAGE
      ? percentDiscounts
      : fixedDiscounts
    ).push(code.discount);

    if (code.additionalDiscounts && code.additionalDiscounts.length > 0) {
      for (let discount of code.additionalDiscounts) {
        const addOnSkuDiscount = addOnSkus.find(
          addOnSku => addOnSku.product === discount.forProductId
        );
        if (
          !discount.forProductId ||
          discount.forProductId === selectedSku.product ||
          addOnSkuDiscount
        ) {
          (discount.discountType === DiscountType.PERCENTAGE
            ? percentDiscounts
            : fixedDiscounts
          ).push(discount.discount);
        }
      }
    }
  }

  for (const discount of fixedDiscounts) {
    discountedPrice -= parseInt(discount);
  }

  for (const discount of percentDiscounts) {
    discountedPrice -=
      (parseFloat(discount.substring(1)) / 100) * discountedPrice;
  }

  if (isNaN(discountedPrice)) {
    throw new Error("Discounted price is NaN");
  }

  return Math.max(Math.ceil(discountedPrice), 0);
}
