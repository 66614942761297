import { redirectWithAuth } from "../authedRedirect";

export type AppVersion = "v1" | "v2";
//v1 = client-account
//v2 = client-dashboard

const smartRedirect = (app: AppVersion, path: string, history?: any) => {
  const currentPort = window.location.port;
  const otherPort = currentPort === "3000" ? "3001" : "3000";

  const LOCAL_HOST_BASE = `http://localhost:${otherPort}`,
    LOCAL_HOST_BASE_V2 = `http://localhost:${currentPort}`,
    DOGFOOD_BASE_V1 = "https://account.dogfood.yardzen.com",
    PRODUCTION_BASE_V1 = "https://account.yardzen.com",
    DOGFOOD_BASE_V2 = "https://dashboard.dogfood.yardzen.com",
    PRODUCTION_BASE_V2 = "https://dashboard.yardzen.com";

  const getDashboardDestination = () => {
    if (process.env.NODE_ENV === "development") {
      return LOCAL_HOST_BASE_V2;
    } else if (window.location.href.includes("dogfood")) {
      return DOGFOOD_BASE_V2;
    } else {
      return PRODUCTION_BASE_V2;
    }
  };

  const getAccountDestination = () => {
    if (process.env.NODE_ENV === "development") {
      return LOCAL_HOST_BASE;
    } else if (window.location.href.includes("dogfood")) {
      return DOGFOOD_BASE_V1;
    } else {
      return PRODUCTION_BASE_V1;
    }
  };

  let destination = "";
  let requiresAuth = false;
  if (app === "v2") {
    // REDIRECTS INSIDE CLIENT-DASHBOARD
    destination = `${getDashboardDestination()}/${path}`;
    if (!!history) {
      return history.push(destination);
    }
  } else {
    // REDIRECTS TO CLIENT-ACCOUNT
    const queryString = getQueryStringForOnboardRedirect();
    destination = `${getAccountDestination()}/${path}?${queryString}`;
    requiresAuth = true;
  }

  if (requiresAuth) {
    void (async function() {
      redirectWithAuth({
        destination: new URL(destination),
        fallbackBehavior: "login-page",
        redirectBehavior: "redirect",
      });
    })();
  } else {
    return window.location.replace(destination);
  }
};

// This function can expand if we want to construct a more
// personalized login experience
function getQueryStringForOnboardRedirect() {
  return "onboard-redirect=true";
}

export { smartRedirect };
export default smartRedirect;
