import React, { FC } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import TypeformEmbed from "./TypeformEmbed";
import { UserCtx, ProfileCtx } from "../../util";
import { FullScreenLoadingPage } from "../loadingComponents";

export interface Props {
  open: boolean;
  formId: string;
  variant: "modal" | "widget";
  widgetStyle?: React.CSSProperties;
  className?: string;
  onClose: () => void;
  onSubmit: () => void;
}

const Typeform: FC<Props> = props => {
  const classes = useStyles();
  const user = React.useContext(UserCtx);
  const profile = React.useContext(ProfileCtx);
  const userId = !!user ? user.uid : undefined;
  const profileId = profile ? profile.id : null;

  if (userId === undefined || profileId === null) {
    return <FullScreenLoadingPage in={true} />;
  }

  if (!props.open) {
    return null;
  }

  const onSubmit = () => {
    props.onSubmit();
  };

  const onClose = () => {
    props.onClose();
  };

  return (
    <div className={classes.root}>
      <TypeformEmbed
        open={props.open}
        widgetStyle={{
          width: "100%",
          height: "100%",
        }}
        className={props.className}
        hiddenFields={{
          userid: userId,
          profileid: profileId,
        }}
        formId={props.formId}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "1rem",
    marginTop: "1rem",
    width: "65%",
    minWidth: "400px",
    maxWidth: "100%",
    margin: "auto",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "0",
    },
  },
  content: {
    display: "flex",
    marginBottom: "1.5rem",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "row nowrap",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column nowrap",
    },
  },
}));

export default Typeform;
