import React, { FC, useContext, ReactNode, useMemo, memo } from "react";
import {
  Box,
  Typography,
  LinearProgress,
  useMediaQuery,
  Theme,
  makeStyles,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { BudgetStepCtx } from "./BudgetStepContext";
import { useLogError } from "../../../util";
import { ItemResponseCtx } from "./ItemResponseContext";
import BudgetChecklistSidebarItem from "./BudgetChecklistSidebarItem";
import { formatNumToUSD, YZTypography } from "@yardzen-inc/react-common";
import { ExpandMore } from "@material-ui/icons";
import useBudgetChecklistPriceEstimate from "./useBudgetChecklistPriceEstimate";
import { calculateBudgetRange } from "./calculateBudgetRange";
import { useFoundationalBudgetCostMultiplier } from "./useFoundationalBudgetCostMultiplier";
import { FoundationalInstallCostsList } from "./FoundationalInstallCostsList";
import { SofiFinancing } from "./SofiFinancing";

export interface ChecklistSidebarContentProps {
  onOpenClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    backgroundColor: "rgba(0,0,0,0.1)",
    margin: "1rem 0",
  },
  faqContainer: {
    "& .MuiExpansionPanelSummary-root": {
      padding: 0,
      minHeight: 0,
    },
    "& .MuiExpansionPanelSummary-content.Mui-expanded": {
      margin: 0,
    },
  },
  mobileNav: {
    boxShadow: "0 -5px 10px rgba(0,0,0,.15)",
    paddingTop: 0,
  },
  monthlyPaymentText: {
    marginBottom: 10,
  },
  sofiLogo: {
    width: 80,
    marginTop: 10,
  },
}));

const ChecklistSidebarContent: FC<ChecklistSidebarContentProps> = memo(
  ({ onOpenClose }) => {
    const [itemResponseMap] = useContext(ItemResponseCtx);
    const {
      project: { id: projectId },
    } = useContext(BudgetStepCtx);

    const [
      lowFoundationCost,
      highFoundationCost,
    ] = useFoundationalBudgetCostMultiplier();
    const [estimate, estimateError] = useBudgetChecklistPriceEstimate(
      projectId
    );

    const sidebarItems = useMemo(renderData, [itemResponseMap]);

    const mdDown = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down("md")
    );
    const smDown = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down("sm")
    );

    const classes = useStyles();

    useLogError(estimateError as Error);

    return (
      <Box
        p={1}
        minHeight="100%"
        bgcolor="#f2f2f2"
        className={mdDown ? classes.mobileNav : undefined}
      >
        {mdDown ? renderBottombarContent() : renderSidebarContent()}
      </Box>
    );

    function renderSidebarContent() {
      const [lowAllIn, highAllIn] = calculateBudgetRange(
        estimate ?? undefined,
        1 + lowFoundationCost,
        1 + highFoundationCost
      );
      return (
        <Box p={1} width="100%" height="100%">
          <Box width="100%" display="flex" flexDirection="column">
            {renderPriceRange("All-in Estimate:", lowAllIn, highAllIn)}
          </Box>

          <Divider className={classes.divider} />

          <YZTypography type="mono" variant="overline">
            Financing Available
          </YZTypography>
          <SofiFinancing estimate={estimate} />
          <Divider className={classes.divider} />

          <YZTypography type="serif" variant="h5">
            What's going into our starting estimate?
          </YZTypography>
          <Box
            maxHeight="45%"
            display="flex"
            flexDirection="column"
            overflow="auto"
            mt={2}
          >
            {sidebarItems}
          </Box>

          <Divider className={classes.divider} />
          {renderPriceRange(
            "Wish List Estimate:",
            ...calculateBudgetRange(estimate ?? undefined)
          )}
          <Divider className={classes.divider} />
          <Box pt={1} flexGrow={1} justifySelf="flex-end">
            <YZTypography type="serif" variant="h5">
              What else do we need to plan for?
            </YZTypography>
          </Box>
          <Box
            maxHeight="45%"
            display="flex"
            flexDirection="column"
            overflow="auto"
            mt={2}
          >
            <YZTypography type="mono" variant="overline">
              Base Installation Costs
            </YZTypography>
            <FoundationalInstallCostsList
              installCostItems={[
                {
                  itemName: "Demo/Grading",
                },
                {
                  itemName: "Base Planting",
                },
                {
                  itemName: "Irrigation",
                },
                {
                  itemName: "Plumbing/Electrical",
                },
              ]}
            />
          </Box>
          <Divider className={classes.divider} />
          {renderPriceRange(
            "Base Installation Estimate",
            ...calculateBudgetRange(
              estimate ?? undefined,
              lowFoundationCost,
              highFoundationCost
            )
          )}
          <Divider className={classes.divider} />
        </Box>
      );
    }

    function renderBottombarContent() {
      return (
        <Accordion
          elevation={0}
          TransitionProps={{
            timeout: {
              enter: smDown ? 0 : 250,
              exit: smDown ? 0 : 250,
            },
          }}
          onChange={onOpenClose}
          style={{
            backgroundColor: "inherit",
            maxWidth: "80vw",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore style={{ transform: "rotate(180deg)" }} />}
          >
            {renderPriceRange(
              "All-in Estimate",
              ...calculateBudgetRange(
                estimate ?? undefined,
                1 + lowFoundationCost,
                1 + highFoundationCost
              )
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="column" width="100%">
              <Divider
                variant="fullWidth"
                className={classes.divider}
                style={{ marginTop: 0 }}
              />
              <YZTypography
                type="mono"
                variant="overline"
                color="textSecondary"
              >
                Financing Available
              </YZTypography>
              <SofiFinancing estimate={estimate} />
            </Box>
          </AccordionDetails>
          <AccordionDetails>
            <Box display="flex" flexDirection="column" width="100%">
              <Divider
                variant="fullWidth"
                className={classes.divider}
                style={{ marginTop: 0 }}
              />
              <Box
                pt={1}
                display="flex"
                flexDirection="column"
                flexWrap="wrap"
                maxHeight="200px"
              >
                {sidebarItems}
              </Box>
            </Box>
          </AccordionDetails>
          <AccordionDetails>
            {renderPriceRange(
              "Wish List Estimate",
              ...calculateBudgetRange(estimate ?? undefined)
            )}
          </AccordionDetails>

          <Divider
            variant="fullWidth"
            className={classes.divider}
            style={{ marginTop: 0 }}
          />
          <AccordionDetails>
            {renderPriceRange(
              "Estimated Base Installation Costs",
              ...calculateBudgetRange(
                estimate ?? undefined,
                lowFoundationCost,
                highFoundationCost
              )
            )}
          </AccordionDetails>
        </Accordion>
      );
    }

    function renderPriceRange(
      title: string,
      low: number,
      high: number
    ): ReactNode {
      return (
        <Box display="flex" flexDirection="column">
          <YZTypography type="serif" variant="h6">
            {title}
          </YZTypography>
          <YZTypography type="mono" color="textSecondary">
            {formatNumToUSD(Math.floor(low))} -{" "}
            {formatNumToUSD(Math.floor(high))}
          </YZTypography>
        </Box>
      );
    }

    function renderData(): ReactNode | ReactNode[] {
      if (!itemResponseMap)
        return (
          <LinearProgress variant="indeterminate" style={{ width: "100%" }} />
        );

      const itemResponseValues = Object.values(itemResponseMap);

      if (!itemResponseValues.length) {
        return <Typography>No Checklist Items Found</Typography>;
      }

      const elements: ReactNode[] = [];
      const quantitativeElements: ReactNode[] = [];

      const sortedItemResponseValues = itemResponseValues.sort((a, b) => {
        if (a.item.name > b.item.name) {
          return 1;
        }
        if (a.item.name < b.item.name) {
          return -1;
        }

        return 0;
      });

      for (let value of sortedItemResponseValues) {
        if (!value.response) continue;

        const { left_yard, right_yard, front_yard, back_yard } = value.response;
        let multiplier = 0;

        if (!value.item.quantitative_item) {
          void [left_yard, right_yard, front_yard, back_yard].forEach(
            booleanValue => {
              if (booleanValue) multiplier += 1;
            }
          );

          if (!multiplier) multiplier = 1;
        }

        void (value.item.quantitative_item
          ? quantitativeElements
          : elements
        ).push(
          <BudgetChecklistSidebarItem
            linkTo={`/onboard/budget/checklist/item/${value.item.id}`}
            key={value.item.name}
            itemName={
              value.item.name + (!!multiplier ? ` x ${multiplier}` : "")
            }
          />
        );
      }

      const combinedElements = [...quantitativeElements, elements];

      return combinedElements.length ? (
        combinedElements
      ) : (
        <Typography color="textSecondary">
          Start selecting items to see a range
        </Typography>
      );
    }
  }
);

export { ChecklistSidebarContent };
export default ChecklistSidebarContent;
