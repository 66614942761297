interface CheckoutReferralCodesArgs {
  referralCodeCollection: string[];
  setReferralCodesToStore: () => void;
}

export const checkReferralCodes = async ({
  referralCodeCollection,
  setReferralCodesToStore,
}: CheckoutReferralCodesArgs) => {
  try {
    if (!referralCodeCollection.length) {
      await setReferralCodesToStore();
    }
  } catch (error) {
    window.newrelic.noticeError(error);
    console.error(error);
  }
};
