import React, { FC } from "react";
import { StepIconProps } from "@material-ui/core/StepIcon";
import {
  Help,
  HelpOutline,
  Home,
  HomeOutlined,
  Eco,
  EcoOutlined,
  Star,
  StarOutline,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  unfilledSvg: {
    "& > svg": {
      color: "rgba(50, 50, 50, .8)",
    },
  },
  filledSvg: {
    "& > svg": {
      color: "rgba(154, 168, 147, 1)",
    },
  },
}));

const ProgressBarIcons: FC<StepIconProps> = ({ active, completed, icon }) => {
  const classes = useStyles();
  const icons: { [index: string]: React.ReactElement } = {
    1: <HelpOutline />,
    2: <HomeOutlined />,
    3: <EcoOutlined />,
    4: <StarOutline />,
  };
  const iconsComplete: { [index: string]: React.ReactElement } = {
    1: <Help />,
    2: <Home />,
    3: <Eco />,
    4: <Star />,
  };

  return (
    <div
      className={activeOrCompleted() ? classes.filledSvg : classes.unfilledSvg}
    >
      {activeOrCompleted() ? iconsComplete[String(icon)] : icons[String(icon)]}
    </div>
  );

  function activeOrCompleted() {
    return active || completed;
  }
};

export { ProgressBarIcons };
