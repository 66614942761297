import React from "react";
import MaskedInput from "react-text-mask";
import { Box, TextField } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import {
  SegmentFlows,
  SegmentForms,
  SegmentInputTypes,
  useSegment,
} from "../../util/Segment";
import { phoneRegex } from "../utility/phoneRegex";

interface MaskedPhoneInputProps {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setFocused: (focused: boolean) => void;
  handleKeyPress: (e: React.KeyboardEvent) => void;
  segmentFields?: {
    flowName: SegmentFlows;
    formName: SegmentForms;
    fieldName: string;
    inputType: SegmentInputTypes;
  };
  title?: string;
  value: string;
  label: string;
  helperText?: string;
  errorText?: string;
  titleTextAlign?: "center" | "left" | "right";
}

export interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const MaskedPhoneInput = ({
  handleChange,
  handleKeyPress,
  title,
  value,
  label,
  segmentFields,
  helperText,
  errorText,
  setFocused,
  titleTextAlign = "center",
}: MaskedPhoneInputProps) => {
  const segment = useSegment();

  return (
    <Box>
      {title && (
        <Box style={{ paddingBottom: 15 }} pt={2}>
          <YZTypography align={titleTextAlign} color="textSecondary">
            {title}
          </YZTypography>
        </Box>
      )}

      <MaskedInput
        mask={phoneRegex}
        showMask
        guide={false}
        render={innerRef => (
          <TextField
            inputRef={innerRef}
            inputProps={{
              type: "tel",
              id: "tel-input",
              style: {
                backgroundColor: "white",
              },
              maxLength: 10,
            }}
            label={label}
            variant="outlined"
            value={value}
            onChange={handleChange}
            name="phonemask"
            id="phone-input"
            data-testid="phone-input"
            color="primary"
            onFocus={() => setFocused(true)}
            onBlur={() => {
              segment.trackFormFieldFilled({
                flow_name: segmentFields?.flowName ?? "",
                form_name: segmentFields?.formName ?? "",
                field_name: segmentFields?.fieldName ?? "",
                input_type: segmentFields?.inputType ?? "",
              });
              setFocused(false);
            }}
            inputMode="tel"
            fullWidth
            error={!!errorText}
            helperText={errorText || helperText}
            onKeyPress={handleKeyPress}
            size="small"
          />
        )}
      />
    </Box>
  );
};
