import { makeStyles, TextField, Theme, useTheme } from "@material-ui/core";
import { useEffect, useState } from "react";

export type HandleZipSelectionFunc = (newVal: any) => Promise<void>;

interface ZipCodeInputProps {
  handleZipCodeProvided: HandleZipSelectionFunc;
}

const useStyles = makeStyles((theme: Theme) => ({
  zipInputContainer: {
    width: "50%",
    [theme.breakpoints.up("md")]: {
      width: "250px",
    },
    "& input": {
      backgroundColor: "white",
    },
  },
  zipInputLabel: {
    display: "none",
  },
}));

export const ZipCodeInput = ({ handleZipCodeProvided }: ZipCodeInputProps) => {
  const theme = useTheme();
  const { zipInputContainer, zipInputLabel } = useStyles();
  const [validZip, setValidZip] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");

  function isValidZip(zipCode: string) {
    // Regular expressions for valid US zip codes
    const fiveDigitZipRegex = /^\d{5}$/;
    const nineDigitZipRegex = /^\d{5}-\d{4}$/;

    // Check if the zip code matches either the five-digit or nine-digit format
    return fiveDigitZipRegex.test(zipCode) || nineDigitZipRegex.test(zipCode);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [inputValue]);

  useEffect(() => {
    if (!debouncedValue || debouncedValue === "") return setValidZip(true);
    const isValid = isValidZip(debouncedValue);
    setValidZip(isValid);
    if (isValid) handleZipCodeProvided(debouncedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <>
      {
        // Hidden element that exists purely to increase accessibility.
      }
      <label className={zipInputLabel} id="zip-input-label">
        Enter your zip code
      </label>
      <TextField
        error={!validZip}
        helperText={validZip ? "" : "Invalid zip code"}
        className={zipInputContainer}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        variant="outlined"
        style={{
          fontSize: "16px",
          margin: "8px",
          borderColor: theme.palette.primary.main,
        }}
        placeholder="Enter your zip code..."
        id="zip-input"
      />
    </>
  );
};
