const yardMap = {
  front: "Front yard",
  back: "Backyard",
  left: "Left side yard",
  right: "Right side yard",
  outdoor: "Outdoor space",
};

const transformYardStatusObjKey = (yardStatusObjKey: string): string => {
  return yardMap[yardStatusObjKey as keyof typeof yardMap] || yardStatusObjKey;
};

export default transformYardStatusObjKey;
