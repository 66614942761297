import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { formatNumToUSD, YZTypography } from "@yardzen-inc/react-common";
import React, { FC } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export interface WishListPrioritizePageEstimateMobileProps {
  lowEstimate: number;
  highEstimate: number;
  lowAllInEstimate: number;
  highAllInEstimate: number;
  lowBaseCost: number;
  highBaseCost: number;
}

const useStyles = makeStyles(theme => ({
  accordion: {
    backgroundColor: "#f2f2f2",
  },
}));

/**
 * Wish List Prioritize Estimate Mobile page component.
 * @description The mobile version of the wish list estimate
 * @param props The WishListPrioritizeEstimateMobileProps to pass to the component
 * @returns The component.
 */
const WishListPrioritizePageEstimateMobile: FC<WishListPrioritizePageEstimateMobileProps> = props => {
  const classes = useStyles();

  const {
    lowEstimate,
    highEstimate,
    lowAllInEstimate,
    highAllInEstimate,
    lowBaseCost,
    highBaseCost,
  } = props;

  return (
    <Box position="fixed" width="100%" zIndex={10}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="wish-list-estimate-content"
          id="wish-list-estimate-header"
        >
          <Box display="flex" flexDirection="column">
            <Box>
              <YZTypography type="serif" variant="h6">
                All-In Estimate
              </YZTypography>
            </Box>
            <Box>
              <YZTypography type="mono" color="textSecondary">
                {formatNumToUSD(lowAllInEstimate)} -{" "}
                {formatNumToUSD(highAllInEstimate)}
              </YZTypography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Divider variant="fullWidth" />
            <Box mt={2}>
              <YZTypography type="serif" variant="h6">
                Wishlist Estimate
              </YZTypography>
            </Box>
            <Box>
              <YZTypography type="mono" color="textSecondary">
                {formatNumToUSD(lowEstimate)} - {formatNumToUSD(highEstimate)}
              </YZTypography>
            </Box>
            <Box mt={2}>
              <YZTypography type="serif" variant="h6">
                Base Costs
              </YZTypography>
            </Box>
            <Box>
              <YZTypography type="mono" color="textSecondary">
                {formatNumToUSD(lowBaseCost)} - {formatNumToUSD(highBaseCost)}
              </YZTypography>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export { WishListPrioritizePageEstimateMobile };
