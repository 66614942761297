import React, { FC } from "react";
// @ts-ignore
import { Widget } from "@typeform/embed-react";
interface TypeformEmbedProps {
  formId: string;
  hiddenFields?: any;
  open?: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
  options?: TypeformOptions;
  className?: string;
  widgetStyle?: React.CSSProperties;
}

interface TypeformOptions {
  opacity?: number;
  buttonText?: string;
  hideScrollbars?: boolean;
  hideFooter?: boolean;
  hideHeaders?: boolean;
}

const TypeformEmbed: FC<TypeformEmbedProps> = props => {
  if (props.open !== true) {
    return null;
  }
  return (
    <div className={props.className}>
      <Widget
        id={props.formId}
        style={props.widgetStyle}
        onSubmit={handleSubmit}
        hidden={props.hiddenFields}
      />
    </div>
  );

  function handleSubmit() {
    props.onSubmit && props.onSubmit();
  }
};

export default TypeformEmbed;
