import React, {
  FC,
  ReactNode,
  useState,
  useRef,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { RouteComponentProps, useHistory } from "react-router";
import {
  useInsertBudgetChecklistResponseMutation,
  ApolloError,
  useUpdateBudgetChecklistResponseMutation,
  useDeleteBudgetChecklistResponseByIdMutation,
  Budget_Checklist_Response,
} from "@yardzen-inc/graphql";
import { OnboardCtx, useLogError, ProfileCtx } from "../../../util";
import {
  IconHeader,
  useTriggerTimeout,
  ToggleSelectWrapper,
  LabeledTextArea,
  YZButton,
  YZTypography,
  theme,
} from "@yardzen-inc/react-common";
import { Box, Slide, Fade, makeStyles, Theme } from "@material-ui/core";
import PriceOptionSelect from "./PriceOptionSelect";
import StyleOptionSelect from "./StyleOptionSelect";
import { BudgetStepCtx } from "./BudgetStepContext";
import { ItemResponseCtx } from "./ItemResponseContext";
import useGetBudgetRedirectUrl from "./useGetBudgetRedirectUrl";
import classnames from "classnames";
import { Close } from "@material-ui/icons";
import SaveBar from "../helpers/SaveBar";
import { getYardOptions } from "../util/getYardOptions";
import { SMALL_SPACE_PACKAGE } from "../../../util/constants/packageTypes";

export type Yards = "left_yard" | "right_yard" | "front_yard" | "back_yard";

export interface SelectableYards
  extends Pick<Budget_Checklist_Response, Yards> {}

export interface ChecklistItemDetailProps extends RouteComponentProps {}

export interface ChecklistItemHistoryState {
  wishlistItemsModified: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  iconHeader: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    zIndex: 999,
    position: "sticky",
    top: 0,
  },
  sectionContainer: {
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  greenTextSpan: {
    color: "#505850",
  },
  notSureBtn: {
    alignSelf: "center",
    cursor: "pointer",
    outline: "none",
    border: "none",
    backgroundColor: "inherit",
    padding: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  button: {
    background: "none",
    color: "#4C4C4C",
    "& span": {
      letterSpacing: "1px",
      fontSize: 12,
    },
  },
  saveBarContainer: {
    width: "calc(100% - 580px)",
    textAlign: "center",
    position: "fixed",
    bottom: "1.5rem",
    [theme.breakpoints.down("md")]: {
      position: "relative",
      marginTop: "3rem",
      width: "100%",
    },
  },
  itemsContainer: {
    "& > div": {
      justifyContent: "center",
    },
  },
}));

type YardOption = {
  value: string;
  statusName: string;
  label: string;
};

const DEFAULT_YARD_SELECTION = Object.freeze({
  left_yard: false,
  front_yard: false,
  back_yard: false,
  right_yard: false,
});

// BUG: item responses do not hydrate properly if you navigate directly to a checklist item

const ChecklistItemDetail: FC<ChecklistItemDetailProps> = ({ match }) => {
  const [redirectUrl, backText] = useGetBudgetRedirectUrl(
    "/onboard/budget/checklist/list",
    "must-haves"
  );

  // We'll need this so that when we redirect, we can determine whether or not
  // the wishlist items were modified, and then further determine if we need
  // to update our budget because of those modifications.
  const [
    redirectHistoryState,
    setRedirectHistoryState,
  ] = useState<ChecklistItemHistoryState | null>(null);

  const { triggerTimeout, triggered } = useTriggerTimeout(
    () => history.push(redirectUrl, redirectHistoryState),
    200
  );

  const classes = useStyles();

  const history = useHistory();
  const onboardData = useContext(OnboardCtx);
  const profile = React.useContext(ProfileCtx);
  const packagePurchased = profile ? profile.package : null;

  const [
    itemResponseContextValue,
    { removeResponseFromCache, refetchResponseData },
  ] = useContext(ItemResponseCtx);

  const itemAndResponse =
    itemResponseContextValue?.[(match.params as any)?.itemId ?? ""];

  const item = itemAndResponse?.item;
  const response = itemAndResponse?.response;

  const [notSureStyle, setNotSureStyle] = useState(
    response?.budget_checklist_style_option_id === null ? true : false
  );

  const {
    user: { id: userId },
    project: { id: projectId },
  } = useContext(BudgetStepCtx);

  const [selectedPriceOptionId, setSelectedPriceOptionId] = useState<
    string | null
  >(response?.budget_checklist_price_option_id ?? null);
  const [selectedStyleOptionId, setSelectedStyleOptionId] = useState<
    string | null
  >(response?.budget_checklist_style_option_id ?? null);
  const [selectedYards, setSelectedYards] = useState<SelectableYards>(() =>
    response
      ? {
          left_yard: response?.left_yard ?? false,
          front_yard: response?.front_yard ?? false,
          back_yard: response?.back_yard ?? false,
          right_yard: response?.right_yard ?? false,
        }
      : { ...DEFAULT_YARD_SELECTION }
  );

  const hasSelectedAYard = useMemo(
    () =>
      !!(response || Object.values(selectedYards).find(selected => !!selected)),
    [selectedYards, response]
  );

  const [optsOutOfYardSelection, setOptsOutOfYardSelection] = useState<boolean>(
    () =>
      packagePurchased === SMALL_SPACE_PACKAGE ||
      (hasSelectedAYard &&
        !Object.values(selectedYards).find(selected => !!selected))
  );

  const [context, setContext] = useState<string>(response?.context ?? "");
  const [styleOptionsRendered, setStyleOptionsRendered] = useState(false);
  const styleOptionsRef = useRef<HTMLDivElement | null>(null);

  const [yardOptionsRendered, setYardOptionsRendered] = useState(false);
  const yardOptionsRef = useRef<HTMLDivElement | null>(null);

  const [
    insertBudgetChecklistResponse,
    {
      error: responseInsertError,
      loading: submittingResponse,
      data: insertReturnData,
    },
  ] = useInsertBudgetChecklistResponseMutation();

  const [
    updateBudgetChecklistResponse,
    {
      error: responseUpdateError,
      loading: updatingResponse,
      data: updateReturnData,
    },
  ] = useUpdateBudgetChecklistResponseMutation();

  const [
    deleteChecklistResponse,
    { data: deleteResponseData, called: deleteStarted },
  ] = useDeleteBudgetChecklistResponseByIdMutation();

  useLogError(responseInsertError as ApolloError);
  useLogError(responseUpdateError as ApolloError);

  useEffect(() => {
    if (styleOptionsRendered && !response) {
      scrollToStyleOptions();
    }
  }, [styleOptionsRendered, response]);

  useEffect(() => {
    if (yardOptionsRendered && !response) {
      scrollToYardSelect();
    }
  }, [yardOptionsRendered, response]);

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onDeleteResponseDataChange, [deleteResponseData]);

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={2}
    >
      <Slide
        mountOnEnter
        in={!triggered && !deleteStarted}
        direction="left"
        timeout={{ enter: 200, exit: 200 }}
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Fade timeout={{ enter: 100 }} in={!!item?.name}>
            <Box
              width="99%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={7}
            >
              <Box className={classes.iconHeader}>
                <Box width="100%" display="flex" justifyContent="space-between">
                  <Box py={1}>
                    <YZButton
                      variant="text"
                      onClick={triggerTimeout}
                      className={classes.button}
                      // @ts-ignore
                      slim
                      size="small"
                    >
                      <>{`← Back to ${backText}`}</>
                    </YZButton>
                  </Box>
                  {response && (
                    <Box py={1}>
                      <YZButton
                        size="small"
                        variant="outlined"
                        style={{ cursor: "pointer" }}
                        onClick={async () => {
                          if (item?.id) {
                            removeResponseFromCache(item.id);
                          }

                          setTimeout(() =>
                            deleteChecklistResponse({
                              variables: { id: response.id },
                              optimisticResponse: {
                                delete_budget_checklist_response: {
                                  affected_rows: 1,
                                },
                              },
                            })
                          );
                        }}
                      >
                        <Close
                          style={{
                            marginRight: 4,
                            fontSize: 17,
                            color: theme.palette.error.main,
                          }}
                        />
                        <YZTypography variant="caption" type="uppercase">
                          Remove Item
                        </YZTypography>
                      </YZButton>
                    </Box>
                  )}
                </Box>
                <IconHeader
                  iconUri={item?.medium?.public_uri ?? ""}
                  title={item?.name ?? ""}
                />
              </Box>

              <Box className={classes.sectionContainer}>
                {renderPriceOptions()}
              </Box>
              <Box className={classes.sectionContainer}>
                {renderStyleOptions()}
              </Box>
              <Box className={classes.sectionContainer}>
                {renderLocationForm()}
              </Box>
              <Box className={classes.sectionContainer}>
                {renderContextInput()}
              </Box>
            </Box>
          </Fade>
          <Fade
            in={!!hasSelectedAYard || optsOutOfYardSelection}
            mountOnEnter
            unmountOnExit
          >
            <Box className={classes.saveBarContainer}>
              <SaveBar
                label={!response ? "Add to wish list" : "update"}
                disabled={
                  !!(
                    submittingResponse ||
                    updatingResponse ||
                    updateReturnData ||
                    insertReturnData
                  )
                }
                onClick={handleSubmit}
              />
            </Box>
          </Fade>
        </Box>
      </Slide>
    </Box>
  );

  function onDeleteResponseDataChange() {
    if (deleteResponseData) {
      setRedirectHistoryState({
        wishlistItemsModified: true,
      });

      triggerTimeout();
    }
  }

  function renderPriceOptions(): ReactNode {
    if (item) {
      return (
        <Fade mountOnEnter in>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box alignSelf="flex-start" mt={3} mb={2}>
              <YZTypography variant="h6" type="serif">
                What price range are you interested in for your{" "}
                <span className={classes.greenTextSpan}>
                  {item.name.toLowerCase() ?? ""}
                </span>
                ?
              </YZTypography>
            </Box>
            <div className={classes.itemsContainer}>
              <PriceOptionSelect
                id={item.id}
                name={item.name}
                onSelect={(id: string) => {
                  setSelectedStyleOptionId(null);
                  setSelectedPriceOptionId(id);
                }}
                selectedItem={selectedPriceOptionId ?? ""}
                description={item.description ?? void 0}
                iconUri={item.medium?.public_uri ?? void 0}
              />
            </div>
          </div>
        </Fade>
      );
    }

    return null;
  }

  function renderStyleOptions(): ReactNode {
    if (!selectedPriceOptionId || !!item?.no_style_options) {
      return <></>;
    } else {
      if (!styleOptionsRendered) {
        setStyleOptionsRendered(true);
      }
      return (
        <Fade
          in={!!selectedPriceOptionId && !item?.no_style_options}
          mountOnEnter
          unmountOnExit
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            ref={styleOptionsRef}
          >
            <Box alignSelf="flex-start" mt={3} mb={2}>
              <YZTypography variant="h6" type="serif">
                Of these, which style do you like best?
              </YZTypography>
            </Box>
            <StyleOptionSelect
              priceOptionId={selectedPriceOptionId ?? ""}
              onSelect={(id: string) => {
                if (notSureStyle) {
                  setNotSureStyle(false);
                }
                setSelectedStyleOptionId(id);
              }}
              selectedItem={selectedStyleOptionId ?? ""}
            />
            <Box
              component="button"
              onClick={toggleNotSureStyle}
              className={classnames(classes.notSureBtn)}
            >
              <YZButton
                style={{
                  backgroundColor: notSureStyle
                    ? theme.palette.secondary.main
                    : undefined,
                }}
              >
                None of these
              </YZButton>
            </Box>
          </div>
        </Fade>
      );
    }
  }

  function toggleNotSureStyle() {
    if (selectedStyleOptionId) {
      setSelectedStyleOptionId(null);
    }

    if (notSureStyle) {
      setNotSureStyle(false);
    } else {
      setNotSureStyle(true);
    }
  }

  function renderLocationForm(): ReactNode {
    let options: YardOption[] = getYardOptions(packagePurchased);

    if (!!Object.values(onboardData.state.yardsStatusObj).find(ex => !!ex)) {
      options = options.filter(
        option =>
          !!(onboardData?.state?.yardsStatusObj as any)?.[
            option.statusName.toLowerCase()
          ]
      );
    }

    if (
      !!(selectedStyleOptionId || item?.no_style_options || notSureStyle) &&
      selectedPriceOptionId &&
      packagePurchased !== SMALL_SPACE_PACKAGE
    ) {
      if (!yardOptionsRendered) {
        setYardOptionsRendered(true);
      }
      return (
        <>
          <Fade
            in={
              !!(
                selectedStyleOptionId ||
                item?.no_style_options ||
                notSureStyle
              )
            }
            mountOnEnter
            unmountOnExit
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              ref={yardOptionsRef}
            >
              <Box alignSelf="flex-start" mt={3}>
                <YZTypography variant="h6" type="serif">
                  Where would you like to install your{" "}
                  <span className={classes.greenTextSpan}>
                    {item?.name.toLowerCase() ?? ""}
                  </span>
                  ?
                </YZTypography>
              </Box>
              <ToggleSelectWrapper
                onSelected={(yard: string) => {
                  if (optsOutOfYardSelection) {
                    setOptsOutOfYardSelection(false);
                  }

                  setSelectedYards({
                    ...selectedYards,
                    [yard]: !selectedYards[yard as keyof SelectableYards],
                  });
                }}
                prompt={<></>}
                selectVariant={"MULTIPLE"}
                inheritFont
                selected={(Object.keys(
                  selectedYards
                ) as (keyof SelectableYards)[]).filter(
                  yardKey => selectedYards[yardKey]
                )}
                orientation={"horizontal"}
                containerProps={{ width: "100%" }}
                options={options}
                colorSecondary
              />
              <Box alignSelf="center">
                <Box
                  onClick={() => {
                    setSelectedYards({ ...DEFAULT_YARD_SELECTION });
                    setOptsOutOfYardSelection(true);
                  }}
                  className={classnames(classes.notSureBtn)}
                >
                  <YZButton
                    style={{
                      backgroundColor: optsOutOfYardSelection
                        ? theme.palette.secondary.main
                        : undefined,
                    }}
                  >
                    I'm not sure
                  </YZButton>
                </Box>
              </Box>
            </div>
          </Fade>
        </>
      );
    }

    return null;
  }

  function renderContextInput(): ReactNode {
    return (
      <>
        <Fade
          in={!!hasSelectedAYard || optsOutOfYardSelection}
          mountOnEnter
          unmountOnExit
        >
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div style={{ alignSelf: "flex-start" }}>
              <YZTypography variant="h6" type="serif">
                Is there anything else we should know about the{" "}
                <span className={classes.greenTextSpan}>
                  {item?.name.toLowerCase() ?? ""}
                </span>
                ?
              </YZTypography>
            </div>
            <LabeledTextArea onChange={setContext} value={context} label="" />
          </div>
        </Fade>
      </>
    );
  }

  function scrollToStyleOptions() {
    setTimeout(() => {
      if (styleOptionsRef.current) {
        styleOptionsRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  }

  function scrollToYardSelect() {
    setTimeout(() => {
      if (yardOptionsRef.current) {
        yardOptionsRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  }

  async function handleSubmit() {
    // TODO: display message on screen appropriate to missing items
    if (!item) return;
    if (
      !selectedPriceOptionId ||
      !(hasSelectedAYard || optsOutOfYardSelection)
    ) {
      throw new Error("missing parameters");
    }

    try {
      if (!response) {
        await insertBudgetChecklistResponse({
          variables: {
            project_id: projectId,
            user_id: userId,
            budget_checklist_item_id: item.id,
            budget_checklist_price_option_id: selectedPriceOptionId,
            budget_checklist_style_option_id: selectedStyleOptionId ?? null,
            context: context,
            ...selectedYards,
          },
        });
      } else {
        await updateBudgetChecklistResponse({
          variables: {
            id: response.id,
            budget_checklist_price_option_id: selectedPriceOptionId,
            budget_checklist_style_option_id: selectedStyleOptionId,
            context: context,
            ...selectedYards,
          },
        });
      }

      setRedirectHistoryState({
        wishlistItemsModified: true,
      });

      await refetchResponseData();
      triggerTimeout();
    } catch (err) {
      window.newrelic.noticeError(err);
      console.error(err);
      // TODO: Handle this error
    }
  }
};

export { ChecklistItemDetail };
export default ChecklistItemDetail;
