import { PriceObject } from "../../types/Prices";
import { Product } from "../../types/Products";
import * as lotSizes from "../../util/constants/lotSizeTypes";

interface returnValue {
  product: Product | undefined;
  sku: PriceObject | undefined;
  packageStyle?: string | null;
}

export function getPackageAndSkuFromUrlParams(
  products: Product[],
  urlParams: URLSearchParams
): returnValue {
  const packageName = urlParams.get("package");
  const lotSize = urlParams.get("lotsize");

  if (!packageName) {
    return {
      product: undefined,
      sku: undefined,
    };
  }

  // Find the product
  const translatedProductName = translatePackageName(packageName);
  const product = products?.find(
    product => product.name === translatedProductName
  );

  // Find the sku (price)
  let sku;
  if (product) {
    const translatedLotSizeName = translateLotSizeName(lotSize);
    sku = product.skus.data.find(
      sku => sku.attributes.name === translatedLotSizeName
    );
  }

  const packageStyle = urlParams.get("package-style");

  return { product, sku, packageStyle };
}

// The way this is passed to us does not line up exactly with what is called in the SKU
// so it needs to be translated
export function translateLotSizeName(
  passedName: string | null
): lotSizes.LotSizeTypes {
  switch (passedName) {
    case "small":
      return lotSizes.SMALL_LOT;
    case "large":
      return lotSizes.LARGE_LOT;
    case "large_b":
      return lotSizes.LARGE_LOT_B;
    case "acreage":
      return lotSizes.ACREAGE_LOT;
    case "standard_b":
      return lotSizes.STANDARD_LOT_B;
    case "standard_lifestyle":
      return lotSizes.STANDARD_LIFESTYLE;
    case "large_lifestyle":
      return lotSizes.LARGE_LIFESTYLE;
    case "standard_lowes":
      return lotSizes.LOWES_LOT;
    case "standard":
    default:
      return lotSizes.STANDARD_LOT;
  }
}

// the way package name is passed to us also does not map with exactly how we store it,
// so that also needs to be translated

// We should be getting the package name from Wordpress using the - instead of _ version with the GREEN-537 ticket,
// but keeping the _ version just in case something gets linked with the _ package name so it will still work to load the package at checkout
export function translatePackageName(passedString: string): string | undefined {
  switch (passedString) {
    case "botanical":
      return "Botanical Package";
    case "front-yard":
    case "front_yard":
      return "Front Yard Package";
    case "back-yard":
    case "back_yard":
      return "Back Yard Package";
    case "curb-appeal":
    case "curb_appeal":
      return "Curb Appeal Package";
    case "outdoor-transformation":
    case "outdoor_transformation":
      return "Whole Home Package";
    case "full-yard":
    case "full_yard":
      return "Full Yard Package";
    case "premium":
      return "Uber Premium Package";
    case "small-space":
    case "small_space":
      return "Small Space Package";
    case "front-or-backyard-starter":
    case "front_or_backyard_starter":
      return "Starter - Front or Backyard Package";
    case "entire-yard-starter":
    case "entire_yard_starter":
      return "Starter - Full Yard Package";
  }

  return;
}
