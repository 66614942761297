import * as React from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { Done } from "@material-ui/icons";
import classnames from "classnames";

interface CheckCircleProps {
  rootStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  complete?: boolean;
  size?: string; // "34px" || "40%" -  Matches icon and root size
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: "50%",
    backgroundColor: "#E2E2E0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {},
  size: {
    width: "40px",
    height: "40px",
  },
}));

const CheckCircle: React.FunctionComponent<CheckCircleProps> = ({
  complete,
  rootStyle,
  iconStyle,
  size,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div
      style={{
        backgroundColor: complete ? theme.palette.success.main : "#E2E2E0",
        width: !!size ? size : undefined,
        height: !!size ? size : undefined,
        ...rootStyle,
      }}
      className={classnames(classes.root, classes.size)}
    >
      <Done
        className={classnames(classes.icon, classes.size)}
        style={{
          color: "white",
          width: !!size ? size : undefined,
          height: !!size ? size : undefined,
          ...iconStyle,
        }}
      />
    </div>
  );
};

export { CheckCircle };
export default CheckCircle;
