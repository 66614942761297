import React, { FC } from "react";
import {
  Box,
  Typography,
  MenuItem,
  FormHelperText,
  FormControl,
  TextField,
  makeStyles,
  Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  [theme.breakpoints.down("sm")]: {
    menuContainer: {
      "& >div:nth-child(3)": {
        left: "1rem !important",
        minWidth: "72vw !important",
      },
    },
  },
  headerText: {
    marginBottom: "5px",
  },
}));

export interface HowDidYouHearAboutYZProps {
  selectedOption: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
  error: string | false;
  optional?: boolean;
  redText?: boolean;
  requiredText?: boolean;
  containerStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  smallVariant?: boolean;
}

const REFERRAL_OPTIONS = [
  "Friend or neighbor",
  "Instagram",
  "Facebook",
  "Google",
  "Pinterest",
  "Nextdoor",
  "Television/Streaming",
  "Podcast",
  "Mail",
  "Press Mention",
  "Other",
];

const HowDidYouHearAboutYZ: FC<HowDidYouHearAboutYZProps> = ({
  selectedOption,
  setSelectedOption,
  disabled,
  error,
  redText,
  requiredText,
  containerStyle,
  textStyle,
  smallVariant,
}) => {
  const { menuContainer, headerText } = useStyles();

  const text = requiredText
    ? " How did you hear about us?"
    : "How did you hear about us? (Optional)";

  return (
    <Box mt={4} style={containerStyle}>
      <Typography
        data-testid="how-did-you-hear-label"
        className={headerText}
        style={redText ? textStyle : {}}
        paragraph
        color="textSecondary"
      >
        {text}
      </Typography>
      <FormControl style={{ marginTop: 0 }} error={!!error} fullWidth>
        <TextField
          inputProps={{
            "data-testid": "how-did-you-hear-select",
          }}
          style={{ background: "white" }}
          select
          variant="outlined"
          size={smallVariant ? "small" : "medium"}
          value={selectedOption}
          onChange={e => setSelectedOption(e.target.value as string)}
          disabled={disabled}
          SelectProps={{
            MenuProps: {
              className: menuContainer,
            },
          }}
        >
          {REFERRAL_OPTIONS.map(o => (
            <MenuItem key={o} value={o}>
              {o}
            </MenuItem>
          ))}
        </TextField>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export { HowDidYouHearAboutYZ };
