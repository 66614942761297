import { useEffect, useRef } from "react";

function usePrevious<t = any>(value: t, rememberFalsey?: boolean): t {
  const ref = useRef<t>(value);

  useEffect(() => {
    if ((value || rememberFalsey) && ref.current !== value) {
      ref.current = value;
    }
  }, [value, rememberFalsey]);

  return ref.current;
}

export default usePrevious;
