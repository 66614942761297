import * as React from "react";
import { DropzoneInputProps } from "react-dropzone";
import { Paper, Typography, Theme } from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

export interface SmallUploadBoxProps {
  limitReached?: boolean;
  isUploading?: boolean;
  getInputProps?: (
    props?: DropzoneInputProps | undefined
  ) => DropzoneInputProps;
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: "pointer",
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(4),
    minWidth: "180px",
  },
  cameraIcon: {
    color: theme.palette.primary.main,
    width: "60px",
    height: "60px",
  },
}));

const SmallUploadBox = ({
  limitReached,
  isUploading,
  getInputProps,
  style,
}: SmallUploadBoxProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper
        style={{ backgroundColor: limitReached ? "grey" : "inherit", ...style }}
        className={classes.paper}
        elevation={2}
      >
        {!isUploading && !!getInputProps && (
          <div>
            <input {...getInputProps()} />
            {limitReached ? (
              <Typography
                style={{
                  color: "#FFF",
                  textAlign: "center",
                }}
              >
                File Limit Reached. <br /> Please delete some photos to upload
                more
              </Typography>
            ) : (
              <div style={{ textAlign: "center" }}>
                <PhotoCamera className={classes.cameraIcon} />
              </div>
            )}
          </div>
        )}
      </Paper>
      <Typography
        style={{
          marginTop: "1.25rem",
          minHeight: "24px",
        }}
      >
        {limitReached ? "" : "Upload file"}
      </Typography>
    </div>
  );
};

export { SmallUploadBox };
export default SmallUploadBox;
