import { Box, TextField, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { YZButton } from "@yardzen-inc/react-common";
import React, { FC, useState } from "react";

export interface ReferralInputProps {
  handleReferralCodeSubmit: (s: string) => void;
  referralCodeError: boolean;
  validReferralCodeEntered: boolean;
  customSuccessMessage?: string;
  invalidCodeMessage?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  referralContainer: {
    display: "flex",
    justifyContent: "flex-start",
    "& p": {
      margin: "3px 0",

      "&.Mui-error": {
        color: "#191919",
      },
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  referralTextInput: {
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: "10px",
    },
  },
  referralSubmitButton: {
    // This will make the button the same height as the submit button.
    height: "48.6px",
    alignSelf: props =>
      (props as ReferralInputProps).referralCodeError ||
      (props as ReferralInputProps).validReferralCodeEntered
        ? "flex-start"
        : "center",
    minWidth: "100px",
    maxWidth: "100px",
    marginLeft: `20px`,
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
      marginLeft: "0px",
    },
    textTransform: "uppercase",
  },
}));

export const ReferralInput: FC<ReferralInputProps> = ({
  handleReferralCodeSubmit,
  referralCodeError,
  validReferralCodeEntered,
  customSuccessMessage,
  invalidCodeMessage,
}) => {
  const [referralCode, setReferralCode] = useState<string>("");
  const classes = useStyles({
    referralCodeError,
    validReferralCodeEntered,
  } as ReferralInputProps);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const casedCode = e.target.value.toUpperCase();
    setReferralCode(casedCode);
  };

  const code = localStorage.getItem("code");
  if (code && code !== referralCode) {
    localStorage.removeItem("code");
    setReferralCode(code);
    handleReferralCodeSubmit(code);
  }

  return (
    <Box>
      <Box
        className={classes.referralContainer}
        data-testid="referral-input-box"
      >
        <TextField
          id="referral-input-field"
          helperText={helperText()}
          label="Enter a name or code"
          value={referralCode}
          variant="outlined"
          onChange={handleChange}
          className={classes.referralTextInput}
        />
        <YZButton
          data-testid="referral-input-button"
          onClick={() => {
            handleReferralCodeSubmit(referralCode);
          }}
          type="submit"
          variant="outlined"
          className={classes.referralSubmitButton}
          disabled={!referralCode}
        >
          Submit
        </YZButton>
      </Box>
    </Box>
  );

  function helperText() {
    if (invalidCodeMessage) {
      return invalidCodeMessage;
    }

    // This is the default error message for an invalid referral code.
    if (referralCodeError) {
      return "Oops! That promo code isn’t valid. Try again or contact support.";
    }

    if (customSuccessMessage) {
      return customSuccessMessage;
    }

    // This is the default success message for a valid referral code.
    if (validReferralCodeEntered) {
      return "Success! Any eligible changes to your cart have been applied.";
    }

    return "";
  }
};
