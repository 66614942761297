import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

export const useUser = (): [Error | null, firebase.User | null | false] => {
  const [user, setUser] = useState<firebase.User | null | false>(null);
  const [err, setErr] = useState<Error | null>(null);

  // See if we can get current user from firebase.auth().currentUser or firebase.auth().onAuthStateChanged
  useEffect(listenForAuthEvents, []);
  // if we can't find a current user, user (on state) is set to false and we try to log in anonymously
  useEffect(() => {
    async function logInAnonymously(): Promise<firebase.auth.UserCredential | null> {
      let res: firebase.auth.UserCredential | null = null;

      try {
        res = await firebase.auth().signInAnonymously();
        getCurrentUserFromAuthState();
      } catch (err) {
        window.newrelic.noticeError(err);
        setErr(err);
      } finally {
        return res;
      }
    }
    if (user === false) {
      logInAnonymously();
    }
  }, [user]);

  return [err, user];

  function listenForAuthEvents(): (() => void) | void {
    if (!firebase.apps.length) {
      return setErr(new Error("APP_NOT_INITIALIZED"));
    } else {
      if (firebase.auth().currentUser) {
        setUser(firebase.auth().currentUser);
      }

      return getCurrentUserFromAuthState();
    }
  }

  function getCurrentUserFromAuthState() {
    firebase.auth().onAuthStateChanged(
      user => {
        if (!user) {
          return setUser(false);
        }

        return setUser(user);
      },
      loginError => {
        window.newrelic.noticeError(loginError);
        setErr((loginError as unknown) as Error);
      }
    );
  }
};
