import * as React from "react";
import BudgetChecklistSidebarItem, {
  BudgetChecklistSidebarItemProps,
} from "./BudgetChecklistSidebarItem";

interface IFoundationalInstallCostsListProps {
  installCostItems: BudgetChecklistSidebarItemProps[];
}

const FoundationalInstallCostsList: React.FunctionComponent<IFoundationalInstallCostsListProps> = ({
  installCostItems,
}) => {
  return (
    <>
      {installCostItems.map(installCostItem => (
        <BudgetChecklistSidebarItem
          key={installCostItem.itemName}
          {...installCostItem}
        />
      ))}
    </>
  );
};

export { FoundationalInstallCostsList };
