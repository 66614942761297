import * as React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";

const useStyles = makeStyles((theme: Theme) => ({
  defaultPrice: {
    height: "100%",
    fontSize: "1.2rem",
  },
}));

interface IRenderPriceProps {
  originalPrice: number;
  finalPrice: number | null;
}

const RenderPrice: React.FunctionComponent<IRenderPriceProps> = ({
  finalPrice,
  originalPrice,
}) => {
  const classes = useStyles();

  if (finalPrice !== null && finalPrice > 0 && finalPrice !== originalPrice) {
    return (
      <div>
        <YZTypography className={classes.defaultPrice}>
          <s>${(originalPrice / 100).toFixed(2)}</s>
        </YZTypography>
        <YZTypography>
          <em>${(finalPrice / 100).toFixed(2)}</em>
        </YZTypography>
      </div>
    );
  }

  if (finalPrice !== null && finalPrice <= 0) {
    return (
      <div>
        <YZTypography className={classes.defaultPrice}>
          <em>NO COST</em>
        </YZTypography>
      </div>
    );
  }

  return (
    <YZTypography className={classes.defaultPrice}>
      ${originalPrice / 100}
    </YZTypography>
  );
};

export { RenderPrice };
export default RenderPrice;
