import { BudgetCategory } from "@yardzen-inc/react-common";
import { PLANTING_ELEMENTS_CATEGORY } from "./wishlistCategoryConstants";

/**
 * Retrieves an array of sorted budget categories. Current sort order is
 * to place the 'Planting elements' first, and then all others in alphabetical
 * order.
 * @param unsortedCategories The budget categories to sort.
 * @returns An array of sorted budget categories.
 */
export const getSortedWishlistCategories = (
  unsortedCategories: BudgetCategory[]
): BudgetCategory[] => {
  const plantingCategory = unsortedCategories.find(
    category =>
      category.name.toLowerCase() === PLANTING_ELEMENTS_CATEGORY.toLowerCase()
  );

  const otherCategories = unsortedCategories
    .filter(
      category =>
        category.name.toLowerCase() !== PLANTING_ELEMENTS_CATEGORY.toLowerCase()
    )
    .sort((category1, category2) =>
      category1.name.localeCompare(category2.name)
    );

  if (!plantingCategory) {
    return otherCategories;
  }

  return [plantingCategory, ...otherCategories];
};
