import { useCallback, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import {
  useCreateDesignProfileMutation,
  useGetDesignProfilesQuery,
  useUpdateDesignProfileMutation,
} from "../../services/pangaeaService";
import { DesignProfile, UpdateDesignProfileDto } from "../../api/apiTypes";
import { DesignProfileVersionId } from "../../types/DesignProfile";
import { fbPixelInit } from "../../util/functions/sendFbPixelEvent";

export interface UseDesignProfile {
  ({
    user,
    versionId,
  }: {
    user: firebase.User;
    versionId: DesignProfileVersionId | undefined;
  }): {
    designProfile: DesignProfile | undefined;
    updateDesignProfile: (
      updateDesignProfileDto: UpdateDesignProfileDto
    ) => Promise<boolean | undefined>;
    error: Error | null;
    isLoading: boolean;
    isFetching: boolean;
    isUpdating: boolean;
  };
}

const useDesignProfile: UseDesignProfile = ({ user, versionId }) => {
  const {
    designProfile,
    error: errorFetchingDesignProfile,
    isSuccess: isDesignProfileQuerySuccessful,
    isFetching: isDesignProfileFetching,
  } = useGetDesignProfilesQuery(
    { userId: user.uid, versionId: versionId },
    {
      selectFromResult: ({ data, ...rest }) => {
        return {
          designProfile: data?.[0],
          ...rest,
        };
      },
    }
  );

  const [
    createDesignProfile,
    {
      error: errorCreatingDesignProfile,
      isLoading: isCreateDesignProfileLoading,
      isSuccess: isCreateDesignProfileSuccessful,
    },
  ] = useCreateDesignProfileMutation({
    fixedCacheKey: "create-design-profile-mutation",
  });

  const [
    updateDesignProfileMutation,
    { error: errorUpdatingDesignProfile, isLoading: isUpdateLoading },
  ] = useUpdateDesignProfileMutation();

  useEffect(() => {
    if (
      !designProfile &&
      isDesignProfileQuerySuccessful &&
      !isCreateDesignProfileSuccessful
    ) {
      (async () =>
        await createDesignProfile({
          userId: user.uid,
          isTestAccount:
            process.env.NODE_ENV === "development" ||
            window.location.href.includes("dogfood")
              ? true
              : false,
          abandonedCartEmailSent: false,
          purchased: false,
          packagePurchased: null,
          step: 0,
          contactInformation: {
            email: "",
          },
          versionId: versionId ?? "unknown",
          questions: [],
        }))();
    }

    // Carried over from the old implementation of useDesignProfile. Not
    // completely sure how this works, but it looks like it was previously
    // called every time design profile data was loaded, so we'll mimic that
    // here.
    if (designProfile) {
      fbPixelInit({
        em: designProfile.contactInformation?.email || undefined,
        fn: designProfile.contactInformation?.firstName || undefined,
        ct: designProfile.contactInformation?.city || undefined,
        ln: designProfile.contactInformation?.lastName || undefined,
        st: designProfile.contactInformation?.state || undefined,
        zp: designProfile.contactInformation?.zip || undefined,
        ph: designProfile.contactInformation?.phoneNumber || undefined,
        external_id: designProfile.userId || undefined,
      });
    }
  }, [
    isDesignProfileQuerySuccessful,
    designProfile,
    isCreateDesignProfileSuccessful,
    user.uid,
    createDesignProfile,
    versionId,
  ]);

  // This needs to be a callback otherwise we can run into infinite re-render
  // issues when updating the design profile's recommended package.
  const updateDesignProfile = useCallback(
    async (updatedDesignProfile: UpdateDesignProfileDto) => {
      if (!designProfile?.id) {
        throw new Error("Cannot update a design profile without an id.");
      }

      try {
        const isUpdated = await updateDesignProfileMutation({
          id: designProfile?.id,
          ...updatedDesignProfile,
        }).unwrap();

        return isUpdated as boolean;
      } catch (error) {
        console.error(
          "Failed to update the design profile. See the error returned by the mutation."
        );

        return;
      }
    },
    [designProfile?.id, updateDesignProfileMutation]
  );

  return {
    designProfile,
    updateDesignProfile,
    error:
      (errorFetchingDesignProfile as Error) ||
      (errorCreatingDesignProfile as Error) ||
      (errorUpdatingDesignProfile as Error),
    isLoading:
      // We want to check to make sure the query is successful, because
      // then we know it has completed. There is a chance for loading to be false
      // initially, making it somewhat unreliable.
      !isDesignProfileQuerySuccessful || isCreateDesignProfileLoading,
    // Fetching is different from loading in that loading is only true on
    // initial render.
    isFetching: isDesignProfileFetching,
    isUpdating: isUpdateLoading,
  };
};

export { useDesignProfile };
