import { SelectOption } from "../../components/onboard";

const STATES_DICTIONARY: SelectOption[] = [
  {
    mask: "Alabama",
    value: "AL",
    live: true,
  },
  {
    mask: "Alaska",
    value: "AK",
    live: true,
  },
  {
    mask: "American Samoa",
    value: "AS",
    live: false,
  },
  {
    mask: "Arizona",
    value: "AZ",
    live: true,
  },
  {
    mask: "Arkansas",
    value: "AR",
    live: true,
  },
  {
    mask: "California",
    value: "CA",
    live: true,
  },
  {
    mask: "Colorado",
    value: "CO",
    live: true,
  },
  {
    mask: "Connecticut",
    value: "CT",
    live: true,
  },
  {
    mask: "Delaware",
    value: "DE",
    live: true,
  },
  {
    mask: "District Of Columbia",
    value: "DC",
    live: true,
  },
  {
    mask: "Federated States Of Micronesia",
    value: "FM",
    live: false,
  },
  {
    mask: "Florida",
    value: "FL",
    live: true,
  },
  {
    mask: "Georgia",
    value: "GA",
    live: true,
  },
  {
    mask: "Guam",
    value: "GU",
    live: false,
  },
  {
    mask: "Hawaii",
    value: "HI",
    live: true,
  },
  {
    mask: "Idaho",
    value: "ID",
    live: true,
  },
  {
    mask: "Illinois",
    value: "IL",
    live: true,
  },
  {
    mask: "Indiana",
    value: "IN",
    live: true,
  },
  {
    mask: "Iowa",
    value: "IA",
    live: true,
  },
  {
    mask: "Kansas",
    value: "KS",
    live: true,
  },
  {
    mask: "Kentucky",
    value: "KY",
    live: true,
  },
  {
    mask: "Louisiana",
    value: "LA",
    live: true,
  },
  {
    mask: "Maine",
    value: "ME",
    live: true,
  },
  {
    mask: "Marshall Islands",
    value: "MH",
    live: false,
  },
  {
    mask: "Maryland",
    value: "MD",
    live: true,
  },
  {
    mask: "Massachusetts",
    value: "MA",
    live: true,
  },
  {
    mask: "Michigan",
    value: "MI",
    live: true,
  },
  {
    mask: "Minnesota",
    value: "MN",
    live: true,
  },
  {
    mask: "Mississippi",
    value: "MS",
    live: true,
  },
  {
    mask: "Missouri",
    value: "MO",
    live: true,
  },
  {
    mask: "Montana",
    value: "MT",
    live: true,
  },
  {
    mask: "Nebraska",
    value: "NE",
    live: true,
  },
  {
    mask: "Nevada",
    value: "NV",
    live: true,
  },
  {
    mask: "New Hampshire",
    value: "NH",
    live: true,
  },
  {
    mask: "New Jersey",
    value: "NJ",
    live: true,
  },
  {
    mask: "New Mexico",
    value: "NM",
    live: true,
  },
  {
    mask: "New York",
    value: "NY",
    live: true,
  },
  {
    mask: "North Carolina",
    value: "NC",
    live: true,
  },
  {
    mask: "North Dakota",
    value: "ND",
    live: true,
  },
  {
    mask: "Northern Mariana Islands",
    value: "MP",
    live: false,
  },
  {
    mask: "Ohio",
    value: "OH",
    live: true,
  },
  {
    mask: "Oklahoma",
    value: "OK",
    live: true,
  },
  {
    mask: "Oregon",
    value: "OR",
    live: true,
  },
  {
    mask: "Palau",
    value: "PW",
    live: false,
  },
  {
    mask: "Pennsylvania",
    value: "PA",
    live: true,
  },
  {
    mask: "Puerto Rico",
    value: "PR",
    live: false,
  },
  {
    mask: "Rhode Island",
    value: "RI",
    live: true,
  },
  {
    mask: "South Carolina",
    value: "SC",
    live: true,
  },
  {
    mask: "South Dakota",
    value: "SD",
    live: true,
  },
  {
    mask: "Tennessee",
    value: "TN",
    live: true,
  },
  {
    mask: "Texas",
    value: "TX",
    live: true,
  },
  {
    mask: "Utah",
    value: "UT",
    live: true,
  },
  {
    mask: "Vermont",
    value: "VT",
    live: true,
  },
  {
    mask: "Virgin Islands",
    value: "VI",
    live: false,
  },
  {
    mask: "Virginia",
    value: "VA",
    live: true,
  },
  {
    mask: "Washington",
    value: "WA",
    live: true,
  },
  {
    mask: "West Virginia",
    value: "WV",
    live: true,
  },
  {
    mask: "Wisconsin",
    value: "WI",
    live: true,
  },
  {
    mask: "Wyoming",
    value: "WY",
    live: true,
  },
];

// Sort the list alphabetically and filter to only include live states
const STATES_DICTIONARY_SORTED_FILTERED: SelectOption[] = STATES_DICTIONARY.sort(
  (a, b) => {
    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  }
).filter(a => a.live === true);

export default Object.freeze(STATES_DICTIONARY_SORTED_FILTERED);
