import { Product } from "../../../types/Products";
import { packageNameForWordpressRedirect } from "../../../util/constants/packageTypes";

export const wordpressUrlForPackage = (product: Product | null) => {
  let wordpressUrl = `${process.env.REACT_APP_WORDPRESS_BASE_URL}`;
  if (product) {
    const packageNamePage = packageNameForWordpressRedirect[product.name];
    if (packageNamePage) {
      wordpressUrl = `${process.env.REACT_APP_WORDPRESS_BASE_URL}/${packageNamePage}`;
    }
  }
  return wordpressUrl;
};
