import { YardName } from "../../components/onboard/stepData/types";

// Wide angle minimum yard photos is used in MyYardUpload
// to display minimum required photos to clients in a chip.

// The getWideAngleMinimum function is then used in the
// handleMinimums function. Once the wideAngleMinimum and
// slowPanMinimum are met, the YardUploadStatus is saved as "COMPLETE".

// The YardUploadStatus is then checked in detectMissingRequirements in
// client-account when a client starts their project.

type YardUploadMinimums = {
  [key in YardName]: number;
};

export const WIDE_ANGLE_MINIMUM_YARD_PHOTOS: YardUploadMinimums = {
  front: 6,
  back: 6,
  left: 4,
  right: 4,
  outdoor: 6,
};
