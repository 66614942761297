import { Loader, LoaderOptions, google } from "google-maps";

export const load = async (): Promise<google> => {
  (window as any).loadingG = true;

  if ((window as any).g) {
    return (window as any).g;
  }

  const options: LoaderOptions = { libraries: ["places"] };
  const loader = new Loader(
    process.env["REACT_APP_GOOGLE_MAPS_API_KEY"],
    options
  );

  (window as any).g = await loader.load();

  return (window as any).g;
};

export default load;
