import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PromotionalCode } from "../api/apiTypes";
import { IAddressObject } from "../util/functions/parseGeocodeLocationToAddress";

interface CheckoutState {
  checkoutEmail: string;
  checkoutFirstName: string;
  referralCodes: string[];
  inputReferralCode: string;
  restrictChanges: boolean;
  firstName: string;
  lastName: string;
  address: IAddressObject;
  billingAndPropertyAddressSame: boolean;
  accountCreated: boolean;
  addressAddedInDesignProfile: boolean;
  promotionalCode: PromotionalCode | null;
  promotionalCodeAppliedAt: string;
}

export const checkoutInitialState: CheckoutState = {
  checkoutEmail: "",
  checkoutFirstName: "",
  referralCodes: [],
  inputReferralCode: "",
  restrictChanges: false,
  firstName: "",
  lastName: "",
  address: {
    street: "",
    city: "",
    state: "",
    zip: "",
    aptNumber: undefined,
    formattedAddress: "",
    country: "",
  },
  billingAndPropertyAddressSame: true,
  accountCreated: false,
  addressAddedInDesignProfile: false,
  promotionalCode: null,
  promotionalCodeAppliedAt: "",
};

export const checkout = createSlice({
  name: "checkout",
  initialState: checkoutInitialState,
  reducers: {
    setCheckoutEmail: (
      state,
      action: PayloadAction<{ checkoutEmail: string }>
    ) => {
      state.checkoutEmail = action.payload.checkoutEmail;
    },
    setCheckoutFirstName: (
      state,
      action: PayloadAction<{ checkoutFirstName: string }>
    ) => {
      state.checkoutFirstName = action.payload.checkoutFirstName;
    },
    setReferralCodes: (
      state,
      action: PayloadAction<{ referralCodes: string[] }>
    ) => {
      state.referralCodes = action.payload.referralCodes;
    },
    setInputReferralCode: (
      state,
      action: PayloadAction<{ inputReferralCode: string }>
    ) => {
      state.inputReferralCode = action.payload.inputReferralCode;
    },
    setRestrictChanges: (
      state,
      action: PayloadAction<{ restrictChanges: boolean }>
    ) => {
      state.restrictChanges = action.payload.restrictChanges;
    },
    setFirstName: (state, action: PayloadAction<{ firstName: string }>) => {
      state.firstName = action.payload.firstName;
    },
    setLastName: (state, action: PayloadAction<{ lastName: string }>) => {
      state.lastName = action.payload.lastName;
    },
    setStreet: (state, action: PayloadAction<{ street: string }>) => {
      state.address.street = action.payload.street;
    },
    setCity: (state, action: PayloadAction<{ city: string }>) => {
      state.address.city = action.payload.city;
    },
    setState: (state, action: PayloadAction<{ state: string }>) => {
      state.address.state = action.payload.state;
    },
    setZip: (state, action: PayloadAction<{ zip: string }>) => {
      state.address.zip = action.payload.zip;
    },
    setApt: (state, action: PayloadAction<{ aptNumber: string }>) => {
      state.address.aptNumber = action.payload.aptNumber;
    },
    setAddress: (
      state,
      action: PayloadAction<{
        address: IAddressObject;
      }>
    ) => {
      state.address = action.payload.address;
    },
    setBillingAndPropertyAddressSame: (
      state,
      action: PayloadAction<{ billingAndPropertyAddressSame: boolean }>
    ) => {
      state.billingAndPropertyAddressSame =
        action.payload.billingAndPropertyAddressSame;
    },
    setAccountCreated: (
      state,
      action: PayloadAction<{ accountCreated: boolean }>
    ) => {
      state.accountCreated = action.payload.accountCreated;
    },
    setAddressAddedInDesignProfile: (
      state,
      action: PayloadAction<{ addressAddedInDesignProfile: boolean }>
    ) => {
      state.addressAddedInDesignProfile =
        action.payload.addressAddedInDesignProfile;
    },
    setPromotionalCode: (
      state,
      action: PayloadAction<PromotionalCode | null>
    ) => {
      state.promotionalCode = action.payload;
      state.promotionalCodeAppliedAt =
        action.payload === null ? "" : new Date().toISOString();
    },
    resetCheckout: state => checkoutInitialState,
  },
});

export const {
  setCheckoutEmail,
  setCheckoutFirstName,
  setReferralCodes,
  setInputReferralCode,
  setRestrictChanges,
  setFirstName,
  setLastName,
  setStreet,
  setCity,
  setState,
  setZip,
  setApt,
  setAddress,
  setBillingAndPropertyAddressSame,
  setAccountCreated,
  setAddressAddedInDesignProfile,
  setPromotionalCode,
  resetCheckout,
} = checkout.actions;

export default checkout.reducer;
