import firebase from "firebase/compat/app";

export default function setUserIdInGoogleAnalytics(
  user: firebase.User | false | null
) {
  const ga = window["ga"];

  if (!user || !ga) {
    return;
  }
}

export { setUserIdInGoogleAnalytics };
