import { ASSETS_BASE_URL } from "./assetsBaseUrl";

export interface howYZWorksTypes {
  day: string;
  title: string;
  description: string;
  imageSrc: string;
  alt: string;
  id: string;
}

const howYZWorks: howYZWorksTypes[] = [
  {
    id: "1",
    imageSrc: `${ASSETS_BASE_URL}/how-step-1.jpg`,
    alt:
      "Upload photos and videos using your phone in Yardzen’s YardBuilder exercise so you can show what your current yard looks like",
    day: "DAY 1",
    title: "YardBuilder Exercise",
    description:
      "Upload photos, videos, define your style, project goals, and budget",
  },
  {
    id: "2",
    imageSrc: `${ASSETS_BASE_URL}/how-step-21.jpg`,
    alt:
      "Your purchased Yardzen package will include 3D design renders of your new yard designed by our design team",
    day: "Week 1 - 3",
    title: "Design",
    description:
      "Our design team gets to work and shares detailed 3D renderings",
  },
  {
    id: "3",
    imageSrc: `${ASSETS_BASE_URL}/how-step-3.jpg`,
    alt:
      "After your design is complete, you can leave your feedback on the design using Yardzen’s design annotator, and that feedback will be taken into account in your design revision",
    day: "Week 3 - 6",
    title: "Design Revision",
    description:
      "Review initial renderings, share feedback, and receive final designs",
  },
  {
    id: "4",
    imageSrc: `${ASSETS_BASE_URL}/how-step-4.jpg`,
    alt:
      "After your design is completed, Yardzen will match you with a vetted contractor to build your project",
    day: "Week 6-7",
    title: "Build",
    description: "Get matched with a vetted contractor to build your project",
  },
];

export { howYZWorks };
