import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { store } from "../store";
import { setReferralCodes } from "../slices/checkoutSlice";
import { noticeError } from "../util/newrelic/newrelic";

const getReferralCodes = async (): Promise<string[]> => {
  const codes: string[] = [];
  try {
    const snapshot = await firebase
      .firestore()
      .collection("codes")
      .get();
    snapshot.forEach(doc => {
      const code = doc.data().code;
      if (code) {
        codes.push(code);
      }
    });
  } catch (error) {
    noticeError(error);
    console.error(error);
  }
  return codes;
};

const setReferralCodesToStore = async (): Promise<void> => {
  try {
    const referralCodes = await getReferralCodes();

    store.dispatch(setReferralCodes({ referralCodes: referralCodes }));
  } catch (error) {
    noticeError(error);
    console.error(error);
  }
};

export { getReferralCodes, setReferralCodesToStore };
