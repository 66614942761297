import { useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import useAbandonedCartContext from "./abandonedCartContext";
import LogRocket from "logrocket";
import { GenericOnCallFunctionAlert } from "../functions/genericAlert";

const useSubscribeToAbandonedCartEmail = (
  email: string | null,
  sku: string,
  firstName?: string | null,
  lastName?: string | null
) => {
  const { hasBeenSubscribed, setHasBeenSubscribed } = useAbandonedCartContext();
  const { email: prevEmail, sku: prevSku } = hasBeenSubscribed;

  useEffect(() => {
    if (!email || !email.length || !sku || !sku.length) return;

    if (email !== prevEmail || sku !== prevSku) {
      subscribeToAbandonedCartEmail(email, sku, firstName, lastName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, prevEmail, prevSku, setHasBeenSubscribed, sku]);

  async function subscribeToAbandonedCartEmail(
    email: string,
    sku: string,
    firstName?: string | null,
    lastName?: string | null
  ) {
    if (!!firstName && !!lastName) {
      try {
        const subscribeToAbandonedCartEmail = firebase
          .functions()
          .httpsCallable("addEmailToAbandonedCartMailingList");
        await subscribeToAbandonedCartEmail({
          email,
          sku,
          firstName,
          lastName,
        });
        setHasBeenSubscribed({ email, sku, firstName, lastName });
      } catch (error) {
        window.newrelic.noticeError(error);
        console.error(`Error subscribing user to email: ${error}`);
        await LogRocket.captureMessage(
          `Error susbscribing user to abandoned cart email: ${error}`
        );
        GenericOnCallFunctionAlert(
          "addEmailToAbandonedCartMailingList",
          error.message
        );
      }
    }
  }
};

export { useSubscribeToAbandonedCartEmail };
export default useSubscribeToAbandonedCartEmail;
