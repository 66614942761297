import * as React from "react";
import { Box, useMediaQuery, Theme, makeStyles } from "@material-ui/core";
import SquareModalBase from "./SquareModalBase";
import { YZButton } from "..";
import { ArrowForwardIos, ArrowBackIos, ArrowRight } from "@material-ui/icons";
import SquareModalCaroselSlideRow from "./SquareModalCaroselSlideRow";

export interface SquareModalDesignCarouselSlideProps {
  imageURIs: [string, string, string];
  blurbs?: [string, string, string];
  open: boolean;
  onClose: () => void;
  buttonText?: string;
  onButtonClick?: () => void;
  onNext?: () => void;
  onPrevious?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  blurb: {
    fontSize: "1.2rem",
    width: "fit-content",
    fontWeight: "lighter",
    paddingBottom: "0.8rem",
  },
  arrow: {
    color: "white",
    height: "2.7rem",
    width: "2.7rem",
  },
}));

const SquareModalDesignCarouselSlide: React.FC<SquareModalDesignCarouselSlideProps> = (
  props: SquareModalDesignCarouselSlideProps
) => {
  const classes = useStyles();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const [selectedURI, setSelectedURI] = React.useState<string>(
    props.imageURIs[0]
  );

  React.useEffect(onUrlsChange, [props.imageURIs]);

  return (
    <SquareModalBase
      onClose={props.onClose}
      open={props.open}
      contentContainerProps={{
        p: 0,
        width: smDown ? "800px" : "900px",
        maxWidth: "100vw",
      }}
      rightBoxChildren={
        props.onNext && (
          <Box
            onClick={props.onNext}
            style={{ pointerEvents: "all", cursor: "pointer" }}
          >
            <ArrowForwardIos className={classes.arrow} />
          </Box>
        )
      }
      leftBoxChildren={
        props.onPrevious && (
          <Box
            onClick={props.onPrevious}
            style={{ pointerEvents: "all", cursor: "pointer" }}
          >
            <ArrowBackIos className={classes.arrow} />
          </Box>
        )
      }
    >
      <Box display="flex" flexDirection="column">
        <Box
          display={"inline-block"}
          minWidth={smDown ? "100%" : "500px"}
          height={smDown ? "350px" : "475px"}
          maxWidth={"100%"}
          style={{
            backgroundImage: `url(${selectedURI})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
        <Box
          justifyContent="space-between"
          flexGrow={1}
          display="flex"
          flexDirection="column"
          px={2}
          py={2}
        >
          <SquareModalCaroselSlideRow
            imageSet={props.imageURIs}
            setSelectedURI={setSelectedURI}
          />

          {!!(props.buttonText && props.onButtonClick) && (
            <Box
              display="flex"
              flexDirection="row-reverse"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              p={1}
              pr={2}
            >
              <ArrowRight style={{ marginLeft: "-0.8rem" }} />
              <YZButton
                variant="text"
                onClick={props.onButtonClick}
                typographyProps={{
                  style: {
                    fontSize: "0.8rem",
                    color: "black",
                    letterSpacing: "0.3rem",
                  },
                }}
              >
                {props.buttonText}
              </YZButton>
            </Box>
          )}
        </Box>
      </Box>
    </SquareModalBase>
  );

  function onUrlsChange(): void {
    setSelectedURI(props.imageURIs[0]);
  }
};

export { SquareModalDesignCarouselSlide };
export default SquareModalDesignCarouselSlide;
