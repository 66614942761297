import moment from "moment";
import { v4 } from "uuid";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import LogRocket from "logrocket";

export interface MediaRecord {
  readonly userId: string;
  readonly path: string; // <userId>.<uuid>.<unix timestamp in milliseconds>
  readonly originalFileName: string;
  readonly fileType: string;
  readonly downloadUrl?: string;
  readonly uploadedAt: string;
  readonly isDesignerVisible?: boolean;
  readonly title?: string;
  readonly isClientVisible?: boolean;
  readonly tag?: string;
  readonly variant?: string;
  readonly projectId?: string;
  readonly description?: string | null;
}

export interface MediaVisibility {
  isClientVisible?: boolean;
  isDesignerVisible?: boolean;
  isDesignerContributed?: boolean;
}

export interface MediaProperties {
  id?: string;
  title?: string;
  file?: File;
  fileType?: string;
  originalFileName?: string;
  userId?: string;
  visibility?: MediaVisibility;
  downloadURL?: string;
  path?: string;
  uploadedAt?: string;
  tag?: string;
  variant?: string;
  projectId?: string;
  description?: string | null;
}

const uploadMedia = async (
  file: File,
  userId: string,
  onProgressCallback: Function | null = null,
  tag: string = "",
  variant: string = "",
  projectId: string = "",
  fileType: string | null = null
) => {
  if (!userId || !file) {
    return false;
  }
  const fileExtension = ((fileName: string) => {
    if (!fileName.includes(".")) {
      return "";
    }

    return fileName.substr(fileName.lastIndexOf("."), fileName.length);
  })(file.name);

  const storagePath = `media/${userId}.${v4()}.${moment()
    .utc()
    .valueOf()}${fileExtension}`;
  var uploadTask = firebase
    .storage()
    .ref(storagePath)
    .put(file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot: any) => {
        if (onProgressCallback) {
          onProgressCallback(file, snapshot);
        }
      },
      (error: any) => {
        console.error(`Error uploading media: ${error}`);
        LogRocket.captureException(error);
        reject(error);
      },
      () => {
        resolve(file);
        return storeMetadata(
          uploadTask.snapshot,
          file,
          userId,
          tag,
          variant,
          projectId,
          fileType
        );
      }
    );
  });
};

async function storeMetadata(
  snapshot: firebase.storage.UploadTaskSnapshot,
  file: File,
  userId: string,
  tag: string = "",
  variant: string = "",
  projectId: string = "",
  fileType: string | null,
  visibility: MediaVisibility = {
    isDesignerVisible: true,
    isClientVisible: true,
  }
) {
  const mediaRecord: MediaRecord = {
    userId: userId,
    path: snapshot.ref.fullPath,
    originalFileName: file.name,
    fileType: fileType || file.type,
    uploadedAt: moment().toISOString(),
    isDesignerVisible: visibility.isDesignerVisible ? true : false,
    isClientVisible: visibility.isClientVisible ? true : false,
    tag: tag,
    variant: variant,
    projectId: projectId,
  };

  try {
    /*
      Running into a known firebase issue with .add:
      Occasionally with get "FirebaseError: Document already exists" even though
      the document is successfully added. Workaround for the time being is to
      use .doc().set():

      Github issue: https://github.com/firebase/firebase-js-sdk/issues/5549

    */
    await firebase
      .firestore()
      .collection("media")
      .doc()
      .set(mediaRecord);

    const querySnapshot = await firebase
      .firestore()
      .collection("media")
      .where("userId", "==", mediaRecord.userId)
      .where("originalFileName", "==", mediaRecord.originalFileName)
      .where("uploadedAt", "==", mediaRecord.uploadedAt)
      .get();

    return querySnapshot.docs[0];
  } catch (error) {
    window.newrelic.noticeError(error);
    console.error(
      `Error creating media record ${mediaRecord.originalFileName}`
    );
    throw error;
  }
}

export default uploadMedia;
