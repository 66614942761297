import React, { FC } from "react";
import { Chip, Theme } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import { makeStyles } from "@material-ui/styles";

interface UploadProps {
  title: string;
  warning?: string;
  info?: string;
  instructions?: JSX.Element;
  accept?: string[];
  id?: string;
  className?: string;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  instructionLink: {
    textDecoration: "underline",
    color: "#4C4C4C",
    "&:hover": {
      cursor: "pointer",
    },
  },
  helpChip: {
    marginLeft: spacing(1),
    backgroundColor: "#E5A5A5",
    color: "secondary",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: spacing(4),
    paddingBottom: spacing(1),
  },
}));

export const UploadTitle: FC<UploadProps> = props => {
  const classes = useStyles();

  return (
    <div className={classes.titleContainer}>
      <YZTypography
        type="serif"
        variant="h5"
        id={props.id ? props.id : undefined}
      >
        {props.title}
      </YZTypography>

      {props.warning && (
        <Chip size="small" label={props.warning} className={classes.helpChip} />
      )}
      {props.info && (
        <Chip
          size="small"
          variant="outlined"
          label={props.info}
          className={classes.helpChip}
        />
      )}
    </div>
  );
};

export default UploadTitle;
