import {
  User,
  Project,
  ApolloClient,
  InMemoryCache,
  SingleUserQueryResult,
  ApolloError,
  SingleProjectQueryResult,
  InsertUserMutationResult,
  InsertProjectMutationResult,
  useApolloClient,
  AllBudgetChecklistsQueryResult,
  ALL_BUDGET_CHECKLISTS,
  INSERT_USER,
  INSERT_PROJECT,
  SINGLE_PROJECT,
  SINGLE_USER,
  ApolloAuthenticationCtx,
} from "@yardzen-inc/graphql";
import { useContext, useState, useEffect } from "react";
import { UserCtx, useLogError, OnboardCtx } from "../../../util";

export interface UseCreateUserProjectInPostgres {
  ():
    | null
    | [
        Pick<User, "id">,
        Pick<Project, "id" | "budget_checklist_id" | "user_id">
      ];
}

// TODO: add budgetChecklist id check and then set on project

const useCreateUserProjectInPostgres: UseCreateUserProjectInPostgres = () => {
  const user = useContext(UserCtx);
  const onboardState = useContext(OnboardCtx);
  const isAuthorized = useContext(ApolloAuthenticationCtx);

  const fbUserId = user ? user.uid : null;
  const fbProjectId = onboardState?.state.projectId ?? null;

  const client = useApolloClient();

  const [hUser, setHUser] = useState<null | Pick<User, "id">>(null);
  const [hProject, setHProject] = useState<null | Pick<
    Project,
    "id" | "budget_checklist_id" | "user_id"
  >>(null);
  const [error, setError] = useState<Error | ApolloError | null>(null);

  useLogError(error);

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleUserChange, [fbUserId, fbProjectId]);

  if (!hUser || !hProject) return null;

  return [hUser, hProject];

  function handleUserChange(): void {
    void (async function() {
      if (!fbUserId || !fbProjectId || !isAuthorized) return;

      // TODO: rewrite this in favor of a user lookup with a project join

      try {
        let [user, project] = await Promise.all([
          fetchUser(client as ApolloClient<InMemoryCache>, fbUserId),
          fetchProject(client as ApolloClient<InMemoryCache>, fbProjectId),
        ]);

        if (!user) {
          const result = (
            await createUser(client as ApolloClient<InMemoryCache>, fbUserId)
          )?.[0];

          if (!result) {
            throw new Error("create user mutation returned nothing");
          }

          const { __typename, ...newUser } = result;
          user = newUser;
        }

        if (!project) {
          const budgetChecklistId = await getLatestPublishedBudgetChecklistId(
            client as ApolloClient<InMemoryCache>
          );

          const result = (
            await createProject(
              client as ApolloClient<InMemoryCache>,
              fbProjectId,
              fbUserId,
              budgetChecklistId
            )
          )?.[0];

          if (!result) {
            throw new Error("create user mutation returned nothing");
          }

          const { __typename, ...newProject } = result;
          project = newProject;
        }

        setHUser(user);
        setHProject(project as typeof hProject);
      } catch (error) {
        window.newrelic.noticeError(error);
        console.error(error);
        setError(error);
      }
    })();
  }
};

async function getLatestPublishedBudgetChecklistId(
  client: ApolloClient<InMemoryCache>
): Promise<string> {
  // TODO: DO NOT pull in all the budget checklists
  // also TODO: ensure that query pulls documents in order from newest to oldest

  const result = (await client.query({
    query: ALL_BUDGET_CHECKLISTS,
    fetchPolicy: "network-only",
  })) as AllBudgetChecklistsQueryResult;

  if (!result.data?.budget_checklist.length) {
    throw new Error("no budget checklist found for assignment to client");
  }

  // TODO: give budget_checklist_table published: bool & publishedAt: timestamptz columns
  return result.data.budget_checklist.find(ch => true)?.id as string;
}

async function createUser(client: ApolloClient<InMemoryCache>, id: string) {
  const result = (await client.mutate({
    mutation: INSERT_USER,
    variables: {
      id,
    },
  })) as InsertUserMutationResult;

  if (result.error) {
    throw result.error;
  }

  return result?.data?.insert_user?.returning;
}

async function createProject(
  client: ApolloClient<InMemoryCache>,
  id: string,
  userId: string,
  budgetChecklistId: string
) {
  const result = (await client.mutate({
    mutation: INSERT_PROJECT,
    variables: {
      id,
      user_id: userId,
      budget_checklist_id: budgetChecklistId,
    },
  })) as InsertProjectMutationResult;

  if (result.error) {
    throw result.error;
  }

  return result?.data?.insert_project?.returning;
}

async function fetchProject(
  client: ApolloClient<InMemoryCache>,
  id: string
): Promise<
  Pick<Project, "id" | "user_id" | "budget_checklist_id"> | undefined
> {
  const result = (await client.query({
    query: SINGLE_PROJECT,
    variables: {
      id,
    },
    fetchPolicy: "network-only",
  })) as SingleProjectQueryResult;

  if (result.error) {
    throw result.error;
  }

  const data = result?.data?.project?.[0];

  if (!data) {
    client.cache.evict({ id: id });
  }

  return data;
}

async function fetchUser(
  client: ApolloClient<InMemoryCache>,
  id: string
): Promise<Pick<User, "id"> | undefined> {
  const result = (await client.query({
    query: SINGLE_USER,
    variables: {
      id,
    },
    fetchPolicy: "network-only",
  })) as SingleUserQueryResult;

  if (result.error) {
    throw result.error;
  }

  const data = result?.data?.user?.[0];

  if (!data) {
    client.cache.evict({ id: id });
  }

  return data;
}

export { useCreateUserProjectInPostgres };
