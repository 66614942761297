import { GenericLoadingSpinner } from "@yardzen-inc/react-common";
import React from "react";
import { useDesignProfileCtx } from "./DesignProfileCtx";
import { CenterFullScreen } from "../utility";

interface DesignProfileContextLoadingProps {
  children?: any;
}

export const DesignProfileContextLoading = ({
  children,
}: DesignProfileContextLoadingProps) => {
  const { isLoading } = useDesignProfileCtx();

  if (isLoading) {
    return (
      <CenterFullScreen>
        <GenericLoadingSpinner />
      </CenterFullScreen>
    );
  }

  return children;
};
