import {
  IAddressObject,
  parseGeocodeLocationToAddress,
} from "../../../util/functions/parseGeocodeLocationToAddress";
import { geocodeByPlaceId } from "react-google-places-autocomplete";

/**
 * Gets a parsed address object from a google place's place id.
 * @param placeId The id of the place to parse into an address.
 * @returns The parsed address object.
 */
export const getParsedAddressFromPlaceId = async (
  placeId: string
): Promise<IAddressObject> => {
  const geoLocation = (await geocodeByPlaceId(placeId))[0];

  return parseGeocodeLocationToAddress(geoLocation);
};
