import React, { FC, useContext, useState, useEffect } from "react";
import {
  Typography,
  Divider,
  makeStyles,
  Theme,
  capitalize,
  useMediaQuery,
} from "@material-ui/core";
import { OnboardCtx, ProfileCtx, useSetTitle } from "../../util";
import capitalizeFirstLetter from "../utility/capitalizeFirstLetter";
import YardUploadGrid from "./YardUploadGrid";
import { YardName } from "./stepData";
import StepWrapper from "./StepWrapper";
import { RouteChildrenProps, useHistory } from "react-router";
import { YardSlopeQuestionAndUpload } from "./myYard/YardSlopeQuestionAndUpload";
import SectionTitle from "./helpers/SectionTitle";
import UploadTitle from "./helpers/UploadTitle";
import SaveBar from "./helpers/SaveBar";
import { YardTaskStatus, YardUploadStatus } from "@yardzen-inc/models";
import {
  SegmentFlows,
  SegmentInputTypes,
  SegmentSubFlows,
  useSegment,
} from "../../util/Segment";
import { segmentTrackUploadHandler } from "../upload/segmentUtil";
import { ASSETS_BASE_URL } from "../../util/constants/assetsBaseUrl";
import { WIDE_ANGLE_MINIMUM_YARD_PHOTOS } from "../../util/constants/yardPhotoMinimums";
import { getAvailableYards } from "./util/getAvailableYards";

export interface MyYardUploadProps {
  yard: YardName;
  routeProps?: RouteChildrenProps;
  uploadClassName?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  instructionLink: {
    textDecoration: "underline",
    color: "#4C4C4C",
    "&:hover": {
      cursor: "pointer",
    },
  },
  helpChip: {
    marginLeft: theme.spacing(1),
  },
  imageUploadContainer: {
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  imageUploadRow: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    objectFit: "contain",
    height: "170px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "auto",
    },
  },
  yardImage: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      objectFit: "contain",
      maxHeight: "30vh",
      width: "auto",
    },
  },
  imageUploadInstructions: {
    textAlign: "left",
    padding: "5px",
    marginLeft: "1rem",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingTop: theme.spacing(2),
    },
  },

  photoUploadGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "1rem",
    gridAutoFlow: "row",
    "& :first-child": {
      gridColumn: "1 / -1",
    },
  },
  uploadClassName: {
    width: "100% !important",
    height: "275px !important",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(3),
  },
  myYardUploadTitle: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginTop: theme.spacing(4),
    },
  },
  divider: {
    backgroundColor: "#F0F0F0",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    marginLeft: "-10%",
    marginRight: "-10%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

const MyYardUpload: FC<MyYardUploadProps> = props => {
  const segment = useSegment();
  const yard = props.yard;
  const variantTagPrefix = React.useMemo(getVariantTagPrefix, [yard]);
  useSetTitle(yard ? `Yardzen - ${yard} yard uploads` : "Yardzen", false, [
    yard,
  ]);
  const history = useHistory();
  const classes = useStyles();
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const profile = React.useContext(ProfileCtx);
  const packagePurchased = profile ? profile.package : null;
  const availableYards = getAvailableYards(packagePurchased);
  const sectionTitle =
    availableYards.length === 1
      ? "Begin Uploading Photos of Your Space"
      : `Upload Photos & Videos of your ${capitalizeFirstLetter(
          yard as YardName
        )} Yard!`;

  const {
    state: { projectId, yardsStatusObj: yardStatusObj },
    setYardStepStatus: setWithCTX,
  } = useContext(OnboardCtx);
  const [wideAngleUploadCount, setWideAngleUploadCount] = useState<
    number | undefined
  >(undefined);
  const [slowPanUploadCount, setSlowPanUploadCount] = useState<
    number | undefined
  >(undefined);

  const [wideAngleMinimumMet, slowPanMinimumMet] = React.useMemo(
    handleMinimums,
    [slowPanUploadCount, wideAngleUploadCount, yard]
  );

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleShouldUpdateContext, [
    wideAngleMinimumMet,
    slowPanMinimumMet,
  ]);

  return (
    <StepWrapper
      id="upload-step-container"
      setMaxWidth={!mobile ? "700px" : undefined}
    >
      <SectionTitle
        className={classes.myYardUploadTitle}
        title={sectionTitle}
        subtitle={`These photos and videos will be used by your designer to understand the
          current landscape of your yard and the architecture of your house. The
          more photos and videos you provide, the more accurate your design will
          be!`}
        hasBack
      />
      <YardSlopeQuestionAndUpload
        yard={yard}
        variantTagPrefix={variantTagPrefix}
        projectId={projectId}
        setIsSloped={async (isSloped: boolean) => {
          await setWithCTX(
            yard,
            "isSloped",
            (isSloped as unknown) as YardTaskStatus
          );

          segment.trackFormFieldFilled({
            field_name: "Yard Sloped",
            form_name: getFormName(),
            flow_name: SegmentFlows.ONBOARD,
            sub_flow_name: SegmentSubFlows.MY_YARD,
            input_type: SegmentInputTypes.SELECT,
            option_selected: isSloped ? "YES" : "NO",
          });
        }}
        isSloped={!!yardStatusObj[yard]?.isSloped}
      />
      <Divider className={classes.divider} />
      <UploadTitle
        id="wide-angle-upload"
        title="Horizontal Photos"
        warning={
          !wideAngleMinimumMet
            ? `At least ${getWideAngleMinimum(yard)} images`
            : undefined
        }
        accept={[".jpg", ".jpeg", ".png", ".svg", ".heic"]}
      />
      {!!projectId && (
        <div className={classes.imageUploadContainer}>
          <div className={classes.imageUploadRow}>
            <img
              className={classes.yardImage}
              src={`${ASSETS_BASE_URL}/Modal-01-WideAngle.jpg`}
              alt="horizontal instructions"
            />
            <Typography
              variant="body1"
              className={classes.imageUploadInstructions}
            >
              Take a photo at each of the arrows in the specified direction.
              Make sure to have your phone in landscape (horizontal) mode at 1x
              (no zoom or panoramic). These photos need to include the entirety
              of your yard and house so we can model it properly.
            </Typography>
          </div>
          <YardUploadGrid
            onDelete={media => {
              segment.trackRemoved({
                flow_name: SegmentFlows.ONBOARD,
                sub_flow_name: SegmentSubFlows.MY_YARD,
                removable_name: `${capitalize(
                  props.yard
                )} Yard Wide Angle Photo`,
                item_name: media.originalFileName,
              });
            }}
            onUploadComplete={files =>
              segmentTrackUploadHandler(segment, {
                files,
                upload_name: `${capitalize(props.yard)} Yard Wide Angle Photo`,
                form_name: getFormName(),
              })
            }
            projectId={projectId}
            fileTag="property"
            variant={`${variantTagPrefix}-wide-angle`}
            setFileCount={setWideAngleUploadCount}
            accept={[".jpg", ".jpeg", ".png", ".svg", ".heic"]}
            className={!mobile ? classes.photoUploadGrid : undefined}
            uploadClassName={!mobile ? classes.uploadClassName : undefined}
          />
          <Divider className={classes.divider} />
        </div>
      )}

      <UploadTitle
        title="Slow-Pan Video"
        warning={!slowPanMinimumMet ? "At least 1 required" : undefined}
        id="slow-pan-upload"
        accept={["video/*"]}
      />

      {!!projectId && (
        <div className={classes.imageUploadContainer}>
          <div className={classes.imageUploadRow}>
            <img
              className={classes.yardImage}
              src={`${ASSETS_BASE_URL}/public/upload-instructions-slowpan.png`}
              alt="horizontal instructions"
            />
            <Typography
              variant="body1"
              className={classes.imageUploadInstructions}
            >
              Slowly pan around each area of your yard and make sure to capture
              everything (including your house)!
            </Typography>
          </div>
          <YardUploadGrid
            video
            onDelete={media => {
              segment.trackRemoved({
                flow_name: SegmentFlows.ONBOARD,
                sub_flow_name: SegmentSubFlows.MY_YARD,
                removable_name: `${capitalize(props.yard)} Yard Slow-pan Video`,
                item_name: media.originalFileName,
              });
            }}
            onUploadComplete={files =>
              segmentTrackUploadHandler(segment, {
                files,
                upload_name: `${capitalize(props.yard)} Yard Slow-pan Video`,
                form_name: getFormName(),
              })
            }
            projectId={projectId}
            fileTag="property"
            variant={`${variantTagPrefix}-slow-pan`}
            setFileCount={setSlowPanUploadCount}
            accept={["video/*"]}
            className={!mobile ? classes.photoUploadGrid : undefined}
            uploadClassName={!mobile ? classes.uploadClassName : undefined}
          />
          <Divider className={classes.divider} />
        </div>
      )}

      <UploadTitle
        title="Chatty Video"
        id="slow-pan-upload"
        accept={["video/*"]}
      />
      {!!projectId && (
        <div className={classes.imageUploadContainer}>
          <div className={classes.imageUploadRow}>
            <img
              className={classes.yardImage}
              src={`${ASSETS_BASE_URL}/public/upload-instructions-chatty.png`}
              alt="chatty video instructions"
            />
            <Typography
              variant="body1"
              className={classes.imageUploadInstructions}
            >
              If there are areas where you need to highlight special
              circumstances, let us know in a 2-3 min long video.
            </Typography>
          </div>
          <YardUploadGrid
            video
            onDelete={media => {
              segment.trackRemoved({
                flow_name: SegmentFlows.ONBOARD,
                sub_flow_name: SegmentSubFlows.MY_YARD,
                removable_name: `${capitalize(props.yard)} Yard Chatty Video`,
                item_name: media.originalFileName,
              });
            }}
            onUploadComplete={files =>
              segmentTrackUploadHandler(segment, {
                files,
                upload_name: `${capitalize(props.yard)} Yard Chatty Video`,
                form_name: getFormName(),
              })
            }
            projectId={projectId}
            fileTag="property"
            variant={`${variantTagPrefix}-chatty`}
            accept={["video/*"]}
            className={!mobile ? classes.photoUploadGrid : undefined}
            uploadClassName={!mobile ? classes.uploadClassName : undefined}
          />
        </div>
      )}

      <SaveBar
        label="Save and go to keep/remove →"
        mdUpStyle={{ marginTop: 40, marginLeft: 130 }}
        smDownStyle={{ marginTop: 40 }}
        onClick={() => {
          handleNav("keepRemove");
          segment.trackFormSubmitted({
            flow_name: SegmentFlows.ONBOARD,
            sub_flow_name: SegmentSubFlows.MY_YARD,
            form_name: getFormName(),
          });
        }}
      />
    </StepWrapper>
  );

  function handleNav(destination: keyof YardUploadStatus) {
    const subStepUrlMap = {
      keepRemove: "keep", // Keep and remove will be consolidated to one view/endpoint
    };

    history.push(
      `/onboard/my-yard/${yard}/${
        destination === "keepRemove" ? subStepUrlMap[destination] : destination
      }`
    );
  }

  // get proper prefix for variant tag to be added to media in firebase
  function getVariantTagPrefix() {
    if (yard === "front") return "front-yard";
    if (yard === "back") return "back-yard";
    if (yard === "left") return "left-yard";
    if (yard === "right") return "right-yard";
    if (yard === "outdoor") return "outdoor-yard";
    return `${yard}-yard`;
  }

  function getWideAngleMinimum(yard: YardName): number {
    return WIDE_ANGLE_MINIMUM_YARD_PHOTOS[yard];
  }

  function handleMinimums() {
    const wideAngleMinimumMet = wideAngleUploadCount
      ? wideAngleUploadCount >= getWideAngleMinimum(yard)
      : null;

    const slowPanMinimumMet =
      slowPanUploadCount !== undefined ? slowPanUploadCount >= 1 : null;

    return [wideAngleMinimumMet, slowPanMinimumMet];
  }

  function handleShouldUpdateContext() {
    const notNull = wideAngleMinimumMet !== null && slowPanMinimumMet !== null;
    const notUndefined =
      wideAngleMinimumMet !== undefined && slowPanMinimumMet !== undefined;

    if (!notNull || !notUndefined) {
      return;
    }

    const status =
      wideAngleMinimumMet && slowPanMinimumMet ? "COMPLETE" : "INCOMPLETE";

    Promise.all([setWithCTX(yard, "upload", status)]);
  }

  function getFormName() {
    return `${capitalize(props.yard)} Yard Upload`;
  }
};

export { MyYardUpload };
export default MyYardUpload;
