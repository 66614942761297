import { PriceObject } from "./Prices";

export type ProductType = "package" | "addOn";

export type Product = {
  id: string;
  object: string;
  active: boolean;
  created: number;
  description: string;
  images?: string[];
  livemode: boolean;
  metadata: {
    package: string;
    packageDisplayName?: string;
    type: ProductType;
  };
  name: ProductName;
  updated: number;
  skus: {
    data: PriceObject[];
  };
};

export enum ProductName {
  ExpeditedUpsell = "Expedited Upsell",
  HOAExtraRevision = "HOA Extra Revision",
  HOAExtraRevisionStarter = "HOA Extra Revision Starter",
  BotanicalPackage = "Botanical Package",
  UberPremium = "Uber Premium Package",
  FullYardPackage = "Full Yard Package",
  FrontYardPackage = "Front Yard Package",
  BackYardPackage = "Back Yard Package",
  CurbAppealPackage = "Curb Appeal Package",
  WholeHomePackage = "Whole Home Package",
  AdditionalRevision = "Additional Revision",
  UpgradePackage = "Upgrade Package",
  GiftCard = "Gift Card",
  StarterFullYardPackage = "Starter - Full Yard Package",
  StarterFrontOrBackyardPackage = "Starter - Front or Backyard Package",
  SmallSpacePackage = "Small Space Package",
}
