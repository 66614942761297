import { Box, makeStyles } from "@material-ui/core";
import { formatNumToUSD, YZTypography } from "@yardzen-inc/react-common";
import React, { FC } from "react";

export interface WishListPrioritizePageEstimateProps {
  lowEstimate: number;
  highEstimate: number;
  lowAllInEstimate: number;
  highAllInEstimate: number;
  lowBaseCost: number;
  highBaseCost: number;
}

const useStyles = makeStyles(theme => ({
  container: {
    border: "2px solid black",
    borderRadius: "10px",
  },
}));

/**
 * Wish List Prioritize Estimate page component.
 * @param props The WishListPrioritizeEstimateProps to pass to the component
 * @returns The component.
 */
const WishListPrioritizePageEstimate: FC<WishListPrioritizePageEstimateProps> = props => {
  const classes = useStyles();

  const {
    lowEstimate,
    highEstimate,
    lowAllInEstimate,
    highAllInEstimate,
    lowBaseCost,
    highBaseCost,
  } = props;

  return (
    <Box p={2} width="100%" className={classes.container}>
      <Box display="flex" alignItems="center">
        <Box textAlign="right" mr={3} flexBasis="100%">
          <YZTypography variant="h6">All-In Estimate</YZTypography>
        </Box>
        <Box flexBasis="100%">
          <YZTypography variant="h6">
            {formatNumToUSD(lowAllInEstimate)} -{" "}
            {formatNumToUSD(highAllInEstimate)}
          </YZTypography>
        </Box>
      </Box>
      <Box mt={2} display="flex" alignItems="center">
        <Box textAlign="right" mr={3} flexBasis="100%">
          <YZTypography>Wishlist Estimate</YZTypography>
        </Box>
        <Box flexBasis="100%">
          <YZTypography>
            {formatNumToUSD(lowEstimate)} - {formatNumToUSD(highEstimate)}
          </YZTypography>
        </Box>
      </Box>
      <Box mt={1} display="flex" alignItems="center">
        <Box textAlign="right" mr={3} flexBasis="100%">
          <YZTypography>Base Costs</YZTypography>
        </Box>
        <Box flexBasis="100%">
          <YZTypography>
            {formatNumToUSD(lowBaseCost)} - {formatNumToUSD(highBaseCost)}
          </YZTypography>
        </Box>
      </Box>
    </Box>
  );
};

export { WishListPrioritizePageEstimate };
