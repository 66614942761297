import React from "react";

export const PackagesGuard = () => {
  React.useEffect(() => {
    if (window.location.pathname.startsWith("/packages")) {
      window.location.assign(
        `${process.env.REACT_APP_WORDPRESS_BASE_URL}/packages` || "/"
      );
    }
  }, []);

  return null;
};
