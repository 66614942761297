import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { YardsStatusObj, OnboardStep } from "../../components/onboard/stepData";

export interface NewOnboardStateData {
  userId: string;
  projectId: string;
  priorities: {};
  inactiveYardsStatusObj: YardsStatusObj;
  yardsStatusObj: YardsStatusObj;
  selectedYard: "front" | null;
  informationVerified: false;
  currentStep: OnboardStep;
}

// create an onboardState document
// this function MUST be wrapped in a try/catch when implemented

async function createOnboardStateDoc(data: NewOnboardStateData) {
  try {
    /*
      Running into a known firebase issue with .add:
      Occasionally with get "FirebaseError: Document already exists" even though
      the document is successfully added. Workaround for the time being is to
      use .doc().set():

      Github issue: https://github.com/firebase/firebase-js-sdk/issues/5549

      If we change back to .add at some point, createdAt should be change to
      firebase.firestore.FieldValue.serverTimestamp() which instructs firebase
      to use the current server time as a value during a write operation.

    */
    const createdAt = firebase.firestore.Timestamp.now();
    await firebase
      .firestore()
      .collection("onboardStates")
      .doc()
      .set({
        ...data,
        createdAt,
      });

    const querySnapshot = await firebase
      .firestore()
      .collection("onboardStates")
      .where("userId", "==", data.userId)
      .where("createdAt", "==", createdAt)
      .get();

    return querySnapshot.docs[0];
  } catch (err) {
    window.newrelic.noticeError(err);
    throw new Error(
      `Error creating onboardStates document for user with id of ${data.userId} in Onboard Context`
    );
  }
}

export { createOnboardStateDoc };
export default createOnboardStateDoc;
