import { DesignProfileQuestionResponse } from "../../../../api/apiTypes";

/**
 * Checks if user has made a selection
 * @param {DesignProfileQuestionResponse} responses The user's responses for the question.
 * @return {boolean} True if selection made, false otherwise
 */
export const isSelectionMade = (
  responses: DesignProfileQuestionResponse
): boolean => {
  if (
    Array.isArray(responses) ||
    typeof responses === "string" ||
    responses instanceof String
  ) {
    return responses.length !== 0;
  }

  let responseMade = false;
  for (const key in responses) {
    if (Array.isArray(responses[key])) {
      responseMade = (responses[key] as any[]).length !== 0;
    } else {
      responseMade = !!responses[key];
    }

    if (responseMade) {
      break;
    }
  }

  return responseMade;
};
