import React, { FC, useEffect, useMemo } from "react";
import {
  BudgetCategoryChecklistItemProps,
  BudgetCategoryChecklistItem,
} from "@yardzen-inc/react-common";
import { useSigleBudgetChecklistPriceOptionByIdLazyQuery } from "@yardzen-inc/graphql";
import { useLogError } from "../../../util";

export interface BudgetChecklistCategoeryListItemWrapperProps
  extends BudgetCategoryChecklistItemProps {
  priceOptionId?: string;
  mod: number;
}

const BudgetChecklistCategoeryListItemWrapper: FC<BudgetChecklistCategoeryListItemWrapperProps> = props => {
  const [
    fetchPriceOption,
    { data, error },
  ] = useSigleBudgetChecklistPriceOptionByIdLazyQuery({
    variables: { id: props.priceOptionId },
  });

  const extractedPriceOption = useMemo(() => {
    return data?.budget_checklist_price_option?.[0] ?? null;
  }, [data]);

  useLogError(error as Error);

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchPriceOptionEffect, [props.priceOptionId]);

  return (
    <BudgetCategoryChecklistItem
      {...props}
      priceRange={
        extractedPriceOption
          ? {
              lowPrice: extractedPriceOption.low_price * props.mod,
              highPrice: extractedPriceOption.high_price * props.mod,
            }
          : void 0
      }
    />
  );

  function fetchPriceOptionEffect() {
    if (props.priceOptionId) {
      fetchPriceOption();
    }
  }
};

BudgetChecklistCategoeryListItemWrapper.defaultProps = {
  mod: 1,
};

export { BudgetChecklistCategoeryListItemWrapper };
export default BudgetChecklistCategoeryListItemWrapper;
