import { PriceObject } from "../types/Prices";
import { Product } from "../types/Products";

type FormatProductsArgs = {
  products: Product[];
  priceObjects: PriceObject[];
};

/** The purpuse of this function is to format the products we get from stripe to include both assiciated skus and price objects */
/** We are using skus and price objects and since skus are deprecated we need a temporary way to format price objects as skus */

export const formatProducts = ({
  products,
  priceObjects,
}: FormatProductsArgs) => {
  const newProducts = products.slice();

  /** Itterate through products and if the product is new(within the past year) then we need to add a skus key with an object as its value */
  newProducts.map((product: Product) => {
    if (!product.skus) {
      product.skus = { data: [] };
    }
    /** We find any matching price objects */
    const matchingPriceObjects = priceObjects.filter(
      price => price.product === product.id
    );

    /** If there are matching price objects we format them to match the sku format */
    if (matchingPriceObjects.length) {
      const formattedPriceObjects = matchingPriceObjects.map(priceObj => {
        priceObj.attributes = { name: priceObj.nickname || "" };
        priceObj.metadata = {
          product: product.metadata.package,
          type: product.metadata.type,
        };
        priceObj.price = priceObj.unit_amount;

        return priceObj;
      });

      product.skus.data = formattedPriceObjects;
    }

    return product;
  });

  return newProducts;
};
