import * as React from "react";
import {
  makeStyles,
  Theme,
  Typography,
  Box,
  LinearProgress,
} from "@material-ui/core";
import { useHeaderHeight } from "../../util";
import { ASSETS_BASE_URL } from "../../util/constants/assetsBaseUrl";

const YardzenLogoBlack = `${ASSETS_BASE_URL}/public/BW-Logo-No-Icon.png`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      display: "fixed",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    minHeight: "53px",
    maxWidth: "100vw",
    borderBottom: "1px solid #eaeaea",
  },
  childrenContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      marginTop: 0,
    },
  },
  address: {
    fontSize: "0.9rem",
    marginLeft: "1.25rem",
  },
  img: {
    height: "24px",
    marginLeft: "1rem",
  },
}));

export interface HeaderBaseProps {
  children: React.ReactNode | null;
  address?: string;
  progress?: number;
}

const HeaderBase = React.forwardRef<HTMLDivElement, HeaderBaseProps>(
  ({ children, address, progress }, ref) => {
    const height = useHeaderHeight();
    const classes = useStyles({ height: `${height.toString()}px` });

    return (
      <div className={classes.root} ref={ref} id="header-base">
        <div className={classes.headerContainer}>
          <div
            className={classes.logoContainer}
            onClick={() =>
              window.location.replace(
                `${process.env.REACT_APP_WORDPRESS_BASE_URL}`
              )
            }
          >
            <img
              src={YardzenLogoBlack}
              alt="Yardzen logo"
              className={classes.img}
            />
            <Typography className={classes.address}>{address}</Typography>
          </div>
          <div className={classes.childrenContainer}>{children}</div>
        </div>
        {progress !== undefined && (
          <Box width="100%">
            <LinearProgress
              variant="determinate"
              color="primary"
              value={progress}
            />
          </Box>
        )}
      </div>
    );
  }
);

export { HeaderBase };
export default HeaderBase;
