import * as React from "react";
import {
  Box,
  Popper,
  Paper,
  makeStyles,
  Typography,
  Grow,
  Divider,
  useMediaQuery,
  Theme,
} from "@material-ui/core";
import { usePageDimensions } from "../../util";

export interface GeocodeResultsPopupProps {
  open: boolean;
  anchor: React.RefObject<HTMLDivElement>;
  results: google.maps.GeocoderResult[] | null;
  searching: boolean;
  onSelect: (result: google.maps.GeocoderResult) => void;
}

const useStyles = makeStyles(() => ({
  paper: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    borderRadius: 0,
    border: "solid 2px #EDEDEA",
  },
}));

const GeocodeResultsPopup: React.FC<GeocodeResultsPopupProps> = (
  props: GeocodeResultsPopupProps
) => {
  const timeoutRef = React.useRef<any>(null);

  const classes = useStyles();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [selected, setSelected] = React.useState<number | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const resref = React.useRef<google.maps.GeocoderResult[] | null>(null);
  const selref = React.useRef<number | null>(null);

  usePageDimensions();

  // TODO: remove disable comment and fix warning next time these hooks are updated
  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(onResultChange, [props.results]);
  React.useEffect(onOpenClose, [props.open]);
  React.useEffect(addKeyboardEventListeners, [open, props.results]);
  /* eslint-enable react-hooks/exhaustive-deps */

  React.useMemo(() => {
    selref.current = selected;
  }, [selected]);

  React.useMemo(() => {
    resref.current = props.results;
  }, [props.results]);

  const inputWidth = React.useMemo(() => {
    if (!props.anchor.current) {
      return "0px";
    }
    return props.anchor.current.getBoundingClientRect().width.toString() + "px";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.anchor, props.results]);

  if (!props.anchor.current) {
    return null;
  }

  const renderedResults = renderResults(
    props.results,
    selected,
    props.onSelect,
    setSelected
  );

  return (
    <Popper
      open={open}
      anchorEl={open ? props.anchor.current : undefined}
      style={{
        zIndex: 1301,
        width: inputWidth,
        maxWidth: inputWidth,
      }}
    >
      <Grow in={props.open}>
        <Box
          zIndex={4}
          width={inputWidth}
          maxWidth={inputWidth}
          display="flex"
          flexDirection="row"
        >
          <Paper style={{ maxWidth: inputWidth }} className={classes.paper}>
            {renderedResults}
          </Paper>
        </Box>
      </Grow>
    </Popper>
  );

  function onResultChange() {
    if (props.results?.length && !selected) {
      setSelected(0);
    }
  }

  function onOpenClose(): void | (() => void) {
    if (props.open) {
      if (smDown) {
        timeoutRef.current = setTimeout(() => {
          setOpen(true);
        }, 500);
      } else {
        setOpen(true);
      }
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      setOpen(false);
    }

    if (!props.open) {
      if (selected) {
        setSelected(null);
      }
    } else {
    }
  }

  function addKeyboardEventListeners(): (() => void) | void {
    if (!props.open || !props.results) {
      return;
    }

    window.addEventListener("keydown", handleKeyPress);

    return () => window.removeEventListener("keydown", handleKeyPress);

    function handleKeyPress(e: KeyboardEvent) {
      if (!resref.current) {
        return;
      }

      try {
        if (e.key === "ArrowDown") {
          let l = resref.current.length;
          const newSel = (selref.current ?? 0) + 1;

          if (newSel < l) {
            setSelected(newSel);
            selref.current = newSel;
          } else {
            selref.current = l - 1;
            setSelected(selref.current);
          }
        } else if (e.key === "ArrowUp") {
          const newSel = (selref.current ?? 0) - 1;

          if (newSel >= 0) {
            setSelected(newSel);
            selref.current = newSel;
          }
        } else if (e.key === "Enter") {
          const selected = resref.current[selref.current as number];

          props.onSelect(selected);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }
};

function renderResults(
  results: google.maps.GeocoderResult[] | null,
  selected: number | null,
  onSelect: (r: google.maps.GeocoderResult) => void,
  setSelected: (s: number) => void
): React.ReactNode[] | null {
  if (!results) {
    return null;
  }

  return results.map((r, i) =>
    makeResultComponent(r, i, selected, onSelect, setSelected)
  );
}

function makeResultComponent(
  r: google.maps.GeocoderResult,
  i: number,
  selected: number | null,
  onSelect: (r: google.maps.GeocoderResult) => void,
  setSelected: (s: number) => void
): React.ReactNode {
  return (
    <Box
      display="flex"
      flexDirection="row"
      maxWidth="inherit"
      p={1}
      justifyContent="flex-start"
      key={(r as google.maps.GeocoderResult).place_id}
      style={{
        pointerEvents: "all",
        cursor: "pointer",
        transition: "background-color 150ms",
        maxWidth: "inherit",
        backgroundColor: i === selected ? "#E3E3E3" : undefined,
      }}
      onMouseOver={() => setSelected(i)}
      onMouseDown={e => {
        onSelect(r);
      }}
    >
      <Typography
        variant="h6"
        noWrap
        style={{
          textOverflow: "elipsis",
          pointerEvents: "all",
          maxWidth: "inherit",
        }}
      >
        {(r as google.maps.GeocoderResult).formatted_address}
      </Typography>
      <Divider />
    </Box>
  );
}

export default GeocodeResultsPopup;
