import { DesignProfileQuestion } from "../../../api/apiTypes";
import { YardSelectionTypes } from "../../../util/constants/yardSelectionTypes";
import { YARD_SELECTION_PATH } from "../constants/paths";

/**
 * Get the yard selection response from the design profile quiz
 *
 * @param {SurveyObject} responses Design profile responses
 * @returns {string} The user response(s)
 */
export const getYardSelectionResponse = (
  responses: DesignProfileQuestion[]
): YardSelectionTypes => {
  return (
    (responses
      .find(group => group.path === YARD_SELECTION_PATH)
      ?.response?.toString()
      .toUpperCase() as YardSelectionTypes) ?? ""
  );
};
