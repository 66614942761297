import React, { FC } from "react";
import { useRouteMatch, Route, Redirect } from "react-router";
import { Switch } from "react-router-dom";
import ChecklistList from "./ChecklistList";
import ChecklistItemDetailWrapper from "./ChecklistItemDetailWrapper";
import ChecklistBudgetPhasingPageWrapper from "./ChecklistBudgetPhasingPageWrapper";
import useAllowAccessToBudgetStep from "./useAllowAccessToBudgetStep";

export interface ChecklistPageRouterProps {}

const ChecklistPageRouter: FC<ChecklistPageRouterProps> = props => {
  const match = useRouteMatch();
  const allowAccessToBudgetStep = useAllowAccessToBudgetStep();

  return (
    <>
      {allowAccessToBudgetStep !== null && !allowAccessToBudgetStep && (
        <Redirect to="/onboard/my-yard" />
      )}
      <Switch>
        <Route path={`${match.url}/list`} component={ChecklistList} />
        <Route
          path={`${match.path}/item/:itemId`}
          component={ChecklistItemDetailWrapper}
        />
        <Route
          path={`${match.path}/phasing`}
          component={ChecklistBudgetPhasingPageWrapper}
        />
        <Route
          render={() => {
            return <Redirect to={`${match.url}/list`} />;
          }}
        />
      </Switch>
    </>
  );
};

export { ChecklistPageRouter };
export default ChecklistPageRouter;
