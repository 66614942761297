import {
  StripeElements,
  Stripe,
  PaymentMethodCreateParams,
} from "@stripe/stripe-js";
import { IAddressObject } from "../util/functions/parseGeocodeLocationToAddress";
import { omitBy } from "lodash";

interface ConfirmPaymentArgs {
  stripe: Stripe;
  skuId: string;
  productId: string;
  isPremium: boolean;
  modifiedPrice: number | null;
  elements: StripeElements;
  premiumSkuId: string;
  billingAddress: IAddressObject;
  name: string;
  email: string;
}

const { REACT_APP_BASE_URL } = process.env;

export const confirmPayment = async ({
  stripe,
  skuId,
  productId,
  isPremium,
  modifiedPrice,
  elements,
  premiumSkuId,
  billingAddress,
  name,
  email,
}: ConfirmPaymentArgs) => {
  let address: PaymentMethodCreateParams.BillingDetails.Address = {
    country: billingAddress.country,
    city: billingAddress.city,
    line1: billingAddress.street,
    line2: billingAddress.aptNumber,
    postal_code: billingAddress.zip,
    state: billingAddress.state,
  };
  address = omitBy(address, value => !value);

  const paymentConfirmed = await stripe.confirmPayment({
    elements,
    redirect: "if_required",
    confirmParams: {
      return_url: `${REACT_APP_BASE_URL}/confirm-payment?sku=${skuId}&product=${productId}&isPremium=${isPremium}&premiumSku=${premiumSkuId}&modifiedPrice=${modifiedPrice}`,
      payment_method_data: {
        billing_details: {
          address,
          name,
          email,
        },
      },
    },
  });

  return paymentConfirmed;
};
