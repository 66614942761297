import { ProfileProperties } from "@yardzen-inc/models";
import { ExperienceVersionType } from "@yardzen-inc/react-common";
import { getProjectDoc } from "./getProjectDoc";

const fetchExperienceVersionByProfileProps = async (
  profileProps: ProfileProperties
) => {
  try {
    /**
     * It's assumed that a user's profile contains a `userId` property; For
     * instances where `userId` is missing, we should fall-back to the
     * profile's documentID, helpfully added as `id` by the Yardzen model.
     */
    const project = await getProjectDoc(profileProps.userId ?? profileProps.id);

    return !!project
      ? (project.experienceVersion as ExperienceVersionType)
      : undefined;
  } catch (err) {
    window.newrelic.noticeError(err);
    return undefined;
  }
};

export { fetchExperienceVersionByProfileProps };
export default fetchExperienceVersionByProfileProps;
