import React, { CSSProperties } from "react";
import { DropzoneInputProps } from "react-dropzone";
import { Paper, Typography, Theme, useMediaQuery } from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

export interface LargeUploadBoxProps {
  limitReached?: boolean;
  isUploading?: boolean;
  getInputProps?: (
    props?: DropzoneInputProps | undefined
  ) => DropzoneInputProps;
  style?: CSSProperties;
  acceptedFileTypes?: string[];
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    cursor: "pointer",
    width: "100%",
  },
  cameraIcon: {
    color: theme.palette.primary.main,
    width: "30px",
    height: "30px",
  },
  acceptedFiles: {
    display: "block",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
}));

const LargeUploadBox = ({
  limitReached,
  isUploading,
  getInputProps,
  style,
  acceptedFileTypes,
  className,
}: LargeUploadBoxProps) => {
  const classes = useStyles();
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const imageFileTypes = ["jpg", ".png", ".svg", ".heic"];
  const acceptImages =
    acceptedFileTypes?.some(fileType => imageFileTypes.includes(fileType)) ||
    acceptedFileTypes?.includes("image/*");

  const acceptVideo = acceptedFileTypes?.includes("video/*");

  // acceptedFileTypes is an optional prop to support updated upload box display
  // keeping the null return for backwards compatibility
  const displayAcceptedFileTypes = (
    acceptedFileTypes: string[] | undefined
  ): string | null => {
    if (!acceptedFileTypes) {
      return null;
    }
    if (acceptVideo && acceptImages) {
      return "all photo and video files";
    } else if (acceptVideo) {
      return "all video files";
    } else {
      return acceptedFileTypes?.join(" ").toString();
    }
  };

  return (
    <Paper
      style={{
        backgroundColor: limitReached ? "grey" : "inherit",
        borderRadius: 0,
        ...style,
      }}
      className={className ? className : classes.root}
      elevation={2}
    >
      {!isUploading && !!getInputProps && (
        <div>
          <input {...getInputProps()} />
          {limitReached ? (
            <Typography style={{ color: "#FFF", textAlign: "center" }}>
              File Limit Reached. <br /> Please delete some photos to upload
              more
            </Typography>
          ) : (
            <div style={{ textAlign: "center" }}>
              <PhotoCamera className={classes.cameraIcon} />
              {!mobile ? (
                <Typography>
                  Drag &amp; Drop <br /> or <br />
                  Upload
                </Typography>
              ) : (
                <Typography>
                  Take a{" "}
                  {acceptVideo && acceptImages
                    ? `Photo / Video`
                    : acceptVideo
                    ? `Video`
                    : `Photo`}
                  <br /> or <br />
                  Upload
                </Typography>
              )}

              {acceptedFileTypes && (
                <Typography variant="caption" className={classes.acceptedFiles}>
                  Supported files: {displayAcceptedFileTypes(acceptedFileTypes)}
                </Typography>
              )}
            </div>
          )}
        </div>
      )}
    </Paper>
  );
};

export { LargeUploadBox };
export default LargeUploadBox;
