import { Grid, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks";
import {
  setApt,
  setCity,
  setState,
  setStreet,
  setZip,
} from "../../slices/checkoutSlice";
import {
  SegmentForms,
  SegmentFlows,
  SegmentInputTypes,
  useSegment,
} from "../../util/Segment";
import StateSelect from "./StateSelect";

import { segmentBlurHandler } from "./util/segmentBlurHandler";

export interface AddressInputProps {
  hasTriedSubmitting: boolean;
}

export const AddressInput = ({ hasTriedSubmitting }: AddressInputProps) => {
  const dispatch = useDispatch();
  const segment = useSegment();

  const {
    address: { street, city, state, zip, aptNumber },
    billingAndPropertyAddressSame,
  } = useAppSelector(state => state.checkout);

  if (billingAndPropertyAddressSame) {
    return null;
  }
  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: "0.5rem" }}>
        <Grid item xs>
          <TextField
            error={hasTriedSubmitting && !street.trim().length}
            label="Street"
            value={street}
            onChange={e => dispatch(setStreet({ street: e.target.value }))}
            fullWidth
            required
            onBlur={segmentBlurHandler({
              formFieldProps: {
                form_name: SegmentForms.CHECKOUT_ADDRESS,
                field_name: "Street",
                flow_name: SegmentFlows.CHECKOUT,
                input_type: SegmentInputTypes.TEXT,
              },
              value: street,
              segment,
            })}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs>
          <TextField
            label="apt #"
            value={aptNumber}
            onChange={e => dispatch(setApt({ aptNumber: e.target.value }))}
            onBlur={segmentBlurHandler({
              formFieldProps: {
                form_name: SegmentForms.CHECKOUT_ADDRESS,
                field_name: "apt #",
                flow_name: SegmentFlows.CHECKOUT,
                input_type: SegmentInputTypes.TEXT,
              },
              value: aptNumber,
              segment,
            })}
            fullWidth
            variant="outlined"
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: "0.5rem" }}>
        <Grid item xs>
          <TextField
            onBlur={segmentBlurHandler({
              formFieldProps: {
                form_name: SegmentForms.CHECKOUT_ADDRESS,
                field_name: "City",
                flow_name: SegmentFlows.CHECKOUT,
                input_type: SegmentInputTypes.TEXT,
              },
              value: city,
              segment,
            })}
            error={hasTriedSubmitting && !city.trim().length}
            label="City"
            value={city}
            onChange={e => dispatch(setCity({ city: e.target.value }))}
            fullWidth
            required
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs>
          <StateSelect
            onChange={e => dispatch(setState({ state: e.target.value }))}
            value={state}
            required
            onBlur={segmentBlurHandler({
              formFieldProps: {
                form_name: SegmentForms.CHECKOUT_ADDRESS,
                field_name: "State",
                flow_name: SegmentFlows.CHECKOUT,
                input_type: SegmentInputTypes.TEXT,
              },
              value: state,
              segment,
            })}
            error={hasTriedSubmitting && !state.length}
          />
        </Grid>
      </Grid>
      <TextField
        error={hasTriedSubmitting && !zip.trim().length}
        inputProps={{ maxLength: "5" }}
        onBlur={segmentBlurHandler({
          formFieldProps: {
            form_name: SegmentForms.CHECKOUT_ADDRESS,
            field_name: "Zip Code",
            flow_name: SegmentFlows.CHECKOUT,
            input_type: SegmentInputTypes.TEXT,
          },
          value: zip,
          segment,
        })}
        label="Zip Code"
        value={zip}
        style={{ marginBottom: "1rem" }}
        onChange={e =>
          !!Number(e.target.value) || e.target.value === ""
            ? dispatch(setZip({ zip: e.target.value }))
            : null
        }
        fullWidth
        required
        variant="outlined"
        size="small"
      />
    </div>
  );
};
