import _ from "lodash";

/**
 *
 * @param percentile Whole number. eg "25" for 25th percentile
 * @param numberRange [lowRange, highRange]
 * @returns Given percentile of range
 */
function calculatePercentileOfRange(
  percentile: string,
  numberRange: [number, number]
) {
  const [low, high] = numberRange;
  const _percentile = Number(percentile);

  if (
    isNaN(low) ||
    isNaN(high) ||
    Math.sign(high) !== 1 ||
    Math.sign(low) !== 1
  ) {
    throw new Error("Invalid number range");
  }

  if (isNaN(_percentile) || _percentile > 100 || _percentile < 1) {
    throw new Error("Invalid percentile");
  }

  let result: number;
  const range = _.range(low, high);
  const q = _percentile / 100;
  const pos = (range.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (range[base + 1] !== undefined) {
    result = range[base] + rest * (range[base + 1] - range[base]);
  } else {
    result = range[base];
  }
  // This trims off the cents
  return Math.floor(result / 100) * 100;
}

export { calculatePercentileOfRange };
