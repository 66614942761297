import { SegmentFlows } from "./constants";

/**
 * Gets the name of the segment flow to use depending on the path.
 * @param path The path name to check against.
 * @returns The segment flow name to use for the path, or default if not handled.
 */
export const getSegmentFlowName = (path: string): SegmentFlows | "default" => {
  if (path.includes("design-profile")) {
    return SegmentFlows.DESIGN_PROFILE;
  }

  if (path.includes("packages")) {
    return SegmentFlows.SHOP_PACKAGES;
  }

  return "default";
};
