import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  makeStyles,
} from "@material-ui/core";
import React, { FC } from "react";
import { handleQuizPageSelect } from "../../../pages/designProfile/util/quizPage/handleQuizPageSelect";
import { Page, Selections } from "../../../types/DesignProfile";
import { useDesignProfileCtx } from "../DesignProfileCtx";

export type QuizDropdownProps = {
  page: Page;
  options: Selections;
  response: string;
  setResponse: (response: string) => void;
};

const useStyles = makeStyles(() => ({
  formControlContainer: {
    width: "275px",
    backgroundColor: "white",
    "& > div": {
      backgroundColor: "transparent",
    },
    "& div:focus": {
      backgroundColor: "white",
    },
    "& > label": {
      paddingLeft: "15px",
    },
  },
}));

/**
 * Renders a dropdown for the Design Profile Quiz
 *
 * @param props The QuizDropdownProps to pass to the component
 * @returns The QuizDropdown component.
 */
const QuizDropdown: FC<QuizDropdownProps> = ({
  page,
  options,
  response,
  setResponse,
}) => {
  const { formControlContainer } = useStyles();
  const dsContext = useDesignProfileCtx();

  return (
    <FormControl className={formControlContainer}>
      <InputLabel>Select {page.path.replace(/_/i, " ")}...</InputLabel>
      <Select
        id={`${page.path.replace(/_/i, "-")}-dropdown`}
        variant="filled"
        value={response}
        onChange={e => {
          handleQuizPageSelect({
            existingResponse: response,
            selectionValue: e.target.value as string,
            dsContext,
            page,
            setUserResponses: setResponse,
          });
        }}
        MenuProps={{
          disableScrollLock: true,
        }}
      >
        {(options?.default ?? []).map((option, i) => (
          <MenuItem key={i} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export { QuizDropdown };
