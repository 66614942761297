import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import LogRocket from "logrocket";

const deleteMedia = async (mediaId: string) => {
  // XXX TODO error checking
  try {
    let docRef = firebase
      .firestore()
      .collection("media")
      .doc(mediaId);
    let mediaDocumentData = await (await docRef.get()).data();

    if (!mediaDocumentData) {
      return;
    }

    await docRef.delete();

    await firebase
      .storage()
      .ref(mediaDocumentData.path)
      .delete();
  } catch (error) {
    window.newrelic.noticeError(error);
    const msg = `Error deleting media: ${JSON.stringify(error)}`;
    console.error(msg);
    LogRocket.captureMessage(msg);
    throw error;
  }
};

export default deleteMedia;
