import React, { FC } from "react";
import { Button, ButtonProps, Theme } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

type DesignProfileBlackButtonProps = {
  text: string | null;
  onClick: (() => void) | undefined;
  fullWidth?: boolean;
} & ButtonProps;

const BlackButton = withStyles((theme: Theme) => ({
  root: {
    color: "white",
    padding: "12px 56px",
    fontWeight: "bold",
    backgroundColor: "#323334",
    "&:hover": {
      backgroundColor: "#323334",
    },
  },
}))(Button);

/**
 * Design Profile Black Button component.
 * @param props The DesignProfileBlackButtonProps to pass to the component
 * @returns The button component.
 */
const DesignProfileBlackButton: FC<DesignProfileBlackButtonProps> = (
  props: DesignProfileBlackButtonProps
) => {
  const { text, onClick, fullWidth } = props;

  return (
    <BlackButton {...props} fullWidth={fullWidth} onClick={onClick}>
      {text}
    </BlackButton>
  );
};

export { DesignProfileBlackButton };
