import {
  SegmentClickTypes,
  SegmentFlows,
  segment,
} from "../../../util/Segment";

/**
 * Runs when the clicks the button to tell us their address is not correct
 * @param onPrevious A function that directs the user to the previous step in the design profile
 * @param buttonContent A string that reflects the text of the button clicked
 * @returns void
 */
export function handleRejectAddress(
  onPrevious: () => void,
  buttonContent: string | null
): void {
  segment.trackClicked({
    click_type: SegmentClickTypes.BUTTON,
    flow_name: SegmentFlows.DESIGN_PROFILE,
    button_name: "Design Profile Address Confirmation Yes",
    button_content: buttonContent ?? "",
  });

  onPrevious();
}
