import React, {
  FC,
  CSSProperties,
  ReactNode,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { Box } from "@material-ui/core";
import { Media, MediaProperties } from "@yardzen-inc/models";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { UserCtx } from "../../util";
import { CommentMediaTile } from "../mediaDisplay";
import {
  FileUpload,
  LargeUploadBox,
  getDocRecordsWithDownloadURLAndID,
} from "../upload";
import useRatio from "../../util/hooks/useRatio";
import { YardName } from "./stepData";
import BoxLoadingSpinner from "../loadingComponents/BoxLoadingSpinner";
import usePrevious from "../../util/hooks/usePrevious";

export interface YardCommentsGridProps {
  style?: CSSProperties;
  projectId: string;
  fileTag: string;
  variant: string;
  setCommentsComplete?: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  allowUpload?: boolean;
  accept?: string[];
  yard?: YardName;
  action: "keep" | "remove";
  deleteable: boolean;
  onUploadComplete?: (files: File[][]) => void;
  onCommentsComplete?: (fileName: string, comment: string) => void;
  onDelete?: (media: MediaProperties) => void;
}

const YardCommentsGrid: FC<YardCommentsGridProps> = props => {
  const [media, setMedia] = useState<Media[] | null>(null);
  const oldMedia = usePrevious(media);
  const user = useContext(UserCtx);
  const width = useMemo(getWidth, []);
  const height = useRatio(16, 9, false, width);
  const [loading, setLoading] = useState(true);

  // TODO: remove disable comment and fix warning next time these hooks are updated
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(subscribeToMedia, [props.fileTag, props.variant, user && user.uid]);
  useEffect(allCommentsComplete, [media]);

  const commentTiles = React.useMemo(getCommentTiles, [
    media,
    oldMedia,
    props.action,
    props.deleteable,
    props.fileTag,
    props.variant,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  if (loading) {
    return <BoxLoadingSpinner boxProps={{ justifyContent: "flex-start" }} />;
  }

  return (
    <Box
      width="100%"
      p={1}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      flexWrap="wrap"
      style={props.style}
    >
      {commentTiles}
      {props.allowUpload && !!media && (
        <Box p={1}>
          <FileUpload
            onUploadComplete={props.onUploadComplete}
            fileTag={props.fileTag}
            variant={props.variant}
            projectId={props.projectId}
            accept={props.accept}
          >
            <LargeUploadBox
              style={{ width: 300, height }}
              acceptedFileTypes={
                props.accept || ["image/*", "video/*", ".pdf", ".dwg", ".heic"]
              }
            />
          </FileUpload>
        </Box>
      )}
    </Box>
  );

  function getCommentTiles() {
    return media?.map(media => {
      return renderCommentTile(
        media,
        props.fileTag,
        props.variant,
        props.action,
        props.deleteable,
        oldMedia
      );
    });
  }

  function renderCommentTile(
    media: Media,
    fileTag: string,
    variant: string,
    action: "keep" | "remove",
    deleteable: boolean,
    oldMedia: Media[] | null
  ): ReactNode {
    return (
      <Box m={1} key={`${media.id}-${fileTag}-${variant}`}>
        <CommentMediaTile
          media={media}
          onDelete={props.onDelete}
          onCommentsComplete={comment => {
            if (props?.onCommentsComplete) {
              props.onCommentsComplete(media.originalFileName, comment);
            }
          }}
          action={action}
          deleteable={deleteable}
          modalOpen={!oldMedia?.some(m => m.id === media.id)}
        />
      </Box>
    );
  }

  function allCommentsComplete() {
    if (!props.setCommentsComplete) return;

    if (
      !media?.length ||
      (!!media && media.length >= 1 && media.some(img => !img.description))
    ) {
      props.setCommentsComplete(false);
    } else if (
      !!media &&
      !!media.length &&
      media.every(img => !!img.description)
    ) {
      props.setCommentsComplete(true);
    } else if (!!media && media.length < 1) {
      props.setCommentsComplete(true);
    }
  }

  function subscribeToMedia() {
    if (!user) {
      return;
    }

    const userId = user.uid;
    return firebase
      .firestore()
      .collection("media")
      .where("tag", "==", props.fileTag)
      .where("variant", "==", props.variant)
      .where("userId", "==", userId)
      .where("projectId", "==", props.projectId)
      .onSnapshot(async snap => {
        const media = await getDocRecordsWithDownloadURLAndID(snap);
        setMedia(media as Media[]);
        setLoading(false);
      });
  }

  // get appropriate width based on screen width media queries
  function getWidth(): number {
    return 300;
  }
};

export { YardCommentsGrid };
export default YardCommentsGrid;
