import React, {
  createContext,
  FC,
  useEffect,
  useCallback,
  useState,
  useContext,
} from "react";
import {
  Budget_Checklist_Item,
  Budget_Checklist_Response,
  useBudgetChecklistItemsMinimalPublishedByChecklistIdQuery,
  useBudgetChecklistResponseItemChoiceIdByProjectIdQuery,
} from "@yardzen-inc/graphql";
import { OnboardCtx, useLogError } from "../../../util";

export interface ItemResponseProviderProps {
  checklistId: string;
  projectId: string;
}

export interface ChecklistItem
  extends Pick<
    Budget_Checklist_Item,
    | "description"
    | "id"
    | "name"
    | "medium"
    | "budget_checklist_category"
    | "no_style_options"
    | "price_prompt"
    | "quantitative_item"
  > {}

export interface ChecklistItemResponse
  extends Pick<
    Budget_Checklist_Response,
    | "budget_checklist_item_id"
    | "budget_checklist_price_option_id"
    | "budget_checklist_style_option_id"
    | "context"
    | "created_at"
    | "id"
    | "project_id"
    | "updated_at"
    | "user_id"
    | "left_yard"
    | "right_yard"
    | "front_yard"
    | "back_yard"
    | "order"
  > {}

export interface ItemResponseContextValue {
  [checklistItemId: string]: {
    item: ChecklistItem;
    response?: ChecklistItemResponse;
  };
}

const ItemResponseCtx = createContext<
  [
    ItemResponseContextValue | null,
    {
      responsesLoading: boolean;
      itemsLoading: boolean;
      removeResponseFromCache: (itemId: string) => void;
      refetchResponseData: () => any;
    }
  ]
>([
  null,
  {
    responsesLoading: false,
    itemsLoading: false,
    removeResponseFromCache: (itemId: string) => null,
    refetchResponseData: async () => void 0,
  },
]);

const ItemResponseProvider: FC<ItemResponseProviderProps> = ({
  children,
  checklistId,
  projectId,
}) => {
  const {
    data: itemData,
    error: itemError,
    loading: itemLoading,
  } = useBudgetChecklistItemsMinimalPublishedByChecklistIdQuery({
    variables: {
      checklistId: checklistId,
      limit: 999,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: responseData,
    error: responseError,
    loading: responseLoading,
    fetchMore: refetchResponseData,
  } = useBudgetChecklistResponseItemChoiceIdByProjectIdQuery({
    variables: { projectId },
    fetchPolicy: "network-only",
  });

  const { setOnboardContextError } = useContext(OnboardCtx);

  useLogError(itemError as Error);
  useLogError(responseError as Error);

  const [
    itemResponseValue,
    setItemResponseValue,
  ] = useState<null | ItemResponseContextValue>(null);

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onDataChange, [responseData, itemData]);
  useEffect(() => {
    if (itemError || responseError) {
      setOnboardContextError(
        `Hasura error: \n\n Item error - ${itemError} \n\n Response error - ${responseError}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemError, responseError]);

  const removeResponseFromCache = useCallback(
    (itemId: string): boolean => {
      const item = itemResponseValue?.[itemId];

      if (!item) return false;

      setItemResponseValue({
        ...itemResponseValue,
        [itemId]: { ...item, response: void 0 },
      });

      return true;
    },
    [itemResponseValue]
  );

  return (
    <ItemResponseCtx.Provider
      value={[
        itemResponseValue,
        {
          itemsLoading: itemLoading,
          responsesLoading: responseLoading,
          removeResponseFromCache,
          refetchResponseData: async () =>
            refetchResponseData({
              variables: { projectId },
            }),
        },
      ]}
    >
      {children}
    </ItemResponseCtx.Provider>
  );

  function onDataChange() {
    if (itemLoading || responseLoading) return;
    setItemResponseValue(mapItemResponseValues());
  }

  function mapItemResponseValues(): ItemResponseContextValue | null {
    const map: ItemResponseContextValue = {};

    if (!itemData || !itemData.budget_checklist_item.length || !responseData)
      return null;

    for (const item of itemData.budget_checklist_item) {
      if (item.budget_checklist_category.name) {
        map[item.id] = { item: item as any };
      }
    }

    if (responseData) {
      for (const response of responseData.budget_checklist_response) {
        map[response.budget_checklist_item_id]["response"] = response;
      }
    }

    return Object.freeze(map);
  }
};

export { ItemResponseProvider, ItemResponseCtx };
export default ItemResponseProvider;
