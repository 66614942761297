import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Spring } from "react-spring/renderprops";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "relative",
    display: "flex",
    width: "min-content",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  fabProgress: {
    position: "relative",
  },
  icon: {
    position: "absolute",
  },
}));

export interface ProgressIconProps {
  iconSrc: string;
  alt: string;
  progress?: number;
  iconWidthPercentage?: number;
  speedMultiplier?: number;
  style?: React.CSSProperties;
  thickness?: number; // default is 3.6
}

const ProgressIcon: React.FC<ProgressIconProps> = ({
  thickness,
  iconWidthPercentage,
  iconSrc,
  style,
  speedMultiplier,
  progress,
  alt,
}: ProgressIconProps) => {
  const classes = useStyles();

  return (
    <Box m={1}>
      <Box style={style}>
        <div className={classes.wrapper}>
          <img
            src={iconSrc}
            style={{ width: `${iconWidthPercentage || 70}%` }}
            alt={alt}
            className={classes.icon}
          />
          <Spring
            from={{ spring: 0 }}
            to={{ spring: 100 }}
            config={{
              friction: 110,
              mass: 15,
              tension: 78 * (speedMultiplier || 1),
            }}
          >
            {props => (
              <CircularProgress
                thickness={thickness}
                style={{ ...style, margin: 0 }}
                color="primary"
                variant={!!progress ? "static" : "determinate"}
                value={progress || props.spring}
                className={classes.fabProgress}
              />
            )}
          </Spring>
        </div>
      </Box>
    </Box>
  );
};

export { ProgressIcon };
export default ProgressIcon;
