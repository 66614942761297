import {
  ApolloClient,
  ApolloError,
  gql,
  useApolloClient,
} from "@yardzen-inc/graphql";
import { useContext, useEffect, useState } from "react";
import {
  ChecklistItem,
  ChecklistItemResponse,
  ItemResponseCtx,
} from "./ItemResponseContext";

export interface UseBudgetChecklistPriceEstimate {
  (projectId: string): [[number, number] | null, ApolloError | null];
}

/*
  returns a price estimate based on client's selected response items in the form of [number, number] | null (loading state)
  This hook consumes the ItemResponseContext, and should be only used in children that can consume said context accordingly.
*/

const pricingDataStorage: {
  [priceOptionId: string]: { low: number; high: number };
} = {};

const useBudgetChecklistPriceEstimate: UseBudgetChecklistPriceEstimate = projectId => {
  const apolloClient = useApolloClient();
  const [irContexeValue] = useContext(ItemResponseCtx);
  const [priceRange, setPriceRange] = useState<[number, number] | null>(null);
  const [error, setError] = useState<ApolloError | null>(null);
  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onIrContextValueChange, [irContexeValue]);

  return [priceRange, error];

  function onIrContextValueChange(): void {
    void (async function() {
      const pricingDataNotFoundInMemory: string[] = [];
      let lowPriceTotal = 0;
      let highPriceTotal = 0;

      const filteredItems: {
        response: ChecklistItemResponse;
        item: ChecklistItem;
      }[] = [];

      for (const itemId in irContexeValue) {
        const { response, item } = irContexeValue[itemId];
        const priceOptionId = response?.budget_checklist_price_option_id;

        if (!priceOptionId || !response) {
          continue;
        }

        filteredItems.push({ item, response });

        const cachedPricingData =
          pricingDataStorage[response?.budget_checklist_price_option_id];

        if (cachedPricingData) {
          continue;
        }

        pricingDataNotFoundInMemory.push(priceOptionId);
      }

      if (pricingDataNotFoundInMemory.length) {
        try {
          const result = await fetchMissingPricingData(
            apolloClient,
            pricingDataNotFoundInMemory
          );

          for (let id in result) {
            pricingDataStorage[id] = result[id];
          }
        } catch (error) {
          window.newrelic.noticeError(error);
          if (error instanceof ApolloError) {
            return setError(error as ApolloError);
          }

          throw error;
        }
      }

      for (let itemResponse of filteredItems) {
        const { low, high } = pricingDataStorage[
          itemResponse.response.budget_checklist_price_option_id
        ];

        if (itemResponse.item.quantitative_item) {
          lowPriceTotal += low;
          highPriceTotal += high;
        } else {
          const {
            front_yard,
            left_yard,
            right_yard,
            back_yard,
          } = itemResponse.response;
          let multiplier = 0;

          void [front_yard, left_yard, right_yard, back_yard].forEach(
            booleanValue => {
              if (booleanValue) multiplier += 1;
            }
          );

          const newLow = low * (multiplier || 1);
          const newHigh = high * (multiplier || 1);

          lowPriceTotal += newLow;
          highPriceTotal += newHigh;
        }
      }

      setPriceRange([lowPriceTotal, highPriceTotal]);
    })();
  }
};

// use a dynamic query to fetch all missing price items and returns pricing values
async function fetchMissingPricingData(
  client: ApolloClient<any>,
  ids: string[]
): Promise<{
  [key: string]: {
    low: number;
    high: number;
  };
}> {
  const { data, error } = await client.query({
    query: generateMissingPricingDataQuery(ids),
  });

  if (!data && error) {
    throw error;
  }

  const returnedValues = Object.values(
    data as {
      [key: string]: {
        low_price: string;
        high_price: string;
        id: string;
      };
    }
  );

  const missingPriceItemMap: {
    [key: string]: {
      low: number;
      high: number;
    };
  } = {};

  for (let value of returnedValues) {
    missingPriceItemMap[value.id] = {
      low: parseInt(value.low_price),
      high: parseInt(value.high_price),
    };
  }

  return missingPriceItemMap;
}

// generate dynamic query based off of priceOption id's not already cached
function generateMissingPricingDataQuery(ids: string[]) {
  const idsFragment = ids.map(
    (id, i) =>
      `
        r_${i}: budget_checklist_price_option_by_pk(id: "${id}") {
          low_price
          id
          high_price
        }
      `
  );

  return gql`
    query GetMissingPricingData {
      ${idsFragment.join("\n")}
    }
  `;
}

export { useBudgetChecklistPriceEstimate };
export default useBudgetChecklistPriceEstimate;
