import React, { FC, useState } from "react";
import { OnboardHeader } from "./OnboardHeader";
import { Box } from "@material-ui/core";
import { OnboardSidebar } from ".";
import OnboardContent from "./OnboardContent";
import "firebase/auth";
import { HelpEmailModal } from "../onboard";

export interface OnboardLayoutProps {
  onboardProgress: number;
}

const OnboardLayout: FC<OnboardLayoutProps> = props => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [helpModalOpen, setHelpModalOpen] = useState(false);

  return (
    <>
      <Box display="flex" flexDirection="column">
        <OnboardHeader
          progress={props.onboardProgress}
          onHelp={() => {}}
          toggleMenu={() => setMenuOpen(!menuOpen)}
          menuOpen={menuOpen}
          setHelpModalOpen={setHelpModalOpen}
        ></OnboardHeader>
        <Box display="flex" flexDirection="row">
          <OnboardSidebar
            sidebarOpen={menuOpen}
            onClose={() => setMenuOpen(false)}
            setHelpModalOpen={setHelpModalOpen}
          ></OnboardSidebar>
          <OnboardContent>{props.children}</OnboardContent>
        </Box>
      </Box>
      <HelpEmailModal
        open={helpModalOpen}
        onClose={() => setHelpModalOpen(false)}
      />
    </>
  );
};

export { OnboardLayout };
export default OnboardLayout;
