/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Profile } from "@yardzen-inc/models";
import { UserCtx } from "./UserContext";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

interface Props {
  children: React.ReactNode[] | React.ReactNode;
}

export const ProfileCtx = React.createContext<Profile | null | false>(null);

export default ProfileCtx;

export const ProfileProvider = React.memo((props: Props) => {
  let user: firebase.User | null | false = React.useContext(UserCtx);
  const [profile, setProfile] = React.useState<Profile | null | false>();

  React.useEffect(() => {
    do {
      if (!user || (user.isAnonymous && !user.email)) {
        setProfile(null);
        break;
      }
      if (!!profile) break;

      user = user as firebase.User;

      firebase
        .firestore()
        .collection("profiles")
        .doc(user.uid)
        .get()
        .then((doc: any) => {
          if (!doc.exists) {
            return setProfile(false);
          }
          const data: Profile = doc.data();

          data["id"] = doc.id;
          return setProfile(data);
        });
    } while (false);
  }, [user]);

  return (
    <ProfileCtx.Provider value={profile as Profile | null}>
      {props.children}
    </ProfileCtx.Provider>
  );
});
