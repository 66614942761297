import {
  Box,
  Drawer,
  IconButton,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import {
  actionWithTracking,
  TrackingEventCategories,
} from "@yardzen-inc/data-layer";
import React, { useState } from "react";
import { useLocation } from "react-router";
import { useDataLayer } from "../../data";
import {
  getSegmentFlowName,
  SegmentClickTypes,
  useSegment,
} from "../../util/Segment";
import { NavLinkList } from "./NavLinkList";

export const MobileNavDrawer = () => {
  const dataLayer = useDataLayer();
  const segment = useSegment();

  const location = useLocation();

  const [open, setOpen] = useState<boolean>(false);

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <>
      <Box p={1} display="fixed" right="0" top="0">
        <IconButton
          onClick={actionWithTracking(
            dataLayer,
            "dashboard_navbar_click_hamburger" + open ? "_x" : "",
            {
              action: "Nav Bar Click - Hamburger" + open ? " X" : "",
              category: TrackingEventCategories.BUTTON_CLICK,
              label: window.location.href,
              value: null,
            },
            () => {
              setOpen(true);

              segment.trackClicked({
                button_name: "Nav Bar Hamburger",
                flow_name: getSegmentFlowName(location.pathname),
                click_type: SegmentClickTypes.NAV,
              });
            }
          )}
          size="small"
        >
          <Menu style={{ width: "30px", height: "30px" }}></Menu>
        </IconButton>
      </Box>
      <Drawer
        open={smDown && open}
        variant="temporary"
        anchor="right"
        onClose={() => setOpen(false)}
        style={{ zIndex: 1000000 }}
      >
        <Box>
          <NavLinkList />
        </Box>
      </Drawer>
    </>
  );
};
