import firebase from "firebase/compat";

interface SubscribeToOrderArgs {
  paymentIntentId: string;
  setOrderId: (orderId: string) => void;
  yzOrderEventErrorTimer: () => void;
}

export const checkForOrderEvent = ({
  paymentIntentId,
  setOrderId,
  yzOrderEventErrorTimer,
}: SubscribeToOrderArgs) => {
  yzOrderEventErrorTimer();
  return firebase
    .firestore()
    .collection("yzOrderEvents")
    .where("paymentIntentId", "==", paymentIntentId)
    .onSnapshot(snap => {
      if (!snap.empty && !!snap.docs.length) {
        const orderId = snap.docs[0].id;
        setOrderId(orderId);
      }
    });
};
