import { Box, Typography } from "@material-ui/core";
import React, { CSSProperties, FC, forwardRef } from "react";
import { ChecklistItem, ChecklistItemResponse } from "./ItemResponseContext";
import {
  reactBeautifulDnd,
  DroppableContainerWrapper,
  DroppableContainerWrapperProps,
} from "@yardzen-inc/react-common";
import BudgetCartItem from "./BudgetCartItem";

export interface BudgetPrioritiesDndListProps {
  itemResponses: { item: ChecklistItem; response: ChecklistItemResponse }[];
  setItemResponses: (
    ir: {
      item: ChecklistItem;
      response: ChecklistItemResponse;
    }[]
  ) => void;
}

const ListContainer = forwardRef<HTMLElement, { style?: CSSProperties }>(
  (props, ref) => {
    return (
      <div
        ref={ref as any}
        style={{
          ...(props.style as any),
          display: "flex",
          flexDirection: "column",
        }}
      >
        {props.children}
      </div>
    );
  }
);

const BudgetPrioritiesDndList: FC<BudgetPrioritiesDndListProps> = ({
  itemResponses,
  setItemResponses,
}) => {
  return (
    <Box width="100%" display="flex" flexDirection="column">
      <reactBeautifulDnd.DragDropContext onDragEnd={onDragEnd}>
        <DroppableContainerWrapper
          Container={<ListContainer />}
          droppableId="priorities-dnd"
        >
          {renderItems()}
        </DroppableContainerWrapper>
      </reactBeautifulDnd.DragDropContext>
    </Box>
  );

  function renderItems(): DroppableContainerWrapperProps["children"] {
    return itemResponses.map((ir, idx) => (
      <Box
        py={1}
        key={ir.item.id + "-dnd-item"}
        display="flex"
        alignItems="center"
        marginLeft={idx > 8 ? "-1rem" : "0"}
      >
        <Box pr={2}>
          <Typography variant="h6">{`${idx + 1}.`}</Typography>
        </Box>
        <BudgetCartItem
          itemData={ir.item}
          responseData={ir.response}
          removeFunction={() => void 0}
          noMenu={true}
          dndIcon
        />
      </Box>
    ));
  }

  function onDragEnd(result: reactBeautifulDnd.DropResult) {
    if (!result.destination) return;

    const reorderedItems = reorder(
      itemResponses,
      result.source.index,
      result.destination.index
    );
    setItemResponses(reorderedItems);
  }

  function reorder(
    list: typeof itemResponses,
    startIndex: number,
    endIndex: number
  ) {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }
};

export { BudgetPrioritiesDndList };
export default BudgetPrioritiesDndList;
