import React, { FC, useState, useContext } from "react";
import { SquareModalBase } from "../modals";
import {
  Typography,
  useMediaQuery,
  Theme,
  makeStyles,
  Box,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
  FormHelperText,
  Link,
  LinearProgress,
} from "@material-ui/core";
import { YZButton } from "../buttons";
import { UserCtx, ProfileCtx } from "../../util";
import LogRocket from "logrocket";
import { GenericOnCallFunctionAlert } from "../../util/functions/genericAlert";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";

export interface HelpEmailModalProps {
  open: boolean;
  onClose: () => void;
}

export type HelpType = "design" | "technical" | "timeline" | "other";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      alignItems: "center",
    },
  },
  subHeader: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  row: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  closeTextButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const HelpEmailModal: FC<HelpEmailModalProps> = ({ open, onClose }) => {
  const classes = useStyles();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const user = useContext(UserCtx);
  const profile = useContext(ProfileCtx);

  const [helpType, setHelpType] = useState<HelpType | "">("");
  const [emailBody, setEmailBody] = useState<string>("");

  const [emailSent, setEmailSent] = useState(false);
  const [success, setSuccess] = useState<boolean | null>(null);
  const [error, setError] = useState(false);

  return (
    <SquareModalBase
      open={open}
      onClose={handleClose}
      contentContainerProps={{
        p: 0,
        width: smDown ? "600px" : "700px",
        height: smDown ? "475px" : "500px",
        maxWidth: "100vw",
        maxHeight: "90vh",
        display: "flex",
        flexDirection: "column",
      }}
      closeable
    >
      <Box className={classes.root}>
        {success === null && (
          <>
            <Typography
              style={{ marginBottom: "5px" }}
              variant={smDown ? "h5" : "h4"}
            >
              We're here to help!
            </Typography>
            <Typography className={classes.subHeader}>
              We'll reach out to you via email within the next business day.
            </Typography>
            <Box className={classes.row}>
              <Typography>Please choose</Typography>
              <Box pl={2}>
                <FormControl>
                  <InputLabel id="help-type-select-label">Select</InputLabel>
                  <Select
                    value={helpType}
                    labelId="help-type-select-label"
                    onChange={e => setHelpType(e.target.value as HelpType)}
                    style={{ width: "15rem" }}
                    error={!!error && helpType === ""}
                  >
                    <MenuItem disabled value="">
                      Select
                    </MenuItem>
                    <MenuItem value="design">Specific design question</MenuItem>
                    <MenuItem value="technical">Technical question</MenuItem>
                    <MenuItem value="timeline">Timeline question</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Box pt={2} width="100%">
              <TextField
                value={emailBody}
                onChange={e => setEmailBody(e.target.value)}
                variant="filled"
                rows={10}
                error={!!error && !emailBody.length}
                multiline
                fullWidth
              />
            </Box>
            <Box
              pt={2}
              pl={2}
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormHelperText>
                {emailBody.trim().length < 5
                  ? "Let us know how we can help!"
                  : " "}
              </FormHelperText>
              <Box justifySelf="flex-end">
                <YZButton
                  disabled={emailSent || !hasRequiredFields()}
                  onClick={() => sendEmail()}
                >
                  Send
                </YZButton>
                {emailSent && <LinearProgress />}
              </Box>
            </Box>
          </>
        )}
        {success === true && (
          <Box pt={5} textAlign="center">
            <Typography variant={smDown ? "h5" : "h4"}>
              We got your message! We'll reach out to you via email within the
              next business day.
            </Typography>
            <Box
              mt={5}
              className={classes.closeTextButton}
              onClick={handleClose}
            >
              <Typography variant="h6" color="primary">
                Close
              </Typography>
            </Box>
          </Box>
        )}
        {success === false && (
          <Box pt={5} textAlign="center">
            <Typography variant={smDown ? "h5" : "h4"}>
              Uh-oh! Something went wrong trying to deliver this email. If this
              error persists, please contact{" "}
              <Link href="mailto:support@yardzen.com">support@yardzen.com</Link>
              .
            </Typography>
            <Box
              mt={5}
              className={classes.closeTextButton}
              onClick={handleClose}
            >
              <Typography variant="h6" color="primary">
                Close
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </SquareModalBase>
  );

  function formatEmailData() {
    if (!user) {
      throw new Error("No user found when trying to send Onboard Help Email!");
    }

    if (!profile) {
      throw new Error(
        "No profile found when trying to send Onboard Help Email!"
      );
    }

    return {
      helpType,
      emailAddress: user.email,
      firstName: profile.firstName,
      lastName: profile.lastName,
      body: emailBody.trim(),
    };
  }

  function hasRequiredFields(): boolean {
    return helpType !== "" && emailBody.trim().length > 4;
  }

  async function sendEmail() {
    if (!hasRequiredFields()) {
      return setError(true);
    }

    setEmailSent(true);

    try {
      const data = formatEmailData();
      const res = await firebase
        .functions()
        .httpsCallable("sendOnboardHelpEmail")(data);

      if (res.data === 202 && user) {
        setSuccess(true);
      } else {
        setSuccess(false);
      }
    } catch (err) {
      window.newrelic.noticeError(err);
      LogRocket.captureMessage(err.message);
      GenericOnCallFunctionAlert("sendOnboardHelpEmail", err.message);
      setSuccess(false);
    }
  }

  function handleClose() {
    setHelpType("");
    setEmailBody("");
    setEmailSent(false);
    setSuccess(null);
    setError(false);
    onClose();
  }
};

export { HelpEmailModal };
export default HelpEmailModal;
