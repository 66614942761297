export default async function genericAlert(message: string) {
  if (
    process.env.REACT_APP_FIREBASE_CONFIG &&
    process.env.REACT_APP_FIREBASE_CONFIG.includes("5f8e9")
  ) {
    // Send message to #eng-alerts in Slack
    return fetch("https://hooks.zapier.com/hooks/catch/3530042/o6x6kxh/", {
      method: "POST",
      body: JSON.stringify({
        body: message,
      }),
    });
  } else {
    // Send message to #eng-staging-alerts in Slack
    return fetch("https://hooks.zapier.com/hooks/catch/3530042/okks9nx/", {
      method: "POST",
      body: JSON.stringify({
        body: message,
      }),
    });
  }
}

export function GenericOnCallFunctionAlert(
  functionName: string,
  errorMessage: string
) {
  genericAlert(
    `Service: Client-App-V2, \nFunction: ${functionName}, \nError: ${errorMessage}, \nDetail: visit Logs in Firebase / Google for detail`
  );
}

export const CANNOT_ONBOARD_ALERT_STRING =
  "\n\n🚨🚨🚨 CLIENT CANNOT ONBOARD - client-app-v2 🚨🚨🚨";
