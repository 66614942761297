import { Box, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { PaymentConfirmed } from "../../components/checkout";
import { getEmail } from "../../components/checkout/util/getEmail";
import { useDesignProfileCtx } from "../../components/designProfile";
import { useAppSelector } from "../../hooks";
import { UserCtx } from "../../util";

interface PaymentConfirmedPageProps {
  onPrevious: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(4),
    },
  },
}));

export const PaymentConfirmedPage: React.FC<PaymentConfirmedPageProps> = ({
  onPrevious,
}) => {
  const { selectedSku, orderId, modifiedPrice } = useAppSelector(
    state => state.products
  );
  const { address } = useAppSelector(state => state.checkout);
  const history = useHistory();
  const { designProfile } = useDesignProfileCtx();
  const userContext = React.useContext(UserCtx);

  const { root } = useStyles();

  const email = getEmail({ designProfile, userContext });

  if (!selectedSku) {
    history.push("/design-profile/find-your-package");
    return null;
  }

  return (
    <Box mt={12} className={root}>
      <PaymentConfirmed
        goBack={onPrevious}
        skuObject={selectedSku}
        firstName={designProfile?.contactInformation?.firstName as string}
        lastName={designProfile?.contactInformation?.lastName as string}
        email={email}
        phone={designProfile?.contactInformation?.phoneNumber as string}
        address={address}
        orderId={orderId}
        modifiedPrice={modifiedPrice}
      />
    </Box>
  );
};
