import {
  CONTACT_INFO_PATH,
  RECOMMENDED_PACKAGE_PATH,
  CREATE_ACCOUNT_PATH,
  TYPEFORM_PATH,
} from ".././pages/designProfile/constants/paths";
import { DesignProfileNavigation } from ".././types/DesignProfile";
import { DESIGN_PROFILE_VERSION_7 } from ".././util/constants/designProfileVersionIds";

// DO NOT EDIT OLD VERSIONS. VERSIONS #'S ARE STORED IN FIRESTORE
// EACH SUBSEQUENT VERSION NEEDS TO INCREMENT IN VERSION # AND NEEDS
// IT'S OWN FILE IN src/designProfileVersions

// Version 7: shorter Typeform without address
export const designProfileVersion7: DesignProfileNavigation = {
  versionId: DESIGN_PROFILE_VERSION_7,
  paths: [
    {
      path: TYPEFORM_PATH,
      component: "DesignProfileTypeform",
      title: "",
      button_text: null,
      alt_button_text: null,
      next: CONTACT_INFO_PATH,
      previous: null,
      header_page: 2,
      completion_number: 2,
    },
    {
      path: CONTACT_INFO_PATH,
      component: "DesignProfileEmailPhoneInputPage",
      title: "Almost done. Let's save your results.",
      button_text: "Next",
      alt_button_text: null,
      next: RECOMMENDED_PACKAGE_PATH,
      previous: TYPEFORM_PATH,
      header_page: 4,
    },
    {
      path: RECOMMENDED_PACKAGE_PATH,
      component: "RecommendedPackagePage",
      title: "We recommend...",
      button_text: "PURCHASE",
      alt_button_text: "Questions? Talk to Our Team",
      next: CREATE_ACCOUNT_PATH,
      previous: CONTACT_INFO_PATH,
      header_page: 4,
    },
  ],
};
