import {
  ENTIRE_YARD_STARTER_PACKAGE,
  FRONT_OR_BACKYARD_STARTER_PACKAGE,
  PackageTypes,
} from "./constants/packageTypes";

export const isStarterPackage = (
  packageType: PackageTypes | undefined
): boolean => {
  return (
    packageType === FRONT_OR_BACKYARD_STARTER_PACKAGE ||
    packageType === ENTIRE_YARD_STARTER_PACKAGE
  );
};
