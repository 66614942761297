import React, { FC } from "react";
import { Box, makeStyles, useMediaQuery, Theme } from "@material-ui/core";
import { NavLinkList } from "./NavLinkList";
import { MobileNavDrawer } from "./MobileNavDrawer";

export interface DefaultHeaderProps {}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "inline-flex",
      flexDirection: "row-reverse",
      alignItems: "center",
    },
  },
}));

const DefaultHeader: FC<DefaultHeaderProps> = () => {
  const { mainContainer } = useStyles();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Box className={mainContainer}>
      {!smDown ? <NavLinkList /> : <MobileNavDrawer />}
    </Box>
  );
};

export { DefaultHeader };
