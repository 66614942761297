import * as React from "react";
import FileUpload from "./FileUpload";
import LargeUploadBox from "./LargeUploadBox";
import { Divider } from "@material-ui/core";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { UserCtx } from "../../util";
import { Media } from "@yardzen-inc/models";

interface UploadContainerProps {
  projectId: string;
  fileTag: string;
  variant: string;
  setFileCount?: React.Dispatch<React.SetStateAction<number>>;
}

const UploadContainer: React.FunctionComponent<UploadContainerProps> = ({
  projectId,
  fileTag,
  variant,
  setFileCount,
}) => {
  const [media, setMedia] = React.useState<Media[]>([]);
  const userContext = React.useContext(UserCtx);

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(subscribeToMedia, [userContext]);

  React.useEffect(() => {
    if (!setFileCount) return;
    setFileCount(media.length);
  }, [media, setFileCount]);

  return (
    <>
      <FileUpload fileTag={fileTag} variant={variant} projectId={projectId}>
        <LargeUploadBox />
      </FileUpload>
      <Divider />
      <div style={{ marginTop: "1rem", marginBottom: "3rem" }}>
        {media.map(media => {
          return (
            <img
              key={`${media.id}-${fileTag}-${variant}`}
              style={{ maxWidth: "200px", maxHeight: "200px" }}
              src={media.downloadURL}
              alt="some alt"
            />
          );
        })}
      </div>
    </>
  );

  function subscribeToMedia() {
    if (!userContext) return;
    const userId = userContext.uid;
    return firebase
      .firestore()
      .collection("media")
      .where("tag", "==", fileTag)
      .where("variant", "==", variant)
      .where("userId", "==", userId)
      .onSnapshot(async snap => {
        const media = await getDocRecordsWithDownloadURLAndID(snap);
        setMedia(media as Media[]);
      });
  }
};

export async function getDocRecordsWithDownloadURLAndID(snapshot: any) {
  let mediaList: any = [];

  snapshot.forEach((doc: any) => {
    let docData = doc.data();
    docData["id"] = doc.id;
    mediaList.push(docData);
  });

  mediaList = await Media.fetchImagesPathFromPromise(mediaList, {
    type: "original",
    path: "path",
    downloadPath: "downloadURL",
    handler: Media.fetchOriginalImage,
  });

  mediaList = await Media.fetchImagesPathFromPromise(mediaList, {
    type: "thumbnail",
    path: "path",
    downloadPath: "thumbnailURL",
    handler: Media.fetchThumbnail,
  });

  return mediaList;
}

export { UploadContainer };
export default UploadContainer;
