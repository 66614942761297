import { DataLayer } from "@yardzen-inc/data-layer";
import { setOrderId } from "../../../slices/productSlice";
import { AppDispatch } from "../../../store";
import { PriceObject } from "../../../types/Prices";
import { Product } from "../../../types/Products";
import { DesignProfileCtxValue } from "../../designProfile";
import { handleSendPurchaseEvents } from "./handleSendPurchaseEvents";
import { checkForOrderEvent } from "./checkForOrderEvent";
import { updateDesignProfilePostOrder } from "./updateDesignProfilePostOrder";
import { sendFbPixelEvent } from "../../../util/functions/sendFbPixelEvent";

interface HandleConfirmedOrderArgs {
  paymentIntentId: string;
  dispatch: AppDispatch;
  isAdditionalRevision: boolean;
  dsContext: DesignProfileCtxValue | null;
  firstName: string;
  lastName: string;
  selectedProduct: Product;
  selectedSku: PriceObject;
  isPremium: boolean;
  dataLayer: DataLayer;
  premiumSku: PriceObject;
  nextStep: () => void;
}

export const handleConfirmedOrder = async ({
  paymentIntentId,
  dispatch,
  isAdditionalRevision,
  dsContext,
  firstName,
  lastName,
  selectedProduct,
  selectedSku,
  isPremium,
  dataLayer,
  premiumSku,
  nextStep,
}: HandleConfirmedOrderArgs) => {
  let orderId = null;

  const handleOrderId = async (updatedOrderId: string) => {
    orderId = updatedOrderId;

    dispatch(setOrderId({ orderId }));

    if (!isAdditionalRevision) {
      await updateDesignProfilePostOrder({
        dsContext,
        firstName,
        lastName,
      });
    }

    const packageName = selectedProduct.name;
    const packagePrice = selectedSku.price + (isPremium ? premiumSku.price : 0);

    handleSendPurchaseEvents({
      dataLayer,
      productObject: {
        id: orderId,
        sku: selectedSku.id,
        name: packageName,
        price: (packagePrice / 100).toString(),
        category: selectedSku.attributes.name,
      },
    });

    sendFbPixelEvent("Purchase", {
      // TODO: Calculate actual price if a giftcard was applied
      value: packagePrice / 100,
      currency: "USD",
    });

    nextStep();
  };

  checkForOrderEvent({
    paymentIntentId,
    setOrderId: handleOrderId,
    yzOrderEventErrorTimer: () => {},
  });
};
