import React, { FC, useContext, useEffect } from "react";
import { makeStyles, Box, capitalize } from "@material-ui/core";
import { OnboardCtx, useSetTitle, ProfileCtx } from "../../util";
import { YardName, YardUploadStatus } from "./stepData";
import StepWrapper from "./StepWrapper";
import DisabledYardSelectContainer from "./myYard/DisabledYardSelectContainer";
import { SectionTitle } from "./helpers/SectionTitle";
import {
  ProgressTracker,
  ProgressTrackerItem,
} from "@yardzen-inc/react-common";
import { useHistory } from "react-router";
import { YardsStatusObj, YardTaskStatus } from "./stepData/types";
import { getAvailableYards } from "./util/getAvailableYards";

export interface MyYardSelectYardProps {}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  yardBoxContainer: {
    display: "flex",
    margin: `0`,
    alignItems: "center",
    flexDirection: "column",
    [breakpoints.down("lg")]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
}));

const MyYardSelectYard: FC<MyYardSelectYardProps> = () => {
  let sectionTitles = [
    "Which yards do you want to design?",
    "Select the yards you'd like to design, then start uploading photos and videos for us to map out your property.",
  ];
  const classes = useStyles();
  const history = useHistory();
  const profile = React.useContext(ProfileCtx);
  const packagePurchased = profile ? profile.package : null;
  const availableYards = getAvailableYards(packagePurchased);
  const singleAvailableYard = availableYards.length === 1;
  if (singleAvailableYard) {
    sectionTitles = [
      "Begin Uploading Photos of Your Space",
      'Select "uploads" below to begin uploading photos and videos of your space',
    ];
  }

  useSetTitle("Yardzen - Start Uploading!", true, []);
  const {
    state: { yardsStatusObj },
    setSelectedYard,
    setYardActiveState,
    swapPrimaryYards,
  } = useContext(OnboardCtx);
  const activeYards = React.useMemo(
    () =>
      Object.keys(yardsStatusObj).sort(
        (a, b) => a.charCodeAt(0) - b.charCodeAt(0)
      ) as YardName[],
    [yardsStatusObj]
  );
  const inactiveYards: YardName[] = React.useMemo(() => {
    return availableYards.filter(
      yard => !activeYards.includes(yard as YardName)
    ) as YardName[];
  }, [activeYards, availableYards]);

  useEffect(() => {
    if (availableYards.length === 1) {
      setYardActiveState(availableYards[0], true);
    }
  }, [availableYards, setYardActiveState]);

  return (
    <Box>
      <StepWrapper>
        <SectionTitle title={sectionTitles[0]} subtitle={sectionTitles[1]} />
        <Box className={classes.yardBoxContainer}>
          {activeYards.map((yardName: keyof YardsStatusObj) => (
            <React.Fragment key={yardName}>
              <ProgressTracker
                title={`${capitalize(yardName)} ${
                  ["right", "left"].includes(yardName) ? "Side" : ""
                } Yard`}
                removeOptions={
                  availableYards.length === 1
                    ? undefined
                    : {
                        onRemove: () => removeYard(yardName),
                        tooltipText: "Remove yard",
                        promptTitle: "Remove yard?",
                        promptBody: "Your work will be saved.",
                      }
                }
              >
                <ProgressTrackerItem
                  title="Uploads"
                  description="This will help our team to map the measurements of your property"
                  completed={renderStepAsCompleted(
                    "upload",
                    yardsStatusObj[yardName]!["upload"]
                  )}
                  onClick={() => handleNav("upload", yardName)}
                />
                <ProgressTrackerItem
                  title="Keep / Remove"
                  description="Tell us everything about the current state of your yard and what you want to keep"
                  completed={renderStepAsCompleted(
                    "keepRemove",
                    yardsStatusObj[yardName]!["keepRemove"]
                  )}
                  onClick={() => handleNav("keepRemove", yardName)}
                  optional
                />
                <ProgressTrackerItem
                  title="Functions"
                  description="Choose the main functions of your dream yard"
                  completed={renderStepAsCompleted(
                    "priorities",
                    yardsStatusObj[yardName]!["priorities"]
                  )}
                  onClick={() => handleNav("priorities", yardName)}
                  optional
                />
              </ProgressTracker>
            </React.Fragment>
          ))}
        </Box>
        <DisabledYardSelectContainer
          swapYards={swapYards}
          addYard={addYard}
          yards={inactiveYards}
        />
      </StepWrapper>
    </Box>
  );

  async function removeYard(yard: YardName) {
    await setYardActiveState(yard, false);
  }

  async function addYard(yard: YardName) {
    await setYardActiveState(yard, true);
  }

  async function swapYards(
    yardToActivate: Exclude<YardName, "left" | "right">
  ) {
    await swapPrimaryYards(yardToActivate);
  }

  function handleNav(destination: keyof YardUploadStatus, yard: YardName) {
    const subStepUrlMap = {
      keepRemove: "keep", // Keep and remove will be consolidated to one view/endpoint
    };

    setSelectedYard(yard);
    history.push(
      `/onboard/my-yard/${yard}/${
        destination === "keepRemove" ? subStepUrlMap[destination] : destination
      }`
    );
  }

  function renderStepAsCompleted(
    step: keyof Omit<YardUploadStatus, "isSloped">,
    status: YardTaskStatus
  ) {
    // The "uploads" step is required, so we actually check whether its status is "complete".
    // Other steps are optional, so we render them completed if they've been visited at least once.
    const userHasVisited = status !== "NOT_STARTED";

    if (step === "upload") {
      return status === "COMPLETE";
    } else {
      return userHasVisited;
    }
  }
};

export { MyYardSelectYard };
export default MyYardSelectYard;
