import { Box, Grid, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { GenericLoadingSpinner } from "@yardzen-inc/react-common";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useDataLayer } from "../../data";
import { useAppSelector } from "../../hooks";
import { setReferralCodesToStore } from "../../services/referralCodes";
import {
  resetCheckout,
  setInputReferralCode,
  setPromotionalCode,
} from "../../slices/checkoutSlice";
import {
  resetPayment,
  setClientSecret,
  setOrderPrice,
  setOriginalOrderPrice,
  setPaymentIntentId,
} from "../../slices/paymentSlice";
import { resetProductCheckoutItems } from "../../slices/productSlice";
import { UserCtx } from "../../util";
import {
  SegmentClickTypes,
  SegmentFlows,
  useSegment,
} from "../../util/Segment";
import { PackageTypes } from "../../util/constants/packageTypes";
import { getStripeKey } from "../../util/functions/getStripeKey";
import { IAddressObject } from "../../util/functions/parseGeocodeLocationToAddress";
import useSubscribeToAbandonedCartEmail from "../../util/hooks/useAbandonedCartEmail";
import { useApplyDiscountCode } from "../../util/hooks/useApplyDiscountCode";
import { usePremiumPackage } from "../../util/hooks/usePremiumPackage";
import { isStarterPackage } from "../../util/isStarterPackage";
import { useDesignProfileCtx } from "../designProfile";
import GenericSnackBar from "../utility/GenericSnackBar";
import { Account } from "./Account";
import CheckoutSidebar from "./CheckoutSidebar";
import GoBackButton from "./GoBack";
import { ReferralInput } from "./ReferralInput";
import RenderInvoiceItem from "./RenderInvoiceItem";
import { StripePaymentForm } from "./StripePaymentForm";
import { DebugInfo } from "./util/DebugInfo";
import { checkReferralCodes } from "./util/checkReferralCodes";
import { constructOrder } from "./util/constructOrder";
import { getEmail } from "./util/getEmail";
import { getPaymentIntent2 } from "./util/getPaymentIntent";
import { getUserId } from "./util/getUserId";
import { handleReferralCodeSubmit } from "./util/handleReferralCodeSubmit";
import { wordpressUrlForPackage } from "./util/wordpressUrlForPackage";
import { updateCartViewForUserDesignProfile } from "./util/updateCartViewForUserDesignProfile";

export interface CheckoutFormProps {
  address?: IAddressObject;
  nextStep: () => void;
  noBackButton?: boolean;
  propertyAddress: IAddressObject;
  showSidebar?: boolean;
  byProxy?: boolean;
}

export const CheckoutForm = ({
  nextStep,
  noBackButton,
  showSidebar,
  byProxy,
}: CheckoutFormProps) => {
  const segment = useSegment();
  const dispatch = useDispatch();
  const dataLayer = useDataLayer();
  const classes = useStyles();
  const {
    root,
    formContainer,
    formContent,
    formSidebar,
    goBackContainer,
    referralInputLabel,
  } = classes;
  const {
    selectedProduct,
    selectedSku,
    modifiedPrice,
    addOnSkus,
    products,
  } = useAppSelector(state => state.products);

  const isStarter = isStarterPackage(
    selectedProduct?.metadata?.package as PackageTypes
  );
  const isAdditionalRevision = selectedProduct?.name === "Additional Revision";
  useApplyDiscountCode();

  const segmentFlow = isAdditionalRevision
    ? SegmentFlows.PURCHASE_ADDITIONAL_REVISION
    : SegmentFlows.CHECKOUT;

  const [error, setError] = React.useState<false | string>(false);

  const {
    referralCodes: referralCodeCollection,
    inputReferralCode,
    promotionalCode,
  } = useAppSelector(state => state.checkout);

  const [referralCodeError, setReferralCodeError] = useState<boolean>(false);
  const [invalidCodeMessage, setInvalidCodeMessage] = useState<string>("");
  const [orderEventError] = React.useState<boolean>(false);
  const { isPremium, premiumSku } = usePremiumPackage();
  const history = useHistory();
  const { paymentIntentId, clientSecret } = useAppSelector(
    state => state.payment
  );
  const [stripeIsLoading, setStripeIsLoading] = useState<boolean>(false);

  const userContext = React.useContext(UserCtx);
  const dsContext = useDesignProfileCtx();

  const stripe = loadStripe(getStripeKey());

  const userId =
    getUserId({
      userContext,
      dsContext,
    }) || "";

  const handleGoBack = () => {
    dispatch(resetProductCheckoutItems());
    dispatch(resetCheckout());
    dispatch(resetPayment());
    const path = wordpressUrlForPackage(selectedProduct);
    if (path) window.location.href = path;
    else history.goBack();
  };

  const { firstName, lastName, checkoutEmail } = useAppSelector(
    state => state.checkout
  );

  const email =
    getEmail({
      userContext,
      designProfile: dsContext.designProfile,
    }) || checkoutEmail;

  const [isEditingAccount, setIsEditingAccount] = useState(
    !firstName || !email || !lastName
  );

  useSubscribeToAbandonedCartEmail(
    email,
    selectedSku?.id || "",
    firstName,
    lastName
  );

  useEffect(() => {
    checkReferralCodes({ referralCodeCollection, setReferralCodesToStore });
  }, [referralCodeCollection]);

  const handleReferralSubmitWithArgs = async (code: string) => {
    await handleReferralCodeSubmit({
      code,
      segment,
      segmentFlow,
      referralCodeCollection,
      setReferralCodeError,
      setInputReferralCode: code => {
        dispatch(setInputReferralCode({ inputReferralCode: code }));
      },
      setPromotionalCode: code => {
        dispatch(setPromotionalCode(code));
      },
      setInvalidCodeMessage,
      isStarterPackage: isStarter,
      products,
      selectedProduct,
    });
  };

  const billingAddress = useMemo(() => {
    const country = "US";
    const { street, city, state, zip } =
      dsContext.designProfile?.contactInformation || {};

    return {
      street: street || "",
      city: city || "",
      state: state || "",
      zip: zip || "",
      aptNumber: "",
      formattedAddress:
        street && city && state && zip
          ? `${street} ${city} ${state} ${zip}`
          : "",
      country,
    };
  }, [dsContext.designProfile?.contactInformation]);

  const order = useMemo(
    () =>
      constructOrder({
        addOnSkus,
        skuId: selectedSku?.id || "",
        propertyAddress: billingAddress,
        userId,
        email,
        inputReferralCode,
        isPremium,
        isAdditionalRevision,
        firstName,
        lastName,
        promotionalCode: promotionalCode?.code,
      }),
    [
      addOnSkus,
      selectedSku?.id,
      billingAddress,
      userId,
      email,
      inputReferralCode,
      isPremium,
      isAdditionalRevision,
      firstName,
      lastName,
      promotionalCode?.code,
    ]
  );

  const updatePaymentIntent = useCallback(
    async ({
      email,
      idToken,
      order,
      userId,
      dispatch,
    }: {
      email: string;
      idToken: string;
      order: any;
      userId: string;
      dispatch: any;
    }) => {
      if (!(userId && email && idToken)) return;
      setStripeIsLoading(true);
      const results = await getPaymentIntent2({
        order: Object.assign({}, order, { email }),
        userId,
        idToken,
        setOrderPrice: orderPrice => dispatch(setOrderPrice({ orderPrice })),
        setOriginalOrderPrice: originalOrderPrice =>
          dispatch(setOriginalOrderPrice({ originalOrderPrice })),
      });
      if ("error" in results) {
        setError(results.error);
        setStripeIsLoading(false);
        return;
      }
      dispatch(
        setPaymentIntentId({ paymentIntentId: results.paymentIntentId })
      );
      dispatch(setClientSecret({ clientSecret: results.clientSecret }));
      setStripeIsLoading(false);
    },
    []
  );

  useEffect(() => {
    const updateIntent = async () => {
      const idToken = await userContext?.getIdToken();
      if (!idToken) return;

      // Conditions from both hooks to determine when to update the payment intent
      if (
        (selectedProduct &&
          selectedSku &&
          email &&
          firstName &&
          lastName &&
          !(paymentIntentId && clientSecret)) ||
        promotionalCode ||
        addOnSkus
      ) {
        updatePaymentIntent({ email, idToken, order, userId, dispatch });
      }
    };

    updateIntent();
  }, [
    promotionalCode,
    addOnSkus,
    selectedProduct,
    selectedSku,
    email,
    firstName,
    lastName,
    paymentIntentId,
    clientSecret,
    updatePaymentIntent,
    userContext,
    order,
    userId,
    dispatch,
  ]);

  if (userContext?.uid && selectedProduct?.name && selectedSku?.id) {
    updateCartViewForUserDesignProfile(userContext.uid, {
      packageName: selectedProduct.name,
      sku: selectedSku.id,
      ...(selectedSku.nickname && { lotSize: selectedSku.nickname }),
      ...(modifiedPrice && { modifiedPrice }),
      ...(promotionalCode?.status === "USABLE" && {
        discountCode: {
          ...(promotionalCode?.code && { code: promotionalCode?.code }),
          ...(promotionalCode?.discountType && {
            discountType: promotionalCode?.discountType,
          }),
          ...(promotionalCode?.discount && {
            discount: promotionalCode?.discount,
          }),
        },
      }),
      addOns:
        addOnSkus?.map(sku => ({
          sku: sku.id,
          name: sku.attributes.name,
          price: sku.price,
        })) ?? [],
    });
  }

  return (
    <Grid container className={root}>
      <Grid item md className={formContainer}>
        <Box className={formContent}>
          {!noBackButton && (
            <Box mb={1} className={goBackContainer}>
              <GoBackButton
                goBack={() => {
                  handleGoBack();
                  segment.trackClicked({
                    button_name: "Confirm Order Go Back",
                    flow_name: segmentFlow,
                    click_type: SegmentClickTypes.BUTTON,
                    button_content: "Go Back",
                  });
                }}
              />
            </Box>
          )}
          {selectedProduct && selectedSku && (
            // Package Name | Package Image | Package Price
            <RenderInvoiceItem />
          )}
          {!isAdditionalRevision && (
            <>
              <Box className={referralInputLabel}>
                <Typography>
                  {selectedSku?.attributes.name.includes("Lowes")
                    ? "Enter your MyLowe's Rewards Yardzen code"
                    : "Enter referral or promo code (optional)"}
                </Typography>
              </Box>
              <ReferralInput
                handleReferralCodeSubmit={handleReferralSubmitWithArgs}
                referralCodeError={referralCodeError}
                customSuccessMessage={
                  promotionalCode?.applicationSuccessMessage ?? undefined
                }
                validReferralCodeEntered={
                  inputReferralCode?.length ? true : false
                }
                invalidCodeMessage={invalidCodeMessage}
              />
            </>
          )}
          <Box my={4}>
            <Account
              isEditing={isEditingAccount}
              setIsEditing={setIsEditingAccount}
              byProxy={byProxy}
            />
          </Box>
          <DebugInfo
            data={{ paymentIntentId, dsContext, email }}
            title="Payment Info"
          />
          {clientSecret &&
            paymentIntentId &&
            selectedProduct &&
            selectedSku &&
            !isEditingAccount &&
            !stripeIsLoading && (
              <>
                {/* Payment Input */}
                <Elements
                  stripe={stripe}
                  options={{
                    clientSecret,
                  }}
                >
                  <StripePaymentForm
                    isAdditionalRevision={isAdditionalRevision}
                    dispatch={dispatch}
                    setError={setError}
                    email={email}
                    billingAddress={billingAddress}
                    premiumSku={premiumSku}
                    isPremium={isPremium}
                    addOnSkus={addOnSkus}
                    modifiedPrice={modifiedPrice}
                    dataLayer={dataLayer}
                    nextStep={nextStep}
                  />
                </Elements>
              </>
            )}
          {!isEditingAccount && stripeIsLoading && <GenericLoadingSpinner />}
          {orderEventError && (
            <Typography color="primary">
              We've received your payment. We can't wait to get started on your
              your your project! Watch out for an email from us with
              instructions instructions on next on next steps. Reach
              support@yardzen.com questions.
            </Typography>
          )}
          <GenericSnackBar
            variant="error"
            message={error ? error : undefined}
            onClose={() => setError(false)}
            in={!!error}
            maxWidth="480px"
          />
        </Box>
      </Grid>
      {showSidebar && (
        <Grid item md={5} lg={4} className={formSidebar}>
          <CheckoutSidebar />
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: "#fff",
    marginTop: "-24px",
  },
  formContainer: {
    background: "#fff",
    padding: "1.5rem 1rem",
    [theme.breakpoints.up("md")]: {
      padding: "1rem 2rem",
    },
  },
  formContent: {
    [theme.breakpoints.up("md")]: {
      maxWidth: 656,
    },
    marginLeft: "auto",
    marginRight: "auto",
  },
  formSidebar: {
    background: "#FAFAFA",
    padding: "0.5rem 0.5rem 6rem",
    [theme.breakpoints.up("md")]: {
      padding: "1rem 0 1rem 1.5rem",
    },
  },
  goBackContainer: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-start",
  },
  referralInputLabel: {
    margin: ".5rem auto",
  },
}));
