import * as React from "react";
import { makeStyles, Theme, List, ListItem, Tooltip } from "@material-ui/core";
import "firebase/auth";
import { useRouteMatch, useHistory } from "react-router";
import { ProfileCtx, smartRedirect, useHeaderHeight } from "../util";
import { MenuItem, useMenuItems } from "./onboard/useMenuItems";
import { firebaseSignOut } from "../util/cookies/methods";
import {
  ListOutlined,
  AddCircleOutlined,
  MapOutlined,
  TuneOutlined,
  NatureOutlined,
  EmojiObjectsOutlined,
  PlayCircleOutlineOutlined,
  FlagOutlined,
  DeckOutlined,
  PowerSettingsNewOutlined,
  PlaylistAddCheckOutlined,
  HouseOutlined,
  HelpOutline,
} from "@material-ui/icons";
import { SegmentClickTypes, SegmentFlows, useSegment } from "../util/Segment";
import { useAppSelector } from "../hooks";
import { useAllowAccessToBudgetStep } from "./onboard/budget";

interface OnboardStepMenuProps {
  setHelpModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  closeSidebar?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    height: "100%",
    maxHeight: "100%",
    overflowX: "hidden",
    padding: "0.5rem",
    [theme.breakpoints.up("md")]: {
      flexShrink: 0,
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
    },
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    fontSize: "1rem",
    padding: "0.5rem 0.75rem",
    borderRadius: 4,
    margin: "0.33rem 0",
  },
  listItemSelected: {
    backgroundColor: "#DFE5DF !important",
    backgroundImage: "none",
    fontWeight: 600,
    fontSize: "1rem",
    "& svg": {
      color: `${theme.palette.text.primary}!important`,
    },
  },
  listItemIcon: {
    width: "1.5rem",
    marginRight: "0.25rem",
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
    "& svg": {
      fontSize: 17,
    },
  },
}));

/*
  The following array is a copy of the client-app-v1 menu list,
  with the following changes:

  "onboardTwoSlug" signals that the client
  should be staying client-app-v2 when navigating to that
  route.

  "hideForPkg" indicates that if the client has a matching package,
  hide that menu item. eg "botanical" packages dont get an "Elements" step.

  No component property, that was dumb.
*/

const OnboardStepMenu: React.FunctionComponent<OnboardStepMenuProps> = props => {
  const segment = useSegment();
  const classes = useStyles();
  const { url } = useRouteMatch();
  const history = useHistory();
  const headerHeight = useHeaderHeight();
  const clientProfile = React.useContext(ProfileCtx);
  const menuItems = useMenuItems();
  const { submittedInstallationPlans } = useAppSelector(state => state.onboard);
  const allowAccessToBudgetStep = useAllowAccessToBudgetStep();

  const getIcon = (slug: string) => {
    const formatSlug = slug.toLowerCase();
    if (formatSlug === "media") return <DeckOutlined />;
    if (formatSlug === "budget") return <ListOutlined />;
    if (formatSlug === "goals") return <FlagOutlined />;
    if (formatSlug === "exterior-design") return <HouseOutlined />;
    if (formatSlug === "elements") return <PlaylistAddCheckOutlined />;
    if (formatSlug === "inspiration") return <EmojiObjectsOutlined />;
    if (formatSlug === "plans") return <MapOutlined />;
    if (formatSlug === "style") return <TuneOutlined />;
    if (formatSlug === "plants") return <NatureOutlined />;
    if (formatSlug === "start") return <PlayCircleOutlineOutlined />;

    return <AddCircleOutlined />;
  };

  return (
    <div style={{ top: headerHeight }} className={classes.drawer}>
      <List style={{ padding: "0" }}>
        {menuItems.map((item, index) => {
          const selected =
            !!item.onboardTwoSlug &&
            window.location.href.includes(item.onboardTwoSlug);
          if (hideListItem(item)) {
            // TODO: component doesn't wait for clientProfile to load,
            // causing a flash of a menu item sometimes.
            return null;
          }
          return (
            <Tooltip
              title={
                isListItemDisabled(item)
                  ? "Submit installation plans in Wish List section before proceeding"
                  : ""
              }
            >
              <span>
                <ListItem
                  onClick={() => handleListItemClick(item, props.closeSidebar)}
                  button
                  key={`${item.slug}-${index}`}
                  selected={selected}
                  className={classes.listItem}
                  classes={{
                    selected: classes.listItemSelected,
                  }}
                  disabled={isListItemDisabled(item)}
                >
                  <div className={classes.listItemIcon}>
                    {getIcon(item.slug)}
                  </div>
                  {item.label}
                </ListItem>
              </span>
            </Tooltip>
          );
        })}
      </List>
      <List>
        {props.setHelpModalOpen !== undefined && (
          <ListItem
            onClick={() => {
              if (props.setHelpModalOpen !== undefined) {
                props.setHelpModalOpen(true);
              }
              if (props.closeSidebar !== undefined) {
                props.closeSidebar();
              }
            }}
            button
            className={classes.listItem}
            classes={{
              selected: classes.listItemSelected,
            }}
          >
            <div className={classes.listItemIcon}>
              <HelpOutline />
            </div>
            Help
          </ListItem>
        )}
        <ListItem
          onClick={() => {
            return firebaseSignOut();
          }}
          button
          className={classes.listItem}
          classes={{
            selected: classes.listItemSelected,
          }}
        >
          <div className={classes.listItemIcon}>
            <PowerSettingsNewOutlined />
          </div>
          Logout
        </ListItem>
      </List>
    </div>
  );

  function isListItemDisabled(item: MenuItem): boolean {
    if (allowAccessToBudgetStep === false) {
      return false;
    }

    return (
      !submittedInstallationPlans &&
      !item.onboardTwoSlug?.includes("my-yard") &&
      !item.onboardTwoSlug?.includes("budget")
    );
  }

  function hideListItem(item: MenuItem): boolean {
    if (item.hideForPkg && clientProfile) {
      if (Array.isArray(item.hideForPkg)) {
        return item.hideForPkg.some(val => val === clientProfile?.package);
      } else {
        return item.hideForPkg === clientProfile?.package;
      }
    }

    return false;
  }

  function handleListItemClick(item: MenuItem, closeSidebar = () => {}) {
    const isClientDashboardLink = !!item.onboardTwoSlug;
    const isAlreadyOnRoute =
      isClientDashboardLink &&
      window.location.href.includes(item.onboardTwoSlug as string);

    segment.trackClicked({
      click_type: SegmentClickTypes.NAV,
      button_name: `Onboard Sidebar`,
      button_content: item.label,
      flow_name: SegmentFlows.ONBOARD,
    });

    if (isAlreadyOnRoute) {
      closeSidebar();
      return;
    }

    if (!!item.onboardTwoSlug) {
      history.push(`${url}/${item.onboardTwoSlug}`);
      closeSidebar();
    } else {
      smartRedirect("v1", `project/${item.slug}`);
    }
  }
};

export { OnboardStepMenu };
export default OnboardStepMenu;
