import { Box, FormControlLabel, Checkbox, Tooltip } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import { useDispatch } from "react-redux";
import { useDataLayer } from "../../data";
import { useAppSelector } from "../../hooks";
import { setBillingAndPropertyAddressSame } from "../../slices/checkoutSlice";
import {
  SegmentFlows,
  SegmentForms,
  SegmentInputTypes,
  useSegment,
} from "../../util/Segment";

export const AddressCheckBox = () => {
  const segment = useSegment();
  const dataLayer = useDataLayer();
  const dispatch = useDispatch();

  const segmentFlow = window.location.href.includes(
    "purchase-additional-revision"
  )
    ? SegmentFlows.PURCHASE_ADDITIONAL_REVISION
    : SegmentFlows.CHECKOUT;

  const { billingAndPropertyAddressSame } = useAppSelector(
    state => state.checkout
  );
  return (
    <div>
      {billingAndPropertyAddressSame !== null && (
        <Box mt={1} mb={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={billingAndPropertyAddressSame}
                onChange={e => {
                  try {
                    segment.trackFormFieldFilled({
                      flow_name: segmentFlow,
                      field_name: "Use property address for billing",
                      form_name: SegmentForms.CHECKOUT_BILLING_DETAILS,
                      input_type: SegmentInputTypes.CHECKBOX,
                      option_selected: e.target.checked.toString(),
                    });
                    dataLayer.recordTrackingEvent(
                      "toggle_billing_same_as_property_address",
                      {
                        category: "Button Click",
                        action:
                          "Toggle billing address same as property address",
                        label: window.location.href,
                        value: e.target.checked,
                      }
                    );
                  } catch (err) {
                    window.newrelic.noticeError(err);
                    console.error(err);
                  }

                  dispatch(
                    setBillingAndPropertyAddressSame({
                      billingAndPropertyAddressSame: e.target.checked,
                    })
                  );
                }}
              />
            }
            label={
              <Tooltip title="If selected, we will use your property address as the billing address for the project">
                <YZTypography>Use property address for billing</YZTypography>
              </Tooltip>
            }
          />
        </Box>
      )}
    </div>
  );
};
