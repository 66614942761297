import * as React from "react";
import {
  MobileStepper,
  Button,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import UploadInstructionTemplate from "./UploadInstructionTemplate";
import {} from "@material-ui/icons";
import { SquareModalBase } from "../modals";
import { useTheme } from "@material-ui/styles";
import Arrow from "../../images/arrow.svg";
import { InstructionStep } from "./InstructionContainer";
import useSetTitle from "../../util/hooks/useSetTitle";

interface UploadInstructionSequenceProps {
  open: boolean;
  onClose: () => void;
  instructionSteps: InstructionStep[];
  specificIndex?: number;
  noStepper?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: "461px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      minHeight: "432px",
      maxWidth: "max-content",
      width: "100%",
      padding: 0,
      margin: "auto",
    },
  },
  content: {
    margin: "auto",
    maxWidth: "100%",
    minWidth: "75%",
    minHeight: "100%",
  },
  activeDot: {
    color: "black",
    backgroundColor: "black",
  },
}));

const UploadInstructionSequence: React.FunctionComponent<UploadInstructionSequenceProps> = props => {
  useSetTitle("Yardzen - Upload Instructions", true, []);

  const [index, setIndex] = React.useState<number>(0);
  const classes = useStyles();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const theme = useTheme<Theme>();

  const currentStep = props.instructionSteps[index];

  React.useEffect(() => {
    if (props.specificIndex !== undefined) {
      setIndex(props.specificIndex);
    }
  }, [props.specificIndex]);

  return (
    <SquareModalBase
      contentContainerProps={{
        minWidth: smDown ? "100vw" : "700px",
        margin: "auto",
      }}
      onClose={props.onClose}
      open={props.open}
      closeable
      onboardSidebar
    >
      <div className={classes.root}>
        <div className={classes.content}>
          <UploadInstructionTemplate
            title={currentStep.title}
            subtitle={currentStep.subtitle}
            imgURL={currentStep.imgUrl}
          />
        </div>

        {!props.noStepper && (
          <MobileStepper
            variant="dots"
            steps={props.instructionSteps.length}
            position="static"
            color="primary"
            style={{ backgroundColor: "#00000000" }}
            activeStep={index}
            classes={{
              dotActive: classes.activeDot,
            }}
            nextButton={
              <Button
                size="small"
                onClick={() => {
                  const len = props.instructionSteps.length;
                  if (index < len - 1) {
                    return setIndex(index + 1);
                  }

                  props.onClose();
                }}
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {index === props.instructionSteps.length - 1 ? (
                  "Let's go!"
                ) : (
                  <img
                    src={Arrow}
                    alt="arrow"
                    style={{
                      height: theme.spacing(4),
                      width: theme.spacing(4),
                      pointerEvents: "all",
                      color: "black",
                      cursor: "pointer",
                    }}
                  />
                )}
              </Button>
            }
            backButton={
              !!index ? (
                <Button
                  size="small"
                  onClick={() => setIndex(index - 1)}
                  disabled={index === 0}
                >
                  <img
                    alt="arrow"
                    src={Arrow}
                    style={{
                      transform: "rotate(180deg)",
                      height: theme.spacing(4),
                      width: theme.spacing(4),
                      pointerEvents: "all",
                      color: "black",
                      cursor: "pointer",
                    }}
                  />
                </Button>
              ) : (
                <Button
                  size="small"
                  onClick={() => setIndex(index - 1)}
                  disabled
                >
                  <div
                    style={{
                      display: "inline-block",
                      height: theme.spacing(4),
                      width: theme.spacing(4),
                    }}
                  ></div>
                </Button>
              )
            }
          />
        )}
      </div>
    </SquareModalBase>
  );
};

export default UploadInstructionSequence;
