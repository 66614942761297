import firebase from "firebase/compat/app";

import { BANYAN_BASE_URL } from "../api/banyanV1API";
import { GET_ACCEPTED_USER_TERMS_OF_SERVICE } from "../api/graphqlQueries";

const REACT_APP_BANYAN_GRAPHQL_ENDPOINT = `${BANYAN_BASE_URL}graphql`;

export async function mustAcceptNewTermsOfService(): Promise<boolean> {
  const user = firebase?.auth?.()?.currentUser;

  if (!user) {
    return false;
  }

  const idToken = await user.getIdToken();
  const profile = await firebase
    .firestore()
    .collection("profiles")
    .doc(user.uid)
    .get();

  if (!profile.exists) {
    //if the user does not have a profile, they don't have a design account yet
    return false;
  }

  const res = await fetch(REACT_APP_BANYAN_GRAPHQL_ENDPOINT, {
    method: "POST",
    body: JSON.stringify({
      query: GET_ACCEPTED_USER_TERMS_OF_SERVICE,
      variables: { input: { userId: user.uid } },
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
  });

  const { data, errors } = await res.json();

  if (errors?.length) {
    console.error(errors?.[0]?.message);
  }

  return data.acceptedCurrentTermsOfService.length === 0;
}
