export function getLoginPageUrl() {
  let origin: string;
  let scheme: string;
  switch (window.location.hostname) {
    case "dashboard.dogfood.yardzen.com":
      origin = "staging.project.yardzen.com";
      scheme = "https";
      break;
    case "localhost":
      scheme = "http";
      origin = "localhost:4200";
      break;
    default:
      scheme = "https";
      origin = "project.yardzen.com";
  }

  return new URL(`${scheme}://${origin}/login`);
}
