import { useEffect, useMemo } from "react";

const useSetTitle = (
  title: string,
  resetOnUnmount: boolean,
  deps: any[]
): void => {
  const originalTitle = useMemo(() => document.title, []);

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setTitle, deps);

  function setTitle(): void | (() => void) {
    document.title = title;

    if (resetOnUnmount) {
      return () => (document.title = originalTitle);
    }
  }
};

export { useSetTitle };
export default useSetTitle;
