import React, { useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { YZButton } from "@yardzen-inc/react-common";
import { useDesignProfileCtx } from "../designProfile";
import { Box, CircularProgress } from "@material-ui/core";
import { setRestrictChanges } from "../../slices/checkoutSlice";
import { AppDispatch } from "../../store";
import { useAppSelector } from "../../hooks";
import { PriceObject } from "../../types/Prices";
import { handleStripeError } from "./util/handleStripeError";
import { handleConfirmedOrder } from "./util/handleConfirmedOrder";
import { confirmPayment } from "../../services/confirmPayment";
import { DataLayer } from "@yardzen-inc/data-layer";
import { IAddressObject } from "../../util/functions/parseGeocodeLocationToAddress";
import { DiscountType } from "../../services/promotionalCodes";

interface FormArgs {
  isAdditionalRevision: boolean;
  dispatch: AppDispatch;
  setError: (err: string) => void;
  isPremium: boolean;
  premiumSku: PriceObject;
  modifiedPrice: number | null;
  dataLayer: DataLayer;
  nextStep: () => void;
  billingAddress: IAddressObject;
  email: string | null;
  addOnSkus: PriceObject[];
}

export const StripePaymentForm = ({
  isAdditionalRevision,
  setError,
  dispatch,
  isPremium,
  premiumSku,
  modifiedPrice,
  dataLayer,
  nextStep,
  billingAddress,
  email,
  addOnSkus,
}: FormArgs) => {
  const elements = useElements();
  const stripe = useStripe();
  const dsContext = useDesignProfileCtx();
  const { firstName, lastName, promotionalCode } = useAppSelector(
    state => state.checkout
  );
  const { selectedProduct, selectedSku } = useAppSelector(
    state => state.products
  );
  const { paymentIntentId } = useAppSelector(state => state.payment);
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<string>("card");

  if (elements) {
    elements.getElement("payment")?.on("change", ev => {
      setPaymentMethod(ev.value.type);
    });
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements || !selectedProduct || !selectedSku) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    try {
      setLoading(true);
      // Build items in purchase
      const discount =
        promotionalCode?.discountType === DiscountType.FIXED
          ? parseFloat(promotionalCode?.discount)
          : 0;
      const discountedPrice = (selectedSku.price - discount) / 100;

      const items = [
        {
          item_id: isPremium ? premiumSku.id : selectedSku.id,
          item_name: selectedProduct.name as string,
          item_category: "package",
          coupon: promotionalCode?.code,
          discount:
            promotionalCode?.discountType === DiscountType.FIXED
              ? discount / 100
              : undefined,
          index: 0,
          price: discountedPrice,
          quantity: 1,
        },
      ];

      addOnSkus.forEach((addOn, i) => {
        items.push({
          item_id: addOn.id,
          item_name: addOn.attributes.name,
          item_category: "add-on",
          index: i + 1,
          price: addOn.price / 100,
          quantity: 1,
          coupon: undefined,
          discount: undefined,
        });
      });

      // push add_payment_info event
      const paymentInfoEvent = {
        event: "add_payment_info",
        ecommerce: {
          currency: "USD",
          value: modifiedPrice,
          coupon: promotionalCode?.code,
          payment_type: paymentMethod,
          items,
        },
      };
      dataLayer.pushEvent(paymentInfoEvent);

      const paymentConfirmed = await confirmPayment({
        stripe,
        skuId: selectedSku.id,
        productId: selectedProduct.id,
        isPremium,
        modifiedPrice,
        elements,
        premiumSkuId: premiumSku.id,
        billingAddress,
        name: `${firstName} ${lastName}`,
        email: email || "",
      });
      if (paymentConfirmed.error) {
        handleStripeError({ error: paymentConfirmed.error, setError });
        setLoading(false);
      } else {
        // push purchase event
        const purchaseEvent = {
          event: "purchase",
          ecommerce: {
            currency: "USD",
            transaction_id: paymentConfirmed.paymentIntent.id,
            value: paymentConfirmed.paymentIntent.amount / 100,
            coupon: promotionalCode?.code,
            payment_type: paymentMethod,
            items,
          },
        };
        dataLayer.pushEvent(purchaseEvent);

        handleConfirmedOrder({
          paymentIntentId,
          dispatch,
          isAdditionalRevision,
          dsContext,
          firstName,
          lastName,
          selectedProduct,
          selectedSku,
          isPremium,
          dataLayer,
          premiumSku,
          nextStep,
        });
      }
    } catch (err) {
      console.error(`Error processing order: `, err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement
        options={{
          defaultValues: {
            billingDetails: {
              address: {
                postal_code:
                  billingAddress.zip ||
                  dsContext.designProfile?.contactInformation?.zip,
              },
            },
          },
        }}
      />
      <div style={{ marginTop: 20 }}>
        {loading ? (
          <Box
            alignItems="center"
            justifyContent="center"
            width="100%"
            display="flex"
          >
            <CircularProgress />
          </Box>
        ) : (
          <YZButton
            disabled={loading}
            id="confirm-purchase-YZ-Button"
            onClick={() => {
              dispatch(setRestrictChanges({ restrictChanges: true }));
            }}
            type="submit"
            color="primary"
            fullWidth
          >
            CONFIRM ORDER
          </YZButton>
        )}
      </div>
    </form>
  );
};
