import * as React from "react";
import { SquareModalBase } from "../modals";
import {
  Typography,
  Divider,
  DialogActions,
  makeStyles,
  Theme,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { YZButton } from "../buttons";

interface ExistingAccountModalProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  title: {},
  body: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ExistingAccountModal: React.FC<ExistingAccountModalProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  return (
    <SquareModalBase open={open} onClose={onClose}>
      <>
        <DialogTitle>Thanks for coming back!</DialogTitle>
        <Divider />
        <DialogContent>
          <Typography className={classes.body}>
            Unfortunately, we're not able to support two projects under the same
            email right now, but we're working on it! Please use a different
            email to purchase a new package.
          </Typography>
        </DialogContent>
        <DialogActions>
          <YZButton onClick={() => onClose()}>Okay, log me out</YZButton>
        </DialogActions>
      </>
    </SquareModalBase>
  );
};

export { ExistingAccountModal };
export default ExistingAccountModal;
