import React, { FC } from "react";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { useHistory } from "react-router";
import { YZTypography } from "@yardzen-inc/react-common";

export interface BudgetChecklistSidebarItemProps {
  itemName: string;
  linkTo?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  bullet: {
    width: 6,
    height: 6,
    borderRadius: 6,
    border: `2px solid ${theme.palette.primary.main}`,
    display: "block",
    marginRight: theme.spacing(2),
  },
}));

const BudgetChecklistSidebarItem: FC<BudgetChecklistSidebarItemProps> = ({
  itemName,
  linkTo,
}) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Box
      display="flex"
      pb={1}
      alignItems="center"
      onClick={
        linkTo
          ? () => {
              history.push(linkTo);
            }
          : void 0
      }
    >
      <span className={classes.bullet} />
      <YZTypography
        style={{
          pointerEvents: "all",
          cursor: linkTo ? "pointer" : void 0,
        }}
      >
        {itemName}
      </YZTypography>
    </Box>
  );
};

export { BudgetChecklistSidebarItem };
export default BudgetChecklistSidebarItem;
