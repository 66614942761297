import { useEffect, useRef, useMemo } from "react";

interface UseScrollTop {
  (skipInitial: boolean, deps: any[]): void;
  (deps: any[]): void;
}

(document.querySelector("html") as HTMLHtmlElement).style.scrollBehavior =
  "smooth";

const useScrollTop: UseScrollTop = (...args: any[]) => {
  const skipInitial = useMemo(() => typeof args[0] === "boolean" && args[0], [
    args,
  ]);

  const initialScrollRef = useRef<boolean>(!skipInitial);

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(scrollToTop, args[1] ?? args[0]);

  function scrollToTop(): void {
    if (initialScrollRef.current) {
      return window.scrollTo({ top: 0 });
    }

    initialScrollRef.current = true;
  }
};

export { useScrollTop };
export default useScrollTop;
