import React from "react";
import { IMaskInput } from "react-imask";
import { Box, Input, makeStyles, TextField, Theme } from "@material-ui/core";
import { YZTypography } from "@yardzen-inc/react-common";
import {
  SegmentFlows,
  SegmentForms,
  SegmentInputTypes,
  useSegment,
} from "../../util/Segment";

interface DesignProfileInputProps {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setFocused: (focused: boolean) => void;
  handleKeyPress: (e: React.KeyboardEvent) => void;
  segmentFields: {
    flowName: SegmentFlows;
    formName: SegmentForms;
    fieldName: string;
    inputType: SegmentInputTypes;
  };
  title: string;
  value: string;
  label: string;
  helperText: string;
  errorText: string;
  isPhoneInput?: boolean;
  titleTextAlign?: "center" | "left" | "right";
}

export interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "500px",
    margin: "0 auto",
  },
  formContainer: {
    maxWidth: "400px",
    margin: "0 auto",
  },
  linkText: {
    cursor: "pointer",
    color: "#000",
    textDecoration: "underline",
    fontWeight: 500,
  },
}));

export const DesignProfileInput = ({
  handleChange,
  handleKeyPress,
  title,
  value,
  label,
  segmentFields,
  helperText,
  errorText,
  isPhoneInput,
  setFocused,
  titleTextAlign = "center",
}: DesignProfileInputProps) => {
  const segment = useSegment();
  const classes = useStyles();
  const { inputType, flowName, formName, fieldName } = segmentFields;

  const renderTitle = () => {
    if (title) {
      return (
        <Box style={{ paddingBottom: 15 }} pt={2}>
          <YZTypography align={titleTextAlign} color="textSecondary">
            {title}
          </YZTypography>
        </Box>
      );
    }

    return null;
  };

  const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props, ref) {
      const { onChange, ...other } = props;
      return (
        <IMaskInput
          {...other}
          mask="(#00) 000-0000"
          definitions={{
            // @ts-ignore
            "#": /[1-9]/,
          }}
          // @ts-ignore
          inputRef={ref}
          onAccept={(value: any) =>
            onChange({ target: { name: props.name, value } })
          }
          overwrite
        />
      );
    }
  );

  const renderInput = () => {
    return isPhoneInput ? (
      <Input
        value={value}
        onChange={handleChange}
        name="textmask"
        id="formatted-text-mask-input"
        inputComponent={TextMaskCustom as any}
        color="primary"
        onFocus={() => setFocused(true)}
        onBlur={() => {
          segment.trackFormFieldFilled({
            flow_name: flowName,
            form_name: formName,
            field_name: fieldName,
            input_type: inputType,
          });
          setFocused(false);
        }}
        inputMode="email"
        fullWidth
        inputProps={{
          type: "email",
          id: "email-input",
          style: { backgroundColor: "white" },
        }}
        error={!!errorText}
        onKeyPress={handleKeyPress}
      />
    ) : (
      <TextField
        color="primary"
        label={label}
        variant="outlined"
        onFocus={() => setFocused(true)}
        onBlur={() => {
          segment.trackFormFieldFilled({
            flow_name: flowName,
            form_name: formName,
            field_name: fieldName,
            input_type: inputType,
          });
          setFocused(false);
        }}
        inputMode="email"
        fullWidth
        inputProps={{
          type: "email",
          id: "email-input",
          style: { backgroundColor: "white" },
        }}
        error={!!errorText}
        helperText={errorText || helperText}
        onKeyPress={handleKeyPress}
        value={value}
        onChange={handleChange}
      />
    );
  };

  return (
    <Box className={classes.formContainer}>
      {renderTitle()}
      {renderInput()}
    </Box>
  );
};
