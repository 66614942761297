import { IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { YZButton } from "../../buttons";
import { YardName } from "../stepData";

interface DisabledYardChipProps {
  yard: YardName;
  locked: boolean;
  onClick: (yard: YardName) => void;
}

const useStyles = makeStyles(() => ({
  btn: {
    width: "100%",
    background: "#fff",
    borderBottom: "1px solid #f2f2f2",
    boxShadow: "0 5px 15px rgba(0,0,0,.05)",
    borderRadius: 4,
    textAlign: "left",
    padding: 10,
    marginBottom: 12,
  },
}));

const DisabledYardChip: React.FunctionComponent<DisabledYardChipProps> = props => {
  const classes = useStyles();
  return (
    <YZButton onClick={handleClick} color="primary" className={classes.btn}>
      <IconButton size="small" color="primary">
        <Add style={{ fontSize: 18, marginRight: 8, marginBottom: 2 }} />
      </IconButton>
      Add {props.yard} yard
    </YZButton>
  );

  function handleClick() {
    props.onClick(props.yard);
  }
};

export default DisabledYardChip;
