import {
  ACREAGE_LOT,
  LARGE_LOT,
  LotSizeTypes,
  SMALL_LOT,
  STANDARD_LOT,
} from "../../../util/constants/lotSizeTypes";
import {
  ACREAGE_YARD,
  LARGE_YARD,
  SMALL_YARD,
  YardSizeTypes,
} from "../../../util/constants/yardSizeTypes";

export const getLotSize = (yardSize: YardSizeTypes): LotSizeTypes => {
  let lotSize: LotSizeTypes = STANDARD_LOT;
  if (yardSize === LARGE_YARD) {
    lotSize = LARGE_LOT;
  } else if (yardSize === ACREAGE_YARD) {
    lotSize = ACREAGE_LOT;
  } else if (yardSize === SMALL_YARD) {
    lotSize = SMALL_LOT;
  }

  return lotSize;
};
